import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import calanderIcon from "../../assets/img/group-11.png";
import DatePickerStyled from './DatePicker.style';
import { IconButton, InputAdornment } from "@material-ui/core"

export const DatePicker = (props) =>{ 
  return  (
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <DatePickerStyled
      margin='normal'
      id={props.id}
      bgcolor={props.bgcolor}
      format="dd MMM yyyy"
      value={props.selectedDate}
      onChange={e => {
        props.onDateChange(e);
      }}
      allowKeyboardControl={false}
      leftArrowButtonProps={{ "aria-label": "Prev month" }}
      rightArrowButtonProps={{ "aria-label": "Next month" }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton classes={{ root: props.adornment }}>
              <img src={calanderIcon} />
            </IconButton>
          </InputAdornment>
        ),

        disableUnderline: true,
        classes: {
          input: props.inputClasses
        }
      }}
      // keyboardIcon = {<img src={calanderIcon} />}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      {...props}
    />
  </MuiPickersUtilsProvider>
)
    }

  DatePicker.defaultProps = {
    classes: {}
  };
  
  DatePicker.propTypes = {
    classes: propTypes.object,
  };
  