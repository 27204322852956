const ticketFAQ = [
    { issue: "I cannot remember my username and/or password and cannot log in. Help!", answer: "Please submit a ticket by filling out the form in the Create Ticket tab and an email will be sent to you shortly with further instructions. Also, how are you viewing this page?" },
    { issue: "How do I???", answer: "Please submit a ticket by filling out the form in the Create Ticket tab and an email will be sent to you shortly with further instructions. "}
  ];

  const issueAndRequestOptions = [
    { value: "Password Issues", label: "Password Issues" },
    { value: "Feature Request", label: "Feature Request" },
    { value: "Other", label: "Other" }
  ];

  export { ticketFAQ, issueAndRequestOptions}