import React from "react";
import { T as Table } from "./table.styled";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import { get } from 'lodash';
function TableComponent(props) {
  const {
    payloadData,
    searchValue,
    tableData,
    tableHeader,
    getDataList,
    organizationPage,
    handleDetails,
    isPaginationNormal,
    isHistory,
    isAppUser,
    subtype,
    mobileStatusFilter,
    sortHeader
  } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [sort, setSort] = React.useState(true);
  const [sortAppuser, setSortAppuser] = React.useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };
  const sortByAppuser = () => {
    setSortAppuser(!sortAppuser);
  };
  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setOffset(0);
  };

  React.useEffect(() => {
    let payload = {};
    if (isAppUser) {
      payload = {
        id: payloadData.id,
        offset,
        rowsPerPage,
        search: searchValue,
        subtype: subtype,
        mobileStatusFilter: mobileStatusFilter,
        sortAppuser
      };
    } else {
      payload = {
        offset,
        rowsPerPage,
        search: searchValue,
        sort
      };
    }

    getDataList(payload);
  }, [offset, rowsPerPage, searchValue, payloadData, subtype, mobileStatusFilter]);
  React.useEffect(() => {
    setPage(0);
    setOffset(0);
    setRowsPerPage(5);
  }, [searchValue, subtype, mobileStatusFilter]);
  React.useEffect(() => {
    let payload = {
      offset,
      rowsPerPage,
      search: searchValue,
      sort
    };
    getDataList(payload);
  }, [sort]);
  React.useEffect(() => {
    if (isAppUser) {
      let payload = {
        id: payloadData.id,
        offset,
        rowsPerPage,
        search: searchValue,
        subtype: subtype,
        mobileStatusFilter: mobileStatusFilter,
        sortAppuser
      };
      getDataList(payload);
    }
  }, [isAppUser, payloadData, sortAppuser]);
  const sortBy = () => {
    setSort(!sort);
  };
  return (
    <Table>
      <div className="wrapper-long">
        <table
          className="table"
          style={{
            marginBottom: "0",
            position: "sticky",
            top: "0",
            zIndex: "1",
            borderRadius: "8px",
            overflow: "hidden"
          }}>
          <tr className="row-head header green">
            {tableHeader &&
              tableHeader.map((item, index) => {
                if (item === sortHeader) {
                  return (
                    <th key={index} className="cellHead">
                      {item}
                      {!isAppUser ? (
                        <IconButton
                          className="tableButton"
                          aria-label="sort"
                          onClick={sortBy}>
                          {sort ? (
                            <ArrowUpward style={{ color: "aliceblue" }} />
                          ) : (
                            <ArrowDownward style={{ color: "aliceblue" }} />
                          )}
                        </IconButton>
                      ) : (
                        <IconButton
                          className="tableButton"
                          aria-label="sort"
                          onClick={sortByAppuser}>
                          {sortAppuser ? (
                            <ArrowUpward style={{ color: "aliceblue" }} />
                          ) : (
                            <ArrowDownward style={{ color: "aliceblue" }} />
                          )}
                        </IconButton>
                      )}
                    </th>
                  );
                } else {
                  return (
                    <th key={index} className="cellHead">
                      {item}
                    </th>
                  );
                }
              })}
          </tr>
        </table>
        <table className="table">
          {!isAppUser &&
            tableData &&
            tableData.length > 0 &&
            tableData.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tbody>
                    <tr
                      className="row"
                      onClick={() => {
                        handleDetails(item);
                      }}>
                      <td className="cell-long">{item.organization_id}</td>
                      <td className="cell-long">{item.name}</td>
                      <td className="cell-long">{item.number_of_accounts}</td>
                      <td className="cell-long">
                        {item.created_at
                          ? moment(item.activated_at)
                              .local()
                              .format("DD MMM YYYY,HH:mm:ss")
                          : "Unavailable"}
                      </td>
                    </tr>
                  </tbody>
                </React.Fragment>
              );
            })}
          {isAppUser &&
            tableData !== undefined &&
            tableData.length > 0 &&
            tableData.map((row, index) => {
              return (
                <React.Fragment key={index}>
                  <tbody>
                    <tr
                      key={index}
                      className="row"
                      onClick={() => {
                        handleDetails(row);
                      }}>
                      <td className="cell-long cell-yellow">{row.username}</td>
                      <td
                        style={{
                          color:
                            row.status !== "online" && row.status !== "offline"
                              ? "#ff9500"
                              : row.status === "offline"
                              ? "#be1e15"
                              : "#30bc48"
                        }}
                        className="cell-long">
                        {row.status}
                      </td>
                      <td className="cell-long">{row.connected_to || "not_setup"}</td>
                      <td className="cell-long">
                        {row.last_location &&
                        row.last_location.lat &&
                        row.last_location.lon
                          ? `lat:${row.last_location.lat} , lng:${row.last_location.lon}`
                          : "Unavailable"}
                      </td>
                      <td className="cell-long">
                        {row.get_recent_activity_time
                          ? moment(row.get_recent_activity_time)
                              .local()
                              .format("DD MMM YYYY,HH:mm:ss")
                          : "Unavailable"}
                      </td>
                    </tr>
                  </tbody>
                </React.Fragment>
              );
            })}

          {tableData && tableData.length === 0 && (
            <React.Fragment>
              <tbody>
                <tr className="cell-long-line">No Record Found</tr>{" "}
              </tbody>
            </React.Fragment>
          )}
        </table>
        {
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={
              organizationPage && organizationPage.count
                ? organizationPage.count
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }

        <div></div>
      </div>
    </Table>
  );
}

export default TableComponent;
