import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  Paper,
  MenuList,
  MenuItem,
  CircularProgress
} from "@material-ui/core";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import get from "lodash/get";
import TimeLine from "../../components/TimeLine";
import ArrowLeft from "../../assets/img/arrowLeft.png";
import batery from "../../assets/img/baterry.png";
import minimizeIcon from "../../assets/img/group-5.png";
import maximizeIcon from "../../assets/img/maximize.png";
import refreshIcon from "../../assets/img/refresh.png";
import { generateChartData } from "../../data/dataConstants";
import {
  useStyles,
  MinMaxIcon,
  SwitchToGeoSpatialButton,
  Graphpicker
} from "./userprofile.style";
import DialogActivityIndicator from "../../components/DialogActivityIndicator";
import DateTimePicker from "../../components/DateTimePicker";
import TrailMap from "../../components/TrailMap";
import AddToWatch from "../../components/AddToWatchList";
import DialogSlideExport from "../../components/DialogExport";
import { convertToDMS } from "../../utils/DMS";
import LineChart from "../../components/Charts/Line";
import DialogTable from "../../components/DialogTable";
import DetailComponents from "../../components/DetailsComponents";
import compareValues from "../../utils/sortCompare";
import { validateFromAndToDate } from "../../utils/dateTime";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import BackToPrevious from "../../components/BackToPrevious";

// Needs to be refactored
function UserProfile(props) {
  const classes = useStyles();
  const refWifi = useRef();
  const { enqueueSnackbar } = useSnackbar();
  const paramid = props.match.params.id;
  const anchorRef = useRef(null);
  const [shrink, setShrink] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const handleCloseDialog = value => {
    setOpenDialog(false);
  };
  const [openCallLog, setCallLog] = useState(false);
  const handleCloseDialogCallLog = value => {
    setCallLog(false);
  };
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const anchorRefMoreActions = useRef(null);
  const [openMore, setOpenMore] = useState(false);
  const handleToggleMore = () => {
    setOpenMore(prevOpen => !prevOpen);
  };
  const handleCloseMore = event => {
    if (
      anchorRefMoreActions.current &&
      anchorRefMoreActions.current.contains(event.target)
    ) {
      return;
    }
    setOpenMore(false);
  };
  function handleListKeyDownMore(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMore(false);
    }
  }
  useEffect(() => {
    if (prevOpen.current === true && openMore === false) {
      anchorRefMoreActions.current.focus();
    }
    prevOpen.current = openMore;
  }, [openMore]);
  const [openWatchList, setOpenWatchList] = useState(false);

  const handleClickOpenWatchList = () => {
    setOpenWatchList(true);
  };
  const handleDialogClose = () => {
    setChecked(false);
  };
  const handleCloseWatchList = () => {
    setOpenWatchList(false);
  };
  const [selectedDateFrom, setDateChangeFrom] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [selectedDateFromChart, setDateChangeFromChart] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [selectedDateToChart, setDateChangeToChart] = useState(new Date());
  const [selectedDateFromMap, setDateChangeFromMap] = useState(new Date());
  const [selectedDateToMap, setDateChangeToMap] = useState(new Date());
  const [selectedDateFromMapSegment, setDateChangeFromMapSegment] = useState(
    null
  );
  const [selectedDateToMapSegment, setDateChangeToMapSegment] = useState(null);
  const [hasSubtrailChange, setHasSubtrailChange] = useState(false);
  const [checked, setChecked] = useState(false);
  const [chartdata, setChartData] = useState({});
  const [chartItem, setChartItem] = useState("speed");
  const [timeLineData, setTimeLineData] = useState({});
  const [timeLineDataActivity, setTimeLineDataActivity] = useState([]);
  const [selectedDateTo, setDateChangeTo] = useState(new Date());
  const [userInfoDetails, setUserInfo] = useState({});
  const [locationTrails, setUserLocationTrails] = useState(props.userInfo);
  const [hasError, setHasError] = useState(false);
  const [confirmtnError, setConfirmtnError] = useState(false);
  const [errorMsg, setErrorMsg] = useState({
    mapMsg: "",
    mapSegmentMsg: "",
    graphMsg: "",
    timelineMsg: ""
  });
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [initailRender, setInitialRender] = useState(true);
  const DMS = coordinates => {
    if (coordinates !== "-") {
      let src = [coordinates.latitude, coordinates.longitude];
      const value = convertToDMS(src);
      return value;
    }
  };

  useEffect(() => {
    props.getUserInfo(paramid, { detailed: true, history: props.history });
    let payload = {
      organizations: [],
      users: [paramid],
      to_datetime: moment.utc(new Date()).toISOString(),
      search: paramid
    };
    props.dashboardSearch(payload);
    props.getCallLog({
      user_id: get(userInfoDetails, "user_info.id"),
      limit: 5
    });
    if (window.markers !== undefined && window.markers.length > 0) {
      window.markers.forEach(marker => {
        marker && marker.setMap(null);
      });
      window.markers = [];
    }
    if (window.paths !== undefined && window.paths.length > 0) {
      window.paths.forEach(path => {
        path.setMap(null);
      });
    }
    props.timeLineTrailClear();
  }, []);

  useEffect(() => {
    if (paramid !== undefined) {
      let payload = {
        organizations: [],
        users: [paramid],
        to_datetime: moment.utc(new Date()).toISOString(),
        search: ""
      };
      props.dashboardSearch(payload);
    }
  }, [paramid]);
  // useEffect(() => {
  //   if (refWifi.current) {
  //     refWifi.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end"
  //     });
  //   }
  // }, [refWifi.current]);
  // useEffect(() => {
  //   if (refWifi.current) {
  //     refWifi.current.scrollIntoView({
  //       behavior: "smooth",
  //       block: "end"
  //     });
  //   }
  // }, [refWifi.current]);
  useEffect(() => {
    const validate = validateFromAndToDate(selectedDateFrom, selectedDateTo);
    if (validate) {
      setErrorMsg({
        ...errorMsg,
        timelineMsg: validate
      });
    } else {
      setErrorMsg("");
      let payload = {
        user_id:
          userInfoDetails &&
          userInfoDetails.user_info &&
          userInfoDetails.user_info.id &&
          userInfoDetails.user_info.id !== undefined
            ? userInfoDetails.user_info.id
            : "",
        to_date: moment.utc(selectedDateTo).toISOString(),
        from_date: moment.utc(selectedDateFrom).toISOString()
      };
      props.timeLine(payload);
    }
  }, [selectedDateFrom, selectedDateTo, userInfoDetails]);
  useEffect(() => {
    const validate = validateFromAndToDate(
      selectedDateFromChart,
      selectedDateToChart
    );
    if (validate) {
      setErrorMsg({
        ...errorMsg,
        graphMsg: validate
      });
    } else {
      setErrorMsg({
        ...errorMsg,
        graphMsg: ""
      });
      let payload = {
        user_id:
          userInfoDetails &&
          userInfoDetails.user_info &&
          userInfoDetails.user_info.id &&
          userInfoDetails.user_info.id !== undefined
            ? userInfoDetails.user_info.id
            : "",
        to_date: moment.utc(selectedDateToChart).toISOString(),
        from_date: moment.utc(selectedDateFromChart).toISOString()
      };
      props.chartFilter(payload, chartItem);
    }
  }, [selectedDateFromChart, selectedDateToChart, userInfoDetails]);
  useEffect(() => {
    if (window.markers !== undefined && window.markers.length > 0) {
      window.markers.forEach(marker => {
        marker && marker.setMap(null);
      });
      window.markers = [];
    }
    if (window.paths !== undefined && window.paths.length > 0) {
      window.paths.forEach(path => {
        path.setMap(null);
      });
    }
    const validateMap = validateFromAndToDate(
      selectedDateFromMap,
      selectedDateToMap
    );
    let validateMapSegment = "",
      validateSubset = "";
    if (
      hasSubtrailChange &&
      (selectedDateFromMapSegment || selectedDateToMapSegment)
    ) {
      validateMapSegment = validateFromAndToDate(
        selectedDateFromMapSegment,
        selectedDateToMapSegment
      );
      validateSubset = validateSubsetDate(
        selectedDateFromMap,
        selectedDateToMap,
        selectedDateFromMapSegment,
        selectedDateToMapSegment
      );
    }
    if (
      !initailRender &&
      (validateMap || validateMapSegment || validateSubset)
    ) {
      setHasError(true);
      setErrorMsg({
        ...errorMsg,
        mapMsg: validateMap,
        mapSegmentMsg: validateMapSegment
      });
    } else {
      setHasError(false);
      setErrorMsg({
        ...errorMsg,
        mapMsg: "",
        mapSegmentMsg: ""
      });
      if (window.markers !== undefined && window.markers.length > 0) {
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
      }
      let payload = {
        user_id:
          userInfoDetails &&
          userInfoDetails.user_info &&
          userInfoDetails.user_info.id &&
          userInfoDetails.user_info.id !== undefined
            ? userInfoDetails.user_info.id
            : "",
        to_datetime: moment.utc(selectedDateToMap).toISOString(),
        from_datetime: moment.utc(selectedDateFromMap).toISOString(),
        from_datetime_sub: selectedDateFromMapSegment
          ? moment.utc(selectedDateFromMapSegment).toISOString()
          : "",
        to_datetime_sub: selectedDateToMapSegment
          ? moment.utc(selectedDateToMapSegment).toISOString()
          : ""
      };
      props.startLocationTrail(payload);
    }
  }, [
    selectedDateFromMap,
    selectedDateToMap,
    selectedDateFromMapSegment,
    selectedDateToMapSegment
  ]);

  useEffect(() => {
    if (props.userProfileState.chartData !== undefined) {
      let dataset = generateChartData(props.userProfileState.chartData);
      setChartData(props.userProfileState.chartData);
    }
  }, [props.userProfileState.chartData]);

  useEffect(() => {
    const sorted =
      props.userProfileState &&
      props.userProfileState.timeline &&
      props.userProfileState.timeline.length > 0 &&
      props.userProfileState.timeline.sort(compareValues("timestamp", "asc"));
    setTimeLineData(sorted);
  }, [props.userProfileState.timeline]);

  useEffect(() => {
    setTimeLineDataActivity(props.userProfileState.timelineactivity);
  }, [props.userProfileState.timelineactivity]);

  useEffect(() => {
    setUserInfo(props.userInfo);
    props.getCallLog({
      user_id:
        props.userInfo &&
        props.userInfo.user_info &&
        props.userInfo.user_info.id &&
        props.userInfo.user_info.id !== undefined
          ? props.userInfo.user_info.id
          : ""
    });
    props.timeLineActivity({
      user_id:
        props.userInfo &&
        props.userInfo.user_info &&
        props.userInfo.user_info.id &&
        props.userInfo.user_info.id !== undefined
          ? props.userInfo.user_info.id
          : "",
      to_date: moment.utc(new Date()).toISOString(),
      from_date: moment
        .utc(new Date().setDate(new Date().getDate() - 1))
        .toISOString()
    });
  }, [props.userInfo]);

  useEffect(() => {
    setUserLocationTrails(props.userProfileState.locationTrail);
  }, [props.userProfileState.locationTrail]);

  const handleDateChangeFrom = e => {
    if (window.markers !== undefined && window.markers.length > 0) {
      window.markers.forEach(marker => {
        marker && marker.setMap(null);
      });
    }
    setDateChangeFrom(e);
  };

  const handleDateChangeTo = e => {
    if (window.markers !== undefined && window.markers.length > 0) {
      window.markers.forEach(marker => {
        marker && marker.setMap(null);
      });
    }
    setDateChangeTo(e);
  };

  const handleDateChangeFromChart = e => {
    setDateChangeFromChart(e);
  };

  const handleDateChangeToChart = e => {
    setDateChangeToChart(e);
  };

  const handleDateChangeFromMap = e => {
    setInitialRender(false);
    setDateChangeFromMap(e);
  };

  const handleDateChangeToMap = e => {
    setInitialRender(false);
    setDateChangeToMap(e);
  };

  const handleDateChangeFromMapSegment = e => {
    setInitialRender(false);
    setHasSubtrailChange(true);
    setDateChangeFromMapSegment(e);
  };

  const handleDateChangeToMapSegment = e => {
    setInitialRender(false);
    setHasSubtrailChange(true);
    setDateChangeToMapSegment(e);
  };

  const validateSubsetDate = (
    minDate,
    maxDate,
    selectedDateFromMapSegment,
    selectedDateToMapSegment
  ) => {
    if (
      selectedDateFromMapSegment === null ||
      selectedDateToMapSegment === null
    ) {
      return "Please enter From and To date - time for subtrail.";
    } else if (
      minDate > selectedDateFromMapSegment ||
      maxDate < selectedDateToMapSegment
    ) {
      return "Selected date range should be a subset of main date range";
    }
  };

  const confirmWatchlistRemove = () => {
    setShowConfirmation(true);
  };

  const handleRemoveUserFromWatchlist = reason => {
    if (reason) {
      setConfirmtnError(false);
      let userInfo = props.getUserInfo;
      let payload = {
        user_id:
          userInfoDetails &&
          userInfoDetails.user_info &&
          userInfoDetails.user_info.id &&
          userInfoDetails.user_info.id !== undefined
            ? userInfoDetails.user_info.id
            : "",
        reason_removed: reason
      };
      props.removefromWatchlist({
        payload,
        enqueueSnackbar,
        userInfo
      });
    } else {
      setConfirmtnError(true);
    }
  };

  const handleCloseConfirmWatchlist = () => {
    setShowConfirmation(false);
    setConfirmtnError(false);
  };

  const refreshGraph = () => {
    if (errorMsg.graphMsg === "") {
      let payload = {
        user_id:
          userInfoDetails &&
          userInfoDetails.user_info &&
          userInfoDetails.user_info.id &&
          userInfoDetails.user_info.id !== undefined
            ? userInfoDetails.user_info.id
            : "",
        to_date: moment.utc(selectedDateToChart).toISOString(),
        from_date: moment.utc(selectedDateFromChart).toISOString()
      };
      // setChartItem("speed");
      props.chartFilter(payload, chartItem);
    }
  };

  const renderLastResponseStatus = () => {
    // if userInfoDetails is empty or areyou_ok_latest is null, then return null
    if (!userInfoDetails || !userInfoDetails.areyou_ok_latest) return null;

    const { ack, user_status } = userInfoDetails.areyou_ok_latest;

    let isSafe = false;

    if (ack === "safe" && user_status === "safe") isSafe = true;

    return (
      <span className={classes.lastRespond}>
        Responded as{" "}
        <span
          className={isSafe ? classes.respondSafe : classes.respondDistress}>
          {isSafe ? "safe" : "distress"}
        </span>{" "}
        {moment(userInfoDetails.areyou_ok_latest.updated_on)
          .local()
          .fromNow()}{" "}
        at{" "}
        {moment(userInfoDetails.areyou_ok_latest.updated_on)
          .local()
          .format("h:mm:ss a")}
      </span>
    );
  };

  const handleSafetyAlert = safteyAlertType => {
    const payload = {
      data: [
        {
          user_id: get(userInfoDetails, "user_info.id"),
          device_id: get(userInfoDetails, "keyword_info.device_id")
        }
      ]
    };
    switch (safteyAlertType) {
      case "text":
        props.sendTextMessage({ payload, enqueueSnackbar });
        break;
      case "push":
        props.sendPushNotification({ payload, enqueueSnackbar });
        break;
      default:
        break;
    }
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const verifyKeywordsExistAndSendAlert = safetyAlertType => {
    if (get(userInfoDetails, "keyword_info.device_id")) {
      handleSafetyAlert(safetyAlertType);
    } else {
      enqueueSnackbar("User has not updated keywords.", { variant: "error" });
    }
  };

  return (
    <div
      className={`${classes.root} ${
        props.isUserInfoLoading ? classes.rootLoader : ""
      }`}>
      {/** hack to adjust the loader to full height */}
      <Grid container>
        <section className={classes.rootSectionBackButton}>
          <BackToPrevious destination="dashboard" />
          {get(props, "userInfo.user_info.watchlisted", false) && (
            <div className={classes.pointer} ref={refWifi}>
              <span className={classes.watchListed}>WATCHLISTED</span>
            </div>
          )}
        </section>
        {props.isUserInfoLoading ? (
          <div className={classes.loader}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <section className={classes.rootSectionMenuDrop}>
              <div className={classes.flex}>
                <div
                  className={classes.refreshIcon}
                  onClick={() => {
                    let payload = {
                      device_id:
                        get(userInfoDetails, "keyword_info.device_id") || "",
                      user_id: get(userInfoDetails, "user_info.id") || ""
                    };
                    props.forceSync(payload, enqueueSnackbar);
                    props.getUserInfo(paramid, { detailed: true });
                  }}>
                  <img className={classes.refreshIconImage} src={refreshIcon} />
                </div>

                <div className={classes.userDetailHeading}>
                  <div className={classes.userText}>
                    <span>
                      {get(userInfoDetails, "user_info.username", "")}
                    </span>
                    <span
                      className={
                        get(userInfoDetails, "user_info.online", false)
                          ? classes.onlineOval
                          : classes.offlineOval
                      }
                    />
                    {renderLastResponseStatus()}
                  </div>
                  <div className={classes.lastUpdated}>
                    Last Updated:{" "}
                    {moment(
                      get(
                        userInfoDetails,
                        "user_info.recent_update",
                        new Date()
                      )
                    )
                      .local()
                      .fromNow()}{" "}
                    at{" "}
                    {moment(
                      get(
                        userInfoDetails,
                        "user_info.recent_update",
                        new Date()
                      )
                    )
                      .local()
                      .format("h:mm:ss a")}
                    &nbsp; via&nbsp;
                    {get(userInfoDetails, "user_info.connected_via", "")}
                  </div>
                </div>
              </div>

              <div className={classes.flex}>
                <div className={classes.safeDistressFlag}>
                  <div className={classes.safeDistresswrapper}>
                    <span className={classes.safeKeyword}>
                      Safe Keyword:&nbsp;
                    </span>
                    <span className={classes.safe}>
                      {get(userInfoDetails, "keyword_info.safe_keyword", "")}{" "}
                      &nbsp;
                    </span>
                    <span className={classes.distressKeyword}>
                      Distress Keyword:&nbsp;
                    </span>
                    <span className={classes.distress}>
                      {get(
                        userInfoDetails,
                        "keyword_info.distress_keyword",
                        ""
                      )}
                    </span>
                  </div>
                </div>
                <div className={classes.safteyAlert}>
                  <div
                    className={classes.safteyAlertMenuWrapper}
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}>
                    <span className={classes.safteyAlertText}>
                      Safety Alert <KeyboardArrowDownIcon />
                    </span>
                    {/* <img src={BackIcon} className={classes.imgOpen} /> */}
                    <Popper
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      style={{ zIndex: "150" }}
                      disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom"
                          }}>
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList
                                autoFocusItem={open}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDown}>
                                <MenuItem
                                  onClick={() =>
                                    verifyKeywordsExistAndSendAlert("text")
                                  }>
                                  Send Text Message
                                </MenuItem>
                                <MenuItem
                                  onClick={() =>
                                    verifyKeywordsExistAndSendAlert("push")
                                  }>
                                  Push Notification
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>
                <div className={classes.moreActions}>
                  <div
                    className={classes.moreActionsWrapper}
                    ref={anchorRefMoreActions}
                    aria-controls={openMore ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggleMore}>
                    <span className={classes.moreActionsText}>
                      More Actions <KeyboardArrowDownIcon />
                    </span>
                    {/* <img src={BackIcon} className={classes.imgOpen} /> */}
                    <Popper
                      open={openMore}
                      anchorEl={anchorRefMoreActions.current}
                      role={undefined}
                      transition
                      style={{ zIndex: "150" }}
                      disablePortal>
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin:
                              placement === "bottom"
                                ? "center top"
                                : "center bottom",
                            zIndex: "100"
                          }}>
                          <Paper>
                            <ClickAwayListener onClickAway={handleCloseMore}>
                              <MenuList
                                autoFocusItem={openMore}
                                id="menu-list-grow"
                                onKeyDown={handleListKeyDownMore}>
                                {!get(
                                  props,
                                  "userInfo.user_info.watchlisted",
                                  false
                                ) ? (
                                  <MenuItem onClick={handleClickOpenWatchList}>
                                    Add to watchlist
                                  </MenuItem>
                                ) : (
                                  <MenuItem onClick={confirmWatchlistRemove}>
                                    Remove from watchlist
                                  </MenuItem>
                                )}
                                <MenuItem
                                  onClick={() => {
                                    setOpenDialog(true);
                                  }}>
                                  Export
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    props.history.push({
                                      pathname: "/editUser",
                                      state: {
                                        userInfo: userInfoDetails || ""
                                      }
                                    });
                                    if (
                                      anchorRefMoreActions.current &&
                                      anchorRefMoreActions.current.contains(
                                        event.target
                                      )
                                    ) {
                                      return;
                                    }
                                    setOpenMore(false);
                                  }}>
                                  Edit User
                                </MenuItem>
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                </div>
              </div>
            </section>
            <section className={shrink ? classes.shrinkON : classes.shrinkOFF}>
              {/* ----------------------------------------------------Map---------------------------------------------------------------------------- */}
              <section
                className={
                  shrink ? classes.marginTopMapShrink : classes.marginTopMap
                }>
                <div className={classes.timeLineClass}>
                  <div className={classes.widthClass}>
                    <section
                      className={`${classes.flexColumn} ${classes.parentmapComp}`}>
                      <div
                        className={
                          shrink ? classes.mapCompShrink : classes.mapCompChange
                        }>
                        <div></div>

                        <div
                          className={
                            shrink ? classes.mapSwitchShrink : classes.mapSwitch
                          }>
                          {!shrink ? (
                            <div className={classes.flex}>
                              {/* <div
                            className={classes.backToGeoSpatial}
                            onClick={() => {
                              let data = {
                                userinfo: userInfoDetails
                              };
                              props.switchData(data);
                              props.history.push({
                                pathname: "/geospatial",
                                state: {
                                  userinfo: userInfoDetails
                                }
                              });
                            }}>
                            <span className={classes.geospactialText}>
                              Switch to Geospatial Search
                            </span>
                          </div> */}
                              <div className={classes.flex}>
                                <SwitchToGeoSpatialButton
                                  onClick={() =>
                                    props.history.push({
                                      pathname: "/geospatial",
                                      state: {
                                        location: get(
                                          props.userInfo,
                                          "location_info.location"
                                        )
                                      }
                                    })
                                  }>
                                  Switch to Geospatial Search
                                </SwitchToGeoSpatialButton>
                                <div className={classes.flexColumn}>
                                  <div className={classes.flex}>
                                    <div className={classes.timelinePicker}>
                                      <DateTimePicker
                                        selectedDate={selectedDateFromMap}
                                        handleDateChange={
                                          handleDateChangeFromMap
                                        }
                                        disableFuture
                                      />
                                    </div>
                                    <div
                                      style={{
                                        margin: "11px 15px 0px 1px"
                                      }}>
                                      to
                                    </div>
                                    <div className={classes.timelinePicker}>
                                      <DateTimePicker
                                        selectedDate={selectedDateToMap}
                                        handleDateChange={handleDateChangeToMap}
                                        disableFuture
                                      />
                                    </div>
                                  </div>
                                  {errorMsg.mapMsg && (
                                    <div className={classes.errorMessage}>
                                      {errorMsg.mapMsg}
                                    </div>
                                  )}
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px"
                                    }}>
                                    <div className={classes.timelinePicker}>
                                      <DateTimePicker
                                        selectedDate={
                                          selectedDateFromMapSegment
                                        }
                                        handleDateChange={
                                          handleDateChangeFromMapSegment
                                        }
                                        minDate={selectedDateFromMap}
                                        minDateMessage={""}
                                        disableFuture
                                      />
                                    </div>
                                    <div
                                      style={{
                                        margin: " 11px 15px 0px 1px"
                                      }}>
                                      to
                                    </div>
                                    <div className={classes.timelinePicker}>
                                      <DateTimePicker
                                        selectedDate={selectedDateToMapSegment}
                                        handleDateChange={
                                          handleDateChangeToMapSegment
                                        }
                                        disableFuture
                                        minDate={selectedDateFromMapSegment}
                                        minDateMessage={""}
                                        maxDate={selectedDateToMap}
                                        maxDateMessage={""}
                                      />
                                    </div>
                                  </div>
                                  {errorMsg.mapSegmentMsg && (
                                    <div className={classes.errorMessage}>
                                      {errorMsg.mapSegmentMsg}
                                    </div>
                                  )}
                                  {hasSubtrailChange && (
                                    <div className={classes.errorMessage}>
                                      {validateSubsetDate(
                                        selectedDateFromMap,
                                        selectedDateToMap,
                                        selectedDateFromMapSegment,
                                        selectedDateToMapSegment
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column"
                            }}>
                            <div
                              className={classes.minimizeMap}
                              onClick={() => {
                                setShrink(!shrink);
                              }}>
                              <MinMaxIcon
                                icon={shrink}
                                src={shrink ? maximizeIcon : minimizeIcon}
                              />
                            </div>
                            {!shrink && (
                              <div
                                className={classes.minimizeMapActivityIndicator}
                                onClick={() => {
                                  setChecked(!checked);
                                }}>
                                <img
                                  className={classes.refreshIconImageSlider}
                                  src={ArrowLeft}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative"
                        }}>
                        <TrailMap
                          shrink={shrink}
                          locationTrails={locationTrails}
                          dataset={props.dashboardState}
                        />
                        {checked && timeLineDataActivity && (
                          <DialogActivityIndicator
                            checked={checked}
                            handelClose={handleDialogClose}
                            activity={timeLineDataActivity}
                          />
                        )}
                      </div>
                      <div className={classes.mapBaseWrapper}>
                        <div className={classes.mapBaseContent}>
                          {/* <div
                      style={{
                        flexDirection: "column",
                        margin: "19px 0px 0px 18px"
                      }}>
                      <div className={classes.panelDetailHeading}>
                        LAST LOCATION
                      </div>
                      <div className={classes.panelDetailContent}>
                        LAST LOCATION
                      </div>
                    </div> */}
                          <div className={classes.mapBaseDetail}>
                            <div className={classes.panelDetailHeading}>
                              COORDINATES
                            </div>
                            <div className={classes.panelDetailContent}>
                              {DMS(
                                get(
                                  userInfoDetails,
                                  "location_info.location",
                                  "-"
                                )
                              )}
                            </div>
                          </div>
                          <div
                            className={classes.callLog}
                            onClick={() => {
                              setCallLog(true);
                            }}>
                            <span className={classes.callLogText}>
                              CALL LOG
                            </span>
                          </div>
                        </div>
                        <div className={classes.mapBatteryWrapper}>
                          <span className={classes.mapBatteryDetail}>
                            {get(
                              userInfoDetails,
                              "cellular_info.network_operator_name"
                            ) || "Unavailable"}
                          </span>
                          <img className={classes.mapBatteryIMG} src={batery} />
                          <span className={classes.mapBatteryPercentage}>
                            {get(userInfoDetails, "battery_info.level")
                              ? `${get(userInfoDetails, "battery_info.level")}%`
                              : ""}
                          </span>
                        </div>
                      </div>
                      <DetailComponents
                        userInfo={userInfoDetails}
                        classes={classes}
                        shrink={shrink}
                      />
                    </section>
                  </div>
                </div>
              </section>
              {/* ----------------------------------------------------Map---------------------------------------------------------------------------- */}
              {/* ----------------------------------------------------Chart---------------------------------------------------------------------------- */}
              <section
                className={`${classes.marginTopClassChart} ${
                  shrink ? "" : classes.chartnew
                }`}>
                <div className={classes.timeLine}>
                  <div className={classes.widthClass}>
                    <section className={classes.flex}></section>
                    {/* Chart */}
                    <section className={classes.spaceBetween}>
                      <div className={classes.graphtab}>
                        <div className={classes.chartWrapperMenu}>
                          <div
                            className={
                              chartItem === "speed"
                                ? classes.toggleChartMenuSelected
                                : classes.toggleChartMenu
                            }
                            onClick={() => {
                              const payload = {
                                user_id: get(
                                  userInfoDetails,
                                  "user_info.id",
                                  ""
                                ),
                                to_date: moment
                                  .utc(selectedDateToChart)
                                  .toISOString(),
                                from_date: moment
                                  .utc(selectedDateFromChart)
                                  .toISOString()
                              };
                              setChartItem("speed");
                              !errorMsg.graphMsg &&
                                props.chartFilter(payload, "speed");
                            }}>
                            <span style={{ margin: "3px 0px 0px 26px" }}>
                              Speed
                            </span>
                          </div>
                          <div
                            className={
                              chartItem === "elevation"
                                ? classes.toggleChartMenuSelected
                                : classes.toggleChartMenu
                            }
                            onClick={() => {
                              const payload = {
                                user_id: get(
                                  userInfoDetails,
                                  "user_info.id",
                                  ""
                                ),
                                to_date: moment
                                  .utc(selectedDateToChart)
                                  .toISOString(),
                                from_date: moment
                                  .utc(selectedDateFromChart)
                                  .toISOString()
                              };
                              setChartItem("elevation");
                              !errorMsg.graphMsg &&
                                props.chartFilter(payload, "elevation");
                            }}>
                            <span style={{ margin: "3px 0px 0px 26px" }}>
                              Elevation
                            </span>
                          </div>
                          <div
                            className={
                              chartItem === "heading"
                                ? classes.toggleChartMenuSelected
                                : classes.toggleChartMenu
                            }
                            onClick={() => {
                              const payload = {
                                user_id: get(
                                  userInfoDetails,
                                  "user_info.id",
                                  ""
                                ),
                                to_date: moment
                                  .utc(selectedDateToChart)
                                  .toISOString(),
                                from_date: moment
                                  .utc(selectedDateFromChart)
                                  .toISOString()
                              };
                              setChartItem("heading");
                              !errorMsg.graphMsg &&
                                props.chartFilter(payload, "heading");
                            }}>
                            <span style={{ margin: "3px 0px 0px 26px" }}>
                              Heading
                            </span>
                          </div>
                        </div>
                        <div
                          className={`${classes.refreshIcon} ${classes.refreshMargin}`}
                          onClick={refreshGraph}>
                          <img
                            className={classes.refreshIconImage}
                            src={refreshIcon}
                          />
                        </div>
                      </div>

                      <div className={classes.flexColumn}>
                        <Graphpicker>
                          <div className={classes.timelinePickerOuter}>
                            <DateTimePicker
                              border={"0px"}
                              bgcolor={"#f2f7fa"}
                              selectedDate={selectedDateFromChart}
                              handleDateChange={handleDateChangeFromChart}
                              disableFuture
                            />
                          </div>
                          <div
                            style={{
                              margin: "11px 0px 0px 0px"
                            }}>
                            to
                          </div>
                          <div className={classes.timelinePickerOuter}>
                            <DateTimePicker
                              border={"0px"}
                              bgcolor={"#f2f7fa"}
                              selectedDate={selectedDateToChart}
                              handleDateChange={handleDateChangeToChart}
                              disableFuture
                            />
                          </div>
                        </Graphpicker>
                        {errorMsg.graphMsg && (
                          <div className={classes.errorMessage}>
                            {errorMsg.graphMsg}
                          </div>
                        )}
                      </div>
                    </section>
                    <div>
                      <LineChart
                        chartData={chartdata}
                        shrink={shrink}
                        fromDate={selectedDateFromChart}
                        toDate={selectedDateToChart}
                      />
                    </div>
                  </div>
                </div>
              </section>
              {/* ----------------------------------------------------Chart---------------------------------------------------------------------------- */}
            </section>
            {/* ----------------------------------------------------TimeLine---------------------------------------------------------------------------- */}
            <section className={classes.marginTopClassTime}>
              <div className={classes.timeLine}>
                <div className={classes.width}>
                  <section className={classes.flex}>
                    <div className={classes.timelineText}>Timeline between</div>
                    <div className={classes.flexColumn}>
                      <Graphpicker>
                        <div className={classes.timelinePickerOuter}>
                          <DateTimePicker
                            border={"0px"}
                            bgcolor={"#f2f7fa"}
                            selectedDate={selectedDateFrom}
                            handleDateChange={handleDateChangeFrom}
                            disableFuture
                          />
                        </div>
                        <div className={classes.timeLineAnd}>and</div>
                        <div className={classes.timelinePickerOuter}>
                          <DateTimePicker
                            // classes={classes.datePickerMargin}
                            border={"0px"}
                            bgcolor={"#f2f7fa"}
                            selectedDate={selectedDateTo}
                            handleDateChange={handleDateChangeTo}
                            disableFuture
                          />
                        </div>
                      </Graphpicker>
                      {errorMsg.timelineMsg && (
                        <div className={classes.errorMessage}>
                          {errorMsg.timelineMsg}
                        </div>
                      )}
                    </div>
                  </section>
                  <TimeLine data={timeLineData} />
                </div>
              </div>
            </section>
            {/* ----------------------------------------------------TimeLine---------------------------------------------------------------------------- */}
          </>
        )}
        {showConfirmation && (
          <ConfirmationAlert
            open={showConfirmation}
            handleClose={handleCloseConfirmWatchlist}
            handleContinueClick={handleRemoveUserFromWatchlist}
            dialogueTitle="Are you sure to remove this user from watchlist ?"
            cancelLabel="Disagree"
            continueLabel="Agree"
            hasError={confirmtnError}
          />
        )}
        <DialogTable
          open={openCallLog}
          handleClose={handleCloseDialogCallLog}
          state={props.userProfileState}
          isCallLogLoading={props.isCallLogLoading}
          getCallLog={props.getCallLog}
          userId={get(userInfoDetails, "user_info.id")}
        />
        {openDialog && (
          <DialogSlideExport
            open={openDialog}
            handleClose={handleCloseDialog}
            exportToExcel={props.exportToExcel}
            setOpenDialog={setOpenDialog}
            userID={get(userInfoDetails, "user_info.id", "")}
            enqueueSnackbar={enqueueSnackbar}
          />
        )}
        <AddToWatch
          open={openWatchList}
          handleClose={handleCloseWatchList}
          addToWatchlist={props.addToWatchlist}
          userID={get(userInfoDetails, "user_info.id", "")}
          enqueueSnackbar={enqueueSnackbar}
        />
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userProfileState: state.UserProfile,
    isCallLogLoading: state.UserProfile.isCallLogLoading,
    dashboardState: state.Dashboard,
    isUserInfoLoading: state.Dashboard.isUserInfoLoading,
    userInfo: state.Dashboard.userInfo
  };
};

const mapDispatchToProps = dispatch => {
  return {
    chartFilter: dispatch.UserProfile.startChart,
    timeLine: dispatch.UserProfile.startTimeline,
    timeLineActivity: dispatch.UserProfile.startTimelineActivity,
    timeLineTrailClear: dispatch.UserProfile.trailDataClear,
    getUserInfo: dispatch.Dashboard.getUserInfo,
    sendTextMessage: dispatch.UserProfile.sendTextMessage,
    sendPushNotification: dispatch.UserProfile.sendPushNotification,
    startLocationTrail: dispatch.UserProfile.startLocationTrail,
    exportToExcel: dispatch.UserProfile.exportToExcel,
    addToWatchlist: dispatch.UserProfile.addToWatchlist,
    dashboardSearch: dispatch.Dashboard.startDashboard,
    forceSync: dispatch.UserProfile.forceSync,
    patchUserDetails: dispatch.UserProfile.patchUserDetails,
    getCallLog: dispatch.UserProfile.getCallLog,
    removefromWatchlist: dispatch.UserProfile.removefromWatchlist,
    switchData: dispatch.Geospatial.switchData
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
