export function convertToDMS(src) {
  function toDMS(n) {
    // The sign doesn't matter
    n = Math.abs(n);

    // Get the degrees
    var d = Math.floor(n);
    // Strip off the answer we already have
    n = n - d;
    // And then put the minutes before the '.'
    n *= 60;

    // Get the minutes
    var m = Math.floor(n);
    // Remove them from the answer
    n = n - m;
    // Put the seconds before the '.'
    n *= 60;

    // Get the seconds
    // Should this be round? Or rounded by special rules?
    var s = Math.floor(n);

    // Put it together.
    return "" + d + "°" + m + " " + s;
  }

  var dir0 = src[0] > 0 ? "N" : "S";

  var dir1 = src[1] > 0 ? "E" : "W";

  return `${src[0]}° ${dir0}, ${src[1]}° ${dir1}`


}
