export function getPoints(marker) {
  let maparray = [];
if( marker!==undefined&&marker.length>0){
   marker.forEach(currentItem => {
     maparray.push(
       new window.google.maps.LatLng(
         currentItem.location.latitude,
         currentItem.location.longitude
       )
     );
   });
}
  return maparray;
}
