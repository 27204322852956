import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import {useStyles} from './addtowatchlist.style'
import { TextFieldWrapper } from "../../styles/Wrapper.style";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AddToWatchDialogSlide({
                 open,
                 handleClose,
                 addToWatchlist,
                 userID,
                 enqueueSnackbar 
               }) {
                 const classes = useStyles();
                 const [reason, setReason] = React.useState("");
                 const handleClick = () => {
                   let payload = {
                     user_id: userID,
                     reason: reason
                   };
                   addToWatchlist(payload, enqueueSnackbar);
                 };
                 return (
                   <div>
                     <Dialog
                       open={open}
                       TransitionComponent={Transition}
                       keepMounted
                       onClose={handleClose}
                       aria-labelledby="alert-dialog-slide-title"
                       aria-describedby="alert-dialog-slide-description">
                       <DialogTitle id="alert-dialog-slide-title">
                         {
                           "What would be the reason for adding this user to watchlist?"
                         }
                       </DialogTitle>
                       <DialogContent>
                         <TextFieldWrapper>
                          <TextField
                            id="reason"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField
                              },
                              disableUnderline: true
                            }}
                            value={reason}
                            onChange={e => {
                              setReason(e.target.value);
                            }}
                            margin="normal"
                            placeholder="enter your reason"
                            type="text"
                            fullWidth
                          />
                        </TextFieldWrapper>
                       </DialogContent>
                       <DialogActions>
                         <div
                           style={{
                             display: "flex",
                             marginRight: "12px",
                             marginTop: "60px",
                             justifyContent: "center",
                             alignItems: "center",
                             marginBottom: "40px"
                           }}>
                           <div
                             className={classes.cancelButton}
                             onClick={handleClose}>
                             <span className={classes.cancelText}>Cancel</span>
                           </div>
                           <div
                             className={classes.updateButton}
                             onClick={handleClick}>
                             <span className={classes.cancelText}>Save</span>
                           </div>
                         </div>
                       </DialogActions>
                     </Dialog>
                   </div>
                 );
               }
