import styled from "styled-components";
import { KeyboardTimePicker } from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";

const TimePickerStyled = styled(TextField)`
  margin: 0px 0px 0px 0px !important;
  // width: 84px;
  width: 110px;
  height: 39px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: ${props =>
    props.border ? props.border : "solid 1px rgba(71, 59, 59, 0.2)"} !important;
  background-color: ${props => (props.bgcolor ? props.bgcolor : "#ffffff")};
  input {
    margin: 10px;
    width: 100%;
    // height: 26px;
    height: 16px;
    line-height: 17px;
    padding: 0px;
    font-family: Google Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    // line-height: normal;
    letter-spacing: normal;
    color: #999999;
    // padding-top: 1px;
    text-align: center;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="time"]::-webkit-clear-button {
    display: none;
  }
  input[type="time"] {
    width: 120px;
    border: none;
    clip-path: inset(0 17px 0 0);
    outline: none;
    outline: 0;
    margin-right: 0px;
    margin-left: 0px;
  }
  input::-ms-clear {
    width: 0;
    height: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="time"]::-moz-clear-button {
    display: none;
    width: 0;
    height: 0;
  }
  input[type="time"]::-ms-clear-button {
    display: none;
    width: 0;
    height: 0;
  }
`;

export default TimePickerStyled;
