/* display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
     */

 import styled from "styled-components";

 export const StyledSection = styled.section`
          display: flex;
          justify-content: space-between;
          align-items: center;
          vertical-align: middle;
        `;    
 export const StyledHeader = styled.header`
          height: 66px;
          box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
          border: solid 1px rgba(151, 151, 151, 0.08);
          background-color: #ffffff;
          z-index: 9989;
          display: flex;
          @supports (-ms-ime-align: auto) {
            margin-top: 0px;
            // position: relative;
          }
        `;   
 export const StyledSpan = styled.span`
          padding: 0;
          margin: 0;
          display: flex;
          justify-content: space-between;
          align-item:center;
        `;  
export const SyledSpanItem = styled.span`
cursor:pointer;
         text-align: center;
         padding:10px;
       `;  
export const StyledOvalBadge = styled.span`
         margin: -36px -13px 4px 12px;
         width: 23px;
         height: 22px;
         border: solid 3px #ffffff;
         border-radius: 13px;
         background-color: #ff2d21;
       `;

 export const StyledOvalBadgeText = styled.span`
          color: #ffffff;
          margin-left: 4px;
        `;      