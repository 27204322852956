import defaultTheme from "./default";
//Changed createMuiTheme to createTheme...creatMuiTheme is depreciated
import { createTheme } from "@material-ui/core";
import GoogleSans from "../assets/fonts/GoogleSans-Regular.ttf";


const googleSans = {
  fontFamily: "Google Sans",
  fontStyle: "normal",

  fontWeight: 400,
  src: `
    local('GoogleSans'),
    url(${GoogleSans}) format("truetype")
  `
};
const overrides = {

  typography: {
    "@font-face": [GoogleSans],
    h1: {
      fontSize: "3rem"
    },
    h2: {
      fontSize: "2rem"
    },
    h3: {
      fontSize: "1.64rem"
    },
    h4: {
      fontSize: "1.5rem"
    },
    h5: {
      fontSize: "1.285rem"
    },
    h6: {
      fontSize: "1.142rem"
    }
  }
};

export default {
  default: createTheme({ ...defaultTheme, ...overrides }),
};
