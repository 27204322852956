import React from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import PageNotFoundImage from "../../assets/img/page_not_found.png";
import { useStyles } from "./pagenotfound.style";

const PageNotFound = () => {
  let history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container direction="column" justify="center" alignItems="center">
        <img
          src={PageNotFoundImage}
          className={classes.pageNotFoundImg}
          alt="Page Not Found"
        />
        <h3 className={classes.errorText}>We couldn&apos;t find the page.</h3>
        <button onClick={() => history.goBack()} className={classes.linkHome}>
          <span>Back</span>
        </button>
      </Grid>
    </div>
  );
};

export default PageNotFound;
