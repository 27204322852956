import { makeStyles } from "@material-ui/styles";
import styled from "styled-components";
import ImageAsset1 from '../../assets/img/imageasset1.png'
import ImageAsset2 from "../../assets/img/imageasset2.png";
export const useStyles = makeStyles(theme => ({
  container: {
    fontFamily:"Google Sans",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    background: `url(${ImageAsset2}) right top no-repeat, url(${ImageAsset1})  left bottom no-repeat`
  },
  logo: {
 width: "323px",
    height: "75px",
    objectFit: "contain",
    display: "flex",
    margin: "1px 0px 29px 54px",
  },
  form: {
      width: "498px",
    height: "188px",
    objectFit: "contain",
  },

  WelcomeBackPlease: {
    width: "244px",
    height: "23px",
    fontFamily: "Google Sans",
    fontSize: "18px",
    fontHeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.44",
    letterSpacing: "normal",
    textAlign: "center",
    color: "#abb0b3 !important",
    margin: "0 auto !important"
  },
  typo:{
        display: "flex",
    width: "500px",
    flexDirection: "column",
    margin:" 0px 0px 0px 1px",
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center"
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40"
  },
  errorMessage: {
    textAlign: "center"
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: "1px solid rgba(80, 67, 59, 0)"
    },
    "&:after": {
      borderBottomColor: "1px solid rgba(80, 67, 59, 0)"
    },
    "&:hover:before": {
      borderBottomColor: "1px solid rgba(80, 67, 59, 0)"
    }
  },
  textField: {
    borderBottomColor: theme.palette.background.light,
    textAlign: "center",
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      textAlign: "center"
    },
    
  },
  caption: {
    height: "20px",
    fontFamily: "Google Sans",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textAlign: "center",
    color: " #000000",
    justifyContent: "center",
    margin: "14px 6px -6px 2px"
  },

  formButtons: {
    width: "100%",
    marginTop: theme.spacing(4),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textButton:{
  width: "60px",
  height: "20px",
  fontFamily: "Google Sans",
  fontSize: "16px",
  fontHeight: "bold",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight:" normal",
  letterSpacing: "normal",
  color: "#ffffff",
  },
  forgetButton: {
    textTransform: "none",
    fontWeight: 400
  },
  loginLoader: {
    marginLeft: theme.spacing(4)
  },
  loginbox: {
    marginTop: "20px",
    borderRadius: "10px",
    boxShadow: "0 20px 24px 0 rgba(244, 233, 233, 0.46)",
    border: "solid 1px #ebebeb",
    backgroundColor: " #ffffff"
  }
  ,divider:{
  height: "1px",
  border: "solid 1px #ebebeb",

  },
  signInButton: {
    padding:"10px 50px"
  }
}));

export const PasswordFieldWrapper = styled.div`
input::-ms-clear {
  width: 0;
  height: 0;
}
input[type=password]::-ms-reveal,
input[type=password]::-ms-clear
{
    display: none;
}
`;
