import styled from "styled-components";
export const MapDiv = styled.div`
test{
    width : "98px";
  height : "30px";
  fontWeight : "bold";
  fontStretch : "normal";
  fontStyle : "normal";
  borderRadius : "7px";
  margin : "5px 2px 2px 8px";
},
testspan{
    width : "74px";
  height : "16px";
  fontFamily : "Google Sans";
  fontSize : "12px";
  fontWeight : "bold";
  fontStretch : "normal";
  fontStyle : "normal";
  lineHeight : "normal";
  color : "#161a1e";
  textAlign : "center";
  display : "flex";
  margin : "8px 0px 6px 16px";
}
`;