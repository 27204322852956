import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from  "moment";
import { 
  Grid, 
  Collapse, 
  IconButton, 
  Typography 
} from "@material-ui/core";
import store from "../../store";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { 
  T as Table,
  TicketWrapper,
  TicketTopWrapper,
  TicketTableWrapper,
  useStyles,
  StyledTab,
  StyledTabs,
  TabPanel
} from "./ticket.style";
import { PageHeading, CreateButton } from "../../styles/Body.style";
import { useSnackbar } from "notistack";
import BackToPrevious from "../../components/BackToPrevious";


const header = ["ORGANIZATION", "TICKET", "EMAIL", "DATE & TIME CREATED", "ACTION"];
const sortHeader = "DATE & TIME CREATED";

function Tickets(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [errorState, setErrorState] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [ticketPage, setTicketPage] = useState({});
  const [selectedIndex, setSelectedIndex] = useState("");
  const [value, setValue] = useState(0);

  useEffect(() => {
    store.dispatch.Ticket.getTicketlist();
  }, []);

  useEffect(() => {
    setTickets(props.ticketState.tickets);
    setTicketPage(props.ticketState.page);
  }, [props.ticketState.tickets], [props.ticketState.page]);

  const handleUpdateTicket = async (item) => {
    let error = [];
    setErrorState(error);
    await timeout(1000);
    let payload = item;
    payload.is_complete = true;
    store.dispatch.Ticket.patchTicket(payload, {
      enqueueSnackbar,
      id: payload.id,
      history: props.history
    });
  };

  const handleClick = index => {
    if (selectedIndex === index) {
      setSelectedIndex("")
    } else {
      setSelectedIndex(index)
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <BackToPrevious destination="dashboard"/>
      <TicketTopWrapper>
        <PageHeading>Tickets</PageHeading>
      </TicketTopWrapper>
      <div >
        <StyledTabs 
          value={value}
          onChange={handleChange}
          aria-label="styled tabs example"
        >
          <StyledTab
            label={`Open Tickets`}
          />{" "}
            <span className={classes.spantest}>|</span>
          <StyledTab
            label={`Closed Tickets`}
          />
        </StyledTabs>
      </div>
        <TabPanel value={value} index={0}>
            <TicketWrapper>
              <Grid container>
                <Grid item xs={12}>
                  <TicketTableWrapper>
                    <Table>
                      <div className="wrapper-long">
                        <table
                          className="table"
                          style={{
                            marginBottom: "0",
                            position: "sticky",
                            top: "0",
                            zIndex: "1",
                            borderRadius: "8px",
                            overflow: "hidden"
                          }}
                        >
                          <tbody>
                            <tr className="row-head header green">
                              <th className="cellHead-small"></th>
                              {header && header.map((item, index) => {
                                if (item === sortHeader) {
                                  return (
                                    <th key={index} className="cellHead">
                                      {item}
                                    </th>
                                  );
                                } else {
                                  return (
                                    <th key={index} className="cellHead">
                                      {item}
                                    </th>
                                  );
                                }
                              })}
                            </tr>
                          </tbody>
                        </table>
                    <table className="table">
                      {tickets && tickets.length > 0 && tickets.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item.is_complete ? <></> :
                              <tbody>
                                <tr className="row" onClick={() => {handleClick(index)}}>
                                  <td className="cell-small">
                                    <IconButton aria-label="expand row" size="small" onClick={() => {handleClick(index)}}>{index === selectedIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                  </td>
                                  <td className="cell-long" >{item.org_name}</td>
                                  <td className="cell-long" >{item.issue_or_request}</td>
                                  <td className="cell-long" >{item.email}</td>
                                  <td className="cell-long" >{item.created_on ? moment(item.created_on).local().format("DD MMM YYYY, HH:mm:ss") : "Unavailable"}</td>
                                  <td className="cell-long" >
                                    <CreateButton onClick={(event) => {event.stopPropagation(); handleUpdateTicket(item)}}>Mark Complete</CreateButton> 
                                  </td>
                                </tr>
                                <tr>
                                  <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
                                    <table style={{width: 1491}}>
                                      <tbody>
                                        <Typography variant="h6" gutterBottom component="div" style={{marginLeft: 20, marginBottom: -9}}>
                                          <i><b>DETAILS</b></i>
                                        </Typography>
                                        <tr>
                                          <td className="cell-long"><i>ISSUE:&#8199;</i> {item.issue_or_request}</td>
                                        </tr>
                                        <tr>
                                          <td className="cell-long"><i>DESCRIPTION:&#8199;</i> {item.issue_or_request_description}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </Collapse>
                                </tr>
                              </tbody>
                            }
                          </React.Fragment>
                        );
                      })}
                        {tickets && tickets.length === 0 && (
                          <>
                            <tbody>
                              <tr className="cell-long-line">No Record Found</tr>
                            </tbody>
                          </>
                        )}
                    </table>
                  </div>
                </Table>
              </TicketTableWrapper>
            </Grid>
          </Grid>
        </TicketWrapper>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TicketWrapper>
          <Grid container>
            <Grid item xs={12}>
              <TicketTableWrapper>
                <Table>
                  <div className="wrapper-long">
                    <table
                      className="table"
                      style={{
                        marginBottom: "0",
                        position: "sticky",
                        top: "0",
                        zIndex: "1",
                        borderRadius: "8px",
                        overflow: "hidden"
                      }}
                    >
                      <tbody>
                        <tr className="row-head header green">
                          <th className="cellHead-small"></th>
                          {header && header.slice(0,4).map((item, index) => {
                            if (item === sortHeader) {
                              return (
                                <th key={index} className="cellHead">
                                  {item}
                                </th>
                              );
                            } else {
                              return (
                                <th key={index} className="cellHead">
                                  {item}
                                </th>
                              );
                            }
                          })}
                        </tr>
                      </tbody>
                    </table>
                    <table className="table">
                      {tickets && tickets.length > 0 && tickets.map((item, index) => {
                        return (
                          <React.Fragment key={index}>
                            {item.is_complete ?
                              <tbody>
                                <tr className="row" onClick={() => {handleClick(index)}}>
                                  <td className="cell-small">
                                    <IconButton aria-label="expand row" size="small" onClick={() => {handleClick(index)}}>{index === selectedIndex ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                                  </td>
                                  <td className="cell-long" >{item.org_name}</td>
                                  <td className="cell-long" >{item.issue_or_request}</td>
                                  <td className="cell-long" >{item.email}</td>
                                  <td className="cell-long" >{item.created_on ? moment(item.created_on).local().format("DD MMM YYYY, HH:mm:ss") : "Unavailable"}</td>
                                </tr>
                                <tr>
                                  <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
                                    <table style={{width: 1491}}>
                                      <tbody>
                                        <Typography variant="h6" gutterBottom component="div" style={{marginLeft: 20, marginBottom: -9}}>
                                          <i><b>DETAILS</b></i>
                                        </Typography>
                                        <tr>
                                          <td className="cell-long"><i>ISSUE:&#8199;</i> {item.issue_or_request}</td>
                                        </tr>
                                        <tr>
                                          <td className="cell-long"><i>DESCRIPTION:&#8199;</i> {item.issue_or_request_description}</td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </Collapse>
                                </tr>
                              </tbody>
                            : <></>}
                          </React.Fragment>
                        );
                      })}
                        {tickets && tickets.length === 0 && (
                          <>
                            <tbody>
                              <tr className="cell-long-line">No Record Found</tr>
                            </tbody>
                          </>
                        )}
                    </table>
                  </div>
                </Table>
              </TicketTableWrapper>
            </Grid>
          </Grid>
        </TicketWrapper>
      </TabPanel> 
    </div>
  );
}

const mapStateToProps = state => {
  return { ticketState: state.Ticket };
};

function timeout(delay) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};

export default connect(
  mapStateToProps,
  null
)(Tickets);
