import styled from "styled-components";
export const BasePanel = styled.section`
  margin: -103px 0px 0px 16px;
  z-index: 1000;
  width: max-content;
  border-radius: 10px;
  object-fit: contain;
  background-color: rgba(39, 45, 49, 0.9);
  height: 77px;
  display: inline-flex;
  justify-content: flex-start;
  * {
    min-width: 120px;
  }
  @supports (-ms-ime-align: auto) {
    max-width: 65%;
    min-width: 50%;
    width:auto
  }
`;
export const ClientReview = styled.div`
  /* width: 209px; */
  fontweight: bold;
  fontstretch: normal;
  fontstyle: normal;
  borderradius: 7px;
  margin: 5px 2px 2px 8px;
`;

export const ClientReviewHeading = styled.span`
  font-family: Google Sans;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #ffffff;
  text-align: center;
  display: flex;
  opacity: 0.8;
  margin: 7px 2px 3px 23px;
`;
export const ClientReviewContent = styled.span`
  font-family: Google Sans;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #ffffff;
  text-align: center;
  display: flex;
  opacity: 0.8;
  margin-left: 23px;
  font-size: 32px;
`;
export const OnlineReviewContent = styled.div`
  /* width: 209px; */
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  border-radius: 7px;
  margin: 5px 2px 2px 8px;
`;

export const ViewMoreContent = styled.span`
  height: 17px;
  font-family: Google Sans;
  border-radius: 100px;
  border: solid 1px #60bca7;
  background-color: #303436;
  font-family: Google Sans;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  color: #60bca7;
  text-align: center;
  display: flex;
  opacity: 0.8;
  margin: 0 5px;
  justify-content: center;
  cursor: pointer;
`;
