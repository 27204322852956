//pages and components
import DashBoard from "../pages/DashBoard"
import TicketList from "../pages/TicketPortal/TicketList"
import TicketPortal from "../pages/TicketPortal"
import GeoSpatial from "../pages/GeoSpatial"
import UserProfile from "../pages/UserProfile"
import EditUsers from "../pages/EditUser"
import WatchList from "../pages/WatchList"
import Organizations from "../pages/Organizations"
import CreateOrganizations from "../pages/Organizations/CreateOrganizations"
import OrganizationDetails from "../pages/Organizations/OrganizationDetails"
import NotificationDetails from "../pages/MassNotification/NotificationDetails"
import History from "../pages/History"
import MassNotifications from "../pages/MassNotification"
import CreateNotification from "../pages/MassNotification/CreateNotifcations"
import ViewMap from "../pages/MassNotification/ViewMap"
import PageNotFound from "../pages/PageNotFound"
import PermissionDenied from "../pages/PermissionDenied"

//config files
import Roles from "./Roles"


const RoutesConfig = [
    /*
        * PLEASE NOTE THE FOLLOWING:
        * super_admin can access and should have access to all components
        * to make above possible, add super_admin role to accessRoles array of all components
        * To change or limit access to a component(for other users), change isSuperAdmin status
        * isSuperAdminOnly: false - gives access
        * isSuperAdminOnly: true - limits access
        * add other roles that you'd like to be able to access components in the accessRoles array
        * Above point doesn't apply if isSuperAdminOnly is set to false
        * 
        * FOLLOW EXISTING CONVENTION WHEN ADDING NEW COMPONENTS/ROUTES
     */
    {
		component: DashBoard,
        path: '/',
        exact: true,
        isSuperAdminOnly: false,
        accessRoles: [Roles.GUEST_USER, Roles.REGULAR_USER, Roles.TENANT_ADMIN, Roles.RISKOVERY_ADMIN, Roles.SUPER_ADMIN, Roles.TEST_ADMIN] //Roles.TEST_ADMIN is for use when debugging in browser developer tools
	},
    {
		component: DashBoard,
        path: '/dashboard',
        isSuperAdminOnly: false,
        accessRoles: [Roles.GUEST_USER, Roles.REGULAR_USER, Roles.TENANT_ADMIN, Roles.RISKOVERY_ADMIN, Roles.SUPER_ADMIN, Roles.TEST_ADMIN] //Roles.TEST_ADMIN is for use when debugging in browser developer tools
    },
    {
		component: TicketList,
        path: '/ticketlist',
        exact: true,
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN]
	},
    {
		component: TicketPortal,
        path: '/createticket',
        exact: true,
        isSuperAdminOnly: false,
        accessRoles: [Roles.GUEST_USER, Roles.REGULAR_USER, Roles.TENANT_ADMIN, Roles.RISKOVERY_ADMIN, Roles.SUPER_ADMIN, Roles.TEST_ADMIN] //Roles.TEST_ADMIN is for use when debugging in browser developer tools
	},
    {
		component: GeoSpatial,
        path: '/geospatial',
        isSuperAdminOnly: false,
        accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN, Roles.TENANT_ADMIN]
	},
	{
		component: WatchList,
        path: '/watchlist',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN, Roles.TENANT_ADMIN],
    },
	{
		component: History,
        path: '/history',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN]
    },
	{
		component: MassNotifications,
        path: '/massnotification',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN]
    },
    {
		component: Organizations,
        path: '/organizations',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN]
    },
    {
		component: EditUsers,
        path: '/editUser',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN, Roles.TENANT_ADMIN]
    },
    {
		component: CreateOrganizations,
        path: '/createorganization',
        exact: true,
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN]
    },
    {
		component: OrganizationDetails,
        path: '/organizationdetails',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN]
    },
    {
		component: NotificationDetails,
        path: '/notificationdetails',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN]
    },
    {
		component: CreateNotification,
        path: '/createnotifications',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN]
    },
    {
		component: ViewMap,
        path: '/viewnotificationmap',
        isSuperAdminOnly: true,
        accessRoles: [Roles.SUPER_ADMIN]
    },
    {
		component: PermissionDenied,
        path: '/permission-denied',
        isSuperAdminOnly: false,
        accessRoles: [Roles.GUEST_USER, Roles.REGULAR_USER, Roles.TENANT_ADMIN, Roles.RISKOVERY_ADMIN, Roles.SUPER_ADMIN, Roles.TEST_ADMIN] //Roles.TEST_ADMIN is for use when debugging in browser developer tools
    },
    {
		component: PageNotFound,
        path: '/page-not-found',
        isSuperAdminOnly: false,
        accessRoles: [Roles.GUEST_USER, Roles.REGULAR_USER, Roles.TENANT_ADMIN, Roles.RISKOVERY_ADMIN, Roles.SUPER_ADMIN, Roles.TEST_ADMIN] //Roles.TEST_ADMIN is for use when debugging in browser developer tools
    },
    //THIS STAYS BETWEEN THE 2 PageNotFound COMPONENTS, OTHERWISE, APP BREAKS
    {
		component: UserProfile,
        path: '/:id',
        exact: true,
        isSuperAdminOnly: false,
        accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN, Roles.TENANT_ADMIN]
    },
    {
		component: PageNotFound,
        path: '',
        isSuperAdminOnly: false,
        accessRoles: [Roles.GUEST_USER, Roles.REGULAR_USER, Roles.TENANT_ADMIN, Roles.RISKOVERY_ADMIN, Roles.SUPER_ADMIN, Roles.TEST_ADMIN] //Roles.TEST_ADMIN is for use when debugging in browser developer tools
    },
]

export { RoutesConfig }