import axios from "axios"
import { getCookie } from '../CookieHelper'

const api = axios.create({
  baseURL: process.env.API_URL,
  responseType: "json",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
})
const { CancelToken } = axios

function onRequest(config) {
  const customConfig = config
  const token = getCookie('token')
  if (token && !customConfig.headers.Authorization) {
    customConfig.headers.Authorization = `token ${token}`
  }
  return customConfig
}

const requestOnFailed = error => Promise.reject(error)

const responseOnSuccess = response => response

// Include user friendly message here.. also, potential infinite loop to /login - look into this 19th Dec, 2020
const responseOnFailed = error => {
  const customError = { ...error }
  if (error.response) {
    const status = error.response.headers.status || error.response.status
    customError.status = status
    switch (status) {
      case 400: //bad request -- client error
        // alert(error.response.data.message) //handle this appropriately
        break
      case 401: //lacking valid authentication credentials the target resource
        window.location.pathname = "/login"
        break
      case 403: //access denied route
        window.location.pathname = "/permission-denied"
        break
      case 404: //404 page not found route
        window.location.pathname = "/page-not-found"
        break
      case 500: //server error
        alert('Server Error. Please contact system admin.')
        break
      default:
        window.location.pathname = "/login"
        break
    }
  }
  return Promise.reject(customError)
}

api.interceptors.request.use(onRequest, requestOnFailed)
api.interceptors.response.use(responseOnSuccess, responseOnFailed)

export { api, CancelToken }
