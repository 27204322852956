import styled from "styled-components";

export const StyledBurger = styled.div`
         background: transparent;
         cursor: pointer;
         border:none;
         :focus{
           outline:none;
         }
         }
       `;
