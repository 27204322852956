import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styled from "styled-components";
export const HistoryWrapper = styled.section`
  display: flex !important;
  margin: 11px 0 70px 0 !important;
`;
export const HistoryTopWrapper = styled.section`
  display: flex !important;
  justify-content: space-between;
`;
export const HistoryTopWrapperFilter = styled.section`
  margin-bottom: 25px;
  display: flex !important;
  justify-content: space-between;
`;
export const HistoryHeading = styled.div`
  margin-bottom: 15px;
  height: 38px;
  font-family: Google Sans;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const HistoryTableTabWrapper = styled.section`
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;
export const HistorySelectwrapper = styled.div`
  display: flex;
  width: 25%;
  .errorMessage {
    color: #ff2d21;
    font-size: 12px;
    text-align: center;
    width: 100%;
    padding-top: 5px;
  }
`;
export const SearchWrapper = styled.div`
  width: 410px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(71, 59, 59, 0.2);
  background-color: #ffffff;
`;

export const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #eff1f4"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 40,

      width: "68px",
      height: "7px",
      borderRadius: "100px",
      backgroundImage: "linear-gradient(to bottom, #f14b4b, #ff0e00)"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "Google Sans",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: "#ffffff"
  },
  spantest: {
    margin: " 15px 0px 0px 0px",
    color: "#5a5d60"
  },
  dateTimePicker: {
    margin: " 1px 0px 0px 19px !important",
    width: "182px",
    height: " 39px",
    borderRadius: "8px",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    border: "solid 1px rgba(71, 59, 59, 0.2) !important",
    backgroundColor: " #ffffff",
    "& $div": {
      textDecoration: "none",
      "& $input": {
        margin: "10px",
        width: "100%",
        height: "16px",
        fontFamily: "Google Sans",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#999999"
      }
    }
  },
  adornment: {
    padding: "0px"
  }
}));

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}
