import styled from "styled-components";

const AutoStyled = styled.div`
    position: relative;
    height: 100%;
    .progress {
        margin-right: 15px;
    }
    .options-wrapper {
        margin: 0;
        padding: 0;
        position: absolute;
        top: calc(100% + 4px);
        left: 0;
        right: 0;
        background: #fff;
        max-height: 320px;
        overflow-y: auto;
        z-index: 1;
        &.hide {
            display: none;
        }
        .option-item {
            margin: 0;
            padding: 10px;
            list-style-type: none;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                background-color: #f8f8f8;
            }
        }
    }
`;

export default AutoStyled;
