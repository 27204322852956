import React, { useEffect } from "react";
import useGoogleMap from "../google/useGoogleMap";
import { useStyles } from "./map.styles";
import { MapDiv } from "./custom.styles";
import BasePanel from "../../BasePanel";

let initialCircle = null;

const removeInitialCircle = () => {
  initialCircle.setMap(null);
  initialCircle = null;
};

export default function Map({
  handleGeoSpatialSearch,
  setInitialRender,
  setState,
  isDevice,
  isFilterDisable,
  center,
  zoom,
  children,
  events,
  styles,
  open,
  onOpen,
  markers,
  handleHeatMap,
  status,
  refreshMap,
  isLoading,
  dataSet,
  isheatmap,
  isDrawerManager,
  geospatialApi,
  setUser,
  setOrganization,
  setDateChangeTo,
  setDateChangeFrom,
  isLocationTrail,
  trails,
  setFilterDisable,
  handleBoundClear,
  setValue,
  trailDataClear,
  ResetPaylod,
  setPayload,
  isTrailMap,
  locationTrails,
  dataTrailSet,
  setDevices,
  shouldRenderInitialCircle,
  centerCoordinates
}) {
  const classes = useStyles();
  const { maps, map, mapRef, loading, drawingManager } = useGoogleMap({
    handleGeoSpatialSearch,
    setInitialRender,
    setState,
    isDevice,
    setDevices,
    isFilterDisable,
    zoom,
    center,
    events,
    styles,
    open,
    onOpen,
    markers,
    handleHeatMap,
    status,
    refreshMap,
    isLoading,
    dataSet,
    isDrawerManager,
    geospatialApi,
    setUser,
    setOrganization,
    setDateChangeTo,
    setDateChangeFrom,
    isLocationTrail,
    trails,
    setFilterDisable,
    handleBoundClear,
    setValue,
    trailDataClear,
    ResetPaylod,
    setPayload,
    isTrailMap,
    locationTrails,
    dataTrailSet,
    shouldRenderInitialCircle
  });
  useEffect(() => {
    map && map.panTo(center);
  }, [center.lat, center.lng]);

  // effect to handle the initial circle if switching to geospatial from user profile page
  useEffect(() => {
    // if shouldRenderInitialCircle is true and map is loaded, draw the initial circle
    if (
      centerCoordinates &&
      shouldRenderInitialCircle &&
      window.google &&
      map &&
      drawingManager
    ) {
      initialCircle && removeInitialCircle();
      const center = new window.google.maps.LatLng(
        centerCoordinates.latitude,
        centerCoordinates.longitude
      );
      initialCircle = new window.google.maps.Circle({
        strokeColor: "#ff9500",
        strokeWeight: 2,
        fillColor: "rgba(255, 149, 0, 0.12)",
        fillOpacity: 0.3,
        map,
        draggable: true,
        editable: true,
        center,
        radius: 50000
      });
      drawingManager.setDrawingMode(null);
      drawingManager.setOptions({
        drawingControl: false
      });
      window.google.maps.event.addListener(
        initialCircle,
        "dragend",
        function() {
          let center = {
            latitude: initialCircle.center.lat(),
            longitude: initialCircle.center.lng()
          };

          geospatialApi([], 4, center, initialCircle.radius / 1000);
        }
      );
      window.google.maps.event.addListener(
        initialCircle,
        "radius_changed",
        function() {
          if (window.paths !== undefined && window.paths.length > 0) {
            window.paths.forEach(path => {
              path.setMap(null);
            });
          }
          let center = {
            latitude: initialCircle.center.lat(),
            longitude: initialCircle.center.lng()
          };
          geospatialApi([], 4, center, initialCircle.radius / 1000);
        }
      );
    } else if (!shouldRenderInitialCircle && initialCircle) {
      // else clear the circle
      removeInitialCircle();
      drawingManager.setDrawingMode(null);
      drawingManager.setOptions({
        drawingControl: true
      });
    }
  }, [
    shouldRenderInitialCircle,
    centerCoordinates,
    window.google,
    map,
    drawingManager
  ]);

  return (
    <div className={classes.root}>
      <div
        className={classes.mapContainer}
        style={isTrailMap ? { minHeight: "514px" } : { minHeight: "740px" }}>
        {/* {trails!==undefined&&trails.length>0?<div className={classes.loaderStyle}></div>:""} */}
        <MapDiv id="map" ref={mapRef} className={classes.mapRefStyle} />
        <BasePanel />
        {!loading &&
          React.Children.map(children, child => {
            return React.cloneElement(child, {
              map,
              maps,
              zoom,
              isheatmap,
              isDrawerManager,
              isLocationTrail,
              trails
            });
          })}
      </div>
    </div>
  );
}
