import React, { useState, useRef, lazy, Suspense } from "react"
import { Switch, withRouter } from "react-router-dom"
import { SnackbarProvider } from "notistack"

// styles
import { Wrapper } from "../../styles/Wrapper.style"
import { Aside, HamburgerContainer, ContentSection } from "./Layout.styled"

// components
import Header from "../Header"
import Sidebar from "../Sidebar"
import Hamburger from "../HamBurger"
import Footer from "../Footer"

//routes/pages
import OutputFilteredRoutes from "../../routes/PrivateRoutes"

//utilities and helpers
import { getCookie } from "../../utils/CookieHelper"

// const UserProfile = lazy(() => import("../../pages/UserProfile"))
// context

// layout hooks
// const Test=()=>{
//   return <div>tEST</div>
// }
// const UserProfile = lazy(() => <Test />)

const Layout = props => {
  const role = getCookie('roles') //looking into wrapping this in a useEffect hook
  const isSuperUser = (role && role.includes('super_admin') || role.includes('riskovery_admin')) ? true : false
  const [open, setOpen] = useState(false)
  const [active, setActive] = useState(true)
  const node = useRef()
  const menuId = "main-menu"
  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <Wrapper>
          <section id="container" className="abcd" style={{ display: "flex" }}>
            {/* show side bar for super users only */}
            {
              (!!isSuperUser) ? (
                <Aside isOpen={open} key={menuId}>
                  <div ref={node}>
                    <HamburgerContainer isOpen={open}>
                      <Hamburger
                        open={open}
                        setOpen={setOpen}
                        aria-controls={menuId}
                      />
                    </HamburgerContainer>

                    <Sidebar
                      active={active}
                      setActive={setActive}
                      open={open}
                      setOpen={setOpen}
                      id={menuId}
                      key={menuId}
                    />
                  </div>
                </Aside>
              )
              : (
                <></>
              )
            }
            <section
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: "1",
                // maxWidth: "calc(100% - 83px)"
                maxWidth: (!!isSuperUser ? "calc(100% - 83px)" : "100%")
              }}>
              <Header history={props.history} />
              <ContentSection
                className="content"
                isOpen={open}
                style={{ backgroundColor: "#FAFAFA" }}>

                <Switch>
                  <OutputFilteredRoutes/>
                </Switch>

                <Footer />
              </ContentSection>
            </section>
          </section>
        </Wrapper>
      </SnackbarProvider>
    </>
  )
}

function WaitingComponent(Component) {
  return props => (
    <Suspense fallback={<div>Loading...</div>}>
      <Component {...props} />
    </Suspense>
  )
}

export default withRouter(Layout)
