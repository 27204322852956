import React, { useState, useEffect } from "react"
import {
  Grid,
  FormControl,
  CircularProgress
} from "@material-ui/core"
import moment from "moment"
import { connect } from "react-redux"
// Needed for commented out api call
import store from "../../store"

import AddIcon from "../../assets/img/addicon.png"
import icon from "../../assets/img/search2x.png"
import Map from "../../components/Map/MapComponent"
import Marker from "../../components/Map/MarkerComponent"
import DateTimePicker from "../../components/DateTimePicker"
import Dialog from "../../components/Dialog"
import DialogCountryFilter from "../../components/DialogCountryFilter"
import DialogUserDetails from "../../components/DialogUserDetails"
import { DEFAULT_CENTER } from "../../config"
import Select from "../../components/GeoSpatialFilterComponent/components/Select"
import { validateFromAndToDate } from "../../utils/dateTime"
import BasePanel from "../../components/BasePanel"
import AutoSuggest from "../../components/AutoSuggest"
import {
  DashboardTopWrapper,
  DashboardHeading,
  DashboardCreateWrapper,
  DashboardListWrapper,
  DashboardButtonWrapper,
  CreateText,
  useStyles 
} from "./dashboard.style"

//utilities and helpers
import { getCookie } from "../../utils/CookieHelper"
import Roles from "../../config/Roles"

let gmMarker = []
let markerWindow = window.marker
let initialPayload = {
  organizations: [],
  users: []
  // to_datetime: ''
}
function CenteredGrid(props) {
  console.log('CenteredGrid props')
  console.log(props)
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [isheatmap, setIsHeatmap] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedDate, setDateChange] = useState(new Date())
  const [Value, setValue] = useState("")
  const {
    markers,
    status,
    userdata,
    userInfo,
    filterMarker,
    organization,
    suggestion,
    userList,
    isUserListFiltered,
  } = props.dashboardState
  const [markerCollection, setCollection] = useState([])
  const [dataSet, setDataSet] = useState([])
  const [panelStatus, setStatus] = useState(status)
  const [payload, setPayload] = useState(initialPayload)
  const [placeIndex, setPlaceIndex] = useState(0)
  const [bound, setBound] = useState({})
  const [organizations, setOrganization] = useState(["0"])
  const [user, setUser] = useState(["0"])
  const [renderr, setRender] = useState(true)
  const [checked, setChecked] = useState(false)
  const [zoom, setZoom] = useState(3)
  const [center, setCenter] = useState(DEFAULT_CENTER)
  const [errorMsg, setErrorMsg] = useState("")
  const [initailRender, setInitialRender] = useState(true)
  const [isFilterApplied, setIsFilterApplied] = useState(false)
  const [userRole, setUserRole] = useState([])
  const [userOrganizationDetails, setUserOrganizationDetails] = useState([])

  const handleDashBoardSearch = event => {
    props.dashboardSearch(payload)
    props.getOrganizationlist()
    props.getUserlist()
    if (
      !isFilterApplied ||
      (payload.organizations.length === 0 &&
        payload.users.length === 0 &&
        payload.to_datetime === undefined)
    ) {
      setTimeout(() => {
        props.getInitialStatus()
      }, 1000)
    }
  }

  useEffect(() => {
    handleDashBoardSearch(payload)
    setTimeout(() => {
      props.getInitialStatus()
    }, 1000)
  }, [])

  useEffect(() => {
    if (status !== undefined) {
      setStatus(status)
      setRender(true)
    }
  }, [status])
  useEffect(() => {
    if (userdata.length === 0) {
      setZoom(2)
      setCenter(DEFAULT_CENTER)
    }
  }, [userdata])

  useEffect(() => {
    setRender(false)
    props.dashboardSearch(payload)
    setDataSet([])
    console.log('useEffect dataSet')
    console.log(dataSet)
    if (
      payload.organizations.length === 0 &&
      payload.users.length === 0 &&
      payload.to_datetime === undefined
    ) {
      setTimeout(() => {
        props.getInitialStatus()
      }, 1500)
    }
  }, [payload])

  useEffect(() => {
    const validate = validateFromAndToDate(selectedDate)
    if (validate) {
      setErrorMsg(validate)
    } else if (!initailRender) {
      setErrorMsg("")
      setPayload(prevState => ({
        ...prevState,
        to_datetime: moment.utc(selectedDate).format("YYYY-MM-DDTHH:mm:ss")
      }))
    }
  }, [selectedDate])

  //listen for change in user role to facilitate page rerender/refresh to show/hide relevant components
  useEffect(() => {
    const role = getCookie('roles')
    setUserRole(role)
  // }, [userRole]) // subscribing to userRole does not really matter as we're changing it's value each time a new cookie is set
  }, [])

  const handleDateChange = e => {
    setIsFilterApplied(true)
    setInitialRender(false)
    setDateChange(e)
  }

  const onlinedata =
    userdata !== undefined && userdata.length > 0
      ? userdata.filter(data => data.is_online)
      : []

  const handleChange = username => {
    props.clearUserInfo()
    handleUserInfo(username)
    setChecked(true)
  }

  const handleDialogClose = () => {
    props.clearUserInfo()
    setChecked(false)
  }

  const handleUserChange = event => {
    let usr = [...event.target.value]
    setIsFilterApplied(true)
    if (usr.includes("0")) {
      const index = usr.indexOf("0")
      usr.splice(index, 1)
    }
    setUser(usr)
    if (event.target.value.length === 0) {
      setUser(["0"])
      setPayload(prevState => ({
        ...prevState,
        users: []
      }))
    }
    if (Value) setValue("")
    setPayload(prevState => ({
      ...prevState,
      users: usr
    }))
  }

  const handleOrgChange = event => {
    setIsFilterApplied(true)
    setUser(["0"])
    setUser(["0"])
    setPayload(prevState => ({
      ...prevState,
      users: []
    }))
    let org = [...event.target.value]
    if (org.includes("0")) {
      const index = org.indexOf("0")
      org.splice(index, 1)
    }
    props.getUserlist(org)
    setOrganization(org)
    if (event.target.value.length === 0) {
      setOrganization(["0"])
      setUser(["0"])
      setPayload(prevState => ({
        ...prevState,
        organizations: []
      }))
    }
    if (Value) setValue("")
    setPayload(prevState => ({
      ...prevState,
      organizations: org,
      users: []
    }))
  }

  const handleClose = value => {
    setOpen(false)
  }

  const handleUserInfo = value => {
    props.getUserInfo(value, { detailed: false })
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleHeatMap = bool => {
    console.log('handleHeatMap bool')
    console.log(bool)
    setIsHeatmap(bool)
  }

  const getTotalMarkers = markerArr => {
    console.log('getTotalMarkers markerArr')
    console.log(markerArr)
    console.log('getTotalMarkers markerCollection 1')
    console.log(markerCollection)
    markerCollection.forEach(marker => {
      marker && marker.setMap(null)
    })
    setCollection(markerArr)
    console.log('getTotalMarkers markerCollection 2')
    console.log(markerCollection)
  }

  const handleValue = val => {
    setValue(val)
    if (organizations.filter(org => org !== "0").length > 0)
      setOrganization(["0"])
    if (user.filter(usr => usr !== "0").length > 0) {
      setUser(["0"])
      props.getUserlist()
    }
    const filterd =
      userdata !== undefined && userdata.length > 0 && val !== ""
        ? userdata.filter(user => {
            return user.username.toLowerCase().indexOf(val.toLowerCase()) > -1
          })
        : []
    setZoom(3)
    setCenter(DEFAULT_CENTER)
    setDataSet(filterd)
    console.log('handleValue dataSet')
    console.log(dataSet)
  }

  const onSelect = option => {
    setDataSet([])
    if (option.username) {
      handleValue(option.username)
      setPayload(prevState => ({
        ...prevState,
        users: [option.username],
        organizations: []
      }))
    } else {
      handleValue(option.name)
      setPayload(prevState => ({
        ...prevState,
        users: [],
        organizations:
          option.name !== option.organization_id
            ? [option.organization_id]
            : [option.name]
      }))
    }
    console.log('onSelect dataSet')
    console.log(dataSet)
  }

  const onKeyDown = event => {
    setIsFilterApplied(true)
    if (event.key === "Enter") {
      event.preventDefault()
      event.stopPropagation()
      let text = event.target.value
      setValue(event.target.value)
      const filterd =
        userdata !== undefined &&
        userdata.length > 0 &&
        event.target.value !== ""
          ? userdata.filter(user => {
              return user.username.toLowerCase() === text.toLowerCase()
            })
          : []
      setZoom(10)
      let center = {}
      if (filterd.length > 0) {
        center = {
          lat: filterd[placeIndex].location
            ? filterd[placeIndex].location.latitude
            : 0,
          lng: filterd[placeIndex].location
            ? filterd[placeIndex].location.longitude
            : 0
        }
        setCenter(center)
      }
      setDataSet(filterd)
      console.log('onKeyDown dataSet')
      console.log(dataSet)
    } else if (event.target.value === "") {
      event.preventDefault()
      event.stopPropagation()
      let text = event.target.value
      // setValue(event.target.value)
      setPayload(prevState => ({
        ...prevState,
        organizations: [],
        users: []
      }))
    }
    // reset users dropdown data
    if (isUserListFiltered) props.getUserlist()
    //ONCE API IS COMMENCED
    let payload = {
      search: event.target.value
    }
    props.dashboardSearch(payload, true)
    setDataSet(suggestion)
    console.log('suggestion/ dataSet')
    console.log(dataSet)
  }

/************************* START USER'S ORGANIZATION DETAILS ***************************************/

  const organizationID = getCookie('userOrgID')

  useEffect(() => { //put these two in separate useEffect hooks if necessary
    //Set up organization object to pre-populate create ticket form
    const activeUserOrg = (organization && organization.length > 0) ? organization.find(org => (org.id == organizationID)) : {}
    setUserOrganizationDetails(activeUserOrg)

    //get detailed org user list
    let payload = {
      id: organizationID,
    }
    store.dispatch.Organization.getOrganizationlistAppUsers(payload)
  }, [organization])

  //Set up new BasePanel data object for individual organizations
  const orgAppUsers = props.organizationState.appuser
  let orgStatus = {}
  let count = 0

  orgStatus.n_orgs = 1 //default for one organization
  orgStatus.n_users = orgAppUsers.length || 0 //account for falsey values

  Array.from(orgAppUsers, element =>
    element.is_online ? count++ : count,
  )
  orgStatus.n_online_users = count
  orgStatus.n_offline_users = orgStatus.n_users - orgStatus.n_online_users || 0 //account for falsey values

  const handleCreateTicketButtonClick = () => {
    props.history.replace({
      pathname: "/createticket",
      state: {
        userOrgDetails: userOrganizationDetails,
        isExistingUser: true
      }
    })
  }

/************************* END USER'S ORGANIZATION DETAILS ***************************************/
  
  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12}>
            <DashboardTopWrapper>
              <DashboardHeading>Dashboard</DashboardHeading>
              <DashboardButtonWrapper>
              { userRole && userRole.includes(Roles.SUPER_ADMIN) &&
                <DashboardListWrapper onClick={() => {
                  props.history.push("/ticketlist")
                }}>
                  <CreateText> Manage Tickets </CreateText>
                </DashboardListWrapper>
              }
              <DashboardCreateWrapper onClick={handleCreateTicketButtonClick}>
                <CreateText> Create Ticket </CreateText>
                <img
                  style={{
                    margin: "12px 7px 13px 9px"
                  }}
                  src={AddIcon}
                />
              </DashboardCreateWrapper>
              </DashboardButtonWrapper>
            </DashboardTopWrapper>
            <Grid container justify="center" spacing={1}>
              <Grid container spacing={1}>
              { userRole && userRole.includes(Roles.SUPER_ADMIN) /* || userRole.includes(Roles.TENANT_ADMIN) */ &&
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%"
                  }}>
                  <div className={classes.searchMargin}>
                    <AutoSuggest
                      value={Value}
                      options={suggestion}
                      optionLabel="username"
                      optionLabelOrg="name"
                      onOptionSelect={onSelect}
                      loading={true}
                      textFieldProps={{
                        setValue: handleValue,
                        error: false,
                        classes,
                        placeHolder: "Search...",
                        width: "fullwidth",
                        type: "Text",
                        id: "search",
                        margin: "normal",
                        variant: "outlined",
                        icon,
                        onKeyDown
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      paddingRight: "55px",
                      width: "600px"
                    }}>
                    <Select
                      classesParent={classes}
                      data={organization}
                      valueProp={organizations}
                      onChange={handleOrgChange}
                      label="Organizations"
                      multiple
                    />
                    <Select
                      classesParent={classes}
                      data={userList}
                      valueProp={user}
                      onChange={handleUserChange}
                      label="Users"
                      multiple
                    />

                    <FormControl className={classes.dateTimePicker}>
                      <DateTimePicker
                        selectedDate={selectedDate}
                        handleDateChange={handleDateChange}
                        disableFuture
                      />
                    </FormControl>
                  </div>
                </div>
                }
                {errorMsg && (
                  <div className={classes.errorMessage}>{errorMsg}</div>
                )}
                <Grid container item xs={12} spacing={3}>
                  <Grid
                    className={classes.mapContainer}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    style={{
                      display: "flex",
                      flexDirection: "column"
                    }}>
                    {renderr &&
                    panelStatus !== undefined &&
                    userdata !== undefined &&
                    userdata.length >= 0 &&
                    markers !== undefined &&
                    markers.length >= 0 ? (
                      <>
                        <Map
                          status={panelStatus}
                          onOpen={handleOpen}
                          handleHeatMap={handleHeatMap}
                          markers={markerWindow}
                          refreshMap={() => {
                            props.clearUserData()
                            handleDashBoardSearch()
                          }}
                          dataSet={dataSet.length > 0 ? dataSet : userdata}
                          isLoading={isLoading}
                          zoom={zoom}
                          center={center}
                          events={{ onBoundsChangerd: arg => setBound(arg) }}
                          styles={classes.mapStyles}>
                          <Marker
                            isheatmap={isheatmap}
                            filterMarker={filterMarker}
                            gmMarker={gmMarker}
                            dataSet={dataSet.length > 0 ? dataSet : userdata}
                            getTotalMarkers={getTotalMarkers}
                            handleChange={handleChange}
                          />
                        </Map>
                        <BasePanel
                          status={ userRole && userRole.includes(Roles.SUPER_ADMIN) || userRole.includes(Roles.RISKOVERY_ADMIN) ? panelStatus && panelStatus : orgStatus && orgStatus}
                          onOpen={handleOpen}
                        />
                      </>
                    ) : (
                      <div
                        style={{
                          width: " 100%",
                          overflow: " hidden",
                          position: " relative",
                          minHeight: " 740px",
                          borderRadius: " 16px",
                          textAlign: "center"
                        }}>
                        <CircularProgress size={40} />
                      </div>
                    )}

                    {userInfo !== undefined ? (
                      <DialogUserDetails
                        checked={checked}
                        handelClose={handleDialogClose}
                        userinfo={userInfo}
                      />
                    ) : (
                      ""
                    )}
                    <Dialog open={open} onClose={handleClose}>
                      <DialogCountryFilter
                        userdata={onlinedata}
                        title="Online users per country"
                        history={props.history}
                      />
                    </Dialog>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

const mapStateToProps = state => {
  return { 
    dashboardState: state.Dashboard,
    organizationState: state.Organization
  }
}

const mapDispatchToProps = dispatch => {
  return {
    dashboardSearch: dispatch.Dashboard.startDashboard,
    getUserInfo: dispatch.Dashboard.getUserInfo,
    searchFilter: dispatch.Dashboard.filterSearch,
    getOrganizationlist: dispatch.Dashboard.getOrganizationlist,
    clearUserInfo: dispatch.Dashboard.UserInfoClear,
    clearUserData: dispatch.Dashboard.UserDataClear,
    getUserlist: dispatch.Dashboard.getUserlist,
    getInitialStatus: dispatch.Dashboard.getInitialStatus,
  }
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CenteredGrid)
