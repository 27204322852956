import React, { useRef } from "react";

import { useGoogleMap, useMap } from "./map.hooks";
import { DEFAULT_CENTER } from "../../../config";

const initialConfig = {
  zoom: 2,
  center: DEFAULT_CENTER,
  styles: [
    {
      elementType: "geometry",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      elementType: "labels.icon",
      stylers: [
        {
          visibility: "off"
        }
      ]
    },
    {
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      elementType: "labels.text.stroke",
      stylers: [
        {
          color: "#f5f5f5"
        }
      ]
    },
    {
      featureType: "administrative.land_parcel",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#bdbdbd"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "poi",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "poi.park",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          color: "#ffffff"
        }
      ]
    },
    {
      featureType: "road.arterial",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#757575"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          color: "#dadada"
        }
      ]
    },
    {
      featureType: "road.highway",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#616161"
        }
      ]
    },
    {
      featureType: "road.local",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    },
    {
      featureType: "transit.line",
      elementType: "geometry",
      stylers: [
        {
          color: "#e5e5e5"
        }
      ]
    },
    {
      featureType: "transit.station",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          color: "#c9c9c9"
        }
      ]
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e"
        }
      ]
    }
  ],
  disableDefaultUI: true
};
const Gmap = ({
  minHeight,
  coordinates,
  isDrawerManager,
  geospatialApi,
  userMarkers,
  userWatchlistClear,
  selectedCircleRadius,
  userMarkersPolygons,
  setFilterDisable,
  history,
  isNotifcations,
  handleWatchlistChangeClear,
  watchlist,
  isViewMapNotification
}) => {
  const googleMap = useGoogleMap(process.env.GOOGLE_MAPS_API_KEY);
  const mapContainerRef = useRef(null);
  useMap({
    googleMap,
    mapContainerRef,
    initialConfig,
    setFilterDisable,
    coordinates,
    isDrawerManager,
    geospatialApi,
    userMarkers,
    userWatchlistClear,
    selectedCircleRadius,
    userMarkersPolygons,
    history,
    isNotifcations,
    handleWatchlistChangeClear,
    watchlist,
    isViewMapNotification
  });
  return (
    <div
      style={{
        margin: " 0px 0px 0px 1px",
        minHeight: minHeight ? minHeight : "433px",
        position: "relative",
        overflow: "hidden",
        width: "100%",
        borderRadius: "16px"
      }}
      ref={mapContainerRef}
    />
  );
};

export default Gmap;
