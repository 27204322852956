const setCookie = (name, value, options = {}) => {
  options = {
    path: '/',
    'max-age': 2592000, //30 days
    //comment below lines out when working locally
    // httpOnly,
    samesite: 'strict',
    secure: true,
    // add other defaults here if necessary
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

// returns the cookie with the given name or undefined if not found
const getCookie = (name) => {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

const deleteCookie = (name) => {
  setCookie(name, "", {
    'max-age': -1
  })
}

export { setCookie, getCookie, deleteCookie }