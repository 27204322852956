import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { sortBy } from "lodash";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

const columns = [
  { id: "name", label: "Name" },
  { id: "number", label: "Number" },
  {
    id: "log_type",
    label: "Type",

    format: value => (value ? value.toLocaleString() : "N/A")
  },
  {
    id: "duration",
    label: "Duration",

    format: value => value.toFixed(2)
  },
  {
    id: "time",
    label: "Time",
    format: value => moment.unix(value).format("MM/DD/YYYY HH:mm:ss")
  }
];
const useStyles = makeStyles({
  root: {
    width: "100%"
  },
  heading: {
    width: " 100%",
    height: "38px",
    fontFamily: " Google Sans",
    fontSize: " 30px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#161a1e"
  },
  tableHeader: {
    borderRadius: "8px",
    fontFamily: "Google Sans",
    fontWeight: "normal",
    "& tr": {
      height: "38px",
      backgroundColor: "rgb(65, 69, 73)",
      "& th": {
        backgroundColor: "inherit"
      },
      "& th:first-child": {
        borderRadius: "8px 0 0 8px"
      },
      "& th:last-child": {
        borderRadius: "0 8px 8px 0 "
      }
    }
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: "10px"
  }
});
const StyledTableCell = withStyles(theme => ({
  root: {
    fontFamily: "Google Sans !important"
  },
  body: {
    fontSize: 14
  }
}))(TableCell);
const StyledTableTableCell = withStyles(theme => ({
  root: {
    color: "#ffffff",
    height: "38px",
    paddingTop: "0",
    paddingBottom: "0",
    fontFamily: " Google Sans",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal"
  }
}))(TableCell);
const StyledTableCellRow = withStyles(theme => ({
  root: {
    color: "#ffffff"
  }
}))(TableRow);

export default function StickyHeadTable(props) {
  const classes = useStyles();
  // const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [callLogs, setCallLogs] = useState({});
  const handleChangePage = (event, newPage) => {
    props.getCallLog({
      user_id: props.userId,
      limit: rowsPerPage,
      page: newPage
    });
  };

  useEffect(() => {
    if (props.call !== undefined && props.call.length > 0) {
      let sorted = sortBy(props.call, function(o) {
        return new moment.unix(o.time);
      }).reverse();
      setCallLogs(sorted);
    }
  }, [props.call]);

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
  };

  useEffect(() => {
    props.getCallLog({
      user_id: props.userId,
      limit: rowsPerPage,
      page: 0
    });
  }, [rowsPerPage])

  return props.isCallLogLoading ? (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  ) : (
    <Paper>
      <Table stickyHeader aria-label="sticky table">
        <TableHead className={classes.tableHeader}>
          <StyledTableCellRow>
            {columns.map(column => (
              <StyledTableTableCell
                key={column.id}
                align={column.align}
                style={{ minWidth: column.minWidth }}>
                {column.label}
              </StyledTableTableCell>
            ))}
          </StyledTableCellRow>
        </TableHead>
        <TableBody>
          {callLogs !== undefined && callLogs.length > 0
            ? callLogs.map(row => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map(column => {
                      const value = row[column.id];
                      return (
                        <StyledTableCell key={column.id} align={column.align}>
                          <span>
                            {column.format ? column.format(value) : value}
                          </span>
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            : ""}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10]}
        component="div"
        count={props.totalCount}
        rowsPerPage={rowsPerPage}
        page={props.currentPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );
}
