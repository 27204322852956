import styled from "styled-components";
export const SubformWrapper = styled.section`
         width: 100%;
         display: flex !important;
         margin:${props =>
           props.idx === 0
             ? " 0px 50px 25px 50px"
             : "0px 0px 25px 48px"}; 0px 0px 25px 48px;
         height: 143px;
         border-radius: 8px;
         border: solid 1px rgba(71, 59, 59, 0.15);
         background-color: #ffffff;
         flex-direction: ${props => (props.idx === 0 ? "column" : "row")};
       `;
export const AddMore = styled.div`
         display: flex;
         width: 118px;
         height: 32px;
         border-radius: 8px;
         background-color: #ffeeed;
         margin: 10px 31px 8px 1px;
         cursor: pointer;
       `;
export const AddmoreText = styled.span`
         display: flex;
         justify-content: center;
         margin: 6px 0px 0px 10px;
         height: 18px;
         font-family: Google Sans;
         font-size: 14px;
         font-weight: 500;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         color: #ff3b30;
       `;       
export const PrimaryContactText = styled.section`
  width: 132px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #acacac;
`;

export const Label = styled.label`
         width: 200px;
         margin-bottom: 10px;
         height: 16px;
         font-family: Google Sans;
         font-size: 12px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: 0.6px;
         color: ${props => (props.error ? "#ff2d21" : "#292b2e")};
       `;
export const SubformFormControl = styled.div`
         display: flex;
         flex-direction: column;
         width: 100%;
         .name {
           width: 88%;
           padding: 12px;
           font-size: 14px;
           height: 38px;
           border-radius: 8px;
           box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
           border: solid 1px rgba(71, 59, 59, 0.2);
           background-color: #ffffff;
         }
         .office_phone_number {
           width: 88%;
           padding: 12px;
           font-size: 14px;
           height: 38px;
           border-radius: 8px;
           box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
           border: solid 1px rgba(71, 59, 59, 0.2);
           background-color: #ffffff;
         }
         .phone {
           width: 88%;
           padding: 12px;
           font-size: 14px;
           height: 38px;
           border-radius: 8px;
           box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
           border: solid 1px rgba(71, 59, 59, 0.2);
           background-color: #ffffff;
         }
         .email {
           width: 88%;
           padding: 12px;
           font-size: 14px;
           height: 38px;
           border-radius: 8px;
           box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
           border: solid 1px rgba(71, 59, 59, 0.2);
           background-color: #ffffff;
         }
       `;

export const InputWrap = styled.input`
  width: 88%;
  padding: 12px;
  font-size: 14px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(71, 59, 59, 0.2);
  background-color: #ffffff;
`;
export const SubformSection = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0px 0px 0px 45px;
`;
export const PhoneNumber = styled.div`
.react-phone-number-input__country-select{
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}
.react-phone-number-input__country-select-arrow{
  display: block;
   content: '';
   width: 0;
   height: 0;
   margin-bottom: 0.1em;
   margin-top: 0.3em;
   margin-left: 0.3em;
   border-width: 0.35em 0.2em 0 0.2em;
   border-style: solid;
   border-left-color: transparent;
   border-right-color: transparent;
   color: #B8BDC4;
   opacity: 0.7;
   transition: color 0.1s;
}
.react-phone-number-input__country{
  display: flex;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  margin-bottom: 0.4em;
}
.react-phone-number-input__row{
  display: flex;
}
.react-phone-number-input {
  input {
    border-bottom: 1px solid #c5d2e0;
    width: 234px;
    border: solid 1px rgba(71, 59, 59, 0.2);
    height: 38px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    padding-left: 12px;
  }
}
.react-phone-number-input__icon {
  width: 32px;
  height: 22px;
  border: none;
}
 .MuiFormHelperText-root.Mui-error{
   width: 300px;
 }
                 .errorElement {
                   color: #990000;
                   /* margin-left: 45px; */
                   margin-top: -4px;
                   font-family: Gilroy;
                   font-size: 14px;
                   line-height: 25px;
                 }
               `;
