import React, { useState, useEffect } from "react";
import moment from "moment";
import GoogleMapsApiLoader from "google-maps-api-loader";
import useGoogleMapMarker from "../google/useGoogleMapMarker";
import MarkerIcon from "../../../assets/img/marker.png";
import SafeIcon from "../../../assets/img/safe.png";
import DistressIcon from "../../../assets/img/distress.png";
import InvalidIcon from "../../../assets/img/invalid1x.png";
import ExceedIcon from "../../../assets/img/exceed.png";
import RespondedIcon from "../../../assets/img/responded.png";

const libraries = ["places", "drawing", "visualization"];
let isDragged = false;
let selectedShape;
let drawingManager;
const useGoogleMap = apiKey => {
  const [googleMap, setGoogleMap] = useState(null);
  useEffect(() => {
    GoogleMapsApiLoader({ apiKey, libraries }).then(google => {
      setGoogleMap(google);
    });
  }, []);
  return googleMap;
};

const useMap = ({
  googleMap,
  mapContainerRef,
  initialConfig,
  setFilterDisable,
  coordinates,
  isDrawerManager,
  geospatialApi,
  userMarkers,
  userWatchlistClear,
  selectedCircleRadius,
  userMarkersPolygons,
  history,
  isNotifcations,
  handleWatchlistChangeClear,
  watchlist,
  isViewMapNotification
}) => {
  const [map, setMap] = useState(null);
  const [radius, setRadius] = useState(null);
  useEffect(() => {
    if (selectedCircleRadius) setRadius(selectedCircleRadius);
  }, [selectedCircleRadius]);

  useEffect(() => {
    if (!googleMap || !mapContainerRef.current) {
      return;
    }
    if (window.paths !== undefined && window.paths.length > 0) {
      window.paths.forEach(path => {
        path.setMap(null);
      });
    }
    window.paths = [];
    if (userMarkersPolygons && !isDrawerManager) {
      window.markers.forEach(marker => {
        marker && marker.setMap(null);
      });

      const bounds = new googleMap.maps.LatLngBounds();
      window.markers = [];
      const markerCollections = userMarkersPolygons.map((m, index) => {
        const title = m.username;
        const position = {
          lat: m.location.latitude,
          lng: m.location.longitude
        };
        const events = {
          onClick: () => {}
        };
        const marker = new googleMap.maps.Marker({
          icon: MarkerIcon,
          title,
          position,
          map
        });
        marker.addListener("click", function() {
          history.push(`/${m.username}`);
        });
        return marker;
      });
      window.markers = markerCollections;

      if (window.paths !== undefined && window.paths.length > 0) {
        window.paths.forEach(path => {
          path.setMap(null);
        });
      }
      var Colors = [
        "#FF0000",
        "#0000FF",
        "#f50093",
        "#FFFF00",
        "#FF00FF",
        "#f56e00",
        "#4cd964"
      ];
      let trailMarkers = [],
        geoDesicPolyArray = [],
        trailData = [];

      let paths = [];
      window.paths = [];
      // trailData = userMarkersPolygons.map((item, index) => {
      //   if (item.trail.length > 0) {
      //     trailMarkers = item.trail.map((m, index) => {
      //       const position = {
      //         lat: m.latitude,
      //         lng: m.longitude
      //       };
      //       paths.push(position);
      //     });
      //   }
      //   let geodesicPoly = new google.maps.Polyline({
      //     path: paths,
      //     strokeColor: Colors[index],
      //     strokeOpacity: 0.6,
      //     strokeWeight: 3,
      //     geodesic: true,
      //     map: map
      //   });
      //   window.paths.push(geodesicPoly);
      // });
    }
  }, [userMarkersPolygons, isDrawerManager, googleMap, mapContainerRef]);

  useEffect(() => {
    if (!googleMap || !mapContainerRef.current) {
      return;
    }
    const newMap =
      map || new googleMap.maps.Map(mapContainerRef.current, initialConfig); // this is bugfix to not reinitialize the map
    if (watchlist !== undefined && watchlist.shape === "custom") {
      const bounds = new googleMap.maps.LatLngBounds();
      window.polygon.forEach(poly => {
        poly.setMap(null);
      });
      let pathsArr = [];
      watchlist.coordinates.forEach(element => {
        let coord = {
          lat: element.lat,
          lng: element.lon
        };
        pathsArr.push(coord);
      });
      var polygon = new google.maps.Polygon({
        fillColor: "rgba(255, 149, 0, 0.12)",
        fillOpacity: 0.3,
        strokeColor: "#ff9500",
        strokeWeight: 2,
        clickable: true,
        zIndex: 1,
        paths: pathsArr
      });
      polygon.setMap(newMap);
      for (let i = 0; i < pathsArr.length; i++) {
        bounds.extend(pathsArr[i]);
      }
      window.polygon.push(polygon);
      newMap.fitBounds(bounds);
    } else if (watchlist !== undefined && watchlist.shape === "circle") {
      window.circle.forEach(cir => {
        cir.setMap(null);
      });
      let latlng = new google.maps.LatLng(
        watchlist.coordinates[0].lat,
        watchlist.coordinates[0].lon
      );

      let sunCircle = {
        strokeColor: "#ff9500",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "rgba(255, 149, 0, 0.12)",
        fillOpacity: 0.35,
        map: newMap,
        center: latlng,
        radius: watchlist.radius ? watchlist.radius * 1000 : 15000 // in meters
      };

      let cityCircle = new google.maps.Circle(sunCircle);
      window.circle.push(cityCircle);
      newMap.fitBounds(cityCircle.getBounds());
    }
  }, [watchlist, isDrawerManager, googleMap, mapContainerRef]);

  useEffect(() => {
    if (!googleMap || !mapContainerRef.current) {
      return;
    }
    if (window.markers !== undefined && window.markers.length > 0) {
      window.markers &&
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
    }
    if (userMarkers !== undefined && !isViewMapNotification) {
      const bounds = new googleMap.maps.LatLngBounds();
      window.markers = [];
      const markerCollections = userMarkers.map((m, index) => {
        const title = m.username;
        const position = {
          lat: m.location.latitude,
          lng: m.location.longitude
        };
        const marker = new googleMap.maps.Marker({
          icon: MarkerIcon,
          title,
          position,
          map: map
        });
        marker.addListener("click", function() {
          history.push(`/${m.username}`);
        });
        return marker;
      });
      window.markers = markerCollections;
    } else if (isViewMapNotification && userMarkers !== undefined) {
      const bounds = new googleMap.maps.LatLngBounds();

      const markerCollections = userMarkers.map((m, index) => {
        const title = m.username;
        const position = {
          lat: m.location.lat,
          lng: m.location.lon
        };
        let marker;
        if (m.status === "safe") {
          const isOld = moment().isSameOrAfter(
            moment(m.updated_on)
              .add(12, "hours")
              .local(),
            "minute"
          );
          marker = new googleMap.maps.Marker({
            icon: isOld ? ExceedIcon : SafeIcon,
            title,
            position,
            map
          });
        } else if (m.status === "not_responded") {
          marker = new googleMap.maps.Marker({
            icon: InvalidIcon,
            title,
            position,
            map
          });
        } else if (m.status === "distress") {
          marker = new googleMap.maps.Marker({
            icon: DistressIcon,
            title,
            position,
            map
          });
        } else if (m.status === "responded") {
          marker = new googleMap.maps.Marker({
            icon: RespondedIcon,
            title,
            position,
            map
          });
        }
        if (marker)
          marker.addListener("click", () => {
            history.push(`/${m.username}`);
          });
        return marker;
      });
      window.markers = markerCollections;
    }
  }, [userMarkers, googleMap, mapContainerRef]);

  useEffect(() => {
    if (!googleMap || !mapContainerRef.current) {
      return;
    }
    const map = new googleMap.maps.Map(mapContainerRef.current, initialConfig);
    let polyarray = [];
    // Define the LatLng coordinates for the polygon's path.
    // Construct the polygon.
    var bounds = new googleMap.maps.LatLngBounds();

    if (isDrawerManager) {
      var polyOptions = {
        editable: true,
        draggable: true,
        fillColor: "rgba(255, 149, 0, 0.12)",
        fillOpacity: 0.3,
        strokeColor: "#ff9500",
        strokeWeight: 2,
        clickable: true,
        zIndex: 1
      };
      drawingManager = new google.maps.drawing.DrawingManager({
        drawingControlOptions: {
          drawingModes: [
            google.maps.drawing.OverlayType.POLYGON,
            google.maps.drawing.OverlayType.CIRCLE
          ]
        },
        markerOptions: {
          draggable: true
        },
        polylineOptions: {
          editable: true
        },
        rectangleOptions: polyOptions,
        circleOptions: polyOptions,
        polygonOptions: polyOptions,
        map: map
      });
      var customoptions = document.createElement("div");
      var controlUI = document.createElement("div");
      controlUI.style.backgroundColor = "rgb(96, 188, 167)";
      controlUI.style.borderStyle = "solid";
      controlUI.style.borderRadius = "10px";
      controlUI.style.boxShadow =
        "rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(0, 0, 0, 0.2) 0px 0px 10px 0px";
      controlUI.style.borderWidth = "1px";
      controlUI.style.borderColor = "#ccc";
      controlUI.style.marginTop = "4px";
      controlUI.style.marginRight = "11px";
      controlUI.style.cursor = "pointer";
      controlUI.style.textAlign = "center";
      controlUI.title = "Click to set the map to Home";
      customoptions.appendChild(controlUI);
      // Set CSS for the control interior
      var controlText = document.createElement("div");
      controlText.style.fontFamily = "Google Sans";
      controlText.style.fontSize = "15px";
      controlText.style.paddingLeft = "4px";
      controlText.style.paddingRight = "4px";
      controlText.style.paddingTop = "7px";
      controlText.style.paddingBottom = "7px";
      controlText.style.color = "#ffffff";
      controlText.style.width = "95px";
      controlText.innerHTML = "Reset";
      controlUI.appendChild(controlText);
      // Setup the click event listeners
      google.maps.event.addDomListener(customoptions, "click", function() {
        if (window.paths !== undefined && window.paths.length > 0) {
          window.paths.forEach(path => {
            path.setMap(null);
          });
        }

        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
        window.markers = [];
        if (!isNotifcations) {
          userWatchlistClear();
        } else {
          handleWatchlistChangeClear();
        }
        deleteSelectedShape(selectedShape, drawingManager);
        setFilterDisable(true);
        if (window.paths !== undefined && window.paths.length > 0) {
          window.paths.forEach(path => {
            path.setMap(null);
          });
        }
      });
      let drawshape = document.get;
      customoptions.index = 1;
      map.controls[google.maps.ControlPosition.TOP_RIGHT].push(customoptions);
      // Creates a drawing manager attached to the map that allows the user to draw
      // markers, lines, and shapes.

      google.maps.event.addListener(drawingManager, "overlaycomplete", function(
        e
      ) {
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
        setFilterDisable(false);
        if (e.type === google.maps.drawing.OverlayType.POLYGON) {
          // Add an event listener that selects the newly-drawn shape when the user
          drawingManager.setDrawingMode(null);
          // To hide:
          drawingManager.setOptions({
            drawingControl: false
          });
          let newShape = e.overlay;
          let coordinateArray = [];
          let coordinates = newShape.getPath().getArray();
          for (let i = 0; i < coordinates.length; i++) {
            let latitude = coordinates[i].lat();
            let longitude = coordinates[i].lng();
            let obj = {
              latitude,
              longitude
            };
            coordinateArray.push(obj);
          }
          coordinateArray.push(coordinateArray[0]);
          geospatialApi(coordinateArray);
          google.maps.event.addListener(newShape, "dragend", function(e) {
            let coordinateArray = [];
            let coordinates = newShape.getPath().getArray();
            for (let i = 0; i < coordinates.length; i++) {
              let latitude = coordinates[i].lat();
              let longitude = coordinates[i].lng();
              let obj = {
                latitude,
                longitude
              };
              coordinateArray.push(obj);
            }
            coordinateArray.push(coordinateArray[0]);
            geospatialApi(coordinateArray);
            isDragged = false;
          });
          google.maps.event.addListener(newShape, "dragstart", function(e) {
            isDragged = true;

            window.markers.forEach(marker => {
              marker && marker.setMap(null);
            });
            window.markers = [];
            if (window.paths !== undefined && window.paths.length > 0) {
              window.paths.forEach(path => {
                path.setMap(null);
              });
            }
          });
          let shapePath = newShape.getPath();
          google.maps.event.addListener(shapePath, "insert_at", function(e) {
            if (!isDragged) {
              window.markers.forEach(marker => {
                marker && marker.setMap(null);
              });
              if (window.paths !== undefined && window.paths.length > 0) {
                window.paths.forEach(path => {
                  path.setMap(null);
                });
              }
              let coordinateArray = [];
              let coordinates = newShape.getPath().getArray();
              for (let i = 0; i < coordinates.length; i++) {
                let latitude = coordinates[i].lat();
                let longitude = coordinates[i].lng();
                let obj = {
                  latitude,
                  longitude
                };
                coordinateArray.push(obj);
              }
              coordinateArray.push(coordinateArray[0]);
            }
          });
          google.maps.event.addListener(shapePath, "set_at", function(e) {
            if (!isDragged) {
              window.markers.forEach(marker => {
                marker && marker.setMap(null);
              });
              if (window.paths !== undefined && window.paths.length > 0) {
                window.paths.forEach(path => {
                  path.setMap(null);
                });
              }
              let coordinateArray = [];
              let coordinates = newShape.getPath().getArray();
              for (let i = 0; i < coordinates.length; i++) {
                let latitude = coordinates[i].lat();
                let longitude = coordinates[i].lng();
                let obj = {
                  latitude,
                  longitude
                };
                coordinateArray.push(obj);
              }
              coordinateArray.push(coordinateArray[0]);
              geospatialApi(coordinateArray);
            }
          });
          newShape.type = e.type;
          google.maps.event.addListener(newShape, "click", function() {
            setSelection(newShape);
          });
          setSelection(newShape);
        }
        if (e.type === google.maps.drawing.OverlayType.CIRCLE) {
          drawingManager.setDrawingMode(null);
          drawingManager.setOptions({
            drawingControl: false
          });
          let circleObj = e.overlay;
          let center = {
            latitude: circleObj.center.lat(),
            longitude: circleObj.center.lng()
          };
          geospatialApi([], 4, center, circleObj.radius / 1000);
          setSelection(circleObj);
          google.maps.event.addListener(circleObj, "dragstart", function(e) {
            isDragged = true;
            window.markers.forEach(marker => {
              marker && marker.setMap(null);
            });
            window.markers = [];
            if (window.paths !== undefined && window.paths.length > 0) {
              window.paths.forEach(path => {
                path.setMap(null);
              });
            }
            // handleBoundClear();
            // setValue("");
          });
          google.maps.event.addListener(circleObj, "dragend", function(e) {
            isDragged = false;
            let center = {
              latitude: circleObj.center.lat(),
              longitude: circleObj.center.lng()
            };
            geospatialApi([], 4, center, circleObj.radius / 1000);
          });
          google.maps.event.addListener(circleObj, "center_changed", function(
            e
          ) {
            window.markers.forEach(marker => {
              marker && marker.setMap(null);
            });
            if (!isDragged) {
              if (window.paths !== undefined && window.paths.length > 0) {
                window.paths.forEach(path => {
                  path.setMap(null);
                });
              }
              let center = {
                latitude: circleObj.center.lat(),
                longitude: circleObj.center.lng()
              };
              geospatialApi([], 4, center, circleObj.radius / 1000);
            }
          });
          google.maps.event.addListener(circleObj, "radius_changed", function(
            e
          ) {
            window.markers.forEach(marker => {
              marker && marker.setMap(null);
            });
            if (window.paths !== undefined && window.paths.length > 0) {
              window.paths.forEach(path => {
                path.setMap(null);
              });
            }
            let center = {
              latitude: circleObj.center.lat(),
              longitude: circleObj.center.lng()
            };
            geospatialApi([], 4, center, circleObj.radius / 1000);
          });
        }
      });
    } else {
      if (coordinates && coordinates.length > 1) {
        coordinates.forEach(element => {
          let coord = { lat: element.lat, lng: element.lon };
          polyarray.push(coord);
        });
        for (let i = 0; i < polyarray.length; i++) {
          bounds.extend(polyarray[i]);
        }
        let Polygon = new google.maps.Polygon({
          paths: polyarray,
          fillColor: "rgba(255, 149, 0, 0.12)",
          fillOpacity: 0.3,
          strokeColor: "#ff9500",
          strokeWeight: 2,
          clickable: true,
          zIndex: 1
        });
        Polygon.setMap(map);
        map.fitBounds(bounds);
      } else if (coordinates && coordinates.length == 1) {
        let latlng = new google.maps.LatLng(
          coordinates[0].lat,
          coordinates[0].lon
        );

        let sunCircle = {
          strokeColor: "#ff9500",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "rgba(255, 149, 0, 0.12)",
          fillOpacity: 0.35,
          map: map,
          center: latlng,
          radius: selectedCircleRadius ? selectedCircleRadius * 1000 : 15000 // in meters
        };

        let cityCircle = new google.maps.Circle(sunCircle);

        map.fitBounds(cityCircle.getBounds());
        //  let cityCircle = new google.maps.Circle(sunCircle);
        //   cityCircle.bindTo("center", marker, "position");
      }
    }
    setMap(map);
  }, [
    googleMap,
    mapContainerRef,
    coordinates,
    isDrawerManager,
    userWatchlistClear,
    selectedCircleRadius
  ]);
  return map;
};

function clearSelection() {
  if (selectedShape) {
    selectedShape.setEditable(false);
    selectedShape = null;
  }
}
function setSelection(shape) {
  clearSelection();
  selectedShape = shape;
  shape.setEditable(true);
  //  selectColor(shape.get("fillColor") || shape.get("strokeColor"));
}
function deleteSelectedShape(selectedShape, drawingManager) {
  if (selectedShape) {
    selectedShape.setMap(null);
    // To show:
    drawingManager.setOptions({
      drawingControl: true
    });
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    window.markers = [];
  }
}

export { useGoogleMap, useMap };
