import styled from "styled-components";
export const WatchlistComponentsWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 30px);
  margin: 20px 20px 0px 20px;
  .time-picker input{
    padding-top: 0px;
  }
  .time-picker .MuiFormControl-marginNormal input{
    // padding-top: 10px;
  }
`;
export const WatchlistHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const WatchlistHeaderContent = styled.div`
display:flex
flex-direction:row;
justify-content:space-between;
`;
export const WatchlistContent = styled.div`
display:flex
flex-direction:row;
justify-content:space-between;
`;
export const HeaderLabel = styled.div`
  height: 36px;
  font-family: Google Sans;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
  display: flex;
  align-items: center;
  padding-right: 5px;
  @media (max-width: 1411px) {
    height: 36px;
    font-family: Google Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #161a1e;
  }
`;
export const ImageStyle = styled.div`
  font-family: Google Sans;
  font-size: 22px;
`;
export const WatchlistTimePicker = styled.div`
display:flex;
  margin-right: 16px;
  height: 38px;
  border-radius: 8px;
  .MuiFormControl-root{
    background-color: #f2f7fa;
  }
    @media (max-width: 1411px) {
           width: 100%
           height: 38px;
         }
  input {
    height: 20px;
  }
`;

export const WatchlistAddButton = styled.div`
  margin-right: 16px;
  height: 38px;
  border-radius: 8px;
  background-color: #ff2d21;
  cursor: pointer;
  @media (max-width: 1411px) {
    margin-right: 16px;
    width: 78%;
    height: 38px;
    border-radius: 8px;
    background-color: #ff2d21;
  }
`;

export const WatchlistAddButtonLocation = styled.div`
  margin-right: 16px;
  height: 38px;
  border-radius: 8px;
  background-color: #ff2d21;
  cursor: pointer;
`;
export const WatchlistAddButtonLocationCancel = styled.div`
  margin-right: 8px;
  width: 66px
  height: 38px;
  border-radius: 8px;
  cursor: pointer;
`;
export const WatchlistCancelButtonText = styled.span`
  display: flex;
  margin: 0 13px;
  height: 38px;
  font-family: Google Sans;
  font-size: 11px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  align-items: center;
  @media (min-width: 1400px) {
    font-size: 14px;
  }
`;
export const WatchlistAddButtonText = styled.span`
  display: flex;
  margin: 0px 13px;
  height: 38px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  align-items: center;
  @media (max-width: 1505px) {
    font-size: 10px;
  }
  .img {
    width: 11px;
    height: 11px;
    margin-left: 15px;
  }
`;

export const Aside = styled.aside`
  width: ${props => (props.isOpen ? "0px" : "100px")};
  transition: width 1s;
`;

export const WatchlistSliderButton = styled.div`
  width: 38px;
  height: 38px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #60bca7;
  cursor: pointer;
  .img {
    width: 11px;
    height: 14px;
    margin: 12px 0px 0px 12px;
  }
`;

export const WatchlistTableHeader = styled.div`
  display: flex;
  height: 25px;
  font-family: Google Sans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const WatchlistTableHeaderViewProfile = styled.div`
  display: flex;
  width: 79px;
  height: 17px;
  border-radius: 100px;
  border: solid 1px #60bca7;
  background-color: #ffffff;
  margin: 6px 200px 0px 7px;
`;
export const WatchlistTableHeaderViewProfileText = styled.span`
  cursor: pointer;
  display: flex;
  width: 67px;
  height: 13px;
  font-family: Google Sans;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #60bca7;
  margin: 1px 0px 0px 6px;
`;

export const WatchlistTableHeaderStatusText = styled.span`
  display: flex;
  height: 13px;
  opacity: 0.7;
  font-family: Google Sans;
  font-size: 10.4px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;

export const WatchlistTableHeaderStatusOvalOnline = styled.span`
  display: flex;
  width: 9.4px;
  height: 9.4px;
  background-color: ${props => (!props.isOnline ? "#ff2d21" : "#4cd964")};
  margin: 6px 3px 2px;
  border-radius: 6px;
`;
export const WatchlistTableHeaderTitle = styled.span`
  width: 175px;
  height: 21px;
  font-family: Google Sans;
  font-size: 16.6px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const SliderComponentOrganizationSelect = styled.div`
  margin: 11px 31px 0px 31px;
  display: flex;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(71, 59, 59, 0.2);
  background-color: #ffffff;
`;
export const BlueToothAutoSuggestWrapper = styled.div`
  display: flex;
  flex-direction: column;
   ul {
    // position: relative !important;
    max-height:100px !important;
  }
  img{
    width:16px !important;
  }
  div:nth-child(2) {
    border-bottom: solid 1px;
    margin-top: 16px;
    margin-bottom: 8px;
  }
  li div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
  }
  input::-ms-clear {
    width: 0;
    height: 0;
  }
  input[type="text"]::-ms-clear-button {
    display: none;
    width: 0;
    height: 0;
  }
`;
export const TextFieldWrapper = styled.div`
  input::-ms-clear {
    width: 0;
    height: 0;
  }
`;

