import React from "react";
import { useStyles } from "./footer.style";
export default () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div
        className={classes.riskoveryFooter}
        style={{ fontFamily: "Muli !important" }}>
        © 2020 Riskovery. All Rights Reserved.
      </div>
    </div>
  );
};
