import { api } from "../utils/http";
import { commonHeader } from "../utils/headerConfig";

export const initialState = {
  error: "",
  status: {},
  userdata: {},
  markers: {},
  gmMarkers: [],
  userInfo: {},
  filterInit: false,
  filterMarker: [],
  organization: {},
  suggestion: [],
  userList: {},
  isUserListFiltered: false,
  isUserInfoLoading: false,
  organizationDetails: [],
};
export const reducers = {
  UserDataClear(state, payload) {
    return {
      ...state,
      status: {},
      userdata: {}
    };
  },
  UserData(state, payload) {
    return {
      ...state,
      status: payload.data.stats,
      userdata: payload.data.users
    };
  },
  UserDataSuggetionClear(state, payload) {
    return {
      ...state,
      suggestion: []
    };
  },
  UserDataSuggetion(state, payload) {
    return {
      ...state,
      suggestion: payload.data
    };
  },
  UpdateUserStatus(state, payload) {
    return {
      ...state,
      status: payload.data.stats
    };
  },
  OrganizationList(state, payload) {
    return {
      ...state,
      organization: payload.data.data.organizations
    };
  },
  UserInfo(state, payload) {
    return {
      ...state,
      userInfo: payload.data
    };
  },
  UserInfoClear(state, payload) {
    return {
      ...state,
      userInfo: {}
    };
  },
  getMarkers(state) {
    let markers = [];
    if (state.userdata.length > 0) {
      state.userdata.forEach(currentItem => {
        markers.push({
          title: currentItem.username,
          lat: currentItem.location.latitude,
          lng: currentItem.location.longitude,
          organization: currentItem.organization_id
        });
      });
    }
    return {
      ...state,
      markers
    };
  },
  filterMarkers(state, payload) {
    let filterInit = "";
    let filterMarker = [];
    if (payload == "") {
      filterInit = false;
      filterMarker;
    }
    return {
      ...state
    };
  },
  userList(state, { payload, isUserListFiltered }) {
    return {
      ...state,
      userList: payload.data.users,
      isUserListFiltered
    };
  },
  addGmArray(state, payload) {
    return {
      ...state,
      error: payload
    };
  },
  addError(state, payload) {
    return {
      ...state,
      error: payload
    };
  },
  removeError(state) {
    return {
      ...state,
      error: ""
    };
  },
  setUserInfoLoading(state, { status }) {
    return {
      ...state,
      isUserInfoLoading: status
    };
  },
  organizationDetails(state, payload) {
    return {
      ...state,
      organizationDetails: payload.data
    };
  },
};

export const effects = {
  async startDashboard(payload, state, search) {
    try {
      this.UserDataSuggetionClear();
      // setIsLoading(true);
      const res = await api
        .post("api/v1/geospatial/dashboard_search/", payload)
        .then(response => {
          // setIsLoading(false);
          if (search) {
            this.UserDataSuggetionClear();
            this.UserDataSuggetion(response.data);
            this.UserData(response.data);
            this.getMarkers();
          } else {
            this.UserData(response.data);
            this.getMarkers();
          }
        })
        .catch(err => {
          // setIsLoading(false);
          this.addError(err.toString());
        });
    } catch (err) {
      // setIsLoading(false);
      this.addError(err.toString());
    }
  },
  async getInitialStatus(payload, search) {
    try {
      const res = await api
        .get("api/v1/organization/stats/")
        .then(response => {
          this.UpdateUserStatus(response.data);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getOrganizationlist() {
    try {
      const res = await api
        .get("/api/v1/organization/?sort_by=name")
        .then(response => {
          this.OrganizationList(response);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getUserlist(data) {
    let payload = {
      organizations: data,
      sort_by: "username"
    };
    let URL = `api/v1/user/list/`;
    try {
      const res = await api
        .post(URL, payload)
        .then(response => {
          this.userList({ payload: response.data, isUserListFiltered: !!data });
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getUserInfo(username, state, { detailed, history }) {
    this.setUserInfoLoading({ status: true });
    const URL = detailed
      ? `api/v1/user/info/?username=${username}&detailed=${detailed}`
      : `api/v1/user/info/?username=${username}`;
    try {
      const res = await api
        .get(URL)
        .then(response => {
          this.UserInfo(response.data);
        })
        .catch(err => {
          this.addError(err.toString());
          if (history) history.replace("/page-not-found");
        })
        .finally(() => this.setUserInfoLoading({ status: false }));
    } catch (err) {
      this.addError(err.toString());
    }
  },
  filterSearch(value) {
    this.filterMarkers(value);
  },
  async getOrganizationlistDetails(payload, state, params) {
    this.removeError();
    try {
      const res = await api
        .get(`/api/v1/organization/${payload.id}/`)
        .then(response => {
          this.organizationDetails(response.data.data);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
};
export default {
  state: initialState,
  reducers,
  effects
};
