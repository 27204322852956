import React, { useState, useEffect, useRef } from "react";
import { useStyles } from "./geospatial.style";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import { connect } from "react-redux";
import Map from "../../components/Map/MapComponent";
import Marker from "../../components/Map/MarkerComponent";
import useGoogleMapMarker from "../../components/Map/google/useGoogleMapMarker";
import Filter from "../../components/GeoSpatialFilterComponent";
import { DEFAULT_CENTER } from "../../config";
import { DEVICEFILTER } from "../../data/dataConstants";
import AddToWatch from "../../components/AddToWatchList/Generic";
import AddtoWatchListLocation from "../../components/WatchList/AddtoWatchListLocation";
import store from "../../store";
import { useSnackbar } from "notistack";
import PushNotification from "../../components/WatchList/PushNotification";
import SendTextMessage from "../../components/WatchList/SendTextMessage";
import { validateFromAndToDate } from "../../utils/dateTime";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { id } from "date-fns/locale";
import get from "lodash/get";
import BackToPrevious from "../../components/BackToPrevious";

//.format("YYYY-MM-DDTHH:mm:ss"),
let markerWindow = window.marker;
function GeospatialSearch(props) {
  const [isDrawerManager, setDrawerManager] = useState(false);
  const [addLocation, setAddLocation] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = React.useState({
    device: false
  });
  const pay = useRef({});
  const payDevice = useRef({});
  const isDevice = useRef(false);
  const classes = useStyles();
  let [isLoading, setIsLoading] = useState(false);
  const [selectedDateFrom, setDateChangeFrom] = useState(new Date());
  const [selectedDateTo, setDateChangeTo] = useState(new Date());
  const [isLocationTrail, setLocationTrail] = useState(false);
  const [username, setValue] = useState(
    get(props, "location.state.username", "")
  );
  const [openWatchList, setOpenWatchList] = React.useState(false);
  let { boundData, deviceBoundData } = props.geospatialState;
  const [dataSet, setDataSet] = useState([]);
  const [organization, setOrganization] = React.useState(["0"]);
  const [user, setUser] = React.useState(["0"]);
  const [devices, setDevices] = React.useState(0);
  // const [isDevice, setDeviceGeo] = React.useState(false);
  const [bluetooth, setBluetooth] = React.useState(["0"]);
  const [placeIndex, setPlaceIndex] = useState(0);
  const [bound, setBound] = useState({});
  const [payload, setPayload] = useState({});
  const [payloadDevice, setPayloadDevice] = useState({});
  const [checked, setChecked] = useState(false);
  const [zoom, setZoom] = useState(3);
  const [Reasonpayload, setReasonPayload] = React.useState({});
  const [openMassText, setOpenMassText] = React.useState(false);
  const [openMassPush, setOpenMassPush] = React.useState(false);
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [massTextPayload, setMassTextPayload] = React.useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [initailRender, setInitialRender] = useState(true);
  const handleClickOpenMassText = (data, popupState) => {
    setMassTextPayload({
      data,
      popupState: setOpenMassText,
      Type: "text"
    });
    setOpenMassText(true);
  };

  const handleCloseMassText = () => {
    setOpenMassText(false);
  };
  const handleToggleChange = name => event => {
    if (window.markers !== undefined && window.markers.length > 0) {
      window.markers.forEach(marker => {
        marker && marker.setMap(null);
      });
    }
    if (window.paths !== undefined && window.paths.length > 0) {
      window.paths.forEach(path => {
        path.setMap(null);
      });
    }
    setState({ ...state, [name]: event.target.checked });
    isDevice.current = event.target.checked;
    if (event.target.checked) {
      setDevices(0);
    } else {
      setOrganization(["0"]);
      setUser(["0"]);
      setPayload(payload => ({
        ...payload,
        organizations: [],
        users: []
      }));
      props.geospatialSearch(payload);
      pay.current = { ...payload, organizations: [], users: [] };
    }
  };

  const handleNotification = () => {
    let payloadData = {};
    let arr = [],
      newarr = [],
      userArr = [];
    boundData.forEach(element => {
      userArr.push(element.user_id);
    });
    if (Reasonpayload) {
      if (payload.polygon.length > 0) {
        let coord = payload.polygon.forEach(item => {
          let obj = {
            lat: item.latitude,
            lon: item.longitude
          };
          newarr.push(obj);
        });
        payloadData = {
          shape: "custom",
          notification_type: massTextPayload.Type,
          coordinates: newarr,
          user_id: userArr,
          name: Reasonpayload
        };
      } else {
        arr.push(payload.center);
        let coord = arr.forEach(item => {
          let obj = {
            lat: item.latitude,
            lon: item.longitude
          };
          newarr.push(obj);
        });
        payloadData = {
          shape: "circle",
          notification_type: massTextPayload.Type,
          user_id: userArr,
          coordinates: newarr,
          name: Reasonpayload,
          radius: payload.radius
        };
      }
      store.dispatch.Watchlist.notifications({
        payload,
        enqueueSnackbar,
        popupState: massTextPayload.popupState,
        setReasonPayload,
        setMassTextPayload,
        setOpenMassText,
        setOpenMassPush,
        payloadData,
        isGeoSpatial: true,
        setFilterDisable
      });
    } else {
      let variant = "error";
      enqueueSnackbar("Please enter the name ", {
        variant
      });
    }
  };

  const handleClickOpenMassPush = (data, popupState) => {
    setMassTextPayload({
      data,
      popupState: setOpenMassPush(),
      Type: "push"
    });
    setOpenMassPush(true);
  };

  const handleCloseMassPush = () => {
    setOpenMassPush(false);
  };
  const handleClickOpenWatchList = () => {
    setOpenWatchList(true);
  };

  const handleCloseWatchList = () => {
    setOpenWatchList(false);
  };
  const handleAddWatchlist = () => {
    let addtoWatchlistPayload = {};
    let arr = [],
      newarr = [];
    if (Reasonpayload) {
      if (payload.polygon.length > 0) {
        let coord = payload.polygon.forEach(item => {
          let obj = {
            lat: item.latitude,
            lon: item.longitude
          };
          newarr.push(obj);
        });
        addtoWatchlistPayload = {
          shape: "custom",
          coordinates: newarr,
          name: Reasonpayload
        };
      } else {
        arr.push(payload.center);
        let coord = arr.forEach(item => {
          let obj = {
            lat: item.latitude,
            lon: item.longitude
          };
          newarr.push(obj);
        });
        addtoWatchlistPayload = {
          shape: "circle",
          coordinates: newarr,
          name: Reasonpayload,
          radius: payload.radius
        };
      }
      store.dispatch.Watchlist.addToWatchlistLocation(addtoWatchlistPayload, {
        enqueueSnackbar,
        setOpenWatchList,
        setDrawerManager,
        setAddLocation,
        setReasonPayload,
        setFilterDisable,
        setOrganization,
        setUser
      });
    } else {
      let variant = "error";
      enqueueSnackbar("Please enter the name for adding polygon/circle", {
        variant
      });
    }
  };
  const [isFilterDisable, setFilterDisable] = useState(true);
  useEffect(() => {
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    handleGeoSpatialSearch();
  }, []);

  useEffect(() => {
    let usernames = [];
    if (boundData && boundData.length > 0) {
      boundData.forEach(element => {
        usernames.push(element.username);
      });
      setLocationTrail(true);
      let trailPayload = {
        users: usernames,
        from_datetime: moment(selectedDateFrom),
        to_datetime: moment(selectedDateTo)
      };
      handleTrail(trailPayload);
    }
  }, [JSON.stringify(boundData)]);

  useEffect(() => {}, [JSON.stringify(deviceBoundData)]);

  const { location } = props;

  //coordinates passed from user profile view
  const centerCoordinates = get(location, "state.location");

  const initialGeospatialSearch = () => {
    props.geospatialSearch({
      polygon: [],
      zoom_level: 4,
      radius: 50,
      center: centerCoordinates
    });
    setFilterDisable(false);
    payDevice.current = {
      ...payDevice.current,
      coordinates: [
        { lat: centerCoordinates.latitude, lon: centerCoordinates.longitude }
      ],
      shape: "circle",
      radius: 50,
      all_data: 1
    };
  };

  //state to handle if the initial circle should be rendered
  const [shouldRenderInitialCircle, setShouldRenderInitialCircle] = useState(
    !!centerCoordinates
  );

  //effect to fetch users in a given radius when switching from user profile view to geospatial view
  useEffect(() => {
    if (centerCoordinates) {
      initialGeospatialSearch();
      setPayload({
        polygon: [],
        zoom_level: 4,
        radius: 50,
        center: centerCoordinates
      });
    }
  }, []);

  useEffect(() => {
    setLocationTrail(true);
    let trailPayload = {
      users: [username],
      from_datetime: moment.utc(selectedDateFrom),
      // to_datetime: moment.utc(selectedDateTo)
      // from_datetime: selectedDateFrom,
      to_datetime: selectedDateTo
    };
    handleTrail(trailPayload);
  }, [username]);

  useEffect(() => {
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    const validate = validateFromAndToDate(selectedDateFrom, selectedDateTo);
    if (!isDevice.current) {
      if (!initailRender) {
        if (validate) {
          setErrorMsg(validate);
        } else {
          props.geospatialSearch(payload);
        }
      } else {
        props.geospatialSearch(payload);
      }
    }
  }, [JSON.stringify(payload)]);

  useEffect(() => {
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    isDevice.current &&
      props.geospatialDeviceSearch({
        payloadDevice,
        devices
      });
  }, [JSON.stringify(payloadDevice)]);

  const ResetPaylod = () => {
    setPayload({});
    pay.current = {};
  };

  useEffect(() => {
    const validate = validateFromAndToDate(selectedDateFrom, selectedDateTo);
    if (validate) {
      setErrorMsg(validate);
    } else {
      setErrorMsg("");
      if (isDevice.current) {
        setPayloadDevice(prevState => ({
          ...payDevice.current,
          from_datetime: moment.utc(selectedDateFrom),
          to_datetime: moment.utc(selectedDateTo)
        }));
      } else {
        setPayload(prevState => ({
          ...prevState,
          from_datetime: moment.utc(selectedDateFrom),
          to_datetime: moment.utc(selectedDateTo)
        }));
      }
    }
  }, [selectedDateFrom, selectedDateTo]);

  const handleTrail = trailPayload => {
    props.geospatialLocationSearch(trailPayload);
  };
  const handleChange = username => {
    setValue(username);
    props.history.push(`/${username}`);
  };
  const handleFromDate = e => {
    setInitialRender(false);
    setDateChangeFrom(e);
    payDevice.current = {
      ...payDevice.current,
      from_datetime: moment.utc(e),
      to_datetime: moment.utc(selectedDateTo)
    };
    setPayload({
      ...payload,
      from_datetime: moment.utc(e),
      to_datetime: moment.utc(selectedDateTo)
    });
    pay.current = {
      ...pay.current,
      from_datetime: moment.utc(e),
      to_datetime: moment.utc(selectedDateTo)
    };
  };
  const handleToDate = e => {
    setInitialRender(false);
    setDateChangeTo(e);
  };
  const handleOrgChange = event => {
    if (window.paths !== undefined && window.paths.length > 0) {
      window.paths.forEach(path => {
        path.setMap(null);
      });
    }
    setUser(["0"]);
    setPayload(prevState => ({
      ...prevState,
      users: []
    }));
    let org = [...event.target.value];
    if (org.includes("0")) {
      const index = org.indexOf("0");
      org.splice(index, 1);
    }
    props.getUserlist(org);
    setOrganization(org);
    if (event.target.value.length === 0) {
      setOrganization(["0"]);
      setUser(["0"]);
      setPayload(prevState => ({
        ...prevState,
        organizations: []
      }));
      if (window.paths !== undefined && window.paths.length > 0) {
        window.paths.forEach(path => {
          path.setMap(null);
        });
      }
    } else {
      pay.current = { ...payload, organizations: org };
      setPayload(prevState => ({
        ...prevState,
        organizations: org
      }));
    }
  };
  const handleBoundClear = () => {
    props.SearchDataClear();
  };
  const handleDeviceChange = event => {
    setDevices(event.target.value);
    if (event.target.value === "0") {
      if (window.markers !== undefined && window.markers.length > 0) {
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
      }
    }
  };

  useEffect(() => {
    const validate = validateFromAndToDate(selectedDateFrom, selectedDateTo);
    if (validate) {
    } else {
      if (isDevice.current) {
        if (devices !== 0) {
          props.geospatialDeviceSearch({
            payloadDevice,
            devices
          });
        }
      } else {
        props.geospatialSearch(payload);
      }
    }
  }, [isDevice.current]);
  useEffect(() => {
    if (devices !== 0) {
      const validate = validateFromAndToDate(selectedDateFrom, selectedDateTo);
      if (!initailRender) {
        if (validate) {
        } else {
          props.geospatialDeviceSearch({
            payloadDevice: payDevice.current,
            devices
          });
        }
      } else {
        props.geospatialDeviceSearch({
          payloadDevice: payDevice.current,
          devices
        });
      }
    } else {
      if (window.marker !== undefined && window.marker.length > 0) {
        window.marker.forEach(marker => {
          marker && marker.setMap(null);
        });
      }
    }
  }, [devices, payDevice.current]);

  const handleUserChange = event => {
    if (window.paths !== undefined && window.paths.length > 0) {
      window.paths.forEach(path => {
        path.setMap(null);
      });
    }
    let usr = [...event.target.value];
    if (usr.includes("0")) {
      const index = usr.indexOf("0");
      usr.splice(index, 1);
    }
    setUser(usr);
    if (event.target.value.length === 0) {
      if (window.paths !== undefined && window.paths.length > 0) {
        window.paths.forEach(path => {
          path.setMap(null);
        });
      }
      setUser(["0"]);
      setPayload(prevState => ({
        ...prevState,
        users: []
      }));
    }
    setPayload(prevState => ({
      ...prevState,
      users: usr
    }));
    pay.current = { ...payload, users: usr };
  };
  const handleFilterChange = (coordinates, zoom, center, radius) => {
    payDevice.current = {
      shape: "custom",
      all_data: 1
    };
    if (center !== undefined) {
      let coord = [
        {
          lat: center.latitude,
          lon: center.longitude
        }
      ];
      payDevice.current = {
        ...payDevice.current,
        shape: "circle",
        coordinates: coord,
        radius
      };
    } else {
      let coord = [];
      coordinates.forEach(element => {
        let data = {
          lat: element.latitude,
          lon: element.longitude
        };
        coord.push(data);
      });
      payDevice.current = { ...payDevice.current, coordinates: coord };
    }
    if (isDevice.current) {
      let temp = {
        ...payDevice.current,
        shape: "custom",
        all_data: 1
      };

      if (center !== undefined) {
        let coord = [
          {
            lat: center.latitude,
            lon: center.longitude
          }
        ];
        temp = { ...temp, shape: "circle", coordinates: coord, radius };
      } else {
        let coord = [];
        coordinates.forEach(element => {
          let data = {
            lat: element.latitude,
            lon: element.longitude
          };
          coord.push(data);
        });
        temp = { ...temp, coordinates: coord };
      }
      payDevice.current = temp;
      setPayloadDevice(payDevice.current);
      setInitialRender(true);
      props.geospatialDeviceSearch({
        payloadDevice: payDevice.current,
        devices
      });
    } else {
      let temp = {
        ...pay.current,
        polygon: coordinates,
        zoom_level: 4
        // from_datetime: moment.utc(selectedDateFrom),
        // to_datetime: moment.utc(selectedDateTo)
      };
      if (center !== undefined) {
        temp = { ...temp, center: center, radius: radius };
      }
      setPayload(temp);
      props.geospatialSearch(temp);
    }
  };
  const handleGeoSpatialSearch = () => {
    props.getOrganizationlist();
    props.getUserlist();
  };
  React.useEffect(() => {
    return () => {
      props.SearchDataClear();
    };
  }, []);
  return (
    <>
      <div className={classes.root}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={classes.headingTypoGraphyContent}>
              <span>Geospatial Search </span>
              <Typography component="div" style={{ marginLeft: "15px" }}>
                <Grid
                  component="label"
                  container
                  alignItems="center"
                  spacing={1}>
                  <Grid item>Users</Grid>
                  <Grid item>
                    <Switch
                      checked={state.device}
                      onChange={handleToggleChange("device")}
                      value="device"
                      disabled={isFilterDisable}
                    />
                  </Grid>
                  <Grid item>Devices</Grid>
                </Grid>
              </Typography>
            </div>
          </Grid>
          {props.geospatialState.organizations !== undefined &&
          props.geospatialState.userList !== undefined ? (
            <Filter
              device={state.device}
              devices={devices}
              DEVICEFILTER={DEVICEFILTER}
              handleDeviceChange={handleDeviceChange}
              handleClickOpenWatchList={handleClickOpenWatchList}
              handleClickOpenMassText={handleClickOpenMassText}
              handleClickOpenMassPush={handleClickOpenMassPush}
              organizations={props.geospatialState.organizations}
              userList={props.geospatialState.userList}
              organization={organization}
              errorMsg={errorMsg}
              initailRender={initailRender}
              user={user}
              selectedDateFrom={selectedDateFrom}
              selectedDateTo={selectedDateTo}
              handleOrgChange={handleOrgChange}
              handleUserChange={handleUserChange}
              handleFromDate={handleFromDate}
              handleToDate={handleToDate}
              isFilterDisable={isFilterDisable}
            />
          ) : (
            ""
          )}
          <Grid item xs={12}>
            <Grid container justify="center" spacing={1}>
              <Grid container spacing={1}>
                <Grid
                  className={classes.gridGeospatia}
                  container
                  item
                  xs={12}
                  spacing={3}>
                  <Grid
                    className={classes.mapContainer}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}>
                    <Map
                      handleGeoSpatialSearch={handleGeoSpatialSearch}
                      setInitialRender={setInitialRender}
                      setDevices={setDevices}
                      setState={setState}
                      isDevice={isDevice}
                      isFilterDisable={isFilterDisable}
                      status={status !== undefined ? status : {}}
                      setFilterDisable={setFilterDisable}
                      isLocationTrail={isLocationTrail}
                      markers={markerWindow}
                      isDrawerManager={true}
                      geospatialApi={handleFilterChange}
                      dataSet={dataSet.length > 0 ? dataSet : []}
                      isLoading={isLoading}
                      handleBoundClear={
                        centerCoordinates && shouldRenderInitialCircle
                          ? () => setShouldRenderInitialCircle(false)
                          : handleBoundClear
                      }
                      zoom={zoom}
                      center={center}
                      setUser={setUser}
                      setOrganization={setOrganization}
                      setDateChangeTo={setDateChangeTo}
                      setDateChangeFrom={setDateChangeFrom}
                      trails={props.geospatialState.locationTrail}
                      trailDataClear={props.trailDataClear}
                      setValue={setValue}
                      events={{ onBoundsChangerd: arg => setBound(arg) }}
                      ResetPaylod={ResetPaylod}
                      setPayload={setPayload}
                      styles={classes.mapStyles}
                      shouldRenderInitialCircle={shouldRenderInitialCircle}
                      setShouldRenderInitialCircle={
                        setShouldRenderInitialCircle
                      }
                      centerCoordinates={centerCoordinates}>
                      {boundData !== undefined && boundData.length > 0 ? (
                        <Marker
                          dataSet={boundData}
                          handleChange={handleChange.bind(username)}
                        />
                      ) : (
                        <div></div>
                      )}
                      {deviceBoundData !== undefined &&
                      deviceBoundData.length > 0 ? (
                        <Marker
                          isDevice={isDevice}
                          dataSet={deviceBoundData}
                          handleChange={handleChange.bind(username)}
                        />
                      ) : (
                        <div></div>
                      )}
                      <div></div>
                    </Map>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {openWatchList === true && (
          <AddToWatch
            open={openWatchList}
            handleClose={handleCloseWatchList}
            buttonName={"Save"}
            handleAddWatchlist={handleAddWatchlist}>
            <AddtoWatchListLocation
              comp={"Location"}
              setReasonPayload={setReasonPayload}
            />
          </AddToWatch>
        )}

        {openMassText && (
          <AddToWatch
            open={openMassText}
            handleClose={handleCloseMassText}
            handleAddWatchlist={handleNotification}
            handleCloseMassText={handleCloseMassText}
            buttonName={"Send"}>
            <SendTextMessage
              setReasonPayload={setReasonPayload}
              title="enter a name for text message"
            />
          </AddToWatch>
        )}
        {openMassPush && (
          <AddToWatch
            open={openMassPush}
            handleClose={handleCloseMassPush}
            handleAddWatchlist={handleNotification}
            buttonName={"Send"}>
            <PushNotification
              setReasonPayload={setReasonPayload}
              title="enter a name for push notification"
            />
          </AddToWatch>
        )}
      </div>
    </>
  );
}

const mapStateToProps = state => {
  return { dashboardState: state.Dashboard, geospatialState: state.Geospatial };
};

const mapDispatchToProps = dispatch => {
  return {
    geospatialSearch: dispatch.Geospatial.startGeoSpatial,
    geospatialDeviceSearch: dispatch.Geospatial.startGeoSpatialDevice,
    geospatialLocationSearch: dispatch.Geospatial.startTrailSearch,
    getOrganizationlist: dispatch.Geospatial.getOrganizationlist,
    getUserlist: dispatch.Geospatial.getUserlist,
    SearchDataClear: dispatch.Geospatial.SearchDataClear,
    trailDataClear: dispatch.Geospatial.trailDataClear
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeospatialSearch);
