import { api } from "../utils/http";

export const initialState = {
  error: "",
  tickets: [],
  appuser: {},
  apppage: [],
  page: []
};
export const reducers = {
  ticketsList(state, response) {
    return {
      ...state,
      tickets: response.data.results,
      page: { count: response.data.count, links: { next: response.data.next, previous: response.data.previous } }
    };
  },

  appUsers(state, payload) {
    return {
      ...state,
      appuser: payload.data.users
      // apppage: payload.data.page
    }
  },
  addError(state, payload) {
    return {
      ...state,
      error: payload
    };
  },
  removeError(state) {
    return {
      ...state,
      error: ""
    };
  }
};

export const effects = {
  async getTicketlist(){//payload) {
    let url = `api/v1/tickets/`;///?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}`;
    this.removeError();

    // if (typeof payload.sort === "boolean") {
    //   url = `${url}${
    //     payload.sort === true ? "&sort_by=-created_on" : "&sort_by=created_on"
    //   }`;
    // }
    try {
      const res = await api
        .get(url)
        .then(response => {
          this.ticketsList(response.data);
          // console.log(response.data.data.results);
          // return response.data.data.results
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async createTicket(
    payload,
    state,
    { enqueueSnackbar, clear, history }
  ) {
    let URL = `/api/v1/tickets/create/`;
    try {
      const res = await api.post(URL, payload).then(response => {
        let variant = "success";
        enqueueSnackbar(response.data.message, {
          variant
        });
        clear();
        history.push("/dashboard");
      });
    } catch (err) {
      let variant = "error";
      this.addError();
      enqueueSnackbar(err.response.data.message, {
        variant
      });
    }
  },
  async patchTicket(payload, state, { enqueueSnackbar, id, history }) {
    let URL = `/api/v1/tickets/${id}/`;
    try {
      const res = await api.patch(URL, payload).then(response => {
        let variant = "success";
        enqueueSnackbar(response.data.message, {
          variant
        });
        history.push("/dashboard");
      });
    } catch (err) {
      let variant = "error";
      this.addError();
      enqueueSnackbar(err.response.data.message, {
        variant
      });
    }
  }
};
export default {
  state: initialState,
  reducers,
  effects
};
