import moment from "moment";
let dataSet = [];
export const generateChartData = chartData => {
  let obj = {};

  if (
    chartData !== undefined &&
    chartData.speed_data !== undefined &&
    chartData.speed_data.length > 0
  ) {
    const uniqList = chartData.speed_data.filter(
      (s1, pos, arr) =>
        arr.findIndex(
          s2 => s2.timestamp.split("T")[0] === s1.timestamp.split("T")[0]
        ) === pos
    );
    uniqList.forEach(acc => {
      const filterList = chartData.speed_data.filter(
        x => x.timestamp.split("T")[0] === acc.timestamp.split("T")[0]
      );
      obj[acc.timestamp] = filterList;
    });
  }
  if (
    chartData !== undefined &&
    chartData.elevation_data !== undefined &&
    chartData.elevation_data.length > 0
  ) {
    const uniqList = chartData.elevation_data.filter(
      (s1, pos, arr) =>
        arr.findIndex(
          s2 => s2.timestamp.split("T")[0] === s1.timestamp.split("T")[0]
        ) === pos
    );
    uniqList.forEach(acc => {
      const filterList = chartData.elevation_data.filter(
        x => x.timestamp.split("T")[0] === acc.timestamp.split("T")[0]
      );
      obj[acc.timestamp] = filterList;
    });
  }
  return obj;
};

export const GRAPHIC = {
  labels: ["5", "15:00", "18:00", "21:00", "00:00"],
  datasets: [
    {
      //  data:dataSet,
      data: [
        {
          x: 80,
          y: 21
        },
        {
          x: 10,
          y: 10
        },
        {
          x: 15,
          y: 12
        },
        {
          x: 65,
          y: 21
        }
      ],
      borderColor: "#5ac8fa",
      backgroundColor: "#5ac8fa",
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 4,
      borderWidth: 2
    }
  ]
};

export const GRAPHICS = [
  {
    type: "line",
    width: 1500,
    height: 300,
    data: { id: 2, ...GRAPHIC, currency: "$" }
  }
];

export const DEVICEFILTER = [
  {
    id: 1,
    value: "wifi",
    label: "Wifi"
  },
  {
    id: 2,
    value: "bluetooth",
    label: "Bluetooth"
  },
  {
    id: 3,
    value: "network",
    label: "Cellular Network"
  }
];

export const EXPORTSELECT = [
         {
           id: 1,
           value: "excel",
           label: "Excel"
         },
         {
           id: 2,
           value: "csv",
           label: "CSV"
         }
       ];
