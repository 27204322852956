import React from "react";
import { T as Table } from "../../components/Table/table.styled";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
const header = [
  "ID",
  "NAME",
  "POLYGON",
  "NOTIFIED AT",
  "RECIPIENT",
  "RESPONSES"
];

function TableComponent(props) {
  const {
    sortHeaders,
    payloadData,
    searchValue,
    tableData,
    tableHeader,
    getDataList,
    organizationPage,
    handleDetails,
    isPaginationNormal,
    isHistory,
    isAppUser,
    isLoading
  } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [sortAppuser, setSortAppuser] = React.useState(true);
  const [sortName, setSortname] = React.useState("");
  const [sort, setSort] = React.useState(true);

  const sortByAppuser = (param) => {
    setSortname(param);
    setSortAppuser(!sortAppuser);
    setSort(!sortAppuser);
  };
   const [sortResponse, setSortResponse] = React.useState(true);
   const sortByesponse = param => {
         setSortname(param);
     setSortResponse(!sortResponse);
       setSort(!sortResponse);
   };
    const [sortRecipient, setSortRecipient] = React.useState(true);
    const sortByRecipeient = param => {
          setSortname(param);
      setSortRecipient(!sortRecipient);
       setSort(!sortRecipient);
    };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setOffset(0);
  };
  React.useEffect(() => {
    setPage(0);
    setOffset(0);
    setRowsPerPage(5);
  }, [searchValue]);
  React.useEffect(() => {
    let payload = {};
    if (isAppUser) {
      payload = {
        id: payloadData.id,
        offset,
        rowsPerPage,
        search: searchValue
      };
    } else {
      payload = {
        offset,
        rowsPerPage,
        search: searchValue,
        sortResponse,
        sortRecipient,
        sortAppuser,
        sortName,
        sort
      };
    }
    getDataList(payload);
  }, [offset, rowsPerPage, searchValue, payloadData ]);


  React.useEffect(() => {
    let payload = {
      offset,
      rowsPerPage,
      search: searchValue,
      sortName,
      sort
    };
    getDataList(payload);
  }, [sortName, sort]);


  return (
    <Table>
      <div className="wrapper-long">
        <table
          className="table"
          style={{
            marginBottom: "0",
            position: "sticky",
            top: "0",
            zIndex: "1",
            borderRadius: "8px",
            overflow: "hidden"
          }}>
          <tr className="row-head header green">
            <th className="cellHead">ID</th>
            <th className="cellHead">NAME</th>
            <th className="cellHead">POLYGON</th>
            <th className="cellHead">
              NOTIFIED AT
              <IconButton
                aria-label="sort"
                onClick={() => {
                  sortByAppuser("created_on");
                }}>
                {sortAppuser ? (
                  <ArrowUpward style={{ color: "aliceblue" }} />
                ) : (
                  <ArrowDownward style={{ color: "aliceblue" }} />
                )}
              </IconButton>
            </th>

            <th className="cellHead">
              RECIPIENT
              <IconButton
                aria-label="sort"
                onClick={() => {
                  sortByRecipeient("recipients");
                }}>
                {sortRecipient ? (
                  <ArrowUpward style={{ color: "aliceblue" }} />
                ) : (
                  <ArrowDownward style={{ color: "aliceblue" }} />
                )}
              </IconButton>
            </th>
            <th className="cellHead">
              RESPONSES
              <IconButton
                aria-label="sort"
                onClick={() => {
                  sortByesponse("responses");
                }}>
                {sortResponse ? (
                  <ArrowUpward style={{ color: "aliceblue" }} />
                ) : (
                  <ArrowDownward style={{ color: "aliceblue" }} />
                )}
              </IconButton>
            </th>
          </tr>
        </table>
        <table className="table">
          <tbody>
            {tableData && tableData.length > 0 ? (
              tableData.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr
                      className="row"
                      onClick={() => {
                        handleDetails(item);
                      }}>
                      <td className="cell-long">{item.id}</td>
                      <td className="cell-long">{item.name}</td>
                      <td className="cell-long">
                        {item.watchlist_location_name
                          ? item.watchlist_location_name
                          : "custom"}
                      </td>
                      <td className="cell-long">
                        {item.created_on
                          ? moment.utc(item.created_on).local()
                              .format("DD MMM YYYY,HH:mm:ss")
                          : "Unavailable"}
                      </td>
                      <td className="cell-long">{item.recipient_count}</td>
                      <td className="cell-long">{item.response_count}</td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : isLoading ? (
              <tr className="cell-long-line">
                <CircularProgress size={26} />
              </tr>
            ) : (
              <tr className="cell-long-line">No Record Found</tr>
            )}
          </tbody>
        </table>
        {
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={
              organizationPage && organizationPage.count
                ? organizationPage.count
                : 0
            }
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }

        <div></div>
      </div>
    </Table>
  );
}

export default TableComponent;
