import React, { useEffect } from "react";
import { T as Table } from "../../components/Table/table.styled";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";
function TableComponent(props) {
  const {
    tableData,
    tableHeader,
    organizationPage,
    handleDetails,
    isHistory,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPage,
    page,
    isLoading
  } = props;

  return (
    <Table>
      <div className="wrapper-long">
        <table
          className="table"
          style={{
            marginBottom: "0",
            position: "sticky",
            top: "0",
            zIndex: "1",
            borderRadius: "8px",
            overflow: "hidden"
          }}>
          <tr className="row-head header green">
            {tableHeader &&
              tableHeader.map((item, index) => (
                <th key={index} className="cellHead">
                  {item}
                </th>
              ))}
          </tr>
        </table>
        <table className="table">
          <tbody>
            {tableData && tableData.length > 0 ? (
              tableData.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <tr className="row">
                      <td className="cell-long">
                        {item.sub_data_type && !isHistory
                          ? `${item.sub_data_type} ${item.data_type}`
                          : item.data_type}
                      </td>
                      <td
                        className="cell-long cell-yellow"
                        onClick={() => {
                          handleDetails(item);
                        }}>
                        {item.username}
                      </td>
                      <td className="cell-long">{item.description}</td>
                      <td className="cell-long">
                        {moment(item.timestamp)
                          .local()
                          .format("DD MMM YYYY,HH:mm:ss")}
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })
            ) : isLoading ? (
              <tr className="cell-long-line">
                <CircularProgress size={26} />
              </tr>
            ) : (
              <tr className="cell-long-line">No Record Found</tr>
            )}
          </tbody>
        </table>

        <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={
            organizationPage && organizationPage.count
              ? organizationPage.count
              : 0
          }
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />

        <div></div>
      </div>
    </Table>
  );
}

export default TableComponent;
