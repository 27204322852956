
import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import LoadingIcon from "@material-ui/icons/Cached";
import { 
    TicketFormControl,
    TicketInputWrap,
    TicketLabel,
} from "../../pages/DashBoard/dashboard.style";
export const LargeField = ({
  label,
  name,
  value,
  onChange,
  errors,
  setErrors,
  pristine,
  validating,
  validate,
  formSubmitted,
  ...other
}) => {
  let showErrors = (!pristine || formSubmitted) && !!errors.length;
  return (
    <TicketFormControl>
      <FormControl className="field" error={showErrors}>
        <TicketLabel error={showErrors} htmlFor={name}>
          {label}
        </TicketLabel>
        <TicketInputWrap
          multiline
          rows={8}
          showErrors={showErrors}
          id={name}
          value={value}
          onChange={onChange}
          onBlur={() => !pristine && validate()}
          endAdornment={
            <InputAdornment position="end">
              {validating && <LoadingIcon className="rotate" />}
            </InputAdornment>
          }
          {...other}
        />
        <FormHelperText component="div">
          {showErrors &&
            errors.map(errorMsg => <div key={errorMsg}>{errorMsg}</div>)}
        </FormHelperText>
      </FormControl>
    </TicketFormControl>
  );
};
