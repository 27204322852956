import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Popper from "@material-ui/core/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Fade from "@material-ui/core/Fade";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles(theme => ({
  typography: {
    padding: theme.spacing(2)
  }
}));

export default function PopperPopupState(props) {
  const classes = useStyles();

  return (
    <PopupState variant="popper" popupId="demo-popup-popper">
      {popupState => (
        <div>
          <span
            className={props.classes.moreActions}
            variant="contained"
            color="primary"
            {...bindToggle(popupState)}>
            <img className={props.classes.moreicon} src={props.MoreIcon} />
          </span>
          <Popper {...bindPopper(popupState)} transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps} timeout={350}>
                <ClickAwayListener onClickAway={popupState.close}>
                  <Paper>
                    <MenuItem
                      onClick={() => {
                        props.handleClickOpenConfirm(props.data.id, popupState);
                        popupState.close;
                        // props.deleteWatchlist(props.data.id, popupState);
                      }}>
                      Remove from Watchlist
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        props.handleClickOpenMassText(props.data, popupState);
                      }}>
                      Send Mass Text
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                          props.handleClickOpenMassPush(props.data, popupState);
                      }}>
                      Push Notifications
                    </MenuItem>
                  </Paper>
                </ClickAwayListener>
              </Fade>
            )}
          </Popper>
        </div>
      )}
    </PopupState>
  );
}
