import React, { useEffect, useState, useRef } from "react";
import {
  useStyles,
  HistoryWrapper,
  HistoryTopWrapper,
  HistoryHeading,
  HistoryTopWrapperFilter,
  HistoryTableTabWrapper,
} from "./massnotification.details.style";
import { Grid, MenuList, MenuItem } from "@material-ui/core";
import Select from "../../components/GeoSpatialFilterComponent/components/Select";
import store from "../../store";
import { connect } from "react-redux";
import WatchListMap from "../../components/Map/WatchListMaps/WatchListMap";
import BackToPrevious from "../../components/BackToPrevious";

function ViewMapNotifications(props) {
  const classes = useStyles();
  const pay = useRef({});
  const [payload, setPayload] = useState({});
  const [notificationItem, setNotificationItem] = useState({});
  const [selectedCircleRadius, setCircleRadius] = React.useState();
  let { boundData } = props.notificationDetailAState;
  let { organization, userList } = props.dashboardState;
  const [organizations, setOrganization] = React.useState(["0"]);
  const [user, setUser] = React.useState(["0"]);
  const [userMarkers, setMarkers] = React.useState([]);
  const handleOrgChange = event => {
    if (window.markers !== undefined && window.markers.length > 0) {
      window.markers &&
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
    }
    setUser(["0"]);
    let org = [...event.target.value];
    if (org.includes("0")) {
      const index = org.indexOf("0");
      org.splice(index, 1);
    }
    store.dispatch.Dashboard.getUserlist(org);
    setOrganization(org);
    setPayload(prevState => ({
      ...prevState,
      organizations: org
    }));
    if (event.target.value.length === 0) {
          let data = {
            massId: props.location.state.notificationItem.id
          };
          store.dispatch.MassNotification.startViewOnMap(data);
      setOrganization(["0"]);
      setUser(["0"]);
    }
    pay.current = { ...payload, organizations: org };
  };
  const handleUserChange = event => {
    if (window.markers !== undefined && window.markers.length > 0) {
      window.markers &&
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
    }
    let usr = [...event.target.value];
    if (usr.includes("0")) {
      const index = usr.indexOf("0");
      usr.splice(index, 1);
    }
    setPayload(prevState => ({
      ...prevState,
      users: usr
    }));
    setUser(usr);
    if (event.target.value.length === 0) {
          let data = {
            massId: props.location.state.notificationItem.id
          };
          store.dispatch.MassNotification.startViewOnMap(data);
      setUser(["0"]);
    }
    pay.current = { ...payload, users: usr };
  };
  useEffect(() => {
    if (props.location.state.notificationItem) {
      setNotificationItem(props.location.state.notificationItem);
      let data = {
        massId: props.location.state.notificationItem.id
      };
      store.dispatch.MassNotification.startViewOnMap(data);
    }
    if (
      props.location.state.notificationItem &&
      props.location.state.notificationItem.radius
    ) {
      setCircleRadius(props.location.state.notificationItem.radius);
    }
    if (props.location.state.notificationItem.shape === "circle") {
      let load = {
        center: {
          latitude: props.location.state.notificationItem.coordinates[0].lat,
          longitude: props.location.state.notificationItem.coordinates[0].lon
        },
        radius: props.location.state.notificationItem.radius
      };
      store.dispatch.Geospatial.startGeoSpatial(load);
      setPayload(prevState => ({
        ...prevState,
        ...load
      }));
    } else {
      let polygon = props.location.state.notificationItem.coordinates.map(
        item => {
          return {
            latitude: item.lat,
            longitude: item.lon
          };
        }
      );
      let load = {
        polygon
      };
      setPayload(prevState => ({
        ...prevState,
        ...load
      }));
      store.dispatch.Geospatial.startGeoSpatial(load);
    }
    store.dispatch.MassNotification.getMassNotificationsWifiList();
  }, [props.location.state.notificationItem]);
  useEffect(() => {
    if (payload !== undefined) {
      // store.dispatch.Geospatial.startGeoSpatial(payload);
   
      let org = [],
        usr = [];
         organization &&
           organization.length > 0 &&
           organization.forEach(element => {
              payload.organizations && payload.organizations.forEach(item => {
                if (element.organization_id === item) {
                  org.push(element.id);
                }
              });
           });
             userList &&
               userList.length > 0 &&
               userList.forEach(element => {
                  payload.users &&  payload.users.forEach(item => {
                    if (element.username === item) {
                      usr.push(element.id);
                    }
                  });
               });
                  let datapayload = {
                    mass_notification_id: notificationItem.id,
                    organization_id: org,
                    user_id: usr,
                    latest_only: 1,
                    notification_type: "push",
                  };
      store.dispatch.MassNotification.startViewOnMapSearch(datapayload);
    }
  }, [payload]);
  const handleHistory = () => {
    store.dispatch.Dashboard.getOrganizationlist();
    store.dispatch.Dashboard.getUserlist();
    store.dispatch.Watchlist.getPolygonWatchList();
  };
  useEffect(() => {
    handleHistory();
  }, []);
  useEffect(() => {
    if (boundData.length > 0) {
      setMarkers(boundData);
    }
  }, [boundData]);

  return (
    <div className={classes.root}>
      <HistoryWrapper>
        <Grid container>
          <Grid item xs={12}>
            <BackToPrevious destination="massnotification" />
            <HistoryTopWrapper>
              <HistoryHeading>{notificationItem.name}</HistoryHeading>
            </HistoryTopWrapper>
            <HistoryTopWrapperFilter>
              <div style={{ width: "30%", display: "flex" }}>
                <Select
                  classesParent={classes}
                  data={organization}
                  valueProp={organizations}
                  onChange={handleOrgChange}
                  label="Organizations"
                  multiple={true}
                />
                <Select
                  classesParent={classes}
                  data={userList}
                  valueProp={user}
                  onChange={handleUserChange}
                  label="Users"
                  multiple={true}
                />
              </div>
            </HistoryTopWrapperFilter>
            <HistoryTableTabWrapper>
              <WatchListMap
                minHeight="651px"
                isDrawerManager={false}
                coordinates={notificationItem.coordinates}
                selectedCircleRadius={selectedCircleRadius}
                userMarkers={userMarkers}
                isViewMapNotification={true}
                history={props.history}
              />
            </HistoryTableTabWrapper>
          </Grid>
        </Grid>
      </HistoryWrapper>
    </div>
  );
}
const mapStateToProps = state => {
  return {
    dashboardState: state.Dashboard,
    GeospatialState: state.Geospatial,
    notificationDetailAState: state.MassNotification
  };
};
export default connect(
  mapStateToProps,
  null
)(ViewMapNotifications);
