import React from "react";
import { CircularProgress, Divider, TextField } from "@material-ui/core";

import {
  SliderComponentsWrapper,
  SliderComponentButtonClose,
  SliderComponentSearch,
  SliderComponentOrganizationSelect
} from "./slider.style";
import Select from "../GeoSpatialFilterComponent/components/Select";
import ArrowRight from "../../assets/img/arrowRight.png";
import icon from "../../assets/img/search2x.png";
import { TextFieldWrapper } from "../../styles/Wrapper.style";
import List from "./ListItems";

function index({
  height,
  id,
  open,
  setOpen,
  dataSlider,
  deleteWatchlist,
  plotted,
  handleNotification,
  handleDateSearch,
  searchFieldRef,
  handleClickOpenConfirm,
  handleClickOpenMassText,
  handleClickOpenMassPush,
  handelSelectItem,
  organizations,
  handleOrgChange,
  organizationsList,
  userList,
  selectedIndex,
  setSelectedIndex,
  isLoading
}) {
  const getSearchFieldProps = () => {
    const searchFieldProps = {
      style: {
        margin: " 9px",
        fontFamily: "Google Sans",
        fontSize: "12px"
      },
      id: "reason",
      InputProps: {
        startAdornment: (
          <img
            style={{
              width: " 21px",
              margin: " 0px 4px 0px 2px",
              padding: "2px 3px 5px 4px"
            }}
            src={icon}
            alt="search-icon"
          />
        ),
        disableUnderline: true
      },
      onChange: e => {
        handleDateSearch(e, id);
      },
      margin: "normal",
      placeholder: "Search...",
      type: "text",
      fullWidth: true
    };

    // only include the "inputRef" prop if searchFieldRef is passed from the parent
    if (searchFieldRef) searchFieldProps.inputRef = searchFieldRef;

    return searchFieldProps;
  };

  return (
    <div>
      <SliderComponentsWrapper>
        <div className="container">
          <div className="flex">
            <SliderComponentButtonClose
              onClick={() => {
                setOpen(!open);
              }}>
              <img className="img" src={ArrowRight} alt="arrow-right" />
            </SliderComponentButtonClose>
            <SliderComponentSearch>
              <TextFieldWrapper>
                <TextField {...getSearchFieldProps()} />
              </TextFieldWrapper>
            </SliderComponentSearch>
          </div>
          <Divider
            style={{
              marginTop: "-4px",
              marginBottom: "11px"
            }}
          />
          {id === "User" ? (
            <SliderComponentOrganizationSelect>
              {organizations && (
                <Select
                  // classesParent={classes}
                  data={organizationsList}
                  valueProp={organizations}
                  onChange={handleOrgChange}
                  label="Organizations"
                  multiple
                  id={id}
                />
              )}
            </SliderComponentOrganizationSelect>
          ) : (
            ""
          )}
          <div
            id={id}
            style={{
              height: height ? height : "485px",
              overflow: "auto",
              display: "flex",
              justifyContent: "center"
            }}>
            {/* {dataSlider((i, index) => (
                <div style={style} key={index}>
                  div - #{index}
                </div>
              ))} */}
            {isLoading ? (
              <CircularProgress style={{ marginTop: "10px" }} />
            ) : (
              <List
                id={id}
                dataSlider={dataSlider}
                deleteWatchlist={deleteWatchlist}
                plotted={plotted}
                handleNotification={handleNotification}
                handleClickOpenConfirm={handleClickOpenConfirm}
                handleClickOpenMassText={handleClickOpenMassText}
                handleClickOpenMassPush={handleClickOpenMassPush}
                handelSelectItem={handelSelectItem}
                selectedIndex={selectedIndex}
                setSelectedIndex={setSelectedIndex}
              />
            )}
          </div>
        </div>
      </SliderComponentsWrapper>
    </div>
  );
}

export default index;
