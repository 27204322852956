import React from "react"
import { connect } from "react-redux"
import {
  OrganizationWrapper,
  OrganizationTopWrapper,
  OrganizationCreateWrapper,
  CreateText,
  SearchWrapper,
  OrganizationTopWrapperSearch,
  OrganizationTableWrapper,
  OrganizationTable
} from "./organization.styles"
import { PageHeading } from "../../styles/Body.style"
import AddIcon from "../../assets/img/addicon.png"
import icon from "../../assets/img/search2x.png"
import TextField from "@material-ui/core/TextField"
import { TextFieldWrapper } from "../../styles/Wrapper.style"
import { Grid, IconButton, InputAdornment } from "@material-ui/core"
import store from "../../store"
import Table from "../../components/Table/ResuableTable"
const header = ["ORGANIZATION ID", "ORGANIZATION", "ACCOUNTS", "MEMBER SINCE"]
const sortHeader = "MEMBER SINCE"

function Organizations(props) {
  const handleCreateOrganization = () => {
    props.history.push("/createorganization")
  }
  const handleOrganizationDetails = item => {
    props.history.push({
      pathname: "/organizationdetails",
      state: {
        organizationItem: item
      }
    })
  }
  const [organization, setOrganization] = React.useState([])
  const [organizationPage, setOrganizationPage] = React.useState({})
  const [searchValue, setSearchValue] = React.useState("")

  const handleDataSearch = e => {
    setSearchValue(e.target.value)
  }
  React.useEffect(() => {
    let payload = {
      offset: 0,
      rowsPerPage: 5
    }
    store.dispatch.Organization.getOrganizationlist(payload)
  }, [])

  React.useEffect(() => {
    setOrganization(props.organizationState.organizations)
    setOrganizationPage(props.organizationState.page)
  }, [props.organizationState.organizations, props.organizationState.page])
  return (
    <OrganizationWrapper>
      <Grid container>
        <Grid item xs={12}>
          <OrganizationTopWrapper>
            <PageHeading>Organizations</PageHeading>
            <OrganizationCreateWrapper onClick={handleCreateOrganization}>
              <CreateText> Create Organizations</CreateText>
              <img
                style={{
                  margin: "12px 7px 13px 9px"
                }}
                src={AddIcon}
              />
            </OrganizationCreateWrapper>
          </OrganizationTopWrapper>
          <OrganizationTopWrapperSearch>
            <SearchWrapper>
              <TextFieldWrapper>
                <TextField
                  style={{
                    margin: " 9px",
                    fontFamily: "Google Sans",
                    fontSize: "12px"
                  }}
                  id="reason"
                  InputProps={{
                    startAdornment: (
                      <img
                        style={{
                          width: " 21px",
                          margin: " 0px 4px 0px 2px",
                          padding: "2px 3px 5px 4px"
                        }}
                        src={icon}
                      />
                    ),
                    disableUnderline: true
                  }}
                  // //   value={reason}
                  onChange={e => {
                    handleDataSearch(e)
                  }}
                  margin="normal"
                  placeholder="Search..."
                  type="text"
                  fullWidth
                />
              </TextFieldWrapper>
            </SearchWrapper>
          </OrganizationTopWrapperSearch>
          <OrganizationTableWrapper>
            <OrganizationTable>
              <Table
                sortHeader={sortHeader}
                searchValue={searchValue}
                tableData={organization}
                tableHeader={header}
                handleDetails={handleOrganizationDetails}
                getDataList={store.dispatch.Organization.getOrganizationlist}
                organizationPage={organizationPage}
              />
            </OrganizationTable>
          </OrganizationTableWrapper>
        </Grid>
      </Grid>
    </OrganizationWrapper>
  )
}

const mapStateToProps = state => {
  return { organizationState: state.Organization }
}

export default connect(
  mapStateToProps,
  null
)(Organizations)

