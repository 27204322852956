import styled from "styled-components";

export const T = styled.table`
         padding-right: 20px;
         width: 100%;
         .wrapper-long {
           margin: 0 auto;
           padding: 0 0 20px;
         }
         .wrapper {
           margin: 0 auto;
           padding: 40px;
           max-width: 800px;
         }
         .table {
           margin: 0 0 40px 0;
           width: 100%;
           display: table;
           text-align: left;
           position: relative;
           border-collapse: collapse;
         }
         @media screen and (max-width: 580px) {
           .table {
             display: block;
             text-align: left;
             position: relative;
             border-collapse: collapse;
           }
         }
         .row-head {
           cursor: pointer;
           display: table-row;
           background-color: #ffffff;
         }
         .row {
           cursor: pointer;
           display: table-row;
           background-color: #ffffff;
           &:hover,
           &:focus {
             outline: none;
             background-color: #f8f8f8;
           }
         }
         .row:nth-of-type(odd) {
           background-color: #ffffff;
           &:hover,
           &:focus {
             outline: none;
             background-color: #f8f8f8;
           }
         }
         .row-head.header {
           height: 38px;
           border-radius: 8px;
           border: solid 1px #414a52;
           color: #ffffff;
           position: sticky;
         }
         .row-head.green {
           background-color: #414549;
         }
         .row.blue {
           background: #2980b9;
         }
         @media screen and (max-width: 580px) {
           .row {
             padding: 14px 0 7px;
             display: block;
           }
           .row-head.header {
             padding: 0;
             height: 6px;
           }
           .row-head.header .cell {
             display: none;
           }
           .row .cell {
             margin-bottom: 10px;
           }
           .row .cell:before {
             margin-bottom: 3px;
             content: attr(data-title);
             min-width: 98px;
             font-size: 10px;
             line-height: 10px;
             font-weight: bold;
             text-transform: uppercase;
             color: #969696;
             display: block;
           }
         }
         .cellHead {
           padding: 6px 20px;
           display: table-cell;
           width: 132px;
           height: 18px;
           font-family: Google Sans;
           font-size: 12px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #ffffffff;
         }

         .cell {
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 11px;
           display: table-cell;
           width: 132px;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }
         .cell-long {
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 20px;
           display: table-cell;
           width: 157px;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }
         .cell-long-line {
           justify-content: center;
           text-align: center;
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 20px;
           display: table-cell;
           width: 100%;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }
         .cell-yellow {
           color: #ff9500;
         }
         @media screen and (max-width: 580px) {
           .cell {
             padding: 2px 16px;
             display: block;
           }
         }
       `;


export const TableWrapper = styled.div`
         overflow-y: auto;
         height: 350px;
         padding-right: 20px;
         .wrapper-long {
           margin: 0 auto;
           padding: 40px;
         }
         .wrapper {
           margin: 0 auto;
           padding: 40px;
           max-width: 800px;
         }
         .table {
           margin: 0 0 40px 0;
           width: 100%;
           display: table;
           text-align: left;
           position: relative;
           border-collapse: collapse;
         }
         @media screen and (max-width: 580px) {
           .table {
             display: block;
             text-align: left;
             position: relative;
             border-collapse: collapse;
           }
         }

         .row {
           display: table-row;
           background-color: #ffffff;
         }
         .row:nth-of-type(odd) {
           background-color: #ffffff;
         }
         .row.header {
           height: 38px;
           border-radius: 8px;
           border: solid 1px #414a52;
           color: #ffffff;
           position: sticky;
         }
         .row.green {
           background-color: #414549;
         }
         .row.blue {
           background: #2980b9;
         }
         @media screen and (max-width: 580px) {
           .row {
             padding: 14px 0 7px;
             display: block;
           }
           .row.header {
             padding: 0;
             height: 6px;
           }
           .row.header .cell {
             display: none;
           }
           .row .cell {
             margin-bottom: 10px;
           }
           .row .cell:before {
             margin-bottom: 3px;
             content: attr(data-title);
             min-width: 98px;
             font-size: 10px;
             line-height: 10px;
             font-weight: bold;
             text-transform: uppercase;
             color: #969696;
             display: block;
           }
         }
         .cellHead {
           padding: 9px 20px;
           display: table-cell;
           width: 132px;
           height: 18px;
           font-family: Google Sans;
           font-size: 12px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #ffffffff;
         }

         .cell {
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 11px;
           display: table-cell;
           width: 132px;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }
         .cell-long {
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 20px;
           display: table-cell;
           width: 132px;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }

         @media screen and (max-width: 580px) {
           .cell {
             padding: 2px 16px;
             display: block;
           }
         }
       `;
