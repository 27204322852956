import React from "react";
import {
  makeStyles,
  withStyles,
  MuiThemeProvider
} from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import icon from "../../assets/img/patharrow.png";
import KeyboardArrowDown from "@material-ui/icons/KeyboardArrowDown";
const BootstrapInput = withStyles(theme => ({
  root: {
    flexGrow: "1",
    margin: "5px 16px 3px 5px",
    width: "100px",

    "label + &": {
      marginTop: theme.spacing(3)
    },
    "& $svg": {
      margin: "0px 6px 0px 0px"
    }
  },
  input: {
    borderRadius: "8px",
    backgroundColor: " #ffffff",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    textAlign: "center",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#6f6e6e",
    fontFamily: ["Google Sans !important"].join(","),
    border: "solid 1px rgba(71, 59, 59, 0.2) !important",
    height: "20px !important",
    fontSize: "12px",
    paddingTop: "10px",

    "&:focus": {
      borderRadius: 4,
      boxShadow: ""
    },
    "&:selected": {
      color: "red"
    }
  }
}))(InputBase);

const useStyles = makeStyles(theme => ({
  margin: {
    margin: theme.spacing(1)
  }
}));

export default function CustomizedSelects({
  exportOrg,
  classNameCls,
  classesParent,
  data,
  valueProp,
  onChange,
  label,
  multiple,
  isFilterDisable,
  disabled,
  id
}) {
  return (
    <Select
      className={classNameCls}
      style={{
        pointerEvents: isFilterDisable || disabled ? "none" : "visible",
        margin: id == "User" ? "0px" : " 5px 16px 3px 5px",
        height: exportOrg ? "20px !important" : "38px !important"
      }}
      labelId="customized-select-label"
      id="customized-select"
      value={valueProp}
      onChange={onChange}
      multiple={multiple}
      placeholder="fdsfdsfd"
      IconComponent={KeyboardArrowDown}
      inputProps={{
        classes: {
          icon: "red"
        }
      }}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left"
        },
        getContentAnchorEl: null
      }}
      input={
        <BootstrapInput
          style={{
            height: exportOrg ? "20px !important" : "38px !important"
          }}
        />
      }>
      {label&&<MenuItem value={"0"}>{label}</MenuItem>}
      {data !== undefined && data.length > 0
        ? data.map((item, index) => {
            if ("username" in item) {
              return (
                <MenuItem key={index} value={item.username}>
                  {item.username}
                </MenuItem>
              );
            }
            if ("organization_id" in item && id !== "User") {
              return (
                <MenuItem key={index} value={item.organization_id}>
                  {item.name}
                </MenuItem>
              );
            }
            if ("format" in item) {
              return (
                <MenuItem key={index} value={item.format}>
                  {item.label}
                </MenuItem>
              );
            }
            if ("organization_id" in item && id === "User") {
              return (
                <MenuItem key={index} value={item.id}>
                  {item.name}
                </MenuItem>
              );
            }
             if ("value" in item) {
               return (
                 <MenuItem key={index} value={item.value}>
                   {item.label}
                 </MenuItem>
               );
             }
          })
        : ""}
    </Select>
  );
}
