import React from 'react'
import { Switch } from 'react-router-dom'

//config, pages and components
import { RoutesConfig } from '../config/RoutesConfig'
import MapFilteredRoutes from './MapFilteredRoutes'

//utilities and helpers
import { getCookie } from "../utils/CookieHelper"
import { intersection } from 'lodash'


export default function OutputFilteredRoutes() {
	const filteredRoutes = filterRoutes(RoutesConfig)

	return (
		<Switch>
			<MapFilteredRoutes routes={filteredRoutes} />
		</Switch>
	)
}

/****************************** HELPER FUNCTIONS *****************************************/

function isArrayWithLength(arr) {
	return (Array.isArray(arr) && arr.length)
}

//filter routes by user role
function filterRoutes(routes) {
	const userRole = []
	userRole.push(getCookie('roles'))

	return routes.filter(({ accessRoles }) => {
		if(!accessRoles) return true
		else if(!isArrayWithLength(accessRoles)) return true
		else return intersection(accessRoles, userRole).length
	})
}