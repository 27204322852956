import { useEffect, useState } from "react";
import useGoogleMapMarker from "../google/useGoogleMapMarker";
import MarkerIcon from "../../../assets/img/marker.png";
import startMarker from "../../../assets/img/startMarker.png";
import endMarker from "../../../assets/img/endMarker.png";
const eventMapping = {
  onClick: "click",
  onDoubleClick: "dblclick"
};
export default function Marker({
  position,
  type,
  maps,
  map,
  handleChange,
  title,
  isheatmap,
  filterMarker,
  dataSet,
  getTotalMarkers,
  zoom,
  isDrawerManager,
  isLocationTrail,
  trails,
  userprof,
  isDevice
}) {
  //heat map functionality
  let iconPos = MarkerIcon;
  useEffect(() => {
    if (isheatmap) {
      window.markers.forEach(marker => {
        marker && marker.setMap(null);
      });
      window.markers = [];
    }
  }, [isheatmap]);

  //zoom functionality
  useEffect(() => {
    if (!isDrawerManager) {
      map.setZoom(zoom);
    }
  }, [zoom]);
  //for adding additional effects in markers
  useEffect(() => {
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    const bounds = new maps.LatLngBounds();
    var infowindow = new google.maps.InfoWindow();
    let markerCollections;
    window.markers = [];
    if (isDevice) {
      let info = true;
      window.markers.forEach(marker => {
        marker && marker.setMap(null);
      });
      markerCollections = dataSet.map((m, index) => {
        let title = "";
        if ("b_device_name" in m) {
          title = `Bluetooth Device Name :${m.b_device_name}`;
        }
        if ("ssid" in m) {
          title = `Wifi Device SSID :${m.ssid}`;
        }
        if ("network_operator_name" in m) {
          title = `Network Operator :${m.network_operator_name}`;
        }
        const position = {
          lat: m.location.latitude,
          lng: m.location.longitude
        };
        const events = {
          onClick: () => {}
        };
        return useGoogleMapMarker(
          position,
          type,
          maps,
          map,
          events,
          title,
          filterMarker,
          iconPos,
          1,
          infowindow,
          m,
          info
        );
      });
    } else {
      markerCollections = dataSet.map((m, index) => {
        const title = m.username;
        const position = {
          lat: m.location.latitude,
          lng: m.location.longitude
        };
        const events = {
          onClick: () => {
            handleChange(m.username);
          }
        };
        return useGoogleMapMarker(
          position,
          type,
          maps,
          map,
          events,
          title,
          filterMarker,
          iconPos
        );
      });
    }
    if (!isDrawerManager) {
      markerCollections.forEach(marker => {
        bounds.extend(marker.getPosition());
      });
      map.fitBounds(bounds);
    }
    window.markers = markerCollections;
  }, [JSON.stringify(dataSet)]);
  return null;
}
