import React from 'react'
import TextField from "@material-ui/core/TextField";
import { TextFieldWrapper } from "./watchlist.style";
function SendTextMessage(props) {
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column"
          }}>
          <div>What would you like to name this text message?</div>
          <TextFieldWrapper>
            <TextField
              id="reason"
              InputProps={{
                disableUnderline: true
              }}
              // //   value={reason}
              onChange={e => {
                e.target.value = e.target.value.substring(0, 200);
                props.setReasonPayload(e.target.value);
              }}
              margin="normal"
              placeholder={
                props.title
                  ? props.title
                  : "enter name for push notification watchlist"
              }
              type="text"
              fullWidth
            />
          </TextFieldWrapper>
        </div>
      </div>
    );
}

export default SendTextMessage
