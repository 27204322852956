import React, { useEffect } from "react";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";
import {
  StyledTab,
  StyledTabs,
  useStyles,
  TabPanel,
  HistoryWrapper,
  HistoryTopWrapper,
  HistoryTopWrapperFilter,
  HistoryTableTabWrapper,
  HistorySelectwrapper,
  SearchWrapper,
} from "./history.styles";
import { PageHeading } from "../../styles/Body.style";
import { TextFieldWrapper } from "../../styles/Wrapper.style";
import moment from "moment";
import calanderIcon from "../../assets/img/group-11.png";
import icon from "../../assets/img/search2x.png";
import TextField from "@material-ui/core/TextField";
import { Grid, IconButton, InputAdornment } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Select from "../../components/GeoSpatialFilterComponent/components/Select";
import store from "../../store";
import Table from "./HistoryTable";
import compareValues from "../../utils/sortCompare";
import { connect } from "react-redux";
import { validateFromAndToDate } from '../../utils/dateTime';
const header = ["NOTIFICATION TYPE", "USER", "MESSAGE", "DATE & TIME"];
const FilterSubDataType = [
  {
    id: 1,
    subtype: "wifi",
    label: "Wifi"
  },
  {
    id: 2,
    subtype: "user",
    label: "User"
  },
  {
    id: 3,
    subtype: "network",
    label: "Cellular Network"
  },
  {
    id: 4,
    subtype: "bluetooth",
    label: "Bluetooth"
  },
  {
    id: 4,
    subtype: "location",
    label: "Location"
  }
];
let initialPayload = {
  users: [],
  organizations: [],
  Search: ""
};
function History(props) {
  const classes = useStyles();
  let { organization, userList } = props.dashboardState;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [rowsPerPageSaftey, setRowsPerPageSaftey] = React.useState(5);
  const [rowsPerPageWatchList, setRowsPerPageWatchList] = React.useState(5);
  const [subtype, setSubtype] = React.useState(0);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [offsetSaftey, setOffsetSaftey] = React.useState(0);
  const [pageSaftey, setPageSaftey] = React.useState(0);
  const [offsetWatchlist, setOffsetWatchlist] = React.useState(0);
  const [pageWatchlist, setPageWatchlist] = React.useState(0);
  const [payload, setPayload] = React.useState(initialPayload);
  const [payloadSaftey, setPayloadSaftey] = React.useState(initialPayload);
  const [payloadWatchList, setPayloadWatchList] = React.useState(
    initialPayload
  );
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage + 1);
  };

  const handleSelectFilter = event => {
    setSubtype(event.target.value);
  };
  const handleChangePageSaftey = (event, newPage) => {
    setPageSaftey(newPage);
    setOffsetSaftey(rowsPerPageSaftey * newPage + 1);
  };
  const handleChangePageWatchlist = (event, newPage) => {
    setPageWatchlist(newPage);
    setOffsetWatchlist(rowsPerPageWatchList * newPage + 1);
  };
  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setOffset(0);
  };
  const handleChangeRowsPerPageSaftey = event => {
    setPageSaftey(0);
    setRowsPerPageSaftey(event.target.value);
    setOffsetSaftey(0);
  };
  const handleChangeRowsPerPageWatchList = event => {
    setPageWatchlist(0);
    setRowsPerPageWatchList(event.target.value);
    setOffsetWatchlist(0);
  };
  const [value, setValue] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState("");
  const [organizations, setOrganization] = React.useState(["0"]);
  const [user, setUser] = React.useState(["0"]);
  const [historylist, setHistorylist] = React.useState([]);
  const [historylistPage, setHistorylistPage] = React.useState({});
  const [historylistSaftey, setHistorylistSaftey] = React.useState([]);
  const [historylistPageSaftey, setHistorylistPageSaftey] = React.useState({});
  const [historylistWatchlist, setHistorylistWatchlist] = React.useState([]);
  const [isLoading, setLoader] = React.useState([]);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [
    historylistPageWatchlist,
    setHistorylistPageWatchlist
  ] = React.useState({});
  const handleUserChange = event => {
    let usr = [...event.target.value];
    if (usr.includes("0")) {
      const index = usr.indexOf("0");
      usr.splice(index, 1);
    }
    setUser(usr);
    if (event.target.value.length === 0) {
      setUser(["0"]);
    }
  };
  const handleHistory = () => {
    store.dispatch.Dashboard.getOrganizationlist();
    store.dispatch.Dashboard.getUserlist();
  };
  const handleDataSearch = e => {
    setOffset(0);
    setSearchValue(e.target.value);
  };
  const handleOrgChange = event => {
    setUser(["0"]);
    let org = [...event.target.value];
    if (org.includes("0")) {
      const index = org.indexOf("0");
      org.splice(index, 1);
    }
    store.dispatch.Dashboard.getUserlist(org);
    setOrganization(org);
    if (event.target.value.length === 0) {
      setOrganization(["0"]);
      setUser(["0"]);
    }
  };
  const [selectedDateFrom, setSelectedDateFrom] = React.useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [selectedDateTo, setSelectedDateTo] = React.useState(new Date());
  const handleDateChangeFrom = date => {
    setSelectedDateFrom(date);
  };
  const handleDateChangeTo = date => {
    setSelectedDateTo(date);
  };
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    handleHistory();
  }, []);
  useEffect(() => {
    setLoader(props.historyState.loading);
  }, [props.historyState.loading]);
  useEffect(() => {
    let org = [],
      usr = [];
    const validate = validateFromAndToDate(selectedDateFrom, selectedDateTo);
      if(validate){
        setErrorMsg(validate);
      } else { 
      setErrorMsg('');
      if (selectedDateFrom && selectedDateTo) {
      organization &&
        organization.length > 0 &&
        organization.forEach(element => {
          organizations.forEach(item => {
            if (element.organization_id === item) {
              org.push(element.id);
            }
          });
        });
      userList &&
        userList.length > 0 &&
        userList.forEach(element => {
          user.forEach(item => {
            if (element.username === item) {
              usr.push(element.id);
            }
          });
        });
      setPayload(prevState => ({
        ...prevState,
        to_date: moment.utc(selectedDateTo).toISOString(),
        from_date: moment.utc(selectedDateFrom).toISOString(),
        organizations: org,
        users: usr,
        search: searchValue
      }));
    }
  }
  }, [
    selectedDateFrom,
    selectedDateTo,
    organizations,
    user,
    searchValue,
    organization,
    userList
  ]);
  useEffect(() => {
    let org = [],
      usr = [];
    const validate = validateFromAndToDate(selectedDateFrom, selectedDateTo);
    if(validate){
      setErrorMsg(validate);
    } else { 
    setErrorMsg('');
    if (selectedDateFrom && selectedDateTo) {
      organization &&
        organization.length > 0 &&
        organization.forEach(element => {
          organizations.forEach(item => {
            if (element.organization_id === item) {
              org.push(element.id);
            }
          });
        });
      userList &&
        userList.length > 0 &&
        userList.forEach(element => {
          user.forEach(item => {
            if (element.username === item) {
              usr.push(element.id);
            }
          });
        });

      setPayloadSaftey(prevState => ({
        ...prevState,
        to_date: moment.utc(selectedDateTo).toISOString(),
        from_date: moment.utc(selectedDateFrom).toISOString(),
        organizations: org,
        users: usr,
        search: searchValue
      }));
    }
  }
  }, [
    selectedDateFrom,
    selectedDateTo,
    organizations,
    user,
    searchValue,
    organization,
    userList
  ]);
  useEffect(() => {
    setPage(0);
    setPageSaftey(0);
    setPageWatchlist(0);
    let org = [],
      usr = [];
    const validate = validateFromAndToDate(selectedDateFrom, selectedDateTo);
    if(validate){
      setErrorMsg(validate);
    } else { 
    setErrorMsg('');
    if (selectedDateFrom && selectedDateTo) {
      organization &&
        organization.length > 0 &&
        organization.forEach(element => {
          organizations.forEach(item => {
            if (element.organization_id === item) {
              org.push(element.id);
            }
          });
        });
      userList &&
        userList.length > 0 &&
        userList.forEach(element => {
          user.forEach(item => {
            if (element.username === item) {
              usr.push(element.id);
            }
          });
        });
      if (subtype !== "0") {
        setPayloadWatchList(prevState => ({
          ...prevState,
          to_date: moment.utc(selectedDateTo).toISOString(),
          from_date: moment.utc(selectedDateFrom).toISOString(),
          organizations: org,
          users: usr,
          search: searchValue,
          subtype
        }));
      } else {
        setPayloadWatchList(prevState => ({
          ...prevState,
          to_date: moment.utc(selectedDateTo).toISOString(),
          from_date: moment.utc(selectedDateFrom).toISOString(),
          organizations: org,
          users: usr,
          search: searchValue,
          subtype: ""
        }));
      }
    }
    setPayloadWatchList(prevState => ({
      ...prevState,
      to_date: moment.utc(selectedDateTo).toISOString(),
      from_date: moment.utc(selectedDateFrom).toISOString(),
      organizations: org,
      users: usr,
      search: searchValue
    }));
  }
  }, [
    selectedDateFrom,
    selectedDateTo,
    organizations,
    user,
    searchValue,
    organization,
    userList,
    subtype
  ]);
  useEffect(() => {
    const arr = props.historyState.timeLineData;
    const sorted = arr && arr.sort(compareValues("timestamp", "desc"));
    setHistorylist(sorted);
    setHistorylistPage(props.historyState.page);
  }, [props.historyState.timeLineData]);
  React.useEffect(() => {
    setPage(0);
    setPageSaftey(0);
    setPageWatchlist(0);
    setOffset(1);
    setRowsPerPage(5);
    setOffsetSaftey(1);
    setRowsPerPageSaftey(5);
    setRowsPerPageWatchList(5);
    setOffsetWatchlist(1);
  }, [searchValue]);
  React.useEffect(() => {
    setPayload(prevState => ({
      ...prevState,
      offset,
      rowsPerPage
    }));
  }, [offset, rowsPerPage]);
  React.useEffect(() => {
    setPayloadWatchList(prevState => ({
      ...prevState,
      offset,
      rowsPerPage
    }));
  }, [offsetSaftey, rowsPerPage]);
  React.useEffect(() => {
    setPayloadSaftey(prevState => ({
      ...prevState,
      offset: offsetSaftey,
      rowsPerPage: rowsPerPageSaftey
    }));
  }, [offsetSaftey, rowsPerPageSaftey]);
  React.useEffect(() => {
    setPayloadWatchList(prevState => ({
      ...prevState,
      offset: offsetWatchlist,
      rowsPerPage: rowsPerPageWatchList
    }));
  }, [offsetWatchlist, rowsPerPageWatchList]);
  React.useEffect(() => {
    store.dispatch.History.getTimeLine(payload);
  }, [payload]);
  React.useEffect(() => {
    store.dispatch.History.getTimeLineSaftey(payloadSaftey);
  }, [payloadSaftey]);
  React.useEffect(() => {
    store.dispatch.History.getTimeLineWatchlist(payloadWatchList);
  }, [payloadWatchList]);
  React.useEffect(() => {
    const arr = props.historyState.safteyData;
    const sorted = arr && arr.sort(compareValues("timestamp", "desc"));
    setHistorylistSaftey(sorted);
    setHistorylistPageSaftey(props.historyState.pageSaftey);
  }, [props.historyState.safteyData, props.historyState.pageSaftey]);
  React.useEffect(() => {
    const arr = props.historyState.watchlistData;
    const sorted = arr && arr.sort(compareValues("timestamp", "desc"));
    setHistorylistWatchlist(sorted);
    setHistorylistPageWatchlist(props.historyState.pageWatchlist);
  }, [props.historyState.watchlistData, props.historyState.pageWatchlist]);
  return (
    <div className={classes.root}>
      <HistoryWrapper>
        <Grid container>
          <Grid item xs={12}>
            <HistoryTopWrapper>
              <PageHeading>History</PageHeading>
            </HistoryTopWrapper>
            <HistoryTopWrapperFilter>
              <HistorySelectwrapper>
                <Select
                  classesParent={classes}
                  data={organization}
                  valueProp={organizations}
                  onChange={handleOrgChange}
                  label="Organizations"
                  multiple={true}
                />
                <Select
                  classesParent={classes}
                  data={userList}
                  valueProp={user}
                  onChange={handleUserChange}
                  label="Users"
                  multiple={true}
                />
              </HistorySelectwrapper>
              <HistorySelectwrapper>
              <div style={{display: "flex", flexDirection: "column"}}>
                <div style={{display: "flex"}}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    className={classes.dateTimePicker}
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd MMM yyyy"
                    value={selectedDateFrom}
                    onChange={handleDateChangeFrom}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            classes={{
                              root: classes.adornment
                            }}>
                            <img src={calanderIcon} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      disableUnderline: true
                    }}
                    disableFuture
                  />
                </MuiPickersUtilsProvider>
                    
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableFuture
                    className={classes.dateTimePicker}
                    margin="normal"
                    id="date-picker-dialog"
                    format="dd MMM yyyy"
                    value={selectedDateTo}
                    onChange={handleDateChangeTo}
                    KeyboardButtonProps={{
                      "aria-label": "change date"
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            classes={{
                              root: classes.adornment
                            }}>
                            <img src={calanderIcon} />
                          </IconButton>
                        </InputAdornment>
                      ),
                      disableUnderline: true
                    }}
                  />
                </MuiPickersUtilsProvider>
                </div>
                { errorMsg &&
                  <div className="errorMessage">{errorMsg}</div>
                }
                </div>
              </HistorySelectwrapper>
              <SearchWrapper>
                <TextFieldWrapper>
                  <TextField
                    style={{
                      margin: " 9px",
                      fontFamily: "Google Sans",
                      fontSize: "12px"
                    }}
                    id="reason"
                    InputProps={{
                      startAdornment: (
                        <img
                          style={{
                            width: " 21px",
                            margin: " 0px 4px 0px 2px",
                            padding: "2px 3px 5px 4px"
                          }}
                          src={icon}
                        />
                      ),
                      disableUnderline: true
                    }}
                    // //   value={reason}
                    onChange={e => {
                      handleDataSearch(e);
                    }}
                    margin="normal"
                    placeholder="Search..."
                    type="text"
                    fullWidth
                  />
                </TextFieldWrapper>
              </SearchWrapper>
            </HistoryTopWrapperFilter>
            <HistoryTableTabWrapper>
              <div className={classes.demo2}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="styled tabs example">
                  <StyledTab
                    label={`All (${
                      historylistPage && historylistPage.count !== undefined
                        ? historylistPage.count
                        : 0
                    })`}
                  />{" "}
                  <span className={classes.spantest}>|</span>
                  <StyledTab
                    label={`Safety Alerts (${
                      historylistPageSaftey &&
                      historylistPageSaftey.count !== undefined
                        ? historylistPageSaftey.count
                        : 0
                    })`}
                  />
                  <span className={classes.spantest}>|</span>
                  <StyledTab
                    label={`Watchlist (${
                      historylistPageWatchlist &&
                      historylistPageWatchlist.count !== undefined
                        ? historylistPageWatchlist.count
                        : 0
                    }) `}
                  />
                </StyledTabs>
                <TabPanel value={value} index={0}>
                  <Table
                    isLoading={isLoading}
                    tableData={historylist}
                    tableHeader={header}
                    handleDetails={item => {
                      props.history.push(`/${item.username}`);
                    }}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    organizationPage={historylistPage}
                    isHistory={true}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <Table
                    isLoading={isLoading}
                    tableData={historylistSaftey}
                    tableHeader={header}
                    handleDetails={item => {
                      props.history.push(`/${item.username}`);
                    }}
                    handleChangePage={handleChangePageSaftey}
                    handleChangeRowsPerPage={handleChangeRowsPerPageSaftey}
                    rowsPerPage={rowsPerPageSaftey}
                    page={pageSaftey}
                    organizationPage={historylistPageSaftey}
                    isHistory={false}
                  />
                </TabPanel>
                <TabPanel value={value} index={4}>
                  <div
                    style={{
                      display: "flex",
                      width: "15%"
                    }}>
                    <Select
                      classesParent={classes}
                      data={FilterSubDataType}
                      valueProp={subtype}
                      onChange={handleSelectFilter}
                      label="Watchlist type"
                    />
                  </div>
                  <Table
                    isLoading={isLoading}
                    tableData={historylistWatchlist}
                    tableHeader={header}
                    handleDetails={item => {
                      props.history.push(`/${item.username}`);
                    }}
                    handleChangePage={handleChangePageWatchlist}
                    handleChangeRowsPerPage={handleChangeRowsPerPageWatchList}
                    rowsPerPage={rowsPerPageWatchList}
                    page={pageWatchlist}
                    organizationPage={historylistPageWatchlist}
                    isHistory={false}
                  />
                </TabPanel>
              </div>
            </HistoryTableTabWrapper>
          </Grid>
        </Grid>
      </HistoryWrapper>
    </div>
  );
}
const mapStateToProps = state => {
  return { dashboardState: state.Dashboard, historyState: state.History };
};
export default connect(
  mapStateToProps,
  null
)(History);
