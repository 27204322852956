import React from "react";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { useStyles } from "./DialogActivity.style";
import compareValues from "../../utils/sortCompare";

export default function SimpleSlide({ checked, handelClose, activity }) {
  const classes = useStyles();
  const sortedarr = activity.sort(compareValues("timestamp", "desc"));
  return (
    <Slide
      className={classes.root}
      direction="left"
      in={checked}
      mountOnEnter
      unmountOnExit>
      <Paper elevation={4} className={classes.paper}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handelClose}>
          <CloseIcon />
        </IconButton>
        <section className={classes.flex}>
          <section className={classes.flexSection}>
            <div className={classes.Heading}>User Activity</div>
          </section>
          <Divider
            style={{
              border: "1px dashed #d1d1d6",
              marginTop: "10px",
              width: "300px",
              marginLeft: "12px"
            }}
          />
          <section className={classes.flexSectionContent}>
            {sortedarr.length > 0 ? (
              sortedarr.map((item, index) => {
                return (
                  //  <div
                  //    key={
                  //      index
                  //    }>{`${moment(item.timestamp).format("hh:mm:ss")} -- ${item.description}`}</div>
                  <div
                    key={index}
                    className={classes.detailSub}
                    style={{ flexDirection: "row" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column"
                      }}>
                      <span className={classes.detailContent}>
                        {moment(item.timestamp).format("HH:mm:ss")}
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        margin: "0px 0px 0px -14px"
                      }}>
                      <span className={classes.detailContentw}>
                        {item.description}
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className={classes.noRecentActivity}>No recent activities</p>
            )}
          </section>
        </section>
      </Paper>
    </Slide>
  );
}
