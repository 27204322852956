import styled from "styled-components";
import MenuItem from "@material-ui/core/MenuItem";
export const StyledMenu = styled.nav`
  height: 1024px;
  background-color: #161a1e;
`;
export const StyledMenuItem = styled(MenuItem)`
                        width: "289px";
                        height: " 91px";
                        background-image;
                          "linear-gradient(to left, #f8f8fa 44%, #ffffff 0%);",
                        display: "flex";
                        flex-direction: "column";
         cursor: "pointer";
           .MuiMenuItem-root {
    &:active fieldset {
      border-color: yellow;
    }
  }
   
       `;
export const StyledCountryBar = styled.div`
  height: " 38px";
  border-radius: "8px";
  border: "solid 1px #414a52";
  background-color: " rgba(39; 45, 49, 0.9)";
  margin: " 21px 15px -3px 15px";
`;
export const SpanText = styled.span`
  width: "53px";
  height: "20px";
  font-family: "Google Sans";
  font-size: "39px";
  font-weight: "normal";
  font-stretch: "normal";
  font-style: "normal";
  line-height: "normal";
  letter-spacing: "normal";
  color: ${props => (props.active === true ? "#000000" : "#ff3b30")};
  font-weight: ${props => (props.active ? "normal" : "bold")};
`;
export const SpanCountryText = styled.span`
  width: 231px;
  height: 25px;
  font-family: Google Sans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  margin: "6px 0px 0px 45px";
`;
