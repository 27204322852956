import React, { useState } from "react";
import PropTypes from "prop-types";
import AddmoreRed from "../../assets/img/addmorered.png";
import Delete from "../../assets/img/delete.png";
import delete4 from "../../assets/svg/delete.svg";
import { Field } from "../FormField";
import FormHelperText from "@material-ui/core/FormHelperText";
import { FieldPhoneNumber } from "../FormField/PhoneNumber";
import {
  SubformWrapper,
  Label,
  InputWrap,
  SubformFormControl,
  SubformSection,
  AddMore,
  AddmoreText,
  PhoneNumber
} from "./subform.style";
const ContactForm = props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    idx,
    contactState,
    handleContactChange,
    addContact,
    deleteContact,
    errorState,
    valid,
    updateFormValid,
    updateFormField
  } = props;
  const [form, setForm] = useState({ ...contactState });
  const [error, setError] = useState({
    name: true,
    office_phone_number: true,
    email: true,
    phone: true
  });

  const validatePhone = value => {
    const phoneRegExp = /^[0-9-+()]*$/;
    return phoneRegExp.test(value);
  };
  const validateOfficeHours = value => {
    const officeHoursRegExp = /^[0-9-+()]*$/;
    return officeHoursRegExp.test(value);
  };
  const validateName = value => {
    const officeHoursRegExp = /^[A-Za-z\s]+$/;
    return officeHoursRegExp.test(value);
  };
  const validateEmail = value => {
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(value)) {
      return true;
    } else if (value === "") {
      return false;
    }
  };

  const validateText = value => {
    return value !== "";
  };
  React.useEffect(() => {
    setForm({ ...contactState });
  }, [contactState]);
  const validateFormFields = (value, state) => {
    let valid = false;
    // if (state === "phone") {
    //   valid = true ;
    //   if(state==="office_phone_number"){
    //     valid = true;
    // } else
    // } else

    if (state === "email") {
      valid = validateEmail(value);
    } else if (state === "name") {
      valid = validateName(value);
    } else {
      valid = validateText(value);
    }

    setError({ ...error, [state]: valid });
  };

  const validateForm = data => {
    const valid = [
      validateName(data.name),
      validateEmail(data.email)
      // validatePhone(data.phone),
    ].every(condition => condition);
    return valid;
  };

  const inputChange = (state, index, number) => event => {
    const temp = { ...form };
    temp[state] =
      state === "office_phone_number" || state === "phone"
        ? event
        : event.target.value;
    const isValid = validateForm(temp);
    if (valid !== isValid) {
      updateFormValid(index, isValid);
    }
    setForm(temp);
    updateFormField(
      state,
      index,
      state === "office_phone_number" || state === "phone"
        ? event
        : event.target.value
    );
    validateFormFields(
      state === "office_phone_number" || state === "phone"
        ? event
        : event.target.value,
      state
    );
  };

  const nameId = `name-${idx}`;
  const phoneID = `phone-${idx}`;
  const office_phone_numberID = `office_phone_number-${idx}`;
  const emailID = `email-${idx}`;

  return (
    <React.Fragment>
      <div
        style={{
          display: "flex"
        }}>
        <SubformWrapper key={`cat-${idx}`} idx={idx}>
          <SubformSection>
            <SubformFormControl>
              <Label error={!error.name} htmlFor={nameId}>{`NAME`}</Label>
              <input
                type="text"
                name={"name"}
                style={{
                  border: !error.name
                    ? "1px solid #ff2d21"
                    : "1px solid  #dddbdb"
                }}
                data-idx={idx}
                id={nameId}
                placeholder="Name"
                className="name"
                value={form.name}
                onChange={inputChange("name", idx)}
              />
              {/* <FormHelperText component="div" style={{ color: "#ff2d21" }}>
                {!error.name && <span>please valid name this field</span>}
              </FormHelperText> */}
            </SubformFormControl>

            {/* <SubformFormControl>
              <Label
                error={!error.office_phone_number}
                htmlFor={office_phone_numberID}>{`OFFICE NUMBER`}</Label>
              <input
                style={{
                  border: !error.office_phone_number
                    ? "1px solid #ff2d21"
                    : "1px solid  #dddbdb"
                }}
                type="text"
                name={office_phone_numberID}
                data-idx={idx}
                id={office_phone_numberID}
                className="office_phone_number"
                placeholder="Office number"
                value={form.office_phone_number}
                onChange={inputChange("office_phone_number", idx)}
              />
              <FormHelperText component="div" style={{ color: "#ff2d21" }}>
                {!error.office_phone_number && <span>please provide number</span>}
              </FormHelperText>
            </SubformFormControl> */}

            <PhoneNumber>
              <FieldPhoneNumber
                // {...phoneNumberField}
                label="OFFICE NUMBER"
                type="text"
                name={office_phone_numberID}
                data-idx={idx}
                id={office_phone_numberID}
                className="office_phone_number"
                placeholder="Office number"
                value={form.office_phone_number}
                onChange={inputChange("office_phone_number", idx)}
              />
            </PhoneNumber>

            <PhoneNumber>
              <FieldPhoneNumber
                // {...phoneNumberField}
                label="PHONE NUMBER"
                type="text"
                name="phone"
                id="phone"
                value={form.phone}
                data-idx={idx}
                placeholder="Phone Number"
                onChange={inputChange("phone", idx)}
              />
            </PhoneNumber>
            {/* <SubformFormControl>
              <Label error={!error.phone} htmlFor={phoneID}>
                PHONE NUMBER
              </Label>
              <input
                style={{
                  border: !error.phone
                    ? "1px solid #ff2d21"
                    : "1px solid  #dddbdb"
                }}
                type="text"
                name={"phone"}
                data-idx={idx}
                id={phoneID}
                className="phone"
                placeholder="Phone Number"
                value={form.phone}
                onChange={inputChange("phone", idx)}
              />
              <FormHelperText component="div" style={{ color: "#ff2d21" }}>
                {!error.phone && <span>please provide valid phone number</span>}
              </FormHelperText>
            </SubformFormControl> */}
            <SubformFormControl>
              <Label error={!error.email} htmlFor={emailID}>{`EMAIL ID`}</Label>
              <input
                style={{
                  border: !error.email
                    ? "1px solid #ff2d21"
                    : "1px solid  #dddbdb"
                }}
                type="text"
                name={emailID}
                data-idx={idx}
                id={emailID}
                className="email"
                placeholder="Email ID"
                value={form.email}
                onChange={inputChange("email", idx)}
              />
              <FormHelperText component="div" style={{ color: "#ff2d21" }}>
                {!error.email && <span>please provide valid email</span>}
              </FormHelperText>
            </SubformFormControl>
          </SubformSection>
          {idx === 0 && valid ? (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <AddMore onClick={addContact}>
                <AddmoreText>Add more</AddmoreText>
                <img
                  style={{ margin: "10px 12px 10px 15px" }}
                  src={AddmoreRed}
                />
              </AddMore>
            </div>
          ) : (
            ""
          )}
        </SubformWrapper>
        {idx !== 0 ? (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "pointer"
            }}
            onClick={() => {
              deleteContact(idx);
            }}>
            <img style={{ margin: "0px 31px 147px 0px" }} src={delete4} />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

ContactForm.propTypes = {
  idx: PropTypes.number,
  contactState: PropTypes.array,
  handleContactChange: PropTypes.func
};

export default ContactForm;
