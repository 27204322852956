import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: "Google Sans",
    paddingTop: "15px"
  },
  flex: {
    display: "flex"
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column"
  },
  marginTopClass: {
    marginTop: "25px"
  },
  widthClass: {
    width: "100%"
  },
  pointer: {
    position: "relative",
    width: " 120.5px",
    //  width: " 99.5px",
    height: "20.9px",
    borderRadius: "1px",
    backgroundColor: "#ffcc00",
    "&::after": {
      content: '""',
      position: "absolute",
      left: "0",
      bottom: " 0",
      width: "0",
      height: "0",
      borderLeft: "10px solid white",
      borderTop: "12px solid transparent",
      borderBottom: "10px solid transparent"
    }
  },
  rootSectionBackButton: {
    display: "flex",
    justifyContent: "space-between",
    width: "calc(100% + 20px)",
    marginRight: "-20px"
  },
  rootSectionMenuDrop: {
    display: "flex",
    marginTop: "23px",
    width: "100%",
    paddingRight: "30px",
    justifyContent: "space-between"
  },
  watchListed: {
    //  width: " 75px",
    height: "13px",
    fontFamily: "Muli",
    fontSize: "12px",
    fontWeight: "800",
    fontStretch: " normal",
    fontStyle: "normal",
    lineHeight: " normal",
    letterSpacing: "normal",
    color: "#79630a",
    margin: "22px"
  },
  userDetailHeading: {
    display: "flex",
    flexDirection: "column",
    marginTop: "-5px"
  },
  safeDistresswrapper: {
    margin: "4px 0px 0px",
    justifyContent: " space-between",
    display: "flex"
  },
  safteyAlertMenuWrapper: {
    display: "flex",
    cursor: "pointer",
    margin: "5px 0px 0px 0px"
  },
  moreActionsWrapper: {
    display: "flex",
    cursor: "pointer",
    margin: "5px 0px 0px 0px"
  },
  refreshIcon: {
    width: "38px",
    height: "38px",
    borderRadius: "10px",
    backgroundColor: "#60bca7",
    cursor: "pointer",
    marginRight: "15px"
  },
  refreshIconImage: {
    padding: "9px 0px 1px 8px"
  },
  refreshIconImageSlider: {
    width: "23px",
    padding: "8px 0px 4px 9px"
  },
  refreshMargin: {
    marginLeft: "10px"
  },
  userText: {
    width: "auto",
    height: "30px",
    fontFamily: "Google Sans",
    fontSize: "24px",
    fontHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: " normal",
    letterSpacing: " normal",
    color: "#161a1e",
    display: "flex"
  },
  lastRespond: {
    display: "block",
    marginTop: "6px",
    fontSize: "13px",
    opacity: "1",
    fontWeight: "600"
  },
  respondSafe: {
    color: "#20bf3b"
  },
  respondDistress: {
    color: "#be1e15"
  },
  lastUpdated: {
    //  width: "274px",
    width: "100%",
    height: "13px",
    opacity: "0.48",
    fontFamily: "Google Sans",
    fontSize: "10px",
    fontHeight: " 500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #000000"
  },
  onlineOval: {
    width: "8px",
    height: "11px",
    backgroundColor: "#4cd964",
    padding: "0px 0px 0px 10px",
    borderRadius: "30px",
    margin: "9px"
  },
  offlineOval: {
    width: "8px",
    height: "11px",
    backgroundColor: "rgb(255, 45, 33)",
    padding: "0px 0px 0px 10px",
    borderRadius: "30px",
    margin: "9px"
  },
  safeDistressFlag: {
    display: "flex",
    padding: "0 20px",
    height: "38px",
    borderRadius: "8px",
    border: " dashed 0.5px #ff2d21",
    backgroundColor: " #fff6f5",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "25px"
  },
  safeKeyword: {
    width: "82px",
    height: "16px",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#959190"
  },
  distressKeyword: {
    width: "102px",
    height: "16px",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#959190"
  },
  safe: {
    height: "18px",
    fontFamily: "Google Sans",
    fontSize: "14px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#20bf3b",
    margin: "-2px 0px 0px 0px"
  },
  distress: {
    height: "18px",
    fontFamily: "Google Sans",
    fontSize: "14px",
    fontWeight: "600",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#be1e15",
    margin: "-2px 0px 0px 0px"
  },
  safteyAlert: {
    width: " 131.5px",
    height: "38px",
    borderRadius: "8px",
    backgroundColor: " #ff2d21",
    marginRight: "25px"
  },
  moreActions: {
    width: " 143px",
    height: "38px",
    borderRadius: "8px",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    border: " solid 1px rgba(0, 0, 0, 0.09)",
    backgroundColor: " #ffffff"
  },
  safteyAlertText: {
    display: "flex",
    alignItems: "center",
    height: "18px",
    fontFamily: "Google Sans",
    fontSize: "14px",
    fontHeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: " normal",
    color: "#ffffff",
    margin: "4px 0px 0px 27px"
  },
  moreActionsText: {
    display: "flex",
    alignItems: "center",
    height: "18px",
    fontFamily: "Google Sans",
    fontSize: "14px",
    fontHeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: " normal",
    color: " #ff2d21",
    margin: "4px 0px 0px 27px"
  },
  charts: {
    margin: "0px 25px 7px 22px",
    display: "flex",
    marginRight: "20px",
    height: "452px",
    borderRadius: "10px",
    boxShadow: " 0 5px 10px 0 rgba(0, 0, 0, 0.06)",
    border: "solid 2px rgba(151, 151, 151, 0.06)",
    backgroundColor: " #ffffff",
    flexDirection: "row"
  },
  chartsControl: {
    width: "322px",
    height: "40px",
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    border: "solid 1px #eaeaea",
    backgroundColor: "#ffffff"
  },
  timeLine: {
    paddingTop: "30px",
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    backgroundColor: " #ffffff"
  },
  timeLineClass: {
    padding: "0",
    width: "100%",
    borderRadius: "10px",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    backgroundColor: " #ffffff"
  },
  timelineText: {
    margin: " 6px 0px 0px 40px",
    width: "144px",
    height: "23px",
    fontFamily: "Google Sans",
    fontSize: "18px",
    fontWeight: " normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#161a1e",
    "@media only screen and (max-width: 1400px)": {
      marginTop: "18px"
    }
  },
  timeLineAnd: {
    margin: "8px 2px 0px 1px",
    "@media only screen and (max-width: 1400px)": {
      marginTop: "18px !important"
    }
  },
  timelinePickerWrap: {
    display: "flex",
    "& $input[type=text]": {
      // paddingTop: "6px ! important",
      // paddingBottom: "5px"
    }
  },
  timelinePicker: {
    margin: "0px 19px 0px 2px",
    //  width: "197px",
    height: "36px",
    borderRadius: "10px",
    border: "0",
    //  boxShadow: "rgba(0, 0, 0, 0.55) 0px 0px 3px !important",
    "& $input[type=text]": {
      // paddingTop: "6px ! important",
      // paddingBottom: "5px"
    },
    "& $input[type=time]": {
      paddingTop: "0px ! important"
    },
    "& $button": {
      // marginTop: "4px",
    }
  },
  timelinePickerOuter: {
    margin: "0px 19px 0px 17px",
    //  width: "197px",
    height: "36px",
    borderRadius: "10px"
    //  backgroundColor: "#f2f7fa"
  },
  datePickerMargin: {
    margin: "8px 4px 7px 10px",
    padding: " 0px"
  },
  dateRoot: {
    backgroundColor: "red"
  },
  mapComponentDetails: {
    borderRadius: "10px",
    backgroundImage:
      "linear-gradient(to bottom, rgba(255, 255, 255, 0) -7%, #ffffff 36%)"
  },
  mapBottomContent: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    margin: "35px 54px 34px 5px"
  },
  detailSub: {
    width: " 80px",
    height: " 35px",
    objectFit: "contain",
    display: "flex",
    flexDirection: "column",
    padding: "14px 0px 0px 26px",
    margin: "20px 0px 0px 3px"
  },
  detailHeading: {
    // width: "200px",
    //  height: "30px",
    opacity: " 0.7",
    fontFamily: "Google Sans",
    fontSize: "10px",
    fontHeight: "500",
    fontStretch: " normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #161a1e"
  },
  userDetailWrapper: {
    display: "flex",
    flexDirection: "column",
    margin: " 0px 0px 0px 45px",
    "& span": {
      width: "200px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    }
  },
  userDetailContent: {
    width: "calc(100% / 5)",
    marginBottom: "37px",
    display: "flex",
    objectfit: "contain"
  },
  detailContent: {
    height: "20px",
    fontFamily: "Google Sans",
    fontSize: "16px",
    fontHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #161a1e"
  },
  mapBaseWrapper: {
    display: "flex",
    flexWrap: "wrap",
    zIndex: "99",
    margin: "-47px 6px 27px",
    justifyContent: "space-between"
  },
  mapBaseContent: {
    display: "flex",
    margin: "-38px 0px 0px 2px",
    width: "468px",
    height: "77px",
    borderRadius: "10px",
    border: "solid 1px #414a52",
    backgroundColor: "rgba(39, 45, 49, 0.9)",
    maxWidth: "100%"
  },
  mapBaseDetail: {
    flexDirection: "column",
    margin: "19px 0px 0px 18px"
  },
  mapBatteryWrapper: {
    display: "flex",
    margin: "0px 52px 0px 2px",
    width: " 150px",
    height: "38px",
    borderRadius: " 10px",
    boxShadow:
      " 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.2)",
    border: "solid 1px #e1e1e1",
    backgroundColor: " #ffffff"
  },
  mapBatteryDetail: {
    margin: "10px 0px 0px 8px",
    width: "80px",
    height: " 16px",
    fontFamily: " Google Sans",
    fontSize: "12px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: " normal",
    letterSpacing: "normal",
    color: " #161a1e",
    overflow: "hidden"
  },
  mapBatteryIMG: {
    width: "11px",
    height: "19px",
    margin: "6px 6px 5px 0px"
  },
  mapBatteryPercentage: {
    margin: "10px 0px 0px 4px",
    fontFamily: " Google Sans",
    fontSize: "12px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: " normal",
    letterSpacing: "normal",
    color: " #161a1e"
  },
  mapControls: {
    width: "322px",
    height: "40px",
    borderRadius: "10px",
    boxShadow:
      "0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.15)",
    border: "solid 1px #eaeaea",
    backgroundColor: " #ffffff"
  },
  backToGeoSpatial: {
    cursor: "pointer",
    display: "flex",
    margin: "0px 20px 0px 4px",
    width: "227px",
    height: "36px",
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.55) 0px 0px 3px",
    border: "solid 1px rgba(0, 0, 0, 0.09)",
    backgroundColor: " #ffffff"
  },
  geospactialText: {
    margin: "7px 0px 0px 20px",
    height: "18px",
    fontFamily: "Google Sans",
    fontSize: "14px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #ff2d21"
  },
  minimizeMap: {
    cursor: "pointer",
    width: "38px",
    height: " 38px",
    borderRadius: "10px",
    boxShadow:
      " 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.2)",
    border: "solid 1px #e1e1e1",
    backgroundColor: " #ffffff"
  },
  minimizeMapActivityIndicator: {
    marginTop: "11px",
    cursor: "pointer",
    width: "38px",
    height: " 38px",
    borderRadius: "10px",
    boxShadow:
      " 0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.2)",
    border: "solid 1px #60bca7",
    backgroundColor: " #60bca7"
  },
  mapControlsDiv: {
    opacity: "0.7",
    color: "#161a1e",
    margin: "4px 0px 0px 4px",
    width: "97px",
    height: "30px",
    borderRadius: "7px",
    //  backgroundColor: " #ff2d21",
    "&:active": {
      color: "#ffffff",
      margin: "4px 0px 0px 4px",
      width: "97px",
      height: "30px",
      borderRadius: "7px",
      backgroundColor: " #ff2d21"
    }
  },
  spaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },
  graphtab: {
    display: "flex",
    marginBottom: "5px",
    marginLeft: "17px"
  },
  graphpicker: {
    display: "flex",
    "& $input": {
      // paddingTop: "8px ! important",
      // paddingBottom: "5px"
      margin: "13px 10px 10px 10px !important"
    },
    "& $button": {
      // marginTop: "4px",
    }
  },
  chartWrapperMenu: {
    display: "flex",
    height: "40px",
    borderRadius: "10px",
    boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    border: " solid 1px #eaeaea",
    backgroundColor: "#ffffff",
    margin: "0"
  },
  marginTopClassChart: {
    display: " flex",
    margin: "26px 0px 7px 20px",
    width: "calc((100% - 20px)/2)"
  },
  panelDetailHeading: {
    width: "79px",
    height: "13px",
    opacity: "0.8",
    fontFamily: "Google Sans",
    fontSize: "10px",
    fontWeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  panelDetailContent: {
    margin: " 4px 0px 0px 0px",
    width: "240px",
    height: " 20px",
    fontFamily: "Google Sans",
    fontSize: "16px",
    fontWeight: "normal",
    fontStretch: " normal",
    fontStyle: " normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },

  callLog: {
    margin: "25px 0px 0px 100px",
    width: "80px",
    height: "25px",
    borderRadius: "100px",
    border: "solid 1.5px #60bca7",
    backgroundColor: "#303436",
    cursor: "pointer"
  },
  callLogText: {
    margin: "8px",
    width: "61px",
    height: "16px",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#60bca7"
  },
  shrinkON: {
    display: "flex",
    width: "100%"
  },
  shrinkOFF: {
    flex: "1",
    display: "flex",
    flexDirection: "column"
  },
  parentmapComp: {
    position: "relative"
  },
  mapComp: {
    display: "flex",
    zIndex: "99",
    alignSelf: "flex-end",
    margin: "16px 6px -113px",
    justifyContent: "space-between"
  },
  mapCompChange: {
    display: "flex",
    zIndex: "99",
    alignSelf: "flex-end",
    margin: "16px 6px 0px",
    justifyContent: "space-between",
    position: "absolute",
    top: "10px",
    right: "10px"
  },
  mapCompShrink: {
    display: "flex",
    zIndex: "99",
    margin: "67px 6px -105px",
    justifyContent: "space-between"
  },
  mapSwitch: {
    display: "flex"
  },
  mapSwitchShrink: {
    display: "flex",
    margin: " -61px 0px 61px 0px"
  },
  marginTopMap: {
    marginTop: "25px",
    width: "100%",
    margin: " 19px 0px 0px 0"
  },
  marginTopMapShrink: {
    marginTop: "25px",
    width: "calc((100% - 20px)/2)",
    margin: " 19px 0px 0px 0",
    paddingRight: "0px"
  },
  chartnew: {
    width: "100%",
    marginLeft: "0"
  },
  toggleChartMenu: {
    color: "#161a1e",
    width: "97px",
    height: "30px",
    borderRadius: "7px",
    cursor: "pointer",
    margin: "5px 3px 3px 7px",
    padding: "3px",
    display: "flex",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontWeight: " bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: " normal",
    letterSpacing: "normal",
    textAlign: "center"
  },
  toggleChartMenuSelected: {
    color: "#ffffff",
    width: "97px",
    height: "30px",
    borderRadius: "7px",
    backgroundColor: " #ff2d21",
    cursor: "pointer",
    margin: "5px 3px 3px 7px",
    padding: "3px",
    display: "flex",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontWeight: " bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: " normal",
    letterSpacing: "normal",
    textAlign: "center"
  },
  marginTopClassTime: {
    display: " flex",
    width: "100%",
    margin: " 19px 0 0 0"
  },
  errorMessage: {
    color: "#ff2d21",
    fontSize: "12px",
    textAlign: "center",
    width: "100%",
    paddingTop: "10px"
  },
  userDetailShrink: {
    width: "calc(100% / 3)"
  },
  rootLoader: {
    display: "flex",
    alignItems: "stretch",
    height: "calc(100vh - 110px)"
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%"
  }
}));

export const MinMaxIcon = styled.img`
        padding: 9px 0px 1px 8px;
        // width: 100%;
        // height: auto
      }`;

export const SwitchToGeoSpatialButton = styled.div`
  display: flex
  height: 38px;
  border-radius: 8px;
  background-color: #ffffff;
  alignSelf: flex-start;
  cursor: pointer;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff2d21;
  padding: 9px 10px 3px 13px;
  margin-right: 20px;
  border: solid 1px rgba(0, 0, 0, 0.09);
  box-shadow: 0 2px 5px 0 rgba(0,0,0,0.04);
`;
export const Graphpicker = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 1400px) {
    > div {
      margin-top: 10px;
    }
  }
  @supports (-ms-ime-align: auto) {
    input {
      margin: 13px 10px 10px 10px !important;
    }
  }
`;
// export const MapPickerWrapper = styled.div`
//   input {
//     margin: 11px 10px 10px 10px !important;
//   }
// `;
