import React, { useState, useEffect } from "react";
import Map from "../Map/MapComponent";
import { DEFAULT_CENTER } from "../../config";

function TrailMap(props) {
  const [isTrailMap, setisTrailMap] = useState(true);
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [zoom, setZoom] = useState(3);
  const [dataset, setDataSet] = useState(props.dataset);
  const [isheatmap, setIsHeatmap] = useState(false);
  const handleHeatMap = bool => {
    setIsHeatmap(bool);
  };
  useEffect(() => {
    if (props.dataset.userdata) {
      setDataSet(props.dataset.userdata);
    }
  }, [props.dataset.userdata]);
  return (
    <div>
      <Map
        isTrailMap={isTrailMap}
        shrink={props.shrink}
        handleHeatMap={handleHeatMap}
        locationTrails={props.locationTrails}
        zoom={zoom}
        center={center}
        dataTrailSet={dataset}>
        <div></div>
      </Map>
    </div>
  );
}

export default TrailMap;
