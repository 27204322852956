import React from "react";
import PropTypes from "prop-types";
import { Typography, InputBase, Fade } from "@material-ui/core";
const TextField = ({
  error,
  classes,
  Value,
  setValue,
  placeHolder,
  type,
  id,
  variant,
  icon,
  onKeyDown,
  onKeyUp,
  InputProps,
  ...props
}) => {
  return (
    <React.Fragment>
      {error ? (
        <Fade in={error}>
          <Typography color="secondary" className={classes.errorMessage}>
            Something is wrong with your login or password :(
          </Typography>
        </Fade>
      ) : (
        ""
      )}
      <InputBase
        {...props}
        id={id}
        variant={variant}
        className={classes.textBox}
        startAdornment={
          <img
            style={{
              width: "33px",
              margin: " 0px 0px 0px 5px",
              padding: "1px 4px 3px 3px"
            }}
            src={icon}
          />
        }
        value={Value}
        onChange={e => setValue(e.target.value)}
        margin="normal"
        placeholder={placeHolder}
        type={type}
        // onKeyDown={onKeyDown}
        onKeyUp={onKeyDown}
        inputProps={{ "aria-label": "search google maps", ...InputProps }}
      />
    </React.Fragment>
  );
};

TextField.defaultProps = {
  classes: {}
};

TextField.propTypes = {
  classes: propTypes.object
};

export default TextField;
