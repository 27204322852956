import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function AlertDialog({
                 open,
                 handleClose,
                 handleRemoveUserFromWatchlist,
                 textLable
               }) {
                 return (
                   <div>
                     <Dialog
                       open={open}
                       onClose={handleClose}
                       aria-labelledby="alert-dialog-title"
                       aria-describedby="alert-dialog-description">
                       <DialogTitle id="alert-dialog-title">
                         {textLable}
                       </DialogTitle>
                       <DialogActions>
                         <Button onClick={handleClose} color="primary">
                           Disagree
                         </Button>
                         <Button
                           onClick={handleRemoveUserFromWatchlist}
                           color="primary"
                           autoFocus>
                           Agree
                         </Button>
                       </DialogActions>
                     </Dialog>
                   </div>
                 );
               }
