import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import LoadingIcon from "@material-ui/icons/Cached";
import PhoneInput from "react-phone-number-input";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  OrganizationFormControl,
  InputWrap,
  Label
} from "../../pages/Organizations/organization.styles";
const useStyles = makeStyles(theme => ({
  root: {
    width: "88%",
    minWidth: "333px",
    fontSize: "14px",
    height: "38px",
    backgroundColor: " #ffffff",
    position: "relative"
  },
  input: {
    fontSize: "11px !important"
  },
  datePickerMargin: {
    margin: "12px 4px 7px 10px",
    padding: " 0px",
    fontSize: "12px !important"
  },
  adornment: {
    position: "absolute",
    left: "calc(100% - 15px)"
  },
  inputClass: {
    color: "#555353",
    width: "137px"
  },
  phlabel: {
    paddingLeft: "50px"
  }
}));
export const FieldPhoneNumber = ({
  label,
  name,
  value,
  onChange,
  error,
  setErrors,
  pristine,
  validating,
  validate,
  formSubmitted,
  disabled,
  ...other
}) => {
  let showErrors = !pristine || formSubmitted;
  const classes = useStyles();
  return (
    <OrganizationFormControl>
      <FormControl className="field" error={error && !error.phone}>
        <Label className="phlabel" error={error && !error.phone} htmlFor={name}>
          {label}
        </Label>
        <div className={classes.root} style={{}}>
          <PhoneInput
            disabled={disabled}
            inputClasses={classes.inputClass}
            // country='US'
            className="phonenumber"
            showErrors={showErrors}
            id={name}
            name
            value={value}
            onChange={number => onChange(number)}
            // onchange={() => !pristine && validate()}
            {...other}
          />
        </div>

        <FormHelperText component="div">
          {error && !error.phone && error.message && <div>{error.message}</div>}
        </FormHelperText>
      </FormControl>
    </OrganizationFormControl>
  );
};
