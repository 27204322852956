import { useEffect, useState, useRef } from "react";
import GoogleMapsApiLoader from "google-maps-api-loader";
import { getPoints } from "../../../data/dataForHeatmap";
import MarkerIcon from "../../../assets/img/marker.png";
import startMarker from "../../../assets/img/start.png";
import endMarker from "../../../assets/img/end.png";

import isEmpty from "lodash/isEmpty";
import useGoogleMapMarker from "./useGoogleMapMarker";
import {
  CustomMapControlsTypeView,
  RefreshControl,
  BasePanel
} from "./CustomMapControls";
const libraries = ["places", "drawing", "visualization"];
const eventsMapping = {
  onCenterChanged: ["center_changed", map => map.getCenter()],
  onBoundsChangerd: ["bounds_changed", map => map.getBounds()]
};
let isDragged = false;
let drawingManager;
let selectedShape;
let selectedColor;
let colorButtons = {};
export default function useGoogleMap({
  handleGeoSpatialSearch,
  setInitialRender,
  setState,
  isDevice,
  setDevices,
  isFilterDisable,
  zoom,
  center,
  events,
  styles,
  open,
  onOpen,
  markers,
  handleHeatMap,
  status,
  refreshMap,
  isLoading,
  dataSet,
  isDrawerManager,
  geospatialApi,
  setUser,
  setOrganization,
  setDateChangeTo,
  setDateChangeFrom,
  trails,
  setFilterDisable,
  handleBoundClear,
  setValue,
  trailDataClear,
  ResetPaylod,
  setPayload,
  isTrailMap,
  locationTrails,
  dataTrailSet
}) {
  let iconPos = MarkerIcon;
  let zIndex = 1;
  const [mapState, setMapState] = useState({ loading: true });
  const [Google, setGoogle] = useState({});
  const [Bounds, setBounds] = useState({});
  const [GMap, setMapRef] = useState(null);
  const [drawingManagerd, setdrawingManager] = useState({});
  const [dataTrailSetState, setDataTrailSet] = useState({});
  const [GeodesicPolygon, setGeodesic] = useState({});
  const mapRef = useRef();
  useEffect(() => {
    GoogleMapsApiLoader({
      apiKey: process.env.GOOGLE_MAPS_API_KEY,
      libraries
    }).then(google => {
      setGoogle(google);
      const bounds = new google.maps.LatLngBounds();
      setBounds(bounds);
      const map = new google.maps.Map(mapRef.current, {
        center: center ? center : { lat: 0, lng: 0 },
        zoom,
        minZoom: 1,
        maxZoom: 20,
        zoomControl: true,
        styles: [
          {
            elementType: "geometry",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            elementType: "labels.icon",
            stylers: [
              {
                visibility: "off"
              }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              {
                color: "#f5f5f5"
              }
            ]
          },
          {
            featureType: "administrative.land_parcel",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#bdbdbd"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "poi",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "poi.park",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry",
            stylers: [
              {
                color: "#ffffff"
              }
            ]
          },
          {
            featureType: "road.arterial",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#757575"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry",
            stylers: [
              {
                color: "#dadada"
              }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#616161"
              }
            ]
          },
          {
            featureType: "road.local",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          },
          {
            featureType: "transit.line",
            elementType: "geometry",
            stylers: [
              {
                color: "#e5e5e5"
              }
            ]
          },
          {
            featureType: "transit.station",
            elementType: "geometry",
            stylers: [
              {
                color: "#eeeeee"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              {
                color: "#c9c9c9"
              }
            ]
          },
          {
            featureType: "water",
            elementType: "labels.text.fill",
            stylers: [
              {
                color: "#9e9e9e"
              }
            ]
          }
        ],
        disableDefaultUI: true
      });
      setMapRef(map);
      Object.keys(events).forEach(eventName =>
        map.addListener(eventsMapping[eventName][0], () =>
          events[eventName](eventsMapping[eventName][1](map))
        )
      );
      setMapState({ maps: google.maps, map, loading: false });

      if (!isDrawerManager) {
        customMapControls(
          map,
          onOpen,
          markers,
          handleHeatMap,
          google,
          status,
          refreshMap,
          isLoading,
          dataSet,
          isDrawerManager
        );
      } else {
        if (isDrawerManager) {
          var polyOptions = {
            editable: true,
            draggable: true,
            fillColor: "rgba(255, 149, 0, 0.12)",
            fillOpacity: 0.3,
            strokeColor: "#ff9500",
            strokeWeight: 2,
            clickable: true,
            zIndex: 1
          };
          drawingManager = new google.maps.drawing.DrawingManager({
            drawingControlOptions: {
              drawingModes: [
                google.maps.drawing.OverlayType.POLYGON,
                google.maps.drawing.OverlayType.CIRCLE
              ]
            },
            markerOptions: {
              draggable: true
            },
            polylineOptions: {
              editable: true
            },
            rectangleOptions: polyOptions,
            circleOptions: polyOptions,
            polygonOptions: polyOptions,
            map: map
          });
          setdrawingManager(drawingManager);
          var customoptions = document.createElement("div");
          var controlUI = document.createElement("div");
          controlUI.style.backgroundColor = "rgb(96, 188, 167)";
          controlUI.style.borderStyle = "solid";
          controlUI.style.borderRadius = "10px";
          controlUI.style.boxShadow =
            "rgba(0, 0, 0, 0.05) 0px 2px 4px 0px, rgba(0, 0, 0, 0.2) 0px 0px 10px 0px";
          controlUI.style.borderWidth = "1px";
          controlUI.style.borderColor = "#ccc";
          controlUI.style.marginTop = "15px";
          controlUI.style.marginRight = "15px";
          controlUI.style.cursor = "pointer";
          controlUI.style.textAlign = "center";
          controlUI.title = "Click to set the map to Home";
          customoptions.appendChild(controlUI);
          // Set CSS for the control interior
          var controlText = document.createElement("div");
          controlText.style.fontFamily = "Google Sans";
          controlText.style.fontSize = "15px";
          controlText.style.paddingLeft = "4px";
          controlText.style.paddingRight = "4px";
          controlText.style.paddingTop = "7px";
          controlText.style.paddingBottom = "7px";
          controlText.style.color = "#ffffff";
          controlText.style.width = "95px";
          controlText.innerHTML = "Reset";
          controlUI.appendChild(controlText);
          // Setup the click event listeners
          google.maps.event.addDomListener(customoptions, "click", function() {
            trailDataClear();
            if (window.paths !== undefined && window.paths.length > 0) {
              window.paths.forEach(path => {
                path.setMap(null);
              });
            }
            if (window.markers !== undefined && window.markers.length > 0) {
              window.markers.forEach(marker => {
                marker && marker.setMap(null);
              });
            }

            window.markers = [];
            deleteSelectedShape(selectedShape, drawingManager);
            setInitialRender(true);
            handleGeoSpatialSearch();
            setUser(["0"]);
            setOrganization(["0"]);
            isDevice.current = false;
            ResetPaylod();
            setDateChangeTo(new Date());
            setDateChangeFrom(new Date());
            setFilterDisable(true);
            handleBoundClear();
            setDevices("0");
            setState({ device: false });
            setValue("");
            if (window.markers !== undefined && window.markers.length > 0) {
              window.markers.forEach(marker => {
                marker && marker.setMap(null);
              });
            }
          });
          let drawshape = document.get;
          customoptions.index = 1;
          map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
            customoptions
          );
          // Creates a drawing manager attached to the map that allows the user to draw
          // markers, lines, and shapes.
          google.maps.event.addListener(
            drawingManager,
            "overlaycomplete",
            function(e) {
              setFilterDisable(false);
              if (e.type === google.maps.drawing.OverlayType.POLYGON) {
                // Add an event listener that selects the newly-drawn shape when the user
                drawingManager.setDrawingMode(null);
                // To hide:
                drawingManager.setOptions({
                  drawingControl: false
                });
                let newShape = e.overlay;
                let coordinateArray = [];
                let coordinates = newShape.getPath().getArray();
                for (let i = 0; i < coordinates.length; i++) {
                  let latitude = coordinates[i].lat();
                  let longitude = coordinates[i].lng();
                  let obj = {
                    latitude,
                    longitude
                  };
                  coordinateArray.push(obj);
                }
                coordinateArray.push(coordinateArray[0]);
                geospatialApi(coordinateArray);
                google.maps.event.addListener(newShape, "dragend", function(e) {
                  let coordinateArray = [];
                  let coordinates = newShape.getPath().getArray();
                  for (let i = 0; i < coordinates.length; i++) {
                    let latitude = coordinates[i].lat();
                    let longitude = coordinates[i].lng();
                    let obj = {
                      latitude,
                      longitude
                    };
                    coordinateArray.push(obj);
                  }
                  coordinateArray.push(coordinateArray[0]);
                  geospatialApi(coordinateArray);
                  isDragged = false;
                });
                google.maps.event.addListener(newShape, "dragstart", function(
                  e
                ) {
                  isDragged = true;
                  window.markers.forEach(marker => {
                    marker && marker.setMap(null);
                  });
                  window.markers = [];
                  if (window.paths !== undefined && window.paths.length > 0) {
                    window.paths.forEach(path => {
                      path.setMap(null);
                    });
                  }
                  handleBoundClear();
                  setValue("");
                });
                let shapePath = newShape.getPath();
                google.maps.event.addListener(shapePath, "insert_at", function(
                  e
                ) {
                  if (!isDragged) {
                    if (window.paths !== undefined && window.paths.length > 0) {
                      window.paths.forEach(path => {
                        path.setMap(null);
                      });
                    }
                    let coordinateArray = [];
                    let coordinates = newShape.getPath().getArray();
                    for (let i = 0; i < coordinates.length; i++) {
                      let latitude = coordinates[i].lat();
                      let longitude = coordinates[i].lng();
                      let obj = {
                        latitude,
                        longitude
                      };
                      coordinateArray.push(obj);
                    }
                    coordinateArray.push(coordinateArray[0]);
                    geospatialApi(coordinateArray);
                  }
                });
                google.maps.event.addListener(shapePath, "set_at", function(e) {
                  if (!isDragged) {
                    if (window.paths !== undefined && window.paths.length > 0) {
                      window.paths.forEach(path => {
                        path.setMap(null);
                      });
                    }
                    let coordinateArray = [];
                    let coordinates = newShape.getPath().getArray();
                    for (let i = 0; i < coordinates.length; i++) {
                      let latitude = coordinates[i].lat();
                      let longitude = coordinates[i].lng();
                      let obj = {
                        latitude,
                        longitude
                      };
                      coordinateArray.push(obj);
                    }
                    coordinateArray.push(coordinateArray[0]);
                    geospatialApi(coordinateArray);
                  }
                });
                newShape.type = e.type;
                google.maps.event.addListener(newShape, "click", function() {
                  setSelection(newShape);
                });
                setSelection(newShape);
              }
              if (e.type === google.maps.drawing.OverlayType.CIRCLE) {
                drawingManager.setDrawingMode(null);
                drawingManager.setOptions({
                  drawingControl: false
                });
                let circleObj = e.overlay;
                let center = {
                  latitude: circleObj.center.lat(),
                  longitude: circleObj.center.lng()
                };
                geospatialApi([], 4, center, circleObj.radius / 1000);
                setSelection(circleObj);
                google.maps.event.addListener(circleObj, "dragstart", function(
                  e
                ) {
                  isDragged = true;
                  trailDataClear();
                  window.markers.forEach(marker => {
                    marker && marker.setMap(null);
                  });
                  window.markers = [];
                  if (window.paths !== undefined && window.paths.length > 0) {
                    window.paths.forEach(path => {
                      path.setMap(null);
                    });
                  }
                  handleBoundClear();
                  setValue("");
                });
                google.maps.event.addListener(circleObj, "dragend", function(
                  e
                ) {
                  isDragged = false;
                  let center = {
                    latitude: circleObj.center.lat(),
                    longitude: circleObj.center.lng()
                  };

                  geospatialApi([], 4, center, circleObj.radius / 1000);
                });
                google.maps.event.addListener(
                  circleObj,
                  "center_changed",
                  function(e) {
                    if (!isDragged) {
                      if (
                        window.paths !== undefined &&
                        window.paths.length > 0
                      ) {
                        window.paths.forEach(path => {
                          path.setMap(null);
                        });
                      }
                      let center = {
                        latitude: circleObj.center.lat(),
                        longitude: circleObj.center.lng()
                      };
                      geospatialApi([], 4, center, circleObj.radius / 1000);
                    }
                  }
                );
                google.maps.event.addListener(
                  circleObj,
                  "radius_changed",
                  function(e) {
                    if (window.paths !== undefined && window.paths.length > 0) {
                      window.paths.forEach(path => {
                        path.setMap(null);
                      });
                    }
                    let center = {
                      latitude: circleObj.center.lat(),
                      longitude: circleObj.center.lng()
                    };
                    geospatialApi([], 4, center, circleObj.radius / 1000);
                  }
                );
              }
            }
          );
        }
      }
    });
  }, []);

  useEffect(() => {
    if (Google.maps && GMap /* && GMap.mapTypeId */ && isTrailMap) {
      trailControls(
        GMap,
        getPoints,
        markers,
        handleHeatMap,
        mapState,
        dataTrailSet
      );
    }
  }, [isTrailMap, Google, GMap]);

  useEffect(() => {
    let markerCollection = [];
    if (
      Google.maps &&
      GMap &&
      // GMap.mapTypeId &&
      dataTrailSet !== undefined &&
      dataTrailSet.length > 0
    ) {
      if (window.markers !== undefined && window.markers.length > 0) {
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
        window.markers = [];
      }
      setDataTrailSet(dataTrailSet);
      dataTrailSet.forEach(element => {
        const position = {
          lat: element.location.latitude,
          lng: element.location.longitude
        };
        const title = element.username;
        const marker = useGoogleMapMarker(
          position,
          "type",
          Google.maps,
          GMap,
          "events",
          title,
          false,
          iconPos
        );
        markerCollection.push(marker);
      });
      window.markers = markerCollection;
      markerCollection.forEach(marker => {
        if (marker !== undefined) {
          Bounds.extend(marker.getPosition());
        }
      });
      GMap.fitBounds(Bounds);
    }
  }, [dataTrailSet, Google, GMap]);

  useEffect(() => {
    if (Google && GMap && !isEmpty(drawingManagerd) && isFilterDisable) {
      if (isFilterDisable && !isEmpty(drawingManagerd)) {
        trailDataClear();
        if (window.paths !== undefined && window.paths.length > 0) {
          window.paths.forEach(path => {
            path.setMap(null);
          });
        }
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
        window.markers = [];
        deleteSelectedShape(selectedShape, drawingManagerd);
        ResetPaylod();
        setDateChangeTo(new Date());
        setDateChangeFrom(new Date());
        setFilterDisable(true);
        handleBoundClear();
        setValue("");
        if (window.paths !== undefined && window.paths.length > 0) {
          window.paths.forEach(path => {
            path.setMap(null);
          });
        }
      }
    }
  }, [isFilterDisable, Google, GMap, drawingManagerd]);

  useEffect(() => {
    if (window.paths !== undefined && window.paths.length > 0) {
      window.paths.forEach(path => {
        path.setMap(null);
      });
    }
    var Colors = [
      "#FF0000",
      "#0000FF",
      "#f50093",
      "#000000",
      "#FFFF00",
      "#00FFFF",
      "#FF00FF",
      "#f56e00"
    ];
    let trailMarkers = [],
      geoDesicPolyArray = [],
      trailData = [];
    if (trails !== undefined && trails.length > 0) {
      trailData = trails.map((trail, index) => {
        let paths = [];
        if (trail.location_trail.length > 0) {
          trailMarkers = trail.location_trail.map((m, index) => {
            const position = {
              lat: m.location.latitude,
              lng: m.location.longitude
            };
            paths.push(position);
          });
          let geodesicPoly = new google.maps.Polyline({
            path: paths,
            strokeColor: Colors[index],
            strokeOpacity: 1.0,
            strokeWeight: 3,
            geodesic: true,
            map: GMap
          });
          window.paths.push(geodesicPoly);
          return trailMarkers;
        }
      });
    }
  }, [trails]);

  useEffect(() => {
    let markerCollection = [];
    let trailmarkers = {},
      paths = [],
      subPaths = [];
    if (
      Google.maps &&
      GMap &&
      // GMap.mapTypeId &&
      locationTrails &&
      locationTrails.main_trail !== undefined &&
      locationTrails.main_trail.length > 0
    ) {
      if (window.paths !== undefined && window.paths.length > 0) {
        window.paths.forEach(path => {
          path.setMap(null);
        });
      }
      if (window.markers !== undefined && window.markers.length > 0) {
        window.markers.forEach(marker => {
          marker && marker.setMap(null);
        });
      }
      trailmarkers = locationTrails.main_trail.map((m, index) => {
        const position = {
          lat: m.latitude,
          lng: m.longitude
        };
        const title = `${m.timestamp} at (${m.latitude},${m.longitude})`;
        paths.push(position);
        if (m.has_pin) {
          if (index === 0) {
            zIndex = 1000;
            iconPos = startMarker;
          } else {
            zIndex = 1;
            iconPos = MarkerIcon;
          }
          if (index === locationTrails.main_trail.length - 1) {
            zIndex = 1000;
            iconPos = endMarker;
          }
          const marker = useGoogleMapMarker(
            position,
            "type",
            Google.maps,
            GMap,
            "events",
            title,
            false,
            iconPos,
            zIndex
          );
          markerCollection.push(marker);
        }
      });
      if (
        locationTrails.sub_trail !== undefined &&
        locationTrails.sub_trail.length > 0
      ) {
        let subtrailmarkers = locationTrails.sub_trail.map((m, index) => {
          const title = `${m.timestamp} at (${m.latitude},${m.longitude})`;
          const position = {
            lat: m.latitude,
            lng: m.longitude
          };
          subPaths.push(position);

          if (m.has_pin) {
            if (index === 0) {
              zIndex = 1000;
              iconPos = startMarker;
            } else {
              zIndex = 1;
              iconPos = MarkerIcon;
            }
            if (index === locationTrails.sub_trail.length - 1) {
              zIndex = 1000;
              iconPos = endMarker;
            }
            const marker = useGoogleMapMarker(
              position,
              "type",
              Google.maps,
              GMap,
              "events",
              title,
              false,
              iconPos,
              zIndex
            );
            markerCollection.push(marker);
          }

          return markerCollection;
        });
      }
      let geodesicPoly = new google.maps.Polyline({
        path: paths,
        strokeColor: "#ff9500",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        geodesic: true,
        map: GMap
      });
      let geodesicPoly2 = new google.maps.Polyline({
        path: subPaths,
        strokeColor: "#5856d6",
        strokeOpacity: 1.0,
        strokeWeight: 3,
        geodesic: true,
        map: GMap
      });
      window.paths.push(geodesicPoly);

      window.paths.push(geodesicPoly2);
      window.markers = markerCollection;
      markerCollection.forEach(marker => {
        if (marker !== undefined) {
          Bounds.extend(marker.getPosition());
        }
      });
      GMap.fitBounds(Bounds);
    }
  }, [locationTrails, Google, GMap]);

  return { mapRef, ...mapState, drawingManager };
}

// DRAWING MANAGER EVENTS AND CONTROLS

function clearSelection() {
  if (selectedShape) {
    selectedShape.setEditable(false);
    selectedShape = null;
  }
}

function selectColor(color) {
  selectedColor = color;
  for (var i = 0; i < colors.length; ++i) {
    var currColor = colors[i];
    colorButtons[currColor].style.border =
      currColor == color ? "2px solid #789" : "2px solid #fff";
  }

  // Retrieves the current options from the drawing manager and replaces the
  // stroke or fill color as appropriate.
  var polylineOptions = drawingManager.get("polylineOptions");
  polylineOptions.strokeColor = color;
  drawingManager.set("polylineOptions", polylineOptions);

  var rectangleOptions = drawingManager.get("rectangleOptions");
  rectangleOptions.fillColor = color;
  drawingManager.set("rectangleOptions", rectangleOptions);

  var circleOptions = drawingManager.get("circleOptions");
  circleOptions.fillColor = color;
  drawingManager.set("circleOptions", circleOptions);

  var polygonOptions = drawingManager.get("polygonOptions");
  polygonOptions.fillColor = color;
  drawingManager.set("polygonOptions", polygonOptions);
}

function setSelection(shape) {
  clearSelection();
  selectedShape = shape;
  shape.setEditable(true);
  //  selectColor(shape.get("fillColor") || shape.get("strokeColor"));
}

function deleteSelectedShape(selectedShape, drawingManager) {
  if (selectedShape) {
    selectedShape.setMap(null);
    // To show:
    drawingManager.setOptions({
      drawingControl: true
    });
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    window.markers = [];
  }
}

function setSelectedShapeColor(color, google) {
  if (selectedShape) {
    if (selectedShape.type == google.maps.drawing.OverlayType.POLYLINE) {
      selectedShape.set("strokeColor", color);
    } else {
      selectedShape.set("fillColor", color);
    }
  }
}

function makeColorButton(color) {
  var button = document.createElement("span");
  button.className = "color-button";
  button.style.backgroundColor = color;
  google.maps.event.addDomListener(button, "click", function() {
    selectColor(color);
    setSelectedShapeColor(color, google);
  });
  return button;
}
function buildColorPalette() {
  var colorPalette = document.getElementById("color-palette");
  for (var i = 0; i < colors.length; ++i) {
    var currColor = colors[i];
    var colorButton = makeColorButton(currColor);
    colorPalette.appendChild(colorButton);
    colorButtons[currColor] = colorButton;
  }
  selectColor(colors[0]);
}
function clearButton() {
  // Clear the current selection when the drawing mode is changed, or when the
  // map is clicked.
  google.maps.event.addListener(
    drawingManager,
    "drawingmode_changed",
    clearSelection
  );
  google.maps.event.addListener(map, "click", clearSelection);
  google.maps.event.addDomListener(
    document.getElementById("delete-button"),
    "click",
    deleteSelectedShape
  );
  //  buildColorPalette();
}

function trailControls(
  map,
  getPoints,
  markers,
  handleHeatMap,
  mapState,
  dataSet
) {
  // Map Type Filter Custom
  let customTopLeftMapControlType = document.createElement("div");
  let TopLeftMapControlType = new CustomMapControlsTypeView(
    customTopLeftMapControlType,
    map,
    getPoints,
    markers,
    handleHeatMap,
    mapState,
    dataSet
  );
  customTopLeftMapControlType.index = 1;
  map.controls[google.maps.ControlPosition.TOP_LEFT].push(
    customTopLeftMapControlType
  );
}

function customMapControls(
  map,
  onOpen,
  markers,
  handleHeatMap,
  mapState,
  status,
  refreshMap,
  isLoading,
  dataSet
) {
  // Map Type Filter Custom
  let customTopLeftMapControlType = document.createElement("div");
  let TopLeftMapControlType = new CustomMapControlsTypeView(
    customTopLeftMapControlType,
    map,
    getPoints,
    markers,
    handleHeatMap,
    mapState,
    dataSet
  );
  customTopLeftMapControlType.index = 1;
  map.controls[google.maps.ControlPosition.TOP_LEFT].push(
    customTopLeftMapControlType
  );
  // Map Custom Referesh
  let customTopRightRefresh = document.createElement("div");
  let TopRightRefresh = new RefreshControl(
    customTopRightRefresh,
    map,
    refreshMap,
    isLoading
  );
  customTopRightRefresh.index = 2;
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(
    customTopRightRefresh
  );
  // let basePanelBottomLeft = document.createElement("div");
  // let BasePanelBottomRight = new BasePanel(
  //   basePanelBottomLeft,
  //   map,
  //   onOpen,
  //   status
  // );
  // basePanelBottomLeft.index = 3;
  // map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(
  //   basePanelBottomLeft
  // );
}
