import React, { useEffect, useState, useRef } from "react";
import { Grid, MenuList, MenuItem } from "@material-ui/core";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { useSnackbar } from "notistack";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { connect } from "react-redux";
import {
  useStyles,
  HistoryWrapper,
  HistoryTopWrapper,
  HistoryTableTabWrapper,
  CreateNotificationWrapper,
  CreateNotificationTopWrapperFilter,
  SafteyAlertText,
  SafteyAlert
} from "./massnotification.details.style";
import { PageHeading } from "../../styles/Body.style";
import AddToWatch from "../../components/AddToWatchList/Generic";
import SendTextMessage from "../../components/WatchList/SendTextMessage";
import PushNotification from "../../components/WatchList/PushNotification";
import WatchListMap from "../../components/Map/WatchListMaps/WatchListMap";
import store from "../../store";
import Select from "../../components/GeoSpatialFilterComponent/components/Select";
import BackToPrevious from "../../components/BackToPrevious";

function CreateNotifications(props) {
  const { enqueueSnackbar } = useSnackbar();
  const pay = useRef({});
  const classes = useStyles();
  const anchorRef = React.useRef(null);
  let { organization, userList } = props.dashboardState;
  let { polygonWatchList } = props.watchlistState;
  let { boundData } = props.GeospatialState;
  const [isDrawerManager, setDrawerManager] = useState(true);
  const [isDisable, setFilterDisable] = useState(true);
  const [userMarkers, setMarkers] = React.useState([]);
  const [coordinates, setCoordinates] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [organizations, setOrganization] = React.useState(["0"]);
  const [user, setUser] = React.useState(["0"]);
  const [location, setLocation] = React.useState(0);
  const [Reasonpayload, setReasonPayload] = React.useState({});
  const [polygonWatchListArray, setLocationWatchList] = React.useState(0);
  const [payload, setPayload] = useState({});
  const [openMassText, setOpenMassText] = React.useState(false);
  const [openMassPush, setOpenMassPush] = React.useState(false);
  const [watchlist, setLocationWatch] = React.useState({});
  const [massTextPayload, setMassTextPayload] = React.useState({});
  const prevOpen = React.useRef(open);
  const handleCloseMassText = () => {
    setOpenMassText(false);
  };
  const handleCloseMassPush = () => {
    setOpenMassPush(false);
  };
  const handleClickOpenMassText = (data, popupState) => {
    setMassTextPayload({
      data,
      popupState: setOpenMassText,
      Type: "text"
    });
    setOpenMassText(true);
  };
  const handleClickOpenMassPush = (data, popupState) => {
    setMassTextPayload({
      data,
      popupState: setOpenMassPush(),
      Type: "push"
    });
    setOpenMassPush(true);
  };
  const handleHistory = () => {
    store.dispatch.Dashboard.getOrganizationlist();
    store.dispatch.Dashboard.getUserlist();
    store.dispatch.Watchlist.getPolygonWatchList();
  };
  useEffect(() => {
    handleHistory();
  }, []);
  const ResetPaylod = () => {
    setPayload({});
    pay.current = {};
  };
  useEffect(() => {
    if (polygonWatchList && polygonWatchList.length > 0) {
      setLocationWatchList(polygonWatchList);
    }
  }, [polygonWatchList]);
  useEffect(() => {
    if (polygonWatchList && polygonWatchList.length > 0) {
      let watch = {};
      let temp = { ...pay.current };
      polygonWatchList.forEach(element => {
        if (location === element.id) {
          watch = element;
          setLocationWatch(element);
        }
      });

      if (
        watch !== undefined &&
        watch.coordinates &&
        watch.coordinates.length > 0 &&
        watch.shape === "custom"
      ) {
        let pathsArr = [];

        watch.coordinates.forEach(element => {
          let coord = {
            latitude: element.lat,
            longitude: element.lon
          };
          pathsArr.push(coord);
        });
        if (pathsArr !== undefined && pathsArr.length > 0) {
          temp = {
            ...pay.current,
            polygon: pathsArr,
            zoom_level: 4
          };
          let payloadData = {
            polygon: pathsArr,
            zoom_level: 4
          };

          let addPayload = {
            shape: "custom",
            coordinates: pathsArr,
            watchlist_location_id: watch.id
          };
          setFilterDisable(false);

          setCoordinates(addPayload);
        }
      }
      if (
        watch !== undefined &&
        watch.coordinates &&
        watch.coordinates.length > 0 &&
        watch.radius !== undefined &&
        watch.shape === "circle"
      ) {
        let pathsArr = [];

        watch.coordinates.forEach(element => {
          let coord = {
            latitude: element.lat,
            longitude: element.lon
          };
          pathsArr.push(coord);
        });
        temp = {
          ...temp,
          center: pathsArr[0],
          radius: watch.radius
        };
        let payloadData = {
          center: watch.center,
          radius: watch.radius
        };
        let addPayload = {
          shape: "circle",
          coordinates: pathsArr[0],
          radius: watch.radius,
          watchlist_location_id: watch.id
        };
        setCoordinates(addPayload);
      }
      setPayload(temp);

      store.dispatch.Geospatial.startGeoSpatial(temp);
    }
  }, [location]);

  const handleNotification = () => {
    let payloadData = {};
    let arr = [],
      newarr = [];
    if (Reasonpayload) {
      if (coordinates.coordinates.length > 1) {
        let coord = coordinates.coordinates.forEach(item => {
          let obj = {
            lat: item.latitude,
            lon: item.longitude
          };
          newarr.push(obj);
        });
        if (coordinates.watchlist_location_id) {
          payloadData = {
            notification_type: massTextPayload.Type,
            name: Reasonpayload,
            watchlist_location_id: coordinates.watchlist_location_id
          };
        } else {
          payloadData = {
            shape: "custom",
            notification_type: massTextPayload.Type,
            coordinates: newarr,
            name: Reasonpayload
          };
        }
      } else {
        arr.push(coordinates.coordinates);
        let coord = arr.forEach(item => {
          let obj = {
            lat: item.latitude,
            lon: item.longitude
          };
          newarr.push(obj);
        });
        if (coordinates.watchlist_location_id) {
          payloadData = {
            notification_type: massTextPayload.Type,
            name: Reasonpayload,
            watchlist_location_id: coordinates.watchlist_location_id
          };
        } else {
          payloadData = {
            shape: "circle",
            notification_type: massTextPayload.Type,
            coordinates: newarr,
            name: Reasonpayload,
            radius: coordinates.radius
          };
        }
      }
      store.dispatch.Watchlist.notifications({
        payload,
        enqueueSnackbar,
        popupState: massTextPayload.popupState,
        setReasonPayload,
        setMassTextPayload,
        setOpenMassText,
        setOpenMassPush,
        payloadData,
        isGeoSpatial: true,
        setFilterDisable,
        history: props.history
      });
    } else {
      let variant = "error";
      enqueueSnackbar("Please enter the name ", {
        variant
      });
    }
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleOrgChange = event => {
    setUser(["0"]);
    let org = [...event.target.value];
    if (org.includes("0")) {
      const index = org.indexOf("0");
      org.splice(index, 1);
    }
    store.dispatch.Dashboard.getUserlist(org);
    setOrganization(org);
    setPayload(prevState => ({
      ...prevState,
      organizations: org
    }));
    if (event.target.value.length === 0) {
      setOrganization(["0"]);
      setUser(["0"]);
    }
    pay.current = { ...payload, organizations: org };
  };
  const handleWatchlistChange = event => {
    const { value } = event.target;
    if (location === value) {
      return;
    }
    window.polygon.forEach(poly => {
      poly.setMap(null);
    });
    window.circle.forEach(cir => {
      cir.setMap(null);
    });
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    setFilterDisable(false);
    if (event.target.value === "0") {
      setDrawerManager(true);
      setFilterDisable(true);
      setLocationWatch({});
      window.polygon.forEach(poly => {
        poly.setMap(null);
      });
      window.circle.forEach(cir => {
        cir.setMap(null);
      });
    } else {
      setDrawerManager(false);
    }
    setLocation(value);
  };
  const handleWatchlistChangeClear = () => {
    setOrganization(["0"]);
    setLocation(0);
    setUser(["0"]);
    setFilterDisable(true);
  };

  const handleMassText = () => {
    store.dispatch.MassNotification.notifications({});
  };
  const handleUserChange = event => {
    let usr = [...event.target.value];
    if (usr.includes("0")) {
      const index = usr.indexOf("0");
      usr.splice(index, 1);
    }
    setPayload(prevState => ({
      ...prevState,
      users: usr
    }));
    setUser(usr);
    if (event.target.value.length === 0) {
      setUser(["0"]);
    }
    pay.current = { ...payload, users: usr };
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);
  const handleDrawingManager = (coordinates, zoom, center, radius) => {
    let temp = {
      ...pay.current,
      polygon: coordinates,
      zoom_level: 4
    };
    if (coordinates !== undefined && coordinates.length > 0) {
      let payloadData = {
        polygon: coordinates,
        zoom_level: 4
      };

      let addPayload = {
        shape: "custom",
        coordinates: coordinates
      };

      setCoordinates(addPayload);
    }
    if (center !== undefined && radius !== undefined) {
      temp = {
        ...temp,
        center: center,
        radius: radius
      };
      let payloadData = {
        center: center,
        radius: radius
      };
      let addPayload = {
        shape: "circle",
        coordinates: center,
        radius: radius
      };

      setCoordinates(addPayload);
    }
    setPayload(temp);
    store.dispatch.Geospatial.startGeoSpatial(temp);
  };
  useEffect(() => {
    if (boundData.length > 0) {
      setMarkers(boundData);
    }
  }, [boundData]);

  useEffect(() => {
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    store.dispatch.Geospatial.startGeoSpatial(payload);
  }, [payload]);
  return (
    <div className={classes.root}>
      <HistoryWrapper>
        <Grid container>
          <Grid item xs={12}>
            <BackToPrevious destination="massnotification" />
            <HistoryTopWrapper>
              <PageHeading>Create Mass Notifications</PageHeading>
            </HistoryTopWrapper>
            <CreateNotificationTopWrapperFilter>
              <CreateNotificationWrapper>
                <Select
                  isFilterDisable={isDisable}
                  classesParent={classes}
                  data={organization}
                  valueProp={organizations}
                  onChange={handleOrgChange}
                  label="Organizations"
                  multiple={true}
                />
                <Select
                  isFilterDisable={isDisable}
                  classesParent={classes}
                  data={userList}
                  valueProp={user}
                  onChange={handleUserChange}
                  label="Users"
                  multiple={true}
                />
                <Select
                  classesParent={classes}
                  data={polygonWatchListArray}
                  valueProp={location}
                  onChange={handleWatchlistChange}
                  label="Watchlist"
                  multiple={false}
                />
              </CreateNotificationWrapper>
              <div>
                <SafteyAlert
                  isFilterDisable={isDisable}
                  ref={anchorRef}
                  aria-controls={open ? "menu-list-grow" : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}>
                  <SafteyAlertText>
                    Saftey Alerts <KeyboardArrowDownIcon />
                  </SafteyAlertText>
                  <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    style={{ zIndex: "150" }}
                    disablePortal>
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === "bottom"
                              ? "center top"
                              : "center bottom"
                        }}>
                        <Paper>
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}>
                              <MenuItem
                                onClick={() => {
                                  handleClickOpenMassText();

                                  if (
                                    anchorRef.current &&
                                    anchorRef.current.contains(event.target)
                                  ) {
                                    return;
                                  }
                                  setOpen(false);
                                }}>
                                Send Text Message
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleClickOpenMassPush();
                                  if (
                                    anchorRef.current &&
                                    anchorRef.current.contains(event.target)
                                  ) {
                                    return;
                                  }
                                  setOpen(false);
                                }}>
                                Push Notification
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper>
                </SafteyAlert>
              </div>
            </CreateNotificationTopWrapperFilter>
            <HistoryTableTabWrapper>
              <WatchListMap
                minHeight="651px"
                setFilterDisable={setFilterDisable}
                isDrawerManager={isDrawerManager}
                geospatialApi={handleDrawingManager}
                userMarkers={userMarkers}
                handleWatchlistChangeClear={handleWatchlistChangeClear}
                isNotifcations={true}
                watchlist={watchlist}
                history={props.history}
              />
            </HistoryTableTabWrapper>
          </Grid>
        </Grid>

        {openMassText && (
          <AddToWatch
            open={openMassText}
            handleClose={handleCloseMassText}
            handleAddWatchlist={handleNotification}
            handleCloseMassText={handleCloseMassText}
            buttonName={"Send"}>
            <SendTextMessage
              setReasonPayload={setReasonPayload}
              title={"enter name for creating text notification"}
            />
          </AddToWatch>
        )}
        {openMassPush && (
          <AddToWatch
            open={openMassPush}
            handleClose={handleCloseMassPush}
            handleAddWatchlist={handleNotification}
            buttonName={"Send"}>
            <PushNotification
              setReasonPayload={setReasonPayload}
              title={"enter name for creating push notification"}
            />
          </AddToWatch>
        )}
      </HistoryWrapper>
    </div>
  );
}
function handleListKeyDown(event) {
  if (event.key === "Tab") {
    event.preventDefault();
    setOpen(false);
  }
}
const mapStateToProps = state => {
  return {
    GeospatialState: state.Geospatial,
    dashboardState: state.Dashboard,
    historyState: state.History,
    notificationDetailAState: state.MassNotification,
    watchlistState: state.Watchlist
  };
};
export default connect(
  mapStateToProps,
  null
)(CreateNotifications);
