import React from "react";
import TextField from "@material-ui/core/TextField";
export default function SafeContent(props) {
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}>
        <div>Changing Status to Distress</div>
        <TextField
          id="reason"
          InputProps={{
            disableUnderline: true
          }}
          // //   value={reason}
          onChange={e => {
            e.target.value = e.target.value.substring(0, 15);
            props.setReasonPayload(e.target.value);
          }}
          margin="normal"
          placeholder="Notes for the change of status"
          type="text"
          fullWidth
        />
      </div>
    </div>
  );
}
