import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
// components
import Layout from "../components/Layout";
// pages
// import Error from "../pages/NotFound";
import Login from "../pages/Login";
// context
import { useUserState } from "../context/UserContext";

export default function App() {
  // global
  const { isAuthenticated } = useUserState();
  return (
    <BrowserRouter>
      <Switch>
        {/* <Route exact path="/" render={() => <Redirect to="/dashboard" />} /> */}
        <PublicRoute path="/login" component={Login} />
        <Route path="/" component={isAuthenticated ? Layout : Login} />
        {/* <PrivateRoute path="/" component={Layout} /> */}
        {/* <Route component={Error} /> */}
      </Switch>
    </BrowserRouter>
  );

  // ##############################PRIVATE ROUTING#########################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location
                }
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/"
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
