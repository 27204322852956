import MarkerIcon from "../../../assets/img/marker.png";
import startMarker from "../../../assets/img/startMarker.png";
import endMarker from "../../../assets/img/endMarker2.png";
const eventMapping = {
  onClick: "click",
  onDoubleClick: "dblclick"
};
export default function useGoogleMapMarker(
  position,
  type,
  maps,
  map,
  events,
  title,
  isheatmap,
  iconPos,
  zIndex,
  infowindow,
  m,
  info
) {
  const marker = new maps.Marker({
    icon: iconPos,
    position,
    map,
    title,
    zIndex: zIndex ? zIndex : 1
  });
  Object.keys(events).forEach(eventName =>
    marker.addListener(eventMapping[eventName], events[eventName])
  );
if(info){
    maps.event.addListener(marker, "click", function() {
      infowindow.setContent(title);
      infowindow.open(map, marker);
    });
}
  return marker;
}
