import React, { useState, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import moment from "moment";
import get from "lodash/get";
import {
  WatchlistWrapper,
  WatchlistStcikyBarWrapper,
  StcikyBarContentDiv
} from "./WatchList.styles";
import { PageHeading } from "../../styles/Body.style";
import AddToWatch from "../../components/AddToWatchList/Generic";
import location from "../../assets/img/location.png";
import locationinactive from "../../assets/img/locationinactive.png";
import userwatchlist from "../../assets/img/userprop.png";
import userwatchlistinactive from "../../assets/img/userinactive.png";
import wifi from "../../assets/img/wifi.png";
import wifiinactive from "../../assets/img/wifiinactive.png";
import bluetooth from "../../assets/img/bluetooth.png";
import bluetoothinactive from "../../assets/img/bluetoothinactive.png";
import cellularNetwork from "../../assets/img/cellular-network.png";
import cellularnetworkInactive from "../../assets/img/cellularnetworkInactive.png";
import Tabs from "../../components/Tabs";
import WatchListComp from "../../components/WatchList";
import WatchListMap from "../../components/Map/WatchListMaps/WatchListMap";
import store from "../../store";
import PushNotification from "../../components/WatchList/PushNotification";
import SendTextMessage from "../../components/WatchList/SendTextMessage";
import UserTable from "../../components/WatchList/UserTable";
import ConnectivityTable from "../../components/WatchList/ConnectivityTable";
import AlertDialog from "../../components/WatchList/ConfirmDeleteWatchlist";
import AlertDialogGeneric from "../../components/WatchList/GenericDelete";
import ConfirmationAlert from "../../components/ConfirmationAlert";
import AddtoWatchListLocation from "../../components/WatchList/AddtoWatchListLocation";
import { validateFromAndToDate } from "../../utils/dateTime";
import compareValues from "../../utils/sortCompare";

let autoSuggestTimer = null;
function WatchList(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [isDrawerManager, setDrawerManager] = useState(false);
  const [ComponentRendered, setComponent] = useState({});
  const refLocation = React.useRef();
  const refUser = React.useRef();
  const refBluetooth = React.useRef();
  const refWifi = React.useRef();
  const refCellular = React.useRef();
  const [addLocation, setAddLocation] = useState(false);
  let { boundData } = props.GeospatialState;
  const [connectivityWifi, setConnectivityDataWifi] = useState([]);
  const [connectivityBluetooth, setConnectivityDataBluetooth] = useState([]);
  const [connectivityNetwork, setConnectivityDataNetwork] = useState({});
  const [selectedDateFromPolygon, setDateChangeFromPolygon] = useState(
    new Date().setMinutes(new Date().getMinutes() - 20)
  );
  const [selectedIndexLoc, setSelectedIndexLoc] = React.useState(0);
  const [selectedIndexUsr, setSelectedIndexUsr] = React.useState(0);
  const [selectedIndexWifi, setSelectedIndexWifi] = React.useState(0);
  const [selectedIndexBluetooth, setSelectedIndexBluetooth] = React.useState(0);
  const [selectedIndexNetwork, setSelectedIndexNetwork] = React.useState(0);

  const [organizationsAdding, setOrganizationAdding] = React.useState(0);
  const [user, setUser] = React.useState(0);
  const [timeLineData, setTimeLineData] = useState({});
  const [wifiData, setWifiData] = useState([]);
  const [bluetoothData, setBluetooth] = useState([]);
  const [networkData, setNetwork] = useState([]);
  const [isDisable, setFilterDisable] = useState(true);
  const [selectedDateToPolygon, setDateChangeToPolygon] = useState(new Date());
  const [userMarkers, setMarkers] = React.useState([]);
  const [userMarkersPolygons, setMarkersPolygon] = React.useState([]);
  const [UserWatchlists, setUserWatch] = React.useState();
  const [PolygonWatchlists, setPolygonWatch] = React.useState();
  const [selectedPolygonCoordinates, setPolygonCoordinates] = React.useState();
  const [selectedCircleRadius, setCircleRadius] = React.useState();
  const [openWatchList, setOpenWatchList] = React.useState(false);
  const [openMassText, setOpenMassText] = React.useState(false);
  const [openMassPush, setOpenMassPush] = React.useState(false);
  const [Reasonpayload, setReasonPayload] = React.useState("");
  const [DeviceWatchlistName, setDeviceWatchlistName] = React.useState("");
  const [organizations, setOrganization] = React.useState(["0"]);
  const [organizationsList, setOrganizationList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [Item, setPayloadItem] = React.useState({});
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openConfirmWatchlist, setOpenConfirmWatchlist] = React.useState(false);
  const [removePolygon, setRemovePolygon] = React.useState({});
  const [removeFromWatchlist, setRemoveFromWatchlist] = React.useState({});
  const [massTextPayload, setMassTextPayload] = React.useState({});
  const [userData, setUserData] = React.useState({});
  const [wifitableData, setWifitableData] = React.useState([]);
  const [bluetoothtableData, setBluetoothtableData] = React.useState([]);
  const [networktableData, setNetworktableData] = React.useState([]);
  const [selectedDateToUser, setDateChangeToUser] = useState(new Date());
  const currentDate = new Date();
  const [confirmtnError, setConfirmtnError] = useState(false);
  currentDate.setUTCDate(new Date().getDate() - 1);
  const [selectedDateFromUser, setDateChangeFromUser] = useState(
    new Date().setDate(new Date().getDate() - 1)
  );
  const [
    selectedDateToConnectivityWifi,
    setDateChangeToConnectivityWifi
  ] = useState(new Date());
  const [
    selectedDateFromConnectivityWifi,
    setDateChangeFromConnectivityWifi
  ] = useState(new Date().setDate(new Date().getDate() - 1));
  const [
    selectedDateToConnectivityBluetooth,
    setDateChangeToConnectivityBluetooth
  ] = useState(new Date());
  const [
    selectedDateFromConnectivityBluetooth,
    setDateChangeFromConnectivityBluetooth
  ] = useState(new Date().setDate(new Date().getDate() - 1));
  const [
    selectedDateToConnectivityNetwork,
    setDateChangeToConnectivityNetwork
  ] = useState(new Date());
  const [
    selectedDateFromConnectivityNetwork,
    setDateChangeFromConnectivityNetwork
  ] = useState(new Date().setDate(new Date().getDate() - 1));
  const handleUserChange = event => {
    setUser(event.target.value);
  };
  const [errorMsg, setErrorMsg] = useState({
    locationMsg: "",
    userMsg: "",
    wifiMsg: "",
    bluetoothMsg: "",
    cellularMsg: ""
  });

  const userSearchRef = useRef(null);

  const handleOrganizationChange = event => {
    setOrganizationAdding(event.target.value);
    let org = [event.target.value].filter(item => item !== "0");
    organizationsList.forEach(item => {
      if (item.id === event.target.value) {
        org = [item.organization_id];
      }
    });
    store.dispatch.Dashboard.getUserlist(org);
    setUser(0);
  };

  useEffect(() => {
    const validate = validateFromAndToDate(
      selectedDateFromUser,
      selectedDateToUser
    );
    if (validate) {
      setErrorMsg({
        ...errorMsg,
        userMsg: validate
      });
    } else {
      setErrorMsg("");
      let payload = {
        user_id:
          userData &&
          userData.user &&
          userData.user.id &&
          userData.user.id !== undefined
            ? userData.user.id
            : "",
        to_date: moment.utc(selectedDateToUser).toISOString(),
        from_date: moment.utc(selectedDateFromUser).toISOString()
      };
      if (
        userData &&
        userData.user &&
        userData.user.id &&
        userData.user.id !== undefined
      ) {
        store.dispatch.UserProfile.startTimeline(payload);
      }
    }
  }, [selectedDateFromUser, selectedDateToUser, userData]);

  useEffect(() => {
    const validate = validateFromAndToDate(
      selectedDateFromConnectivityWifi,
      selectedDateToConnectivityWifi
    );
    if (validate) {
      setErrorMsg({
        ...errorMsg,
        wifiMsg: validate
      });
    } else {
      setErrorMsg("");
      if (connectivityWifi) {
        let payload = {
          connectivityType: "wifi",
          data: connectivityWifi.name,
          from_datetime: moment
            .utc(selectedDateFromConnectivityWifi)
            .toISOString(),
          to_datetime: moment.utc(selectedDateToConnectivityWifi).toISOString()
        };
        store.dispatch.Watchlist.startTimelineDevice(payload);
      }
    }
  }, [
    selectedDateToConnectivityWifi,
    selectedDateFromConnectivityWifi,
    connectivityWifi
  ]);
  useEffect(() => {
    const validate = validateFromAndToDate(
      selectedDateFromConnectivityBluetooth,
      selectedDateToConnectivityBluetooth
    );
    if (validate) {
      setErrorMsg({
        ...errorMsg,
        bluetoothMsg: validateFromAndToDate(
          selectedDateFromConnectivityBluetooth,
          selectedDateToConnectivityBluetooth
        )
      });
    } else {
      setErrorMsg("");
      if (connectivityBluetooth !== undefined) {
        let payload = {
          connectivityType: "bluetooth",
          data: connectivityBluetooth.name,
          from_datetime: moment
            .utc(selectedDateFromConnectivityBluetooth)
            .toISOString(),
          to_datetime: moment
            .utc(selectedDateToConnectivityBluetooth)
            .toISOString()
        };
        store.dispatch.Watchlist.startTimelineDevice(payload);
      }
    }
  }, [
    selectedDateToConnectivityBluetooth,
    selectedDateFromConnectivityBluetooth,
    connectivityBluetooth
  ]);
  useEffect(() => {
    const validate = validateFromAndToDate(
      selectedDateFromConnectivityNetwork,
      selectedDateToConnectivityNetwork
    );
    if (validate) {
      setErrorMsg({
        ...errorMsg,
        cellularMsg: validate
      });
    } else {
      setErrorMsg("");
      if (connectivityNetwork !== undefined) {
        let payload = {
          connectivityType: "network",
          data: connectivityNetwork.name,
          from_datetime: moment
            .utc(selectedDateFromConnectivityNetwork)
            .toISOString(),
          to_datetime: moment
            .utc(selectedDateToConnectivityNetwork)
            .toISOString()
        };
        store.dispatch.Watchlist.startTimelineDevice(payload);
      }
    }
  }, [
    selectedDateToConnectivityNetwork,
    selectedDateFromConnectivityNetwork,
    connectivityNetwork
  ]);
  useEffect(() => {
    const arr = props.UserProfileState.timeline;
    if (arr.length > 0) {
      const sortedarr = props.UserProfileState.timeline.sort(
        compareValues("timestamp", "desc")
      );
      setTimeLineData(sortedarr);
    }

    setTimeLineData(props.UserProfileState.timeline);
  }, [props.UserProfileState.timeline]);

  const handleClickOpenConfirm = (i, popupState) => {
    setRemovePolygon({
      i,
      popupState
    });
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setRemovePolygon({});
    setOpenConfirm(false);
  };
  const handleClickOpenConfirmWatchlist = (i, id) => {
    setRemoveFromWatchlist({
      i,
      id
    });
    setOpenConfirmWatchlist(true);
  };

  const handleCloseConfirmWatchlist = () => {
    setRemoveFromWatchlist({});
    setOpenConfirmWatchlist(false);
    setConfirmtnError(false);
  };
  const [coordinates, setCoordinates] = React.useState("");

  const handleDateSearch = e => {
    const { value } = e.target;

    if (value === "") {
      setPayloadItem({});
      setPolygonWatch([]);
      setCoordinates([]);
    }
    if (autoSuggestTimer) {
      clearTimeout(autoSuggestTimer);
    }
    autoSuggestTimer = setTimeout(() => {
      store.dispatch.Watchlist.getPolygonWatchList(value || "");
    }, 1000);
  };

  const handleUserSearch = (e, i) => {
    const Search = e.target.value;
    const cleartimeline = store.dispatch.UserProfile.timelineDataClear;
    store.dispatch.Watchlist.getUserWatchListFilter({ Search }, cleartimeline);
    if (organizations.filter(org => org !== "0").length > 0)
      setOrganization(["0"]);
  };

  const handleSearchWatchlist = (e, id) => {
    let payload = {};
    if (id !== "Cellular Network") {
      payload = {
        connectivityType: id.toLowerCase(),
        searchTerm: e.target.value
      };
    } else {
      payload = {
        connectivityType: id.toLowerCase().split(" ")[1],
        searchTerm: e.target.value
      };
    }
    // store.dispatch.Watchlist.getConnectivityWatchlist({ payload });
    if (autoSuggestTimer) {
      clearTimeout(autoSuggestTimer);
    }
    autoSuggestTimer = setTimeout(() => {
      store.dispatch.Watchlist.getConnectivityWatchlist({ payload });
    }, 1000);
  };
  const handleDateChangeFromPolygon = e => {
    setDateChangeFromPolygon(e);
  };
  const handleDateChangeToPolygon = e => {
    setDateChangeToPolygon(e);
  };
  const handleDateChangeFromUser = e => {
    setDateChangeFromUser(e);
  };
  const handleDateChangeToUser = e => {
    setDateChangeToUser(e);
  };

  const handleDateChangeFromConnectivity = e => {
    setDateChangeFromConnectivityWifi(e);
  };
  const handleDateChangeToConnectivity = e => {
    setDateChangeToConnectivityWifi(e);
  };
  const handleDateChangeFromConnectivityBluetooth = e => {
    setDateChangeFromConnectivityBluetooth(e);
  };
  const handleDateChangeToConnectivityBluetooth = e => {
    setDateChangeToConnectivityBluetooth(e);
  };
  const handleDateChangeFromConnectivityNetwork = e => {
    setDateChangeFromConnectivityNetwork(e);
  };
  const handleDateChangeToConnectivityNetwork = e => {
    setDateChangeToConnectivityNetwork(e);
  };
  const handleOrgChange = event => {
    let org = [...event.target.value];
    if (org.includes("0")) {
      const index = org.indexOf("0");
      org.splice(index, 1);
    }
    setOrganization(org);
    if (event.target.value.length === 0) {
      setOrganization(["0"]);
    }
    // clear the search field
    userSearchRef.current.value = "";
  };
  useEffect(() => {
    const validate = validateFromAndToDate(
      selectedDateFromPolygon,
      selectedDateToPolygon
    );
    if (validate) {
      setErrorMsg({
        ...errorMsg,
        locationMsg: validate
      });
    } else {
      setErrorMsg("");
      if (!props.WatchlistState || !props.WatchlistState.watchlist_id) {
        return;
      }
      if (
        props.WatchlistState &&
        props.WatchlistState.watchlist_id &&
        selectedDateFromPolygon &&
        selectedDateToPolygon
      ) {
        let payload = {
          watchlist_id:
            Item && Item.id
              ? Item.id
              : props.WatchlistState && props.WatchlistState.watchlist_id,
          from_datetime: moment.utc(selectedDateFromPolygon).toISOString(),
          to_datetime: moment.utc(selectedDateToPolygon).toISOString()
        };
        let coodrinatesForPlotting = Item && Item.coordinates;
        let radius = Item && Item.radius;
        if (radius) {
          store.dispatch.Watchlist.getPolygonWatchlistCoordinates({
            payload,
            coodrinatesForPlotting,
            radius
          });
        } else {
          store.dispatch.Watchlist.getPolygonWatchlistCoordinates({
            payload,
            coodrinatesForPlotting
          });
        }
      }
    }
  }, [
    selectedDateFromPolygon,
    selectedDateToPolygon,
    props.WatchlistState.watchlist_id,
    Item
  ]);

  const validate = comp => {
    let errors = [];
    switch (comp) {
      case "User":
        !user && errors.push("Please select a user");
        break;
      case "Wifi":
      case "Bluetooth":
        !DeviceWatchlistName &&
          errors.push(
            "Please enter the name of device adding to the watchlist"
          );
        break;
      case "Cellular Network":
        !DeviceWatchlistName && errors.push("Please enter cellular network ID");
        break;
    }
    !Reasonpayload &&
      errors.push("Please enter the reason for adding to watchlist");
    return errors;
  };

  const handleAddWatchlist = comp => {
    if (comp === "Location") {
      let arr = [],
        newarr = [];
      if (coordinates.coordinates.length > 0) {
        let coord = coordinates.coordinates.forEach(item => {
          let obj = {
            lat: item.latitude,
            lon: item.longitude
          };
          newarr.push(obj);
        });
      } else {
        arr.push(coordinates.coordinates);
        let coord = arr.forEach(item => {
          let obj = {
            lat: item.latitude,
            lon: item.longitude
          };
          newarr.push(obj);
        });
      }
      let addtoWatchlistPayload = {
        ...coordinates,
        coordinates: newarr,
        name: Reasonpayload
      };
      if (Reasonpayload) {
        store.dispatch.Watchlist.addToWatchlistLocation(addtoWatchlistPayload, {
          enqueueSnackbar,
          setOpenWatchList,
          setDrawerManager,
          setAddLocation,
          setReasonPayload,
          setFilterDisable,
          setSelectedIndexLoc,
          setOrganization,
          setUser
        });
        handleCloseWatchList();
      }
    }
    const errors = validate(comp);
    if (errors.length === 0) {
      if (comp == "User") {
        let datapay = {};
        const list = userList.forEach(item => {
          if (item.username === user) {
            datapay = {
              userId: item.id
            };
          }
        });
        let payload = {
          user_id: datapay.userId,
          reason: Reasonpayload
        };
        const getUserlist = store.dispatch.Dashboard.getUserlist;
        store.dispatch.Watchlist.addToWatchlist(payload, {
          enqueueSnackbar,
          setOpenWatchList,
          setReasonPayload,
          setOrganizationAdding,
          setUser,
          setSelectedIndexUsr,
          getUserlist
        });
      }
      if (comp == "Wifi" || comp == "Bluetooth") {
        let setSelectedIndex =
          comp == "Wifi" ? setSelectedIndexWifi : setSelectedIndexBluetooth;
        let payloadData = {
          name: DeviceWatchlistName,
          reason: Reasonpayload,
          data_type: comp.toLowerCase()
        };
        store.dispatch.Watchlist.addDeviceToWatchlist(payloadData, {
          enqueueSnackbar,
          setOpenWatchList,
          setReasonPayload,
          setDeviceWatchlistName,
          setSelectedIndex
        });
      }
      if (comp == "Cellular Network") {
        let setSelectedIndex = setSelectedIndexNetwork;
        let payloadData = {
          name: DeviceWatchlistName,
          reason: Reasonpayload,
          data_type: "network"
        };
        store.dispatch.Watchlist.addDeviceToWatchlist(payloadData, {
          enqueueSnackbar,
          setOpenWatchList,
          setReasonPayload,
          setDeviceWatchlistName,
          setSelectedIndex
        });
      }
    } else {
      errors.forEach(err => enqueueSnackbar(err, { variant: "error" }));
    }
  };

  const handleClickOpenMassText = (data, popupState) => {
    setMassTextPayload({
      data,
      popupState,
      Type: "text"
    });
    setOpenMassText(true);
  };

  const handleCloseMassText = () => {
    setOpenMassText(false);
  };
  const handleClickOpenMassPush = (data, popupState) => {
    setMassTextPayload({
      data,
      popupState,
      Type: "push"
    });
    setOpenMassPush(true);
  };

  const handleCloseMassPush = () => {
    setOpenMassPush(false);
  };
  const handleClickOpenWatchList = ID => {
    setComponent(ID);
    setOpenWatchList(true);
  };
  const handleClickOpenWatchListConnectivity = (data, id) => {
    if (id === "Wifi") {
      setConnectivityDataWifi(data);
    } else if (id === "Cellular Network") {
      setConnectivityDataNetwork(data);
    } else {
      setConnectivityDataBluetooth(data);
    }
  };

  const handleCloseWatchList = () => {
    setDeviceWatchlistName("");
    setReasonPayload("");
    setOrganizationAdding(0);
    setUser(0);
    setOpenWatchList(false);
  };
  useEffect(() => {
    store.dispatch.Watchlist.getPolygonWatchList();
    props.getUserWatchList();
    store.dispatch.Dashboard.getOrganizationlist();
    store.dispatch.Dashboard.getUserlist();
    let type = ["network", "bluetooth", "wifi"];
    type.forEach(element => {
      let payload = {
        connectivityType: element
      };
      store.dispatch.Watchlist.getConnectivityWatchlist({ payload });
    });
  }, []);
  useEffect(() => {
    if (boundData.length > 0) {
      setMarkers(boundData);
    }
  }, [boundData]);
  useEffect(() => {
    store.dispatch.UserProfile.timelineDataClear();
    if (organizations.length > 0 && !userSearchRef.current.value) {
      store.dispatch.Watchlist.getUserWatchListFilter({ organizations });
    }
  }, [organizations]);
  useEffect(() => {
    if (props.DashboardState && props.DashboardState.organization) {
      setOrganizationList(props.DashboardState.organization);
    }
    if (props.DashboardState && props.DashboardState.userList) {
      setUserList(props.DashboardState.userList);
    }
  }, [props.DashboardState]);

  useEffect(() => {
    setUserWatch(props.WatchlistState.userWatchlist);
    setUserData(props.WatchlistState.userWatchlist[0]);
  }, [props.WatchlistState.userWatchlist]);

  useEffect(() => {
    if (
      props.WatchlistState &&
      props.WatchlistState.plottedCoordinates &&
      props.WatchlistState.plottedCoordinates.length > 0
    ) {
      setPolygonCoordinates(props.WatchlistState.plottedCoordinates);
    }
    if (props.WatchlistState && props.WatchlistState.radius) {
      setCircleRadius(props.WatchlistState.radius);
    }
    if (
      props.WatchlistState &&
      props.WatchlistState.userLocations &&
      props.WatchlistState.userLocations.length > 0
    ) {
      setMarkersPolygon(props.WatchlistState.userLocations);
    }
    if (props.WatchlistState && props.WatchlistState.networkDataTimeline) {
      const sorted = props.WatchlistState.networkDataTimeline.sort(
        compareValues("timestamp", "desc")
      );
      setNetworktableData(sorted);
    }
    if (props.WatchlistState && props.WatchlistState.wifiDataTimeline) {
      const sorted = props.WatchlistState.wifiDataTimeline.sort(
        compareValues("timestamp", "desc")
      );
      setWifitableData(sorted);
    }
    if (props.WatchlistState && props.WatchlistState.bluetoothDataTimeline) {
      const sorted = props.WatchlistState.bluetoothDataTimeline.sort(
        compareValues("timestamp", "desc")
      );
      setBluetoothtableData(sorted);
    }
  }, [props.WatchlistState]);

  useEffect(() => {
    if (
      props.WatchlistState.userWatchlist &&
      props.WatchlistState.userWatchlist.length > 0
    ) {
      setUserWatch(props.WatchlistState.userWatchlist);
      setUserData(props.WatchlistState.userWatchlist[0]);
      setMarkers(props.WatchlistState.userWatchlist.user);
    }
    if (props.WatchlistState.polygonWatchList) {
      setPolygonWatch(get(props, "WatchlistState.polygonWatchList"));
      setCircleRadius(get(props, "WatchlistState.polygonWatchList.0.radius"));
      if (props.WatchlistState.polygonWatchList.length === 0)
        setTimeout(() => setPolygonCoordinates([]), 1000); // it's a workaround to clear the coordinates when polygonWatchlist is empty
    }
    if (props.WatchlistState.wifiData) {
      setWifiData(props.WatchlistState.wifiData);
      setConnectivityDataWifi(props.WatchlistState.wifiData[0]);
      setSelectedIndexWifi(0);
    }
    if (props.WatchlistState.bluetooth) {
      setBluetooth(props.WatchlistState.bluetooth);
      setConnectivityDataBluetooth(props.WatchlistState.bluetooth[0]);
      setSelectedIndexBluetooth(0);
    }
    if (props.WatchlistState.network) {
      setNetwork(props.WatchlistState.network);
      setConnectivityDataNetwork(props.WatchlistState.network[0]);
      setSelectedIndexNetwork(0);
    }
  }, [
    props.WatchlistState.wifiData,
    props.WatchlistState.bluetooth,
    props.WatchlistState.network,
    props.WatchlistState.polygonWatchList,
    props.WatchlistState.userWatchlist
  ]);
  useEffect(() => {
    setMarkersPolygon(props.WatchlistState.userLocations);
  }, [props.WatchlistState.userLocations]);

  const handleRemoveUserFromWatchlist = reason => {
    let payload = removeFromWatchlist.i;
    if (
      removeFromWatchlist.id === "Wifi" ||
      removeFromWatchlist.id === "Cellular Network" ||
      removeFromWatchlist.id === "Bluetooth"
    ) {
      store.dispatch.Watchlist.removeDevicesfromWatchlist(payload, {
        setOpenConfirmWatchlist,
        enqueueSnackbar,
        type: removeFromWatchlist.id
      });
    } else {
      if (reason) {
        setConfirmtnError(false);
        let payloadData = {
          user_id: removeFromWatchlist.i.user.id,
          reason_removed: reason
        };
        store.dispatch.UserProfile.timelineDataClear();
        store.dispatch.Watchlist.removefromUserWatchlist(payloadData, {
          setOpenConfirmWatchlist,
          enqueueSnackbar
        });
      } else {
        setConfirmtnError(true);
      }
    }
  };
  const handleRemoveConnectivityFromWatchlist = () => {};
  const handleRemovePolygonFromWatchlist = () => {
    setPolygonCoordinates([]);
    setPayloadItem([]);
    let payload = {
      watchlist_id: removePolygon.i
    };
    store.dispatch.Watchlist.removefromPolygonWatchlist({
      payload,
      enqueueSnackbar,
      popupState: removePolygon.popupState,
      setPolygonWatch
    });
    setOpenConfirm(false);
  };

  const handleNotification = () => {
    let payload = {};
    payload = {
      name: Reasonpayload,
      notification_type: massTextPayload.Type,
      watchlist_location_id: massTextPayload.data.id
    };
    store.dispatch.Watchlist.notifications({
      payload,
      enqueueSnackbar,
      popupState: massTextPayload.popupState,
      setReasonPayload,
      setMassTextPayload,
      setOpenMassText,
      setOpenMassPush
    });
  };
  const handleDrawingManager = (coordinates, zoom, center, radius) => {
    if (coordinates !== undefined && coordinates.length > 0) {
      let payload = {
        polygon: coordinates,
        zoom_level: 4
      };
      let addPayload = {
        shape: "custom",
        coordinates: coordinates
      };

      setCoordinates(addPayload);
      store.dispatch.Geospatial.startGeoSpatial(payload);
    }
    if (center !== undefined && radius !== undefined) {
      let payload = {
        center: center,
        radius: radius
      };
      let addPayload = {
        shape: "circle",
        coordinates: center,
        radius: radius
      };

      setCoordinates(addPayload);
      store.dispatch.Geospatial.startGeoSpatial(payload);
    }
  };
  const handleLocationButton = () => {
    setDateChangeFromPolygon(
      new Date().setMinutes(new Date().getMinutes() - 20)
    );
    setDateChangeToPolygon(new Date());
    setAddLocation(true);
    setDrawerManager(true);
  };
  const handleLocationButtonCancel = () => {
    setDateChangeFromPolygon(
      new Date().setMinutes(new Date().getMinutes() - 20)
    );
    setDateChangeToPolygon(new Date());
    window.markers.forEach(marker => {
      marker && marker.setMap(null);
    });
    setAddLocation(false);
    setDrawerManager(false);
  };
  const handlePlotCoordinatesWatchlist = item => {
    setPayloadItem(item);
    let payload = {
      watchlist_id: item.id,
      from_datetime: moment.utc(selectedDateFromPolygon).toISOString(),
      to_datetime: moment.utc(selectedDateToPolygon).toISOString()
    };
    let coodrinatesForPlotting = item.coordinates;
    let radius = item.radius;
    if (radius) {
      store.dispatch.Watchlist.getPolygonWatchlistCoordinates({
        payload,
        coodrinatesForPlotting,
        radius
      });
    } else {
      store.dispatch.Watchlist.getPolygonWatchlistCoordinates({
        payload,
        coodrinatesForPlotting
      });
    }
  };
  const smoothScroll = (target, offset = 0, duration = 10) => {
    let scrollContainer = target;
    do {
      scrollContainer = scrollContainer.parentNode;
      if (!scrollContainer) return;
      scrollContainer.scrollTop += 1;
    } while (scrollContainer.scrollTop == 0);

    let targetY = 0;
    do {
      if (target == scrollContainer) break;
      targetY += target.offsetTop;
    } while ((target = target.offsetParent));
    targetY -= offset;
    const scroll = (container, top, element, i) => {
      i++;
      if (i > 30) return;
      container.scrollTop = top + ((element - top) / 30) * i;
      setTimeout(() => {
        requestAnimationFrame(() => scroll(container, top, element, i));
      }, duration);
    };
    scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
  };
  const handleContent = comp => {
    // var element = document.getElementById("container");
    // element.classList.add("header-margin");
    if (comp == "Location") {
      // const ele = document.getElementById(linkId);
      if (refLocation.current) {
        // refLocation.current.scrollIntoView({
        //   behavior: "smooth",
        //   block: "end"
        // });
        smoothScroll(refLocation.current, 200);
      }
    }
    if (comp == "User") {
      if (refUser.current) {
        // refUser.current.scrollIntoView({
        //   behavior: "smooth",
        //   block: "end"
        // });
        smoothScroll(refUser.current, 200);
      }
    }
    if (comp == "Bluetooth") {
      if (refBluetooth.current) {
        // refBluetooth.current.scrollIntoView({
        //   behavior: "smooth",
        //   block: "end"
        // });
        smoothScroll(refBluetooth.current, 200);
      }
    }
    if (comp == "Wifi") {
      if (refWifi.current) {
        // refWifi.current.scrollIntoView({
        //   behavior: "smooth",
        //   block: "end"
        // });
        smoothScroll(refWifi.current, 200);
      }
    }
    if (comp == "Cellular Network") {
      if (refCellular.current) {
        // refCellular.current.scrollIntoView({
        //   behavior: "smooth",
        //   block: "end"
        // });
        smoothScroll(refCellular.current, 200);
      }
    }
  };

  const handleUserWatchlist = item => {
    setUserData(item);
  };

  //clean up
  useEffect(() => {
    return () => {
      store.dispatch.Geospatial.SearchDataClear();
    };
  }, []);
  return (
    <WatchlistWrapper>
      <Grid container>
        <Grid item xs={12} className="sticky">
          <div>
            <PageHeading>Watchlist</PageHeading>
            <WatchlistStcikyBarWrapper>
              <Tabs>
                <div
                  activeIcon={location}
                  inActiveIcon={locationinactive}
                  label="Location"
                  handleContent={handleContent}></div>
                <div
                  activeIcon={userwatchlist}
                  inActiveIcon={userwatchlistinactive}
                  label="User"
                  handleContent={handleContent}></div>
                <div
                  activeIcon={wifi}
                  inActiveIcon={wifiinactive}
                  label="Wifi"
                  handleContent={handleContent}></div>
                <div
                  activeIcon={bluetooth}
                  inActiveIcon={bluetoothinactive}
                  label="Bluetooth"
                  handleContent={handleContent}></div>

                <div
                  activeIcon={cellularNetwork}
                  inActiveIcon={cellularnetworkInactive}
                  label="Cellular Network"
                  handleContent={handleContent}></div>
              </Tabs>
            </WatchlistStcikyBarWrapper>
          </div>
        </Grid>
        <Grid item xs={12} className="watchlist-content">
          <StcikyBarContentDiv ref={refLocation} height="591px">
            <WatchListComp
              Title="Location Watchlist"
              imgSrc={location}
              id="Location"
              isLoading={props.islocationWatchlistLoading}
              isDisable={isDisable}
              errorMsg={errorMsg.locationMsg}
              dataSlider={PolygonWatchlists}
              plotted={handlePlotCoordinatesWatchlist}
              handleLocationButton={handleLocationButton}
              addLocation={addLocation}
              handleLocationButtonCancel={handleLocationButtonCancel}
              handleClickOpenWatchList={handleClickOpenWatchList}
              deleteWatchlist={handleRemovePolygonFromWatchlist}
              handleDateChangeFrom={handleDateChangeFromPolygon}
              selectedDateFrom={selectedDateFromPolygon}
              selectedDateTo={selectedDateToPolygon}
              handleDateChangeTo={handleDateChangeToPolygon}
              handleNotification={handleNotification}
              handleDateSearch={handleDateSearch}
              handleClickOpenConfirm={handleClickOpenConfirm}
              handleClickOpenMassText={handleClickOpenMassText}
              handleClickOpenMassPush={handleClickOpenMassPush}
              selectedIndex={selectedIndexLoc}
              setSelectedIndex={setSelectedIndexLoc}>
              <WatchListMap
                setFilterDisable={setFilterDisable}
                isDrawerManager={isDrawerManager}
                PolygonWatchlists={PolygonWatchlists}
                coordinates={selectedPolygonCoordinates}
                geospatialApi={handleDrawingManager}
                userMarkers={userMarkers}
                userWatchlistClear={store.dispatch.Watchlist.UserWatchlistClear}
                selectedCircleRadius={selectedCircleRadius}
                userMarkersPolygons={userMarkersPolygons}
                history={props.history}
              />
            </WatchListComp>
          </StcikyBarContentDiv>

          <StcikyBarContentDiv ref={refUser} height="591px">
            <WatchListComp
              Title="User Watchlist"
              imgSrc={userwatchlist}
              id="User"
              isLoading={props.isuserWatchlistLoading}
              errorMsg={errorMsg.userMsg}
              dataSlider={UserWatchlists}
              handleOrgChange={handleOrgChange}
              deleteWatchlist={handleRemoveUserFromWatchlist}
              handleClickOpenWatchList={handleClickOpenWatchList}
              handelSelectItem={handleUserWatchlist}
              handleDateChangeFrom={handleDateChangeFromUser}
              selectedDateFrom={selectedDateFromUser}
              selectedDateTo={selectedDateToUser}
              handleDateChangeTo={handleDateChangeToUser}
              organizations={organizations}
              organizationsList={organizationsList}
              handleDateSearch={handleUserSearch}
              searchFieldRef={userSearchRef}
              handleClickOpenConfirm={handleClickOpenConfirmWatchlist}
              userList={userList}
              selectedIndex={selectedIndexUsr}
              setSelectedIndex={setSelectedIndexUsr}>
              <UserTable
                userData={userData}
                history={props.history}
                activityData={timeLineData}
              />
            </WatchListComp>
          </StcikyBarContentDiv>
          <StcikyBarContentDiv ref={refWifi} height="591px">
            <WatchListComp
              Title="Wifi Watchlist"
              imgSrc={wifi}
              id="Wifi"
              isLoading={props.iswifiWatchlistLoading}
              dataSlider={wifiData}
              errorMsg={errorMsg.wifiMsg}
              deleteWatchlist={handleRemoveConnectivityFromWatchlist}
              handleClickOpenWatchList={handleClickOpenWatchList}
              handelSelectItem={handleClickOpenWatchListConnectivity}
              handleClickOpenConfirm={handleClickOpenConfirmWatchlist}
              handleSearch={handleSearchWatchlist}
              handleDateChangeFrom={handleDateChangeFromConnectivity}
              selectedDateFrom={selectedDateFromConnectivityWifi}
              selectedDateTo={selectedDateToConnectivityWifi}
              handleDateChangeTo={handleDateChangeToConnectivity}
              selectedIndex={selectedIndexWifi}
              setSelectedIndex={setSelectedIndexWifi}>
              <ConnectivityTable
                id="Wifi"
                history={props.history}
                activityData={connectivityWifi}
                table={wifitableData}
              />
            </WatchListComp>
          </StcikyBarContentDiv>
          <StcikyBarContentDiv ref={refBluetooth} height="591px">
            <WatchListComp
              Title="Bluetooth Watchlist"
              imgSrc={bluetooth}
              dataSlider={bluetoothData}
              deleteWatchlist={handleRemoveConnectivityFromWatchlist}
              id="Bluetooth"
              isLoading={props.isbluetoothWatchlistLoading}
              errorMsg={errorMsg.bluetoothMsg}
              handleClickOpenWatchList={handleClickOpenWatchList}
              handelSelectItem={handleClickOpenWatchListConnectivity}
              handleClickOpenConfirm={handleClickOpenConfirmWatchlist}
              handleSearch={handleSearchWatchlist}
              handleDateChangeFrom={handleDateChangeFromConnectivityBluetooth}
              selectedDateFrom={selectedDateFromConnectivityBluetooth}
              selectedDateTo={selectedDateToConnectivityBluetooth}
              handleDateChangeTo={handleDateChangeToConnectivityBluetooth}
              selectedIndex={selectedIndexBluetooth}
              setSelectedIndex={setSelectedIndexBluetooth}>
              <ConnectivityTable
                id="Bluetooth"
                history={props.history}
                activityData={connectivityBluetooth}
                table={bluetoothtableData}
              />
            </WatchListComp>
          </StcikyBarContentDiv>
          <StcikyBarContentDiv ref={refCellular} height="591px">
            <WatchListComp
              Title="Cellular Network Watchlist"
              imgSrc={cellularNetwork}
              dataSlider={networkData}
              errorMsg={errorMsg.cellularMsg}
              deleteWatchlist={handleRemoveConnectivityFromWatchlist}
              id="Cellular Network"
              isLoading={props.isnetworkWatchlistLoading}
              handleClickOpenWatchList={handleClickOpenWatchList}
              handelSelectItem={handleClickOpenWatchListConnectivity}
              handleClickOpenConfirm={handleClickOpenConfirmWatchlist}
              handleSearch={handleSearchWatchlist}
              handleDateChangeFrom={handleDateChangeFromConnectivityNetwork}
              selectedDateFrom={selectedDateFromConnectivityNetwork}
              selectedDateTo={selectedDateToConnectivityNetwork}
              handleDateChangeTo={handleDateChangeToConnectivityNetwork}
              selectedIndex={selectedIndexNetwork}
              setSelectedIndex={setSelectedIndexNetwork}>
              <ConnectivityTable
                id="Cellular Network"
                history={props.history}
                activityData={connectivityNetwork}
                table={networktableData}
              />
            </WatchListComp>
          </StcikyBarContentDiv>
        </Grid>
      </Grid>
      {openWatchList === true && (
        <AddToWatch
          open={openWatchList}
          handleClose={handleCloseWatchList}
          addToWatchlist={props.addToWatchlist}
          userID="test"
          enqueueSnackbar={enqueueSnackbar}
          handleAddWatchlist={handleAddWatchlist}
          comp={ComponentRendered}
          buttonName="Save">
          <AddtoWatchListLocation
            setReasonPayload={setReasonPayload}
            setDeviceWatchlistName={setDeviceWatchlistName}
            DeviceWatchlistName={DeviceWatchlistName}
            reason={Reasonpayload}
            organizationsList={organizationsList}
            handleOrgChange={handleOrganizationChange}
            handleUserChange={handleUserChange}
            organizations={organizations}
            organization={organizationsAdding}
            userList={userList}
            user={user}
            comp={ComponentRendered}
            suggestions={props.WatchlistState.bluetoothDeviceList}
          />
        </AddToWatch>
      )}
      {openConfirm && (
        <AlertDialog
          setRemovePolygon={setRemovePolygon}
          open={openConfirm}
          handleClose={handleCloseConfirm}
          handleRemovePolygonFromWatchlist={handleRemovePolygonFromWatchlist}
        />
      )}
      {openConfirmWatchlist && removeFromWatchlist.id !== "User" && (
        <AlertDialogGeneric
          setRemoveFromWatchlist={setRemoveFromWatchlist}
          open={openConfirmWatchlist}
          handleClose={handleCloseConfirmWatchlist}
          handleRemoveUserFromWatchlist={handleRemoveUserFromWatchlist}
          textLable="Are you sure to remove this from watchlist?"
        />
      )}
      {openConfirmWatchlist && removeFromWatchlist.id === "User" && (
        <ConfirmationAlert
          setRemoveFromWatchlist={setRemoveFromWatchlist}
          open={openConfirmWatchlist}
          handleClose={handleCloseConfirmWatchlist}
          handleContinueClick={handleRemoveUserFromWatchlist}
          dialogueTitle="Are you sure to remove this user from watchlist?"
          cancelLabel="Disagree"
          continueLabel="Agree"
          hasError={confirmtnError}
        />
      )}
      {openMassText && (
        <AddToWatch
          open={openMassText}
          handleClose={handleCloseMassText}
          handleAddWatchlist={handleNotification}
          handleCloseMassText={handleCloseMassText}
          buttonName="Send">
          <SendTextMessage
            setReasonPayload={setReasonPayload}
            comp={ComponentRendered}
            title="enter a name for text message"
          />
        </AddToWatch>
      )}
      {openMassPush && (
        <AddToWatch
          open={openMassPush}
          handleClose={handleCloseMassPush}
          handleAddWatchlist={handleNotification}
          buttonName="Send">
          <PushNotification
            setReasonPayload={setReasonPayload}
            comp={ComponentRendered}
            title="enter a name for push notification"
          />
        </AddToWatch>
      )}
    </WatchlistWrapper>
  );
}

const mapStateToProps = state => {
  return {
    WatchlistState: state.Watchlist,
    islocationWatchlistLoading: state.Watchlist.islocationWatchlistLoading,
    isuserWatchlistLoading: state.Watchlist.isuserWatchlistLoading,
    isnetworkWatchlistLoading: state.Watchlist.isnetworkWatchlistLoading,
    iswifiWatchlistLoading: state.Watchlist.iswifiWatchlistLoading,
    isbluetoothWatchlistLoading: state.Watchlist.isbluetoothWatchlistLoading,
    GeospatialState: state.Geospatial,
    UserProfileState: state.UserProfile,
    DashboardState: state.Dashboard
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserWatchList: dispatch.Watchlist.getUserWatchList
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WatchList);
