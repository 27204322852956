import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
const useStyles = makeStyles(theme =>  ({
  root: {
    margin: 0,
    padding: theme.spacing(2)
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));
export default function SimpleDialog(props) {
  const classes= useStyles()
  const { onClose, open ,title} = props;
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      // onClose={handleClose}
      style={{zIndex:"1000"}}
      PaperProps={{ borderRadius: "24px" }}
      aria-labelledby="simple-dialog-title"
      fullWidth={true}
      maxWidth={"md"}
      open={open}>
      <div style={{ display: "flex" }}>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
      {props.children}
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
