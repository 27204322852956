import styled from "styled-components";

export const Aside = styled.aside`
  display: flex;
  width: ${props => (props.isOpen ? "236px" : "83px")};
  transition: width 1s;
`;

export const HamburgerContainer = styled.div`
  height: 88px;
  background-image: linear-gradient(rgb(22, 26, 30) 19%, rgb(0, 0, 0));
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
  width: ${props => (props.isOpen ? "236px" : "83px")};
  transition: width 1s;
`;
export const ContentSection = styled.section`
  overflow: auto;
  height: calc(100vh - 70px);
`;
