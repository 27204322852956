
import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
         root: {
           flexGrow: 1,
           marginTop: "10px",
           padding: "0px",
           fontFamily: "Google Sans"
         },
         paper: {
           padding: theme.spacing(2),
           textAlign: "center",
           color: theme.palette.text.secondary
         },
         flex: {
           display: "flex"
         },
         gridGeospatia:{
          paddingRight: "0 !important"
         },
         searchMargin: {
           margin: " 18px 0px 0px 18px",
           width: "438px",
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: "#ffffff"
         },

         dashboard: {
           width: "150px !important",
           height: " 38px !important",
           fontFamily: "Google Sans",
           fontSize: "30px !important",
           fontWeight: "normal !important",
           fontStretch: "normal !important",
           fontStyle: "normal !important",
           lineHeight: "normal !important",
           letterSpacing: "normal !important",
           color: "#161a1e !important"
         },
         rectangle: {
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: "#ffffff"
         },
         textField: {
           marginLeft: theme.spacing(1),
           marginRight: theme.spacing(1),
           width: 200,
           fontFamily: "Google Sans"
         },
         textBox: {
           margin: "9px 5px 4px 2px",
           fontFamily: "Google Sans",
           fontSize: "12px !important",
           width: "100%",
           "& $div": {
             "& $input": {
               width: "100%",
               height: "16px",
               fontFamily: "Google Sans",
               fontSize: "12px !important",
               fontWeight: "normal",
               fontStretch: "normal",
               fontStyle: "normal",
               lineHeight: "normal",
               letterSpacing: "normal",
               color: "#999999"
             }
           },
           "& $img": {
             width: "11px",
             height: "19px",
             objectFit: "contain"
           }
         },
         formControl: {
           margin: theme.spacing(1),
           minWidth: 120
         },
         organizationDropDown: {
           margin: "18px 0px 0px 0px",
           width: "182px !important",
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: " solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: "#ffffff",
           fontFamily: "Google Sans"
         },
         orgSelect: {
           height: "39px !important",
           borderRadius: "8px !important",
           boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.04) !important",
           border: "solid 1px rgba(71, 59, 59, 0.2) !important",
           backgroundColor: "#ffffff !important",
           padding: "5px",
           margin: "18px 0px 0px 0px",
           fontFamily: "Google Sans"
         },
         allOrganaizations: {
           width: "93px",
           height: "16px",
           fontFamily: "Google Sans",
           fontSize: "12px",
           fontHeight: "normal",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: " #6f6e6e"
         },
         headingTypoGraphyContent: {
          padding: "10px",
           display:"flex",
           width: "100%",
           height: "38px",
           fontFamily: "Google Sans",
           fontSize: " 30px",
           fontHeight: "normal",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: "#161a1e",
           margin: "25px 0px 0px 0px"
         },
         mapContainer: {
           display: "flex",
           marginTop: "30px !important",
           marginLeft: "13px !important",
           position: "relative",
           border: "0px",
           paddingRight: "0 !important"
         },
         selectDropDown: {
           width: "182px",
           margin: "15px 25px 0px -5px",
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: " #ffffff"
         }
       }));
