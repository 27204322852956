import React, { useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import InputAdornment from "@material-ui/core/InputAdornment";
import LoadingIcon from "@material-ui/icons/Cached";
import DateTimePicker from "../DateTimePicker";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  OrganizationFormControl,
  InputWrap,
  Label
} from "../../pages/Organizations/organization.styles";
const useStyles = makeStyles(theme => ({
  root: {
    width: "88%",
    // padding: "9px",
    fontSize: "14px",
    height: "38px",
    // borderRadius: "8px",
    // boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    // border: "solid 1px rgba(71, 59, 59, 0.2)",
    backgroundColor: " #ffffff",
    position: "relative",
    "& $input": {
      fontSize: "11px !important",
      // margin: "0px !important",
    },
    "& $input[type=time]": {
      // paddingBottom: "11px"
    }
    // "& $div": {
    //   justifyContent: "space-between",
    // }
  },
  datePickerMargin: {
    margin: "12px 4px 7px 10px",
    padding: " 0px",
    fontSize: "12px !important"
  },
  adornment: {
    // position: "absolute",
    // left: "calc(100% - 15px)"
  },
  inputClass: {
    color: "#555353",
    width: "137px"
  }
}));
export const FieldDatePicker = ({
  label,
  name,
  value,
  onChange,
  errors,
  setErrors,
  pristine,
  validating,
  validate,
  formSubmitted,
  disableFuture,
  disabled,
  ...other
}) => {
  let showErrors = (!pristine || formSubmitted) && !!errors.length;
  const classes = useStyles();
  return (
    <OrganizationFormControl className="datepickerfield">
      <FormControl className="field" error={showErrors}>
        <Label error={showErrors} htmlFor={name}>
          {label}
        </Label>
        <div
          className={classes.root}
          style={{
          }}>
          <DateTimePicker
            disabled={disabled}
            disableFuture={disableFuture}
            inputClasses={classes.inputClass}
            adornment={classes.adornment}
            // className="datepicker"
            showErrors={showErrors}
            id={name}
            selectedDate={value}
            handleDateChange={onChange}
            onBlur={() => !pristine && validate()}
            {...other}
          />
        </div>

        <FormHelperText component="div">
          {showErrors &&
            errors.map(errorMsg => <div key={errorMsg}>{errorMsg}</div>)}
        </FormHelperText>
      </FormControl>
    </OrganizationFormControl>
  );
};
