import React, { useState } from "react"
import { Link } from "react-router-dom"
import MuiAlert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

export default function CustomAlert(props) {

  return (
    <Alert
      severity="warning"
      action={
        <Button component={Link} to="/dashboard" color="inherit" size="small">
          {props.buttonText}
        </Button>
      }
    >
      {props.alertMessage}
    </Alert>
  )
}
