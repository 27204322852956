
import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    breadcrumbContainer: {
        width: "164px",
        display: "flex",
        justifyContent: "center",
        cursor: "pointer" 
    },
    breadCrumb: {
        width: "120px",
        height: "16px",
        fontFamily: "Google Sans",
        fontSize: " 12px",
        fontHeight: "normal",
        fontStretch: " normal",
        fontStyle: " normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#4e4e4e",
        margin: "9px 0px 0px 7px",
        cursor: "pointer"
    },
    imageBack: {
        margin: "11px 0px 0px 0px"
    }
}));