import React, { useState, useEffect, useRef } from "react";
import SubformComponents from "../../components/SubformComponents";
import { Field } from "../../components/FormField";
import { LargeField } from "../../components/FormField/Large";
import { ticketFAQ, issueAndRequestOptions } from "../../data/ticketFormData"
import {
  TicketWrapper,
  TicketForm,
  TicketBlock,
  TicketOrgNameSection,
  TicketSelectSection,
  TicketDescriptionSection,
  DetailsText,
  Textspan,
  useStyles,
  StyledTab,
  StyledTabs,
  AccordionSummary, 
  TabPanel
} from "./ticket.style";
import { PageHeading, CancelButton, CreateButton } from "../../styles/Body.style"
import { useField, useForm } from "../../utils/validations/ValidationHooks";
import {
  Grid, 
  Divider, 
  Accordion, 
  AccordionDetails, 
  Typography 
} from "@material-ui/core";
import store from "../../store";
import moment from "moment";
import { useSnackbar } from "notistack";
import Select from 'react-select';
import 'react-dropdown/style.css';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import BackToPrevious from "../../components/BackToPrevious";

const payload = {
  name: "",
  org_name: "",
  office_phone_number: "",
  email: "",
  phone: "",
  is_active: true,
  created_on: new Date(),
  POC: [],
  issue_or_request: "",
  issue_or_request_description: ""
};
const blankContact = {
  name: "",
  office_phone_number: "",
  email: "",
  phone: ""
};
const Form = props => {
  const classes = useStyles();
  let { userOrgDetails, isExistingUser } = props.location.state || {};
  const { enqueueSnackbar } = useSnackbar();
  const [ticketIssue, setTicketIssue] = useState();
  const [ownerState, setOwnerState] = useState(userOrgDetails || payload);
  const [errorState, setErrorState] = useState([]);
  const forms = useRef([{ ...blankContact }]);
  const [value, setValue] = useState(0);
  const [contactState, setContactState] = useState([{ ...blankContact }]);
  const [formValid, setFormValid] = useState([false]);
  
  useEffect(() => {
    let temp = ownerState.POC;
    if (ownerState.POC && ownerState.POC.length > 0) {
      forms.current = [...ownerState.POC];
      temp = ownerState.POC;
    }
    let arr = [];
    temp && temp.forEach(element => {arr.push(true)});
    setFormValid(arr);
  }, [ownerState.POC]);

  const clear = () => {
    forms.current = [{ ...blankContact }];
    setFormValid([false]);
    const payload = {
      name: "",
      org_name: "",
      office_phone_number: "",
      email: "",
      phone: "",
      is_active: true,
      created_on: new Date(),
      POC: [],
      issue_or_request: "",
      issue_or_request_description: "",
      ticketIssue
    };
    setOwnerState(payload);
  };

  const handleContactChange = e => {
    const updatedContact = [...contactState];
    updatedContact[e.target.dataset.idx][e.target.className] = e.target.value;
    setContactState(updatedContact);
  };

  const form = useForm({
    onSubmit: async (formData) => {
      let error = [];
      setErrorState(error);
      await timeout(1000);
      let payload = formData;
      payload.POC = forms.current;
      payload.name = payload.POC[0].name;
      payload.office_phone_number = payload.POC[0].office_phone_number;
      payload.email = payload.POC[0].email;
      payload.phone = payload.POC[0].phone;
      payload.issue_or_request = ticketIssue;
      payload.is_active = true;
      payload.created_on = moment
        .utc(new Date().getTime())
        .toISOString();
      store.dispatch.Ticket.createTicket(payload, {
        enqueueSnackbar,
        clear,
        history: props.history
      });
    }
  });

  const orgNameField = useField("org_name", form, {
    defaultValue: ownerState.name,
    validations: [
      async formData => {
        return formData.org_name.length === 0 && "please provide organization name";
      }
    ],
    fieldsToValidateOnChange: ["org_name"]
  });

  const descriptionField = useField("issue_or_request_description", form, {
    defaultValue: ownerState.issue_or_request_description,
    validations: [
      async formData => {
        return formData.issue_or_request_description.length === 0 && "please provide a description of your issue or request";
      }
    ],
    fieldsToValidateOnChange: ["issue_or_request_description"]
  });

  const updateFormField = (state, index, value) => {
    const form = [...forms.current];
    const temp = { ...forms.current[index] };
    temp[state] = value;
    form[index] = temp;
    forms.current = form;
  };

  const updateFormValid = (index, isValid) => {
    const temp = [...formValid];
    temp[index] = isValid;
    setFormValid(temp);
  };

  const addContact = () => {
    forms.current = [...forms.current, { ...blankContact }];
    const validForm = [...formValid];
    validForm.push(false);
    setFormValid(validForm);
  };

  const deleteContact = idx => {
    const temp = [...forms.current];
    let state = temp.filter(function(e, index) {
      return index !== idx;
    });
    forms.current = state;
    const valid = [...formValid];
    let stateFilter = valid.filter(function(e, index) {
      return index !== idx;
    });
    setFormValid(stateFilter);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const options = issueAndRequestOptions.map(issue =>{
    return {label: issue.label, value: issue.value}
  });

  return (
    <TicketWrapper>
      <Grid container>
        <TicketBlock>
          <BackToPrevious destination="dashboard"/>
          <PageHeading>
            Create Ticket
          </PageHeading>
          <div>
            <StyledTabs 
              value={value}
              onChange={handleChange}
              aria-label="styled tabs example"
            >
              <StyledTab
                label={`Create Ticket`}
              />{" "}
                <span className={classes.spantest}>|</span>
              <StyledTab
                label={`FAQ's`}
              />
            </StyledTabs>
          </div>
          <TabPanel value={value} index={0}>
            <TicketForm onSubmit={form.onSubmit}>
              <TicketOrgNameSection>
                <Field
                  {...orgNameField}
                  formSubmitted={form.submitted}
                  label="ORGANIZATION NAME"
                  type="text"
                  name="org_name"
                  id="org_name"
                  placeholder="Organization Name"
                />
              </TicketOrgNameSection>
              <Divider
                style={{
                  border: "1px dashed #d1d1d6",
                  margin: " 40px 20px 40px 20px"
                }}
              />
              <DetailsText>Details of POC</DetailsText>
              {forms.current.map((form, idx) => (
                <SubformComponents
                  addContact={addContact}
                  key={`POC-${idx}`}
                  idx={idx}
                  contactState={form}
                  handleContactChange={handleContactChange}
                  deleteContact={deleteContact}
                  useField={useField}
                  timeout={timeout}
                  errorState={errorState}
                  valid={formValid.every(condition => condition)}
                  updateFormField={updateFormField}
                  updateFormValid={updateFormValid}
                />
              ))}
              <Divider
                style={{
                  border: "1px dashed #d1d1d6",
                  margin: " 40px 20px 40px 20px"
                }}
              />
              <DetailsText>Details of Ticket</DetailsText>
              <TicketSelectSection>
                <Select
                placeholder='Select issue...'
                isSearchable
                value={options.label}
                options={options}
                onChange={(option) => setTicketIssue(option.value) }
                />
              </TicketSelectSection>
              <TicketDescriptionSection>
                <LargeField
                  {...descriptionField}
                  formSubmitted={form.submitted}
                  label="Ticket Description"
                  type="text"
                  name="IORD"
                  id="IORD"
                  placeholder="Please describe your issue or request."
                />
              </TicketDescriptionSection>
              <Divider
                style={{
                  border: "1px dashed #d1d1d6",
                  margin: " 40px 20px 40px 20px"
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "40px"
                }}>
                <CancelButton
                  onClick={() => {
                    props.history.goBack();
                  }}>
                  <Textspan>Cancel</Textspan>
                </CancelButton>
                <CreateButton
                  onClick={form.onSubmit}
                  disabled={!formValid.every(condition => condition)}>
                  Submit
                </CreateButton>
              </div>
            </TicketForm>
          </TabPanel>
          <TabPanel value={value} index={2}>
                <DetailsText>
                  Frequently Asked Questions
                </DetailsText>
                {ticketFAQ.map((item, index) => {
                  return (
                    <Accordion key={index}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>{item.issue}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography className={classes.description}>
                          {item.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  )
                })}
          </TabPanel>
        </TicketBlock>
      </Grid>
    </TicketWrapper>
  );
};
function timeout(delay) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
};

export default Form;
