import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import styled from "styled-components";

export const NotifivationWrapperArea = styled.div`
  padding-right: 10px;
  display: flex;
  width: 233px;
  height: 38px;
  border-radius: 8px;
  border: dashed 0.5px #ff2d21;
  background-color: #fff6f5;
`;
export const DateWrapper = styled.span`
  margin: 10px 0px 1px 10px;
  display: flex;
  width: 130px;
  height: 16px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const AreaSpan = styled.span`
  margin: 10px 0px 0px 10px;
  width: 29px;
  height: 16px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #959190;
`;
export const AreaSpanDel = styled.span`
  display: flex;
  margin: 11px 0px 0px 10px;
  /* width: 29px; */
  height: 16px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #959190;
`;
export const WatchlistName = styled.span`
  display: flex;
  margin: 9px 0px 0px 4px;
  width: 143px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5f65;
`;
export const DeliveredItem = styled.span`
  display: flex;
  margin: 11px 0px 0px 0px;
  /* width: 30px; */
  height: 18px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5f65;
`;
export const WatchlistRecipientName = styled.span`
  display: flex;
  margin: 9px 0px 0px 29px;
  width: 143px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #5f5f65;
`;
export const FilterWrapper = styled.div`
  margin: 1px 2px 25px 0px;
  display: flex;
  justify-content: space-between;
`;
export const FilterWrapperSections = styled.div`
  display: flex;
`;
export const SafteyAlert = styled.div`
  margin-right: 20px;
  display: flex;
  cursor: pointer;
  pointer-events: ${props => (props.isFilterDisable ? "none" : "visible")};
  display: flex;
  width: 132px;
  height: 38px;
  border-radius: 8px;
  background-color: #ff2d21;
`;
export const Safe = styled.div`
  margin-right: 15px;
  display: flex;
  width: 87px;
  height: 38px;
  border-radius: 8px;
  background-color: #e8fbeb;
`;
export const SafeText = styled.span`
  margin: 10px 0px 0px 10px;
  width: 30px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #33b549;
`;
export const NoResponse = styled.div`
  margin-right: 15px;
  display: flex;
  width: 143px;
  height: 38px;
  border-radius: 8px;
  background-color: #fff2df;
`;
export const NoResponseText = styled.span`
  margin: 10px 0px 0px 10px;
  width: 86px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff7500;
`;
export const Distress = styled.div`
  margin-right: 15px;
  display: flex;
  width: 111px;
  height: 38px;
  border-radius: 8px;
  background-color: #ffeae9;
`;
export const DistressText = styled.span`
  margin: 10px 0px 0px 10px;
  width: 54px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff3b30;
`;
export const SentAtWrapper = styled.div`
  display: flex;
`;

export const StatusWrapper = styled.div`
  display: flex;
  width: 133px;
  height: 38px;
  opacity: 0.8;
  border-radius: 8px;

  background-color: ${props => {
    const backgroundColorMap = {
      responded: "#fff2df",
      safe: "#e8fbeb",
      distress: "#ffeae9",
      old: "#fffae7"
    };
    return (
      (props.isOld
        ? backgroundColorMap.old
        : backgroundColorMap[props.status]) || "#eeeef6"
    );
  }};
`;
export const StatusWrapperText = styled.span`
  justify-content: center;
  margin: 10px 0px 0px 20px;
  display: flex;
  align-items: center;
  width: 86px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => {
    const colorMap = {
      responded: "#ff7500",
      safe: "#33b549",
      distress: "#ff3b30",
      old: "#eeb706"
    };
    return (props.isOld ? colorMap.old : colorMap[props.status]) || "#7e7e84";
  }};
`;

// commented codes added by athira for edge browser fixes
export const OvalContent = styled.span`
  text-align: center;
  /* margin: 6px 0px 0px 9px; */
  display: flex;
  /* width: 16px;
  height: 16px; */
  font-family: Google Sans;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #717179;
  /* @supports (-ms-ime-align: auto) {
    margin: 7px 0px 0px 10px;
  } */
`;
export const OvalWrapper = styled.span`
  margin: 5px;
  border-radius: 30px;
  width: 28px;
  height: 28px;
  background-color: #ffffff;
  /* edge browser fix  */
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SafteyAlertText = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 10px;
  width: auto;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;
export const CreateNotificationWrapper = styled.div`
  width: 30%;
  display: flex;
`;
export const Deliverd = styled.span`
  display: flex;
  /* width: 322px; */
  height: 38px;
  border-radius: 8px;
  border: dashed 0.5px #ff2d21;
  background-color: #fff6f5;
  padding-right: 8px;
`;
export const ViewMapText = styled.span`
  display: flex;
  margin: 9px 0px 0px 25px;
  width: 82px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;
export const ViewMap = styled.div`
  margin-left: 20px;
  width: 116px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background-color: #60bca7;
  cursor: pointer;
`;
export const ImageImg = styled.img`
  margin: 0px 0px 0px 12px;
  @supports (-ms-ime-align: auto) {
    width: 15px;
  }
`;
export const RecipientWrapper = styled.div`
  margin: 0px 0px 0px 25px;
  display: flex;
  width: 128px;
  height: 38px;
  object-fit: contain;
  border-radius: 8px;
  border: dashed 0.5px #ff2d21;
  background-color: #fff6f5;
`;
export const SentAt = styled.span`
  margin: 10px 1px 0px 16px;
  width: 60px;
  height: 16px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const TimeData = styled.div`
  display: flex;
  width: 178px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(71, 59, 59, 0.2);
  background-color: #ffffff;
`;
export const HistoryWrapper = styled.section`
  display: flex !important;
  margin: 11px 0 70px 0 !important;
`;
export const HistoryTopWrapper = styled.section`
  display: flex !important;
  justify-content: space-between;
`;
export const HistoryTopWrapperFilter = styled.section`
  margin-bottom: 25px;
  display: flex !important;
`;
export const CreateNotificationTopWrapperFilter = styled.section`
  margin-bottom: 15px;
  display: flex !important;
  justify-content: space-between;
`;
export const HistoryHeading = styled.div`
  margin-bottom: 15px;
  min-height: 38px;
  font-family: Google Sans;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const HistoryTableTabWrapper = styled.section`
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;
export const HistorySelectwrapper = styled.div`
  display: flex;
  width: 25%;
`;
export const SearchWrapper = styled.div`
  width: 410px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(71, 59, 59, 0.2);
  background-color: #ffffff;
`;

export const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #eff1f4"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 40,
      width: "68px",
      height: "7px",
      borderRadius: "100px",
      backgroundImage: "linear-gradient(to bottom, #f14b4b, #ff0e00)"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "Google Sans",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  padding: {
    padding: theme.spacing(3)
  },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: "#ffffff"
  },
  spantest: {
    margin: " 15px 0px 0px 0px",
    color: "#5a5d60"
  },
  dateTimePicker: {
    margin: " 1px 0px 0px 19px !important",
    width: "182px",
    height: " 39px",
    borderRadius: "8px",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    border: "solid 1px rgba(71, 59, 59, 0.2) !important",
    backgroundColor: " #ffffff",
    "& $div": {
      textDecoration: "none",
      "& $input": {
        margin: "10px",
        width: "100%",
        height: "16px",
        fontFamily: "Google Sans",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#999999"
      }
    }
  },
  adornment: {
    padding: "0px"
  },
  inputClass: {
    width: "144px",
    margin: "0px 16px 3px 0px"
  }
}));

export function TabPanel(props) {
  const { children, value, index, tabId, selectedTab, ...other } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={tabId !== selectedTab}
      display={tabId !== selectedTab ? "none" : "block"}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      <Box p={3}>{children}</Box>
    </Typography>
  );
}
