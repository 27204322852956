import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Divider from "@material-ui/core/Divider";
import {
  WatchlistComponentsWrapper,
  WatchlistHeaderContent,
  WatchlistHeaderWrapper,
  HeaderLabel,
  ImageStyle,
  WatchlistTimePicker,
  WatchlistAddButton,
  WatchlistSliderButton,
  WatchlistAddButtonText,
  WatchlistContent,
  WatchlistAddButtonLocationCancel,
  WatchlistAddButtonLocation,
  WatchlistCancelButtonText
} from "./watchlist.style";
import DateTimePicker from "../DateTimePicker";
import Slider from "../SliderPoperWatchList";
import AddIcon from "../../assets/img/addicon.png";
import ArrowLeft from "../../assets/img/arrowLeft.png";

const useStyles = makeStyles(theme => ({
  root: {
    width: "calc(100% - 338px)"
  },
  widthFull: {
    width: "calc(100% - 38px) !important"
  },
  input: {
    fontSize: "11px !important"
  },
  datePickerMargin: {
    margin: "12px 4px 7px 10px",
    padding: " 0px",
    fontSize: "12px !important"
  },
  adornment: {
    // padding: "0px"
  },
  errorMessage: {
    color: "#ff2d21",
    fontSize: "12px",
    textAlign: "center",
    width: "100%",
    paddingTop: "5px"
  }
}));
function Watchlist({
  Title,
  imgSrc,
  call,
  children,
  id,
  dataSlider,
  errorMsg,
  deleteWatchlist,
  plotted,
  handleButton,
  handleLocationButton,
  addLocation,
  handleLocationButtonCancel,
  handleClickOpenWatchList,
  handleDateChangeFrom,
  handleDateChangeTo,
  selectedDateFrom,
  selectedDateTo,
  handleNotification,
  handleDateSearch,
  searchFieldRef,
  handleClickOpenConfirm,
  handleClickOpenMassText,
  handleClickOpenMassPush,
  isDisable,
  handelSelectItem,
  organizations,
  handleOrgChange,
  organizationsList,
  userList,
  handleSearch,
  selectedIndex,
  setSelectedIndex,
  isLoading
}) {
  const [open, setOpen] = useState(true);
  const classes = useStyles();
  const [org, setOrg] = useState([]);

  useEffect(() => {
    if (organizationsList && organizationsList.length > 0) {
      setOrg(organizationsList);
    }
  }, [organizationsList]);

  return (
    <WatchlistComponentsWrapper>
      <div className={`${classes.root} ${!open ? classes.widthFull : ""}`}>
        <WatchlistHeaderWrapper isOpen={open}>
          <WatchlistHeaderContent>
            <div style={{ display: "flex" }}>
              <ImageStyle>
                {imgSrc ? (
                  <img
                    style={{
                      width: "19px",
                      height: "18px",
                      marginRight: "12px",
                      marginTop: "10px"
                    }}
                    src={imgSrc}
                  />
                ) : (
                  ""
                )}
              </ImageStyle>
              <HeaderLabel>{Title}</HeaderLabel>
            </div>
            <div style={{ display: "flex" }}>
              {!addLocation ? (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ display: "flex" }}>
                    <WatchlistTimePicker className="time-picker">
                      <DateTimePicker
                        border="0px"
                        adornment={classes.adornment}
                        classes={classes.datePickerMargin}
                        inputClasses={classes.input}
                        selectedDate={selectedDateFrom}
                        handleDateChange={handleDateChangeFrom}
                        disableFuture
                      />
                    </WatchlistTimePicker>
                    <div
                      style={{
                        margin: "11px 8px 0px -9px",
                        fontSize: "12px"
                      }}>
                      to
                    </div>
                    <WatchlistTimePicker className="time-picker">
                      <DateTimePicker
                        border="0px"
                        classes={classes.datePickerMargin}
                        inputClasses={classes.input}
                        selectedDate={selectedDateTo}
                        handleDateChange={handleDateChangeTo}
                        disableFuture
                      />
                    </WatchlistTimePicker>
                  </div>
                  {errorMsg && (
                    <div className={classes.errorMessage}>{errorMsg}</div>
                  )}
                </div>
              ) : (
                ""
              )}
              {id === "Location" ? (
                !addLocation ? (
                  <WatchlistAddButton
                    id={id}
                    onClick={() => {
                      handleLocationButton();
                      setOpen(false);
                    }}>
                    <WatchlistAddButtonText>
                      <span className={classes.addButtonText}>
                        {`Add ${id}`}
                      </span>
                      <img className="img" src={AddIcon} />
                    </WatchlistAddButtonText>
                  </WatchlistAddButton>
                ) : (
                  <>
                    <WatchlistAddButtonLocation
                      style={{
                        pointerEvents: isDisable ? "none" : "visible"
                      }}
                      id={id}
                      onClick={() => {
                        handleClickOpenWatchList(id);
                      }}>
                      <WatchlistAddButtonText>
                        Add to watchlist
                        <img
                          style={{
                            width: " 11px",
                            margin: "4px 3px 4px 7px"
                          }}
                          src={AddIcon}
                        />
                      </WatchlistAddButtonText>
                    </WatchlistAddButtonLocation>
                    <WatchlistAddButtonLocationCancel
                      onClick={() => {
                        handleLocationButtonCancel();
                        setOpen(!open);
                      }}>
                      <WatchlistCancelButtonText>
                        cancel
                      </WatchlistCancelButtonText>
                    </WatchlistAddButtonLocationCancel>
                  </>
                )
              ) : (
                <WatchlistAddButton
                  id={id}
                  onClick={() => {
                    handleClickOpenWatchList(id);
                  }}>
                  <WatchlistAddButtonText>
                    <span className={classes.addButtonText}>{`Add ${id}`}</span>
                    <img className="img" src={AddIcon} />
                  </WatchlistAddButtonText>
                </WatchlistAddButton>
              )}
            </div>
          </WatchlistHeaderContent>
          <Divider
            style={{
              marginTop: "20px",
              marginBottom: "20px"
            }}
          />
          <WatchlistContent>{children}</WatchlistContent>
        </WatchlistHeaderWrapper>
      </div>
      <div style={{ display: "flex" }}>
        <div style={{ display: "flex" }}>
          {id === "Location" && !open && !addLocation && (
            <WatchlistSliderButton
              onClick={() => {
                setOpen(!open);
              }}>
              <img className="img" src={ArrowLeft} alt="arrow-left" />
            </WatchlistSliderButton>
          )}
          {(id === "User" ||
            id === "Wifi" ||
            id === "Bluetooth" ||
            id === "Cellular Network") &&
          !open ? (
            <WatchlistSliderButton
              onClick={() => {
                setOpen(!open);
              }}>
              <img className="img" src={ArrowLeft} alt="arrow-left" />
            </WatchlistSliderButton>
          ) : (
            ""
          )}
        </div>
        {id === "Location" && open && !addLocation && (
          <Slider
            id={id}
            setOpen={setOpen}
            open={open}
            isLoading={isLoading}
            dataSlider={dataSlider}
            deleteWatchlist={deleteWatchlist}
            plotted={plotted}
            handleNotification={handleNotification}
            handleDateSearch={handleDateSearch}
            handleClickOpenConfirm={handleClickOpenConfirm}
            handleClickOpenMassText={handleClickOpenMassText}
            handleClickOpenMassPush={handleClickOpenMassPush}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        )}
        {(id === "Wifi" || id === "Bluetooth" || id === "Cellular Network") &&
        open ? (
          <Slider
            id={id}
            setOpen={setOpen}
            open={open}
            isLoading={isLoading}
            dataSlider={dataSlider}
            deleteWatchlist={deleteWatchlist}
            plotted={plotted}
            handleNotification={handleNotification}
            handleDateSearch={handleSearch}
            handleClickOpenConfirm={handleClickOpenConfirm}
            handleClickOpenMassText={handleClickOpenMassText}
            handleClickOpenMassPush={handleClickOpenMassPush}
            handelSelectItem={handelSelectItem}
            organizations={organizations}
            handleOrgChange={handleOrgChange}
            organizationsList={org}
            userList={userList}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        ) : (
          ""
        )}
        {id === "User" && open ? (
          <Slider
            height="456px"
            id={id}
            setOpen={setOpen}
            open={open}
            isLoading={isLoading}
            dataSlider={dataSlider}
            deleteWatchlist={deleteWatchlist}
            plotted={plotted}
            handleNotification={handleNotification}
            handleDateSearch={handleDateSearch}
            searchFieldRef={searchFieldRef}
            handleClickOpenConfirm={handleClickOpenConfirm}
            handleClickOpenMassText={handleClickOpenMassText}
            handleClickOpenMassPush={handleClickOpenMassPush}
            handelSelectItem={handelSelectItem}
            organizations={organizations}
            handleOrgChange={handleOrgChange}
            organizationsList={org}
            userList={userList}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
        ) : (
          ""
        )}
      </div>
    </WatchlistComponentsWrapper>
  );
}

export default Watchlist;
