import { makeStyles } from "@material-ui/core/styles";
import styled from 'styled-components';
export const useStyles = makeStyles(theme => ({
         root: {
           flexGrow: 1,
           marginTop: "10px",
           marginRight: "10px",
           fontFamily: "Google Sans"
         },
         breadCrumb: {
           width: "120px",
           height: "16px",
           fontFamily: "Google Sans",
           fontSize: " 12px",
           fontHeight: "normal",
           fontStretch: " normal",
           fontStyle: " normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: "#4e4e4e",
           margin: "9px 0px 0px 7px",
           cursor: "pointer"
         },
         breadCrumbContainer: {
           width: "164px",
           display: "flex",
           justifyContent: "center"
         },
         imageBack: {
           margin: "11px 0px 0px 22px"
         },
         Heading: {
           margin: " 13px 0px 0px 23px",
           width: "122px",
           height: "38px",
           fontFamily: "Google Sans",
           fontSize: "30px",
           fontWeight: "normal",
           fontStretch: " normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: "#161a1e,"
         },
         EditUsers: {
           width: "100%",
           borderRadius: "10px",
           boxShadow: " 0 5px 10px 0 rgba(0, 0, 0, 0.06)",
           border: "solid 2px rgba(151, 151, 151, 0.06)",
           backgroundColor: " #ffffff"
         },
         label: {
          //  width: "105px",
           height: "16px",
           fontFamily: " Google Sans",
           fontSize: " 12px",
           fontWeight: " normal",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: " 0.6px",
           color: "#292b2e"
         },
         phnumlabel: {
          paddingLeft: "50px",
         },
         errorElement: {
          color: "#ff2d21",
          // marginTop: "10px",
          fontSize: "12px"
        },
        alignField: {
          marginTop: "15px"
        },
         textBox: {
           width: " 234px",
           height: " 38px",
           borderRadius: "8px",
           backgroundColor: "#f2f7fa"
         },
         divider: {
           border: "dashed 1px rgba(151, 151, 151, 0.06)",
           borderRadius: " 1px",
           boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
           backgroundColor: " #ffffff",
           width: "93%",
           margin: "30px 0px 0px 45px"
         },
         activeTextBox: {
           width: "234px",
           height: "38px",
           borderRadius: " 8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: " #ffffff"
         },
         cancelButton: {
           marginRight: "25px",
           width: "146px",
           height: "38px",
           borderRadius: " 8px",
           backgroundColor: "#4e4e54",
           display: "flex",
           cursor: "pointer",
           margin: "0px 25px 0px 0px"
         },
         cancelText: {
           justifyContent: "center",
           alignContent: "center",
           alignSelf: "center",
           margin: "0px 0px 0px 45px",
           width: "47px",
           height: "18px",
           fontFamily: " Google Sans",
           fontSize: "14px",
           fontWeight: "bold",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: "#ffffff"
         },
         updateButton: {
           width: "146px",
           height: "38px",
           borderRadius: " 8px",
           backgroundColor: "#ff2d21",
           display: "flex",
           cursor: "pointer"
         },
         valueText: {
           width: "178px",
           height: " 16px",
           fontFamily: "Google Sans",
           fontSize: "12px",
           fontWeight: "normal",
           fontStretch: " normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: "#161a1e",
           margin: "12px 0px 0px 22px"
         },
         selectFormat:{
             width: "234px",
  height: "38px",
  borderRadius: "8px",
  boxShadow:" 0 2px 5px 0 rgba(0, 0, 0, 0.04)",
  border:" solid 1px rgba(71, 59, 59, 0.2)",
  backgroundColor: "#ffffff",
         }
       }));

export const PhoneNumber = styled.div`
.react-phone-number-input__country-select{
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}
.react-phone-number-input__country-select-arrow{
  display: block;
   content: '';
   width: 0;
   height: 0;
   margin-bottom: 0.1em;
   margin-top: 0.3em;
   margin-left: 0.3em;
   border-width: 0.35em 0.2em 0 0.2em;
   border-style: solid;
   border-left-color: transparent;
   border-right-color: transparent;
   color: #B8BDC4;
   opacity: 0.7;
   transition: color 0.1s;
}
.react-phone-number-input__country{
  display: flex;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  margin-bottom: 0.4em;
}
.react-phone-number-input__row{
  display: flex;
}
.react-phone-number-input {
  input {
    border-bottom: 1px solid #c5d2e0;
    width: 234px;
    border: solid 1px rgba(71, 59, 59, 0.2);
    height: 38px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    padding-left: 12px;
  }
  input::-ms-clear {
    width: 0;
    height: 0;
  }
}
.react-phone-number-input__icon {
  width: 32px;
  height: 22px;
  border: none;
}
 .MuiFormHelperText-root.Mui-error{
   width: 300px;
 }
  `;

