import React from 'react';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { IconButton, InputAdornment } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import calanderIcon from "../../assets/img/group-11.png";
import TimePickerStyled from './TimePicker.style';
import TextField from '../TextField';

export const TimePicker = (props) => (
  <TimePickerStyled
    type="time"
    {...props}
    id={props.id}
    value={props.selectedTime}
    ampm={false}
    hour12Timer = {false}
    onChange={e => {
      props.onTimeChange(e);
    }}
    onClose={props.onClose}
    InputProps={{
      disableUnderline: true,
      classes: {
        input: props.inputClasses
      }
    }}
  />
    
)

TimePicker.defaultProps = {
    classes: {}
  };
  
TimePicker.propTypes = {
  classes: propTypes.object,
};
  