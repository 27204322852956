import React from "react"
import {
  BasePanel,
  ClientReview,
  ClientReviewHeading,
  ClientReviewContent,
  OnlineReviewContent,
  ViewMoreContent
} from "./basepanel.style"

export default function index(props) {
  let { status, onOpen } = props
  return (
    <BasePanel>
      {status && (
        <>
          <ClientReview>
            <ClientReviewHeading>CLIENT</ClientReviewHeading>
            <ClientReviewContent>
              {status.n_orgs && status.n_orgs}
            </ClientReviewContent>
          </ClientReview>
          <ClientReview>
            <ClientReviewHeading>TOTAL USERS</ClientReviewHeading>
            <ClientReviewContent>
              {status.n_users && status.n_users}
            </ClientReviewContent>
          </ClientReview>
          <OnlineReviewContent>
            <ClientReview>
              <ClientReviewHeading>
                ONLINE USERS
                <ViewMoreContent onClick={onOpen}>VIEW MORE</ViewMoreContent>
              </ClientReviewHeading>
              <ClientReviewContent>
                {status.n_online_users && status.n_online_users}
              </ClientReviewContent>
            </ClientReview>
          </OnlineReviewContent>
          <ClientReview>
            <ClientReviewHeading>OFFLINE USERS</ClientReviewHeading>
            <ClientReviewContent>
              {status.n_offline_users && status.n_offline_users}
            </ClientReviewContent>
          </ClientReview>
        </>
      )}
    </BasePanel>
  )
}
