import React from "react";
import { Provider } from "react-redux";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import { createGlobalStyle } from "styled-components";
import AppRouter from "./routes/AppRouter";
import { UserProvider } from "./context/UserContext";
import { LayoutProvider } from "./context/LayoutContext";
import GoogleSansBold from "./assets/fonts/GoogleSans-Regular.ttf";
import { ErrorBoundry } from "./components";
import theme from "./theme";
import store from "./store";
import "./App.css";

const GlobalStyle = createGlobalStyle`
body {
    overflow: hidden;
    margin: 0;
    padding: 0;
    font-family:"Google Sans"
}`;
function App() {
  return (
    <div>
      <StylesProvider injectFirst>
        <GlobalStyle />
        {/* <ErrorBoundry> */}
        <LayoutProvider>
          <Provider store={store}>
            <UserProvider>
              <ThemeProvider theme={theme.default}>
                <AppRouter />
              </ThemeProvider>
            </UserProvider>
          </Provider>
        </LayoutProvider>
        {/* </ErrorBoundry> */}
      </StylesProvider>
    </div>
  );
}

export default App;
