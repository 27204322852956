import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import TextField from "@material-ui/core/TextField";
import { TextFieldWrapper} from "../../styles/Wrapper.style";

export default function ConfirmationAlert(props) {
                const [reason, setReason] = React.useState("");
                 return (
                   <div>
                     <Dialog
                       open={open}
                       onClose={props.handleClose}>
                       <DialogTitle id="alert-dialog-title">
                         {props.dialogueTitle}
                       </DialogTitle>
                       <DialogContent>
                         <TextFieldWrapper>
                          <TextField
                            id="reason"
                            error={props.hasError}
                            helperText={props.hasError ? "Please enter your reason": ''}
                            inputProps={{
                              maxlength: "500"
                            }}
                            value={reason}
                            onChange={e => {
                              setReason(e.target.value);
                            }}
                            margin="normal"
                            placeholder="enter your reason"
                            type="text"
                            fullWidth
                          />
                         </TextFieldWrapper>
                       </DialogContent>
                       <DialogActions>
                         <Button onClick={props.handleClose} color="primary">
                            {props.cancelLabel}
                         </Button>
                         <Button
                           onClick={()=>props.handleContinueClick(reason)}
                           color="primary"
                           autoFocus>
                           {props.continueLabel}
                         </Button>
                       </DialogActions>
                     </Dialog>
                   </div>
                 );
               }
