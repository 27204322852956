import React from "react";
import moment from "moment";
import { TableWrapper as Table } from "./table.styled";

function TableComponent(props) {
  const { tableData, id } = props;

  return (
    <Table>
      <div>
        <table
          className="table"
          style={{
            marginBottom: "0",
            position: "sticky",
            top: "0",
            zIndex: "1",
            borderRadius: "8px",
            overflow: "hidden"
          }}>
          <th className="row header green">
            <td className="cellHead">DATE & TIME</td>
            <td className="cellHead">ACTIVITY</td>
          </th>
        </table>
        <table className="table">
          <tbody>
            {id === "Wifi" ||
            id === "Cellular Network" ||
            id === "Bluetooth" ? (
              tableData && tableData.length > 0 ? (
                tableData.map((item, index) => {
                  return (
                    <tr className="row" key={index}>
                      <td className="cell" data-title="Product">
                        {moment
                          .utc(item.timestamp)
                          .local()
                          .format("DD MMM YYYY HH:mm:ss")}
                      </td>
                      <td className="cell" data-title="Unit Price">
                        {`${item.description}`}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}>{`No user available`}</tr>
              )
            ) : tableData && tableData.length > 0 ? (
              tableData.map((item, index) => {
                return (
                  <tr className="row" key={index}>
                    <td className="cell" data-title="Product">
                      {moment
                        .utc(item.timestamp)
                        .local()
                        .format("DD MMM YYYY HH:mm:ss")}
                    </td>
                    <td className="cell" data-title="Unit Price">
                      {item.description}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}>{`No content available`}</tr>
            )}
            {}
          </tbody>
        </table>
      </div>
    </Table>
  );
}

export default TableComponent;
