import refreshIcon from "../../../assets/img/refresh.png";
import React from "react";

export function CustomMapControlsTypeView(
  controlDiv,
  map,
  getPoints,
  markers,
  handleHeatMap,
  mapState,
  dataSet
) {
  // Adding heat map layer
  let heatmap = new window.google.maps.visualization.HeatmapLayer();
  // Creating divs & styles for custom ui
  controlDiv.style.padding = "15px";
  // Set CSS for the control wrapper
  let controlWrapper = document.createElement("div");
  controlWrapper.style.width = "322px";
  controlWrapper.style.borderRadius = "10px";
  controlWrapper.style.boxShadow =
    "0 2px 5px 0 rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.15)";
  controlWrapper.style.borderWidth = "1px";
  controlWrapper.style.cursor = "pointer";
  controlWrapper.style.border = "1px #eaeaea";
  controlWrapper.style.backgroundColor = "#ffffff";
  controlWrapper.style.height = "40px";
  controlWrapper.style.display = "flex";
  controlDiv.appendChild(controlWrapper);

  // Set CSS for the Satellite View
  let satelliteViewButton = document.createElement("div");
  satelliteViewButton.style.width = "98px";
  satelliteViewButton.style.height = "30px";
  satelliteViewButton.style.fontWeight = "bold";
  satelliteViewButton.style.fontStretch = "normal";
  satelliteViewButton.style.fontStyle = "normal";
  satelliteViewButton.style.borderRadius = "7px";
  satelliteViewButton.style.margin = "5px 2px 2px 8px";
  let textSpanSatellite = document.createElement("span");
  textSpanSatellite.style.width = "73px";
  textSpanSatellite.style.height = "16px";
  textSpanSatellite.style.fontFamily = "Google Sans";
  textSpanSatellite.style.fontSize = "12px";
  textSpanSatellite.style.fontWeight = "normal";
  textSpanSatellite.style.fontStretch = "normal";
  textSpanSatellite.style.fontStyle = "normal";
  textSpanSatellite.style.lineHeight = "normal";
  textSpanSatellite.style.color = "#161a1e";
  textSpanSatellite.style.textAlign = "center";
  textSpanSatellite.style.display = "flex";

  textSpanSatellite.style.margin = "8px 0px 6px 16px";
  let textSatellite = document.createTextNode("Satellite View");
  textSpanSatellite.appendChild(textSatellite);
  satelliteViewButton.appendChild(textSpanSatellite);
  controlWrapper.appendChild(satelliteViewButton);

  // Set CSS for the zoomOut
  let streetViewButton = document.createElement("div");
  streetViewButton.style.width = "98px";
  streetViewButton.style.height = "30px";
  streetViewButton.style.fontWeight = "bold";
  streetViewButton.style.fontStretch = "normal";
  streetViewButton.style.fontStyle = "normal";
  streetViewButton.style.borderRadius = "7px";
  streetViewButton.style.margin = "5px 2px 2px 3px";
  streetViewButton.style.backgroundColor = "#ff2d21";
  let streetViewTextSpan = document.createElement("span");
  streetViewTextSpan.style.width = "68px";
  streetViewTextSpan.style.height = "16px";
  streetViewTextSpan.style.fontFamily = "Google Sans";
  streetViewTextSpan.style.fontSize = "12px";
  streetViewTextSpan.style.fontWeight = "normal";
  streetViewTextSpan.style.fontStretch = "normal";
  streetViewTextSpan.style.fontStyle = "normal";
  streetViewTextSpan.style.lineHeight = "normal";
  streetViewTextSpan.style.color = "#ffffff";
  streetViewTextSpan.style.textAlign = "center";
  streetViewTextSpan.style.display = "flex";
  streetViewTextSpan.style.margin = "8px 0px 6px 16px";

  let streetViewText = document.createTextNode("Street View");
  streetViewTextSpan.appendChild(streetViewText);
  streetViewButton.appendChild(streetViewTextSpan);
  controlWrapper.appendChild(streetViewButton);
  // Set CSS for the zoomOut
  let heatMapViewButton = document.createElement("div");
  heatMapViewButton.style.width = "98px";
  heatMapViewButton.style.height = "30px";
  heatMapViewButton.style.fontWeight = "bold";
  heatMapViewButton.style.fontStretch = "normal";
  heatMapViewButton.style.fontStyle = "normal";
  heatMapViewButton.style.borderRadius = "7px";
  heatMapViewButton.style.margin = "5px 2px 2px 3px";
  // heatMapViewButton.style.backgroundColor = "#ff2d21";
  let textSpanHeatMap = document.createElement("span");
  textSpanHeatMap.style.width = "74px";
  textSpanHeatMap.style.height = "16px";
  textSpanHeatMap.style.fontFamily = "Google Sans";
  textSpanHeatMap.style.fontSize = "12px";
  textSpanHeatMap.style.fontWeight = "normal";
  textSpanHeatMap.style.fontStretch = "normal";
  textSpanHeatMap.style.fontStyle = "normal";
  textSpanHeatMap.style.lineHeight = "normal";
  textSpanHeatMap.style.color = "#161a1e";
  textSpanHeatMap.style.textAlign = "center";
  textSpanHeatMap.style.display = "flex";
  textSpanHeatMap.style.margin = "8px 0px 6px 16px";

  let textHeatMap = document.createTextNode("Heat Map");
  textSpanHeatMap.appendChild(textHeatMap);
  heatMapViewButton.appendChild(textSpanHeatMap);
  controlWrapper.appendChild(heatMapViewButton);
  // Setup the click event listener - zoomIn
  window.google.maps.event.addDomListener(
    satelliteViewButton,
    "click",
    function() {
      handleHeatMap(false);
      satelliteViewButton.style.backgroundColor = "#ff2d21";
      satelliteViewButton.style.opacity = "3.7";
      textSpanSatellite.style.color = "#ffffff";
      textSpanSatellite.style.opacity = "3.7";
      heatMapViewButton.style.backgroundColor = "white";
      textSpanHeatMap.style.color = "#161a1e";
      streetViewButton.style.backgroundColor = "white";
      streetViewTextSpan.style.color = "#161a1e";

      heatmap.setMap(null);
      map.setMapTypeId(google.maps.MapTypeId.SATELLITE);
    }
  );

  // Setup the click event listener - zoomOut
  window.google.maps.event.addDomListener(
    streetViewButton,
    "click",
    function() {
      handleHeatMap(false);
      heatmap.setMap(null);
      heatMapViewButton.style.backgroundColor = "white";
      textSpanHeatMap.style.color = "#161a1e";
      satelliteViewButton.style.backgroundColor = "white";
      textSpanSatellite.style.color = "#161a1e";
      streetViewButton.style.backgroundColor = "#ff2d21";
      streetViewTextSpan.style.color = "#ffffff";
      streetViewTextSpan.style.opacity = "3.7";
      map.setMapTypeId(google.maps.MapTypeId.ROADMAP);
    }
  );
  // Setup the click event listener - zoomOut
  window.google.maps.event.addDomListener(
    heatMapViewButton,
    "click",
    function() {
      handleHeatMap(true);
      map.setMapTypeId(google.maps.MapTypeId.TERRAIN);
      heatMapViewButton.style.backgroundColor = "#ff2d21";
      textSpanHeatMap.style.color = "#ffffff";
      textSpanHeatMap.style.opacity = "3.7";
      satelliteViewButton.style.backgroundColor = "white";
      //  satelliteViewButton.style.opacity = "0.7";
      textSpanSatellite.style.color = "#161a1e";
      streetViewButton.style.backgroundColor = "white";
      streetViewTextSpan.style.color = "#161a1e";

      heatmap = new window.google.maps.visualization.HeatmapLayer({
        data: getPoints(dataSet)
      });
      heatmap.set("radius", heatmap.get("radius") ? null : 20);
      heatmap.setMap(map);
    }
  );
}

export function RefreshControl(refreshDiv, map, refreshMap) {
  refreshDiv.style.padding = "15px";
  // Set CSS for the control wrapper
  let controlWrapper = document.createElement("div");
  controlWrapper.setAttribute("class", "gm-zoom-slider");
  controlWrapper.style.width = "38px";
  controlWrapper.style.borderRadius = "10px";
  controlWrapper.style.boxShadow =
    "0 2px 4px 0 rgba(0, 0, 0, 0.05), 0 0 10px 0 rgba(0, 0, 0, 0.2)";
  controlWrapper.style.borderWidth = "1px";
  controlWrapper.style.cursor = "pointer";
  controlWrapper.style.backgroundColor = "#60bca7";
  controlWrapper.style.height = "38px";
  controlWrapper.style.display = "flex";
  let image = document.createElement("img");
  image.src = refreshIcon;
  image.style.width = "44px";
  image.style.padding = "10px 8px 10px 8px";
  controlWrapper.appendChild(image);
  refreshDiv.appendChild(controlWrapper);
  window.google.maps.event.addDomListener(controlWrapper, "click", function() {
    refreshMap();
  });
}

export function BasePanel(basePanelDiv, map, onOpen, status) {
  basePanelDiv.style.padding = "15px 15px 0";
  // Set CSS for the control wrapper
  let controlWrapper = document.createElement("div");
  controlWrapper.style.width = "836px ";
  controlWrapper.style.borderRadius = "10px";
  controlWrapper.style.objectFit = "contain";
  controlWrapper.style.backgroundColor = "rgba(39, 45, 49, 0.9)";
  controlWrapper.style.height = "77px";
  controlWrapper.style.display = "flex";
  controlWrapper.style.border = " solid 1px #414a52";
  // Set CSS for the client View
  let clientViewButton = document.createElement("div");
  clientViewButton.style.width = "209px";
  //  clientViewButton.style.height = "13px";
  clientViewButton.style.fontWeight = "bold";
  clientViewButton.style.fontStretch = "normal";
  clientViewButton.style.fontStyle = "normal";
  clientViewButton.style.borderRadius = "7px";
  clientViewButton.style.margin = "5px 2px 2px 8px";
  let textClientView = document.createElement("span");
  //  textClientView.style.width = "40px";
  //  textClientView.style.height = "16px";
  textClientView.style.fontFamily = "Google Sans";
  textClientView.style.fontSize = "12px";
  textClientView.style.fontWeight = "500";
  textClientView.style.fontStretch = "normal";
  textClientView.style.fontStyle = "normal";
  textClientView.style.lineHeight = "normal";
  textClientView.style.color = "#ffffff";
  textClientView.style.textAlign = "center";
  textClientView.style.display = "flex";
  textClientView.style.opacity = "0.8";
  textClientView.style.margin = "7px 2px 3px 23px";
  let textClient = document.createTextNode("CLIENT");
  textClientView.appendChild(textClient);
  let textClientViewDetails = document.createElement("span");
  //  textClientViewDetails.style.width = "97px";
  //  textClientViewDetails.style.height = "43px";
  textClientViewDetails.style.fontFamily = "Google Sans";
  textClientViewDetails.style.fontSize = "12px";
  textClientViewDetails.style.fontWeight = "500";
  textClientViewDetails.style.fontStretch = "normal";
  textClientViewDetails.style.fontStyle = "normal";
  textClientViewDetails.style.lineHeight = "normal";
  textClientViewDetails.style.color = "#ffffff";
  textClientViewDetails.style.textAlign = "center";
  textClientViewDetails.style.display = "flex";
  textClientViewDetails.style.opacity = "0.8";
  textClientViewDetails.style.marginLeft = "23px";
  textClientViewDetails.style.fontSize = "32px";
  let textClientDetail = document.createTextNode(status.n_orgs);
  textClientViewDetails.appendChild(textClientDetail);

  clientViewButton.appendChild(textClientView);
  clientViewButton.appendChild(textClientViewDetails);

  // Set CSS for the User View
  let totalUserButton = document.createElement("div");
  totalUserButton.style.width = "209px";
  //  totalUserButton.style.height = "13px";
  totalUserButton.style.fontWeight = "bold";
  totalUserButton.style.fontStretch = "normal";
  totalUserButton.style.fontStyle = "normal";
  totalUserButton.style.borderRadius = "7px";
  totalUserButton.style.margin = "5px 2px 2px 8px";
  let textTotalUserView = document.createElement("span");
  //  textTotalUserView.style.width = "100px";
  //  textTotalUserView.style.height = "13px";
  textTotalUserView.style.fontFamily = "Google Sans";
  textTotalUserView.style.fontSize = "12px";
  textTotalUserView.style.fontWeight = "500";
  textTotalUserView.style.fontStretch = "normal";
  textTotalUserView.style.fontStyle = "normal";
  textTotalUserView.style.lineHeight = "normal";
  textTotalUserView.style.color = "#ffffff";
  textTotalUserView.style.textAlign = "center";
  textTotalUserView.style.display = "flex";
  textTotalUserView.style.opacity = "0.8";
  textTotalUserView.style.margin = "7px 2px 3px 23px";
  let textTotalUser = document.createTextNode("TOTAL USERS");
  textTotalUserView.appendChild(textTotalUser);
  let textTotalUserDetails = document.createElement("span");
  //  textTotalUserDetails.style.width = "97px";
  //  textTotalUserDetails.style.height = "43px";
  textTotalUserDetails.style.fontFamily = "Google Sans";
  textTotalUserDetails.style.fontSize = "12px";
  textTotalUserDetails.style.fontWeight = "500";
  textTotalUserDetails.style.fontStretch = "normal";
  textTotalUserDetails.style.fontStyle = "normal";
  textTotalUserDetails.style.lineHeight = "normal";
  textTotalUserDetails.style.color = "#ffffff";
  textTotalUserDetails.style.textAlign = "center";
  textTotalUserDetails.style.display = "flex";
  textTotalUserDetails.style.opacity = "0.8";
  textTotalUserDetails.style.marginLeft = "76px";
  textTotalUserDetails.style.fontSize = "32px";
  textTotalUserDetails.style.marginLeft = "23px";
  let textTotalUserDetailsText = document.createTextNode(status.n_users);
  textTotalUserDetails.appendChild(textTotalUserDetailsText);

  // Set CSS for the online Users View
  let onlinUserButton = document.createElement("div");
  onlinUserButton.style.width = "209px";
  //  onlinUserButton.style.height = "13px";
  onlinUserButton.style.fontWeight = "bold";
  onlinUserButton.style.fontStretch = "normal";
  onlinUserButton.style.fontStyle = "normal";
  onlinUserButton.style.borderRadius = "7px";
  onlinUserButton.style.margin = "5px 2px 2px 8px";
  let textOnlineUserView = document.createElement("span");
  //  textOnlineUserView.style.width = "100px";
  //  textOnlineUserView.style.height = "13px";
  textOnlineUserView.style.fontFamily = "Google Sans";
  textOnlineUserView.style.fontSize = "12px";
  textOnlineUserView.style.fontWeight = "500";
  textOnlineUserView.style.fontStretch = "normal";
  textOnlineUserView.style.fontStyle = "normal";
  textOnlineUserView.style.lineHeight = "normal";
  textOnlineUserView.style.color = "#ffffff";
  textOnlineUserView.style.textAlign = "center";
  textOnlineUserView.style.display = "flex";
  textOnlineUserView.style.opacity = "0.8";
  textOnlineUserView.style.margin = "7px 2px 3px 23px";
  let textOnlineUser = document.createTextNode("ONLINE USERS");
  textOnlineUserView.appendChild(textOnlineUser);
  let textOnlineUserDetails = document.createElement("span");
  //  textOnlineUserDetails.style.width = "100px";
  //  textOnlineUserDetails.style.height = "43px";
  textOnlineUserDetails.style.fontFamily = "Google Sans";
  textOnlineUserDetails.style.fontSize = "12px";
  textOnlineUserDetails.style.fontWeight = "500";
  textOnlineUserDetails.style.fontStretch = "normal";
  textOnlineUserDetails.style.fontStyle = "normal";
  textOnlineUserDetails.style.lineHeight = "normal";
  textOnlineUserDetails.style.color = "#ffffff";
  textOnlineUserDetails.style.textAlign = "center";
  textOnlineUserDetails.style.display = "flex";
  textOnlineUserDetails.style.opacity = "0.8";
  textOnlineUserDetails.style.marginLeft = "76px";
  textOnlineUserDetails.style.fontSize = "32px";
  textOnlineUserDetails.style.marginLeft = "23px";
  let textOnlineUserDetailsText = document.createTextNode(
    status.n_online_users
  );
  textOnlineUserDetails.appendChild(textOnlineUserDetailsText);
  let textOnlineUserViewMore = document.createElement("span");
  textOnlineUserViewMore.style.width = "67.7px";
  textOnlineUserViewMore.style.height = "17px";
  textOnlineUserViewMore.style.fontFamily = "Google Sans";
  textOnlineUserViewMore.style.borderRadius = "100px";
  textOnlineUserViewMore.style.border = "solid 1px #60bca7";
  textOnlineUserViewMore.style.backgroundColor = "#303436";
  textOnlineUserViewMore.style.fontFamily = "Google Sans";
  textOnlineUserViewMore.style.fontSize = "10px";
  textOnlineUserViewMore.style.fontWeight = "500";
  textOnlineUserViewMore.style.fontStretch = "normal";
  textOnlineUserViewMore.style.fontStyle = "normal";
  textOnlineUserViewMore.style.lineHeight = "normal";
  textOnlineUserViewMore.style.color = "#60bca7";
  textOnlineUserViewMore.style.textAlign = "center";
  textOnlineUserViewMore.style.display = "flex";
  textOnlineUserViewMore.style.opacity = "0.8";
  textOnlineUserViewMore.style.margin = "-61px 13px 0px 112px";
  textOnlineUserViewMore.style.justifyContent = "center";
  textOnlineUserViewMore.style.cursor = "pointer";
  let textOnlineUserViewMoreText = document.createElement("span");
  let textOnlineUserViewMoreDetailsText = document.createTextNode("VIEW MORE");
  textOnlineUserViewMoreText.style.margin = "1px 0px 1.5px 0px";
  textOnlineUserViewMoreText.style.fontSize = "10px";
  textOnlineUserViewMoreText.style.textAlign = "center";
  textOnlineUserViewMoreText.appendChild(textOnlineUserViewMoreDetailsText);
  textOnlineUserViewMore.appendChild(textOnlineUserViewMoreText);

  let offlineUserButton = document.createElement("div");
  offlineUserButton.style.width = "209px";
  //  offlineUserButton.style.height = "13px";
  offlineUserButton.style.fontWeight = "bold";
  offlineUserButton.style.fontStretch = "normal";
  offlineUserButton.style.fontStyle = "normal";
  offlineUserButton.style.borderRadius = "7px";
  offlineUserButton.style.margin = "5px 2px 2px 8px";
  let textOfflineUserView = document.createElement("span");
  //  textOfflineUserView.style.width = "100px";
  //  textOfflineUserView.style.height = "13px";
  textOfflineUserView.style.fontFamily = "Google Sans";
  textOfflineUserView.style.fontSize = "12px";
  textOfflineUserView.style.fontWeight = "500";
  textOfflineUserView.style.fontStretch = "normal";
  textOfflineUserView.style.fontStyle = "normal";
  textOfflineUserView.style.lineHeight = "normal";
  textOfflineUserView.style.color = "#ffffff";
  textOfflineUserView.style.textAlign = "center";
  textOfflineUserView.style.display = "flex";
  textOfflineUserView.style.opacity = "0.8";
  textOfflineUserView.style.margin = "7px 2px 3px 23px";
  let textOfflineUser = document.createTextNode("OFFLINE USERS");
  textOfflineUserView.appendChild(textOfflineUser);
  let textOfflineUserDetails = document.createElement("span");
  //  textOfflineUserDetails.style.width = "97px";
  //  textOfflineUserDetails.style.height = "43px";
  textOfflineUserDetails.style.fontFamily = "Google Sans";
  textOfflineUserDetails.style.fontSize = "12px";
  textOfflineUserDetails.style.fontWeight = "500";
  textOfflineUserDetails.style.fontStretch = "normal";
  textOfflineUserDetails.style.fontStyle = "normal";
  textOfflineUserDetails.style.lineHeight = "normal";
  textOfflineUserDetails.style.color = "#ffffff";
  textOfflineUserDetails.style.textAlign = "center";
  textOfflineUserDetails.style.display = "flex";
  textOfflineUserDetails.style.opacity = "0.8";
  textOfflineUserDetails.style.marginLeft = "76px";
  textOfflineUserDetails.style.fontSize = "32px";
  textOfflineUserDetails.style.marginLeft = "23px";
  let textOfflineUserDetailsText = document.createTextNode(
    status.n_offline_users
  );
  textOfflineUserDetails.appendChild(textOfflineUserDetailsText);

  clientViewButton.appendChild(textOfflineUserView);
  clientViewButton.appendChild(textClientViewDetails);

  offlineUserButton.appendChild(textOfflineUserView);
  offlineUserButton.appendChild(textOfflineUserDetails);

  totalUserButton.appendChild(textTotalUserView);
  totalUserButton.appendChild(textTotalUserDetails);

  onlinUserButton.appendChild(textOnlineUserView);
  onlinUserButton.appendChild(textOnlineUserDetails);
  onlinUserButton.appendChild(textOnlineUserViewMore);

  controlWrapper.appendChild(clientViewButton);
  controlWrapper.appendChild(totalUserButton);
  controlWrapper.appendChild(onlinUserButton);
  controlWrapper.appendChild(offlineUserButton);

  basePanelDiv.appendChild(controlWrapper);
  window.google.maps.event.addDomListener(
    textOnlineUserViewMore,
    "click",
    function() {
      onOpen();
    }
  );
}
