import { api } from "../utils/http";

export const initialState = {
  error: "",
  chartData: {},
  timeline: {},
  timelineactivity: [],
  success: "",
  locationTrail: {},
  url: {},
  callLogs: {},
  callLogPage: 0,
  callLogCount: 0,
  isCallLogLoading: false
};

export const reducers = {
  chartDataClear(state) {
    return {
      ...state,
      chartData: {}
    };
  },
  chartData(state, payload) {
    return {
      ...state,
      chartData: payload
    };
  },
  callLogDataClear(state) {
    return {
      ...state,
      callLogs: {},
      callLogPage: 0,
      callLogCount: 0
    };
  },
  callLogData(state, { payload, callLogPage, callLogCount }) {
    return {
      ...state,
      callLogs: payload,
      callLogPage,
      callLogCount
    };
  },
  trailDataClear(state) {
    return {
      ...state,
      locationTrail: {}
    };
  },
  trailSearchData(state, payload) {
    return {
      ...state,
      locationTrail: payload
    };
  },
  timelineDataClear(state) {
    return {
      ...state,
      timeline: []
    };
  },
  timelineData(state, payload) {
    return {
      ...state,
      timeline: payload.timeline
    };
  },
  timelineActivity(state, payload) {
    return {
      ...state,
      timelineactivity: payload.timeline
    };
  },
  addSuccess(state, payload) {
    return {
      ...state,
      success: payload
    };
  },
  removeSuccess(state) {
    return {
      ...state,
      success: ""
    };
  },
  addError(state, payload) {
    return {
      ...state,
      error: payload
    };
  },
  removeError(state) {
    return {
      ...state,
      error: ""
    };
  },
  setCallLogsLoading(state, { status }) {
    return {
      ...state,
      isCallLogLoading: status
    };
  }
};

export const effects = {
  async startChart(payload, state, chart) {
    if (payload.user_id !== undefined && payload.user_id !== "") {
      let URL = `/api/v1/timeline/${chart}?user_id=${payload.user_id}&from_date=${payload.from_date}&to_date=${payload.to_date}`;
      try {
        const res = await api
          .get(URL, payload)
          .then(response => {
            this.chartData(response.data.data);
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async startTimeline(payload, state) {
    this.timelineDataClear();
    if (payload.user_id !== undefined && payload.user_id !== "") {
      let URL = `/api/v1/timeline/?user_id[]=${payload.user_id}&from_date=${payload.from_date}&to_date=${payload.to_date}`;
      try {
        const res = await api
          .get(URL, payload)
          .then(response => {
            this.timelineData(response.data.data);
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async startTimelineActivity(payload, state) {
    if (payload.user_id !== undefined && payload.user_id !== "") {
      let URL = `/api/v1/timeline/?user_id[]=${payload.user_id}&from_date=${payload.from_date}&to_date=${payload.to_date}`;
      try {
        const res = await api
          .get(URL, payload)
          .then(response => {
            this.timelineActivity(response.data.data);
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async startLocationTrail(payload, state) {
    if (payload.user_id !== undefined && payload.user_id !== "") {
      let URL = `api/v1/user/location_trail/?from_datetime=${payload.from_datetime}&to_datetime=${payload.to_datetime}&user_id=${payload.user_id}&from_datetime_sub=${payload.from_datetime_sub}&to_datetime_sub=${payload.to_datetime_sub}&time_step=15`;
      try {
        const res = await api
          .get(URL, payload)
          .then(response => {
            if (window.markers !== undefined && window.markers.length > 0) {
              window.markers.forEach(marker => {
                marker && marker.setMap(null);
              });
            }
            this.trailSearchData(response.data.data);
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async sendTextMessage({
    payload,
    enqueueSnackbar,
    callback,
    callbackPush,
    callbackPayload
  }) {
    let URL = `/api/v1/distress/send_sms/`;
    try {
      const res = await api
        .post(URL, payload)
        .then(response => {
          if (response.status === 200) {
            if (typeof callback === "function") {
              callback(callbackPayload);
              callbackPush(callbackPayload);
            }
            let variant = "success";
            enqueueSnackbar("Text notification sent successfully!", {
              variant
            });
          }
        })
        .catch(err => {
          let variant = "error";
          this.addError(err.toString());
          enqueueSnackbar("Something went wrong!", {
            variant
          });
          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err.toString());
      enqueueSnackbar("Something went wrong!", {
        variant
      });
      this.removeSuccess();
    }
  },
  async forceSync(payload, state, enqueueSnackbar) {
    let URL = `/api/v1/push/force_sync/`;
    try {
      const res = await api
        .post(URL, payload)
        .then(response => {
          if (response.status === 200) {
            window.location.reload();
            let variant = "success";
            enqueueSnackbar("Force sync initiated successfully!", {
              variant
            });
          }
        })
        .catch(err => {
          let variant = "error";
          this.addError(err.toString());
          enqueueSnackbar("Something went wrong!", {
            variant
          });
          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err.toString());
      enqueueSnackbar("Something went wrong!", {
        variant
      });
      this.removeSuccess();
    }
  },
  async sendPushNotification({
    payload,
    enqueueSnackbar,
    callback,
    callbackPush,
    callbackPayload
  }) {
    let URL = `/api/v1/push/areyou_ok/`;
    this.removeSuccess();
    try {
      const res = await api
        .post(URL, payload)
        .then(response => {
          if (response.status === 200) {
            if (typeof callback === "function") {
              callback(callbackPayload);
              callbackPush(callbackPayload);
            }
            let variant = "success";
            enqueueSnackbar("Push notification sent successfully!", {
              variant
            });
          }
        })
        .catch(err => {
          let variant = "error";
          this.addError(err.toString());
          enqueueSnackbar(err.response.data.message, {
            variant
          });
          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err.toString());
      enqueueSnackbar("Something went wrong!", {
        variant
      });
      this.removeSuccess();
    }
  },
  async patchUserDetails({
    payload,
    enqueueSnackbar,
    username,
    getUserInfo,
    callback
  }) {
    try {
      const res = await api
        .patch(`/api/v1/user/app_user/${username}/`, payload)
        .then(response => {
          getUserInfo(username, { detailed: true });
          let variant = "success";
          enqueueSnackbar("Edited successfully", {
            variant
          });
          if (callback) callback();
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
          enqueueSnackbar(err.response.data.message, {
            variant
          });
          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
      enqueueSnackbar(err.response.data.message, {
        variant
      });
      this.removeSuccess();
    }
  },
  async exportToExcel({ payload, enqueueSnackbar, setOpenDialog }) {
    try {
      const res = await api
        .post(`api/v1/user/export/`, payload)
        .then(response => {
          let variant = "success";
          window.open(response.data.data.url);
          enqueueSnackbar(response.data.message, {
            variant
          });
          setOpenDialog(false);
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);

          if (
            err.response.data.message == "Fields: This list may not be empty."
          ) {
            enqueueSnackbar(
              "Please check any of the above for generating the excel",
              {
                variant
              }
            );
          } else {
            enqueueSnackbar(err.response.data.message, {
              variant
            });
          }

          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
      enqueueSnackbar(err.response.data.message, {
        variant
      });
      this.removeSuccess();
    }
  },
  async addToWatchlist(payload, state, enqueueSnackbar) {
    try {
      const res = await api
        .post(`/api/v1/watchlist/user/`, payload)
        .then(response => {
          let variant = "success";
          enqueueSnackbar("Added user to watchlist successfully!", {
            variant
          });
          window.location.reload();
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
          enqueueSnackbar(err.response.data.message, {
            variant
          });
          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
      enqueueSnackbar(err.response.data.message, {
        variant
      });
      this.removeSuccess();
    }
  },
  async removefromWatchlist({ payload, enqueueSnackbar, userInfo }) {
    try {
      const res = await api
        .post(`/api/v1/watchlist/remove_user/`, payload)
        .then(response => {
          let variant = "success";
          userInfo(payload.user_id, true);
          enqueueSnackbar("Removed user from watchlist successfully!", {
            variant
          });
          window.location.reload();
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
          enqueueSnackbar(err.response.data.message, {
            variant
          });
          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
      enqueueSnackbar(err.response.data.message, {
        variant
      });
      this.removeSuccess();
    }
  },
  async getCallLog({ user_id, page = 0, limit = 5 }) {
    if (user_id !== undefined && user_id !== "") {
      let payloadData = {
        user_id,
        pagination: 1,
        limit,
        offset: page * limit
      };
      this.setCallLogsLoading({ status: true });
      let URL = `api/v1/device/call_log/`;
      try {
        const res = await api
          .get(URL, { params: payloadData })
          .then(response => {
            this.callLogData({
              payload: response.data.data.call_logs,
              callLogPage: page,
              callLogCount: response.data.data.page.count
            });
          })
          .catch(err => {
            this.addError(err.toString());
          })
          .finally(() => this.setCallLogsLoading({ status: false }));
      } catch (err) {
        this.addError(err.toString());
      }
    }
  }
};
export default {
  state: initialState,
  reducers,
  effects
};
