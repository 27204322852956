import styled from "styled-components";
export const Wrapper = styled.div`
body{
    overflow:"hidden"
    font-family:"Google Sans"
}
* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -ms-box-sizing: border-box;
    -o-box-sizing: border-box;
    box-sizing: border-box !important;
}
.global-width {
    width: 100%;
    max-width: 2000px;
    padding-left: 20px;
    padding-right 20px;
    margin: 0 auto;
}

@media only screen and (min-width: 768px) {
    .container{
        padding-left:20px;
    }
}
@media only screen and (max-width: 768px) {
          .header {
            position: absolute;
            width: 100%;
            padding-top:15px;
            }
            .install-app {
                color: #ffff !important;
                cursor: pointer;
                background-color: #d02525 !important;
                margin: 3px 0px 0 0 !important;
                padding: 3px 18px !important;
                max-height: 32px;
             }
          }
        }
 .header {
    @media only screen and  (min-width: 767px) {
        width: 1352px;
  height: 66px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 1px rgba(151, 151, 151, 0.08);
  background-color: #ffffff;
  .group {
     width: 155px;
    height: 61px;
    object-fit: contain;
}

.admin-Copy {
    margin-top: 21px;
  width: 42px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #161a1e;
}
.rectangle {
  width: 15.5px;
  height: 18.3px;
  border-radius: 100px;
  border: solid 1.5px #161a1e;
  .Group {
  width: 16px;
  height: 23px;
  object-fit: contain;
}
}
.badge-text{
  width: 8px;
  height: 16px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.oval {
  width: 14px;
  height: 14px;
  border: solid 3px #ffffff;
  background-color: #ff2d21;
}
    .logo {
        color: #d02525;
        text-decoration: none;
        font-family: LatinWide;
        font-size: 15px;
        font-weight: 700;
        .logo-image {
            margin: 5px 10px 1px 4px !important;
        }
    }
        }
    .global-width {
        display: flex;
        justify-content: space-between;
    }
   
    .logo {
        color: #d02525;
        text-decoration: none;
        font-family: LatinWide;
        font-size: 15px;
        font-weight: 700;
        .logo-image {
            @media only screen and  (max-width: 767px) {
                display: block;
                padding-bottom: 10px;
                margin: 7px 10px -6px 0px !important;
            }
            margin: 16px 10px 0px 0px !important;
        }
    }
    .install-app {
    color: #d02525;
    cursor: pointer;
    background-color: #ffffff;
    line-height: normal;
    margin: 14px 5% 0 0;
    padding: 6px 25px;
    font-weight: 300;
    border: 2px solid;
    border-radius: 8px;
    font-size: 14px;
    max-height: 35px;
    font-family: Montserrat-SemiBold;
    }
 }
 .content {
     padding: 0 20px;
     overflow-x: hidden;
    .paragraph-conditions {
        font-family:Google Sans;
        line-height:29px
        font-size: 18px;
        margin: 14px 3px 14px 0px;
        @media only screen and  (max-width: 767px) {
             text-align:center;
             font-size: 14px;
            }
    }
    .margin-top-terms{
        @media only screen and  (max-width: 767px) {
            margin-top: 65px;
            }
    }
    .term-link{
        text-align: center;
        justify-content: center;
      }
    .paragraph {
        font-family: Montserrat-Regular;
        line-height:29px
        color: #797a7c;
        font-size: 18px;
        margin: 14px 3px 14px 0px;
        @media only screen and  (max-width: 767px) {
             text-align:center;
             font-size: 14px;
            }
    }
    &.home-page {
        .global-width {
            display: flex;
            flex-wrap: wrap;
        }
        
        margin: 0px -8px 2px 0px;
        padding: 37px 5px 6px 0px;
        background-size: 96% auto;

        @media only screen and (min-width: 768px) {
          
            background-size: 49% auto !important
        }
    }
    &.terms-page {
        .global-width {
            display: flex;
            flex-wrap: wrap;
        }
        margin: 0px -8px 2px 0px;
        padding: 37px 5px 6px 0px;
        background-size: 96% auto;

        @media only screen and (min-width: 768px) {
            background-size: 49% auto !important
        }
    }
    .left-section {
        order: 2;
        width: 100%;
        .main-heading {
            @media only screen and  (max-width: 767px) {
                font-size: 25px;
                text-align:center
             
            }
            margin-bottom: 15px;
            font-family: Montserrat-ExtraBold;
            font-size: 50px;
            font-weight: 700;
        }
        .store-wrap {
            display : none !important;
            .store-link {
                cursor: pointer;
                .app-store{
                    margin: 0px 40px 0px 0px;  
                }
                .play-store{
                    
                }
            }
            @media only screen and (min-width: 768px) {
            order: 2;
        }
        }
        @media only screen and (min-width: 768px) {
            .store-wrap {
                    display: block !important
                }
            width: 50%;
            order: 1;
        }
    
    }
    .right-section {
        text-align: center;
        order: 1;
        width: 100%;
        .mobile-banner {
            width: 92%;
            margin: 36px 0px 0px 0px;
        }
        .store-wrap-right {
            margin: -13px 8px -1px 8px;
            display:block !important;
            justify-content:center;
            .store-link {
                cursor: pointer;
                .app-store{
                    width: 83px;
                    width:118px;
                    padding: 4px;
                }
                .play-store{
                    width:95px;
                    width:133px;
                    padding: 4px;
                }
                &:focus {
                    
                }
            }
        }
        @media only screen and (min-width: 768px) {
            width:50%;
            .store-wrap-right {
                display: none !important;
            }
        }
    }
 }
 .footer {
    color: #797a7c;
    font-weight:400
    font-family: Montserrat-Regular;
    font-size: 10px;
    display: flex;
    text-align: center;
    .sitename {
        font-family: Montserrat-Bold;
        font-weight: 700;
        color: #797a7c;
    }
 }
 .tableButton {
     padding: 0 12px;
 }
`;

export const TextFieldWrapper = styled.div`
  input::-ms-clear {
    width: 0;
    height: 0;
  }
`;