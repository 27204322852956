import React, { useState, useRef, useEffect } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { Grid, Typography, InputBase, Divider } from "@material-ui/core";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";

import backIcon from "../../assets/img/backicon.png";
import { useStyles, PhoneNumber } from "./EditUser.style";

function EditUsers(props) {
  const { enqueueSnackbar } = useSnackbar();
  const [userInfo, setUserInfo] = useState(props.location.state.userInfo);
  const [userInfoCancel, setUserCancel] = useState(false);
  const [disable, setDisable] = useState(true);
  const [countryCode, setCountryCode] = useState("");
  const phoneRef = useRef(null);
  const [isValid, setIsValid] = useState({
    phNum: true,
    email: true,
    password: true
  });

  const handleToggle = () => {
    setDisable(prev => !prev);
  };

  const [inputValues, setInputValues] = useState({
    username: userInfo.user_info.username,
    password: userInfo.user_info.password,
    email: userInfo.user_info.email,
    organization_id: userInfo.user_info.organization_id,
    phone_number: userInfo.user_info.phone_number
  });

  useEffect(() => {
    setUserCancel(false);
    setIsValid({
      ...isValid,
      phNum: true
    });
  }, [inputValues.phone_number]);

  useEffect(() => {
    setUserCancel(false);
    setIsValid({
      ...isValid,
      email: true
    });
  }, [inputValues.email]);

  useEffect(() => {
    setUserCancel(false);
    setIsValid({
      ...isValid,
      password: true
    });
  }, [inputValues.password]);

  useEffect(() => {
    if (userInfoCancel) {
      setIsValid({
        phNum: true,
        email: true,
        password: true
      });
    }
  }, [userInfoCancel]);

  useEffect(() => {
    setUserInfo(props.location.state.userInfo);
  }, [props.location.state.userInfo]);

  const handleUpdate = () => {
    const { email, password, username } = inputValues;
    const phone_number = inputValues.phone_number;
    const emailRegex = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/; // To check email validity
    const isvalidPhNum = phone_number
      ? isValidPhoneNumber(phone_number)
      : false;
    const isvalidEmail = email ? emailRegex.test(email) : false;
    const isvalidPassword =
      password === null ? true : /^[^-\s]{8,20}$/.test(password);
    setIsValid({
      email: isvalidEmail,
      phNum: isvalidPhNum,
      password: isvalidPassword
    });
    if (isvalidPhNum && isvalidEmail && isvalidPassword) {
      if (password === "" || password === null) {
        let payload = {
          username,
          phone_number,
          email
        };
        props.patchUserDetails({
          payload,
          enqueueSnackbar,
          username,
          getUserInfo: props.getUserInfo,
          callback: () => props.history.goBack()
        });
      } else {
        let payload = {
          username,
          phone_number,
          email,
          password
        };
        props.patchUserDetails({
          payload,
          enqueueSnackbar,
          username,
          getUserInfo: props.getUserInfo,
          callback: () => props.history.goBack()
        });
      }
    }
  };

  const handleOnChange = event => {
    const { name, value } = event.target;
    // setIsValid({
    //   ...isValid,
    //   email: name === "email",
    //   password: name === "password"
    // });
    setInputValues({ ...inputValues, [name]: value });
  };

  const countryChange = value1 => {
    setCountryCode(value1);
  };

  const handleCancelClick = () => {
    setInputValues({
      username: userInfo.user_info.username,
      password: userInfo.user_info.password,
      email: userInfo.user_info.email,
      organization_id: userInfo.user_info.organization_id,
      phone_number: userInfo.user_info.phone_number
    });
    setUserCancel(true);
  };

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <section
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%"
          }}>
          <div
            aria-label="breadcrumb"
            className={classes.breadCrumbContainer}
            onClick={() => {
              props.history.goBack();
            }}>
            <span>
              <img className={classes.imageBack} src={backIcon} />
            </span>
            <Typography color="textPrimary" className={classes.breadCrumb}>
              Back to Previous Page
            </Typography>
          </div>
        </section>
        <section
          style={{
            display: "flex"
          }}
          className={classes.Heading}>
          Edit User
        </section>
        <section
          style={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            margin: " 25px 0px 0px 22px"
          }}>
          <div className={classes.EditUsers}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "60px 0px 0px 3px"
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px"
                }}>
                <span className={classes.label}>USER NAME</span>
                <div className={classes.textBox}>
                  <InputBase
                    disabled
                    className={classes.valueText}
                    value={
                      inputValues && inputValues.username
                        ? inputValues.username
                        : ""
                    }
                  />
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span className={classes.label}>ORGANIZATION ID</span>
                <div className={classes.textBox}>
                  <InputBase
                    disabled
                    className={classes.valueText}
                    value={
                      inputValues && inputValues.organization_id
                        ? inputValues.organization_id
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
            <Divider className={classes.divider} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "40px 0px 0px 3px"
              }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px"
                }}
                className={!isValid.phNum ? classes.alignField : ""}>
                <span className={`${classes.label} ${classes.phnumlabel}`}>
                  PHONE NUMBER
                </span>
                <PhoneNumber>
                  <PhoneInput
                    id="for-phno"
                    placeholder=""
                    ref={phoneRef}
                    value={
                      inputValues && inputValues.phone_number
                        ? inputValues.phone_number
                        : ""
                    }
                    onCountryChange={countryChange}
                    onChange={number => {
                      setInputValues({
                        ...inputValues,
                        phone_number: number
                      });
                      setIsValid({
                        ...isValid,
                        phNum: true
                      });
                    }}
                  />
                </PhoneNumber>
                {!isValid.phNum && (
                  <div className={classes.errorElement}>
                    {" "}
                    Please enter a valid phone number
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px"
                }}
                className={!isValid.email ? classes.alignField : ""}>
                <span className={classes.label}>EMAIL ID</span>
                <div className={classes.activeTextBox}>
                  <InputBase
                    name="email"
                    className={classes.valueText}
                    onChange={handleOnChange}
                    value={
                      inputValues && inputValues.email ? inputValues.email : ""
                    }
                  />
                </div>
                {!isValid.email && (
                  <div className={classes.errorElement}>
                    {" "}
                    Please enter a valid email
                  </div>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: "20px"
                }}
                className={!isValid.password ? classes.alignField : ""}>
                <span className={classes.label}>PASSWORD</span>
                <div className={classes.activeTextBox}>
                  <InputBase
                    disabled={disable}
                    name="password"
                    type="password"
                    placeholder="●●●●●●●●●●●"
                    className={classes.valueText}
                    value={
                      inputValues && inputValues.password
                        ? inputValues.password
                        : ""
                    }
                    onChange={handleOnChange}
                    endAdornment={
                      <div
                        style={{
                          cursor: "pointer",
                          width: "44px",
                          height: "16px",
                          fontFamily: "Google Sans",
                          fontSize: "12px",
                          fontWeight: "500",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          color: " #ff2d21"
                        }}
                        onClick={handleToggle}>
                        Change
                      </div>
                    }
                  />
                </div>
                {!isValid.password && (
                  <div className={classes.errorElement}>
                    {" "}
                    Password must contain atleast 8 charecters. No space
                    charecter allowed
                  </div>
                )}
              </div>
            </div>
            <Divider className={classes.divider} />
            <div
              style={{
                display: "flex",
                marginRight: "12px",
                marginTop: "60px",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "40px"
              }}>
              <div className={classes.cancelButton} onClick={handleCancelClick}>
                <span className={classes.cancelText}>Reset</span>
              </div>
              <div className={classes.updateButton} onClick={handleUpdate}>
                <span className={classes.cancelText}>Update</span>
              </div>
            </div>
          </div>
        </section>
      </Grid>
    </div>
  );
}

const mapStateToProps = state => {
  return { userProfileState: state.UserProfile };
};

const mapDispatchToProps = dispatch => {
  return {
    patchUserDetails: dispatch.UserProfile.patchUserDetails,
    getUserInfo: dispatch.Dashboard.getUserInfo
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditUsers);
