import React, { useState, useEffect, useRef } from "react";
import { TimelineStyle } from "./Timeline.style";
import IconButton from "@material-ui/core/IconButton";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import moment from "moment";
let count = 0;
const Timeline = props => {
  const [translateCount, setTranslateCount] = useState(props.data.length);
  const [currentIndex, setCurrentIndex] = useState(1);
  const [actualWidth, setActualWidth] = useState(0);
  const timelineRef = useRef(0);
  let styles = {
    width: "1410px",
    transform: `translate(${translateCount}px)`,
    margin: "0 auto",
    transition: " transform 0.45s ease-out 0s"
  };

  // useEffect(() => {
  //   styles = {
  //     width: "1410px",
  //     transform: `translate(${translateCount}px)`,
  //     margin: "0 auto",
  //     transition: "transform 0.45s ease-out 0s"
  //   };
  // }, [translateCount]);

  //  useEffect(() => {
  //    setActualWidth(timelineRef.current.clientWidth-6);
  //    setTranslateCount(
  //      -((235 * props.data.length - (timelineRef.current.clientWidth - 6))+(235/2))
  //    );
  //   //  setTranslateCount((props.data.length -1) * ((timelineRef.current.clientWidth-6)/235))
  //    setCurrentIndex(props.data.length);
  //  }, [props.data, timelineRef.current && timelineRef.current.clientWidth]);

  // const Next=()=>{
  //   if (currentIndex!==props.data.length) {
  //   (actualWidth / 235) * props.data.length;
  //   if (count) {
  //     setTranslateCount(translateCount - 235);
  //     count -= 1;
  //   }
  //    setCurrentIndex(currentIndex + 1);
  // }
  // }
  //  const Prev = () => {
  //   if (currentIndex!==0) {
  //    if (props.data.length > 1 + count + actualWidth / 235) {
  //      setTranslateCount(translateCount + 235);
  //      count += 1;
  //    }
  //    setCurrentIndex(currentIndex - 1);
  //  };
  // }

  useEffect(() => {
    if (window.matchMedia("(min-width:1467px)")) {
      count = 6;
    } else if ("(min-width:1412px) and (max-width: 1446px)") {
      count = 5;
    } else if ("(min-width:1175px) and (max-width: 1411px)") {
      count = 4;
    }
  }, []);

  useEffect(() => {
    //  setActualWidth(timelineRef.current.clientWidth-6);
    //  setTranslateCount(
    //    -((235 * props.data.length - (timelineRef.current.clientWidth - 6))+(235/2))
    //  );
    //  setTranslateCount((props.data.length -1) * ((timelineRef.current.clientWidth-6)/235))
    setTranslateCount(-((props.data.length - count) * 235));
    setCurrentIndex(props.data.length);
  }, [props.data, timelineRef.current && timelineRef.current.clientWidth]);

  const Next = () => {
    //   if (currentIndex!==props.data.length) {
    //   (actualWidth / 235) * props.data.length;
    //   if (count) {
    //     setTranslateCount(translateCount - 235);
    //     count -= 1;
    //   }
    //    setCurrentIndex(currentIndex + 1);
    // }

    if (currentIndex < props.data.length) {
      setTranslateCount(translateCount - 235);
      setCurrentIndex(currentIndex + 1);
    }
  };
  const Prev = () => {
    // if (currentIndex!==0) {
    //  if (props.data.length > 1 + count + actualWidth / 235) {
    //    setTranslateCount(translateCount + 235);
    //    count += 1;
    //  }
    //  setCurrentIndex(currentIndex - 1);
    if (currentIndex > count) {
      setTranslateCount(translateCount + 235);
      setCurrentIndex(currentIndex - 1);
    }
  };
  return (
    <TimelineStyle>
      <div style={{ display: "flex" }} ref={timelineRef} className="ref">
        {props.data !== undefined && props.data.length > 0 ? (
          <div
            className="sliderContainer"
            // style={{ width: "1420px", margin: "0 auto", display: "flex" }}
          >
            <div
              style={{
                margin: "166px 2px 9px 1px",
                cursor: currentIndex > count ? "pointer" : "not-allowed"
              }}
              aria-label="close"
              onClick={Prev}>
              <ArrowLeft
                color={currentIndex > count ? "inherit" : "disabled"}
              />
            </div>
            <div
              className="sliderWrapper"
              // style={{
              //   display: "flex",
              //   width: "1410px",
              //   overflow: "hidden",
              //   paddingTop: "120px",
              //   position: "relative",
              //   margin: "0 auto"
              // }}
            >
              <ol style={styles}>
                {/* <li></li> */}
                {props.data.map((item, index) => {
                  return (
                    <li key={index}>
                      <span className="point"></span>
                      <span className="circle-up"></span>
                      <span className="circle"></span>

                      <span className="timestamp">
                        <span className="timeSpanText">
                          {moment(item.timestamp).format(
                            "DD-MM-YYYY, HH:mm:ss"
                          )}
                        </span>
                      </span>
                      <span className="description">{item.description}</span>
                    </li>
                  );
                })}
                {/* <li></li> */}
                {/* <li>
                  <span className="point"></span>
                  <span className="circle-up"></span>
                  <span className="circle"></span>

                  <span className="timestamp">
                    <span className="timeSpanText">dsjkl.hdgfjsb</span>
                  </span>
                  <span className="description">hafscdgsv</span>
                </li>
                <li>
                  <span className="point"></span>
                  <span className="circle-up"></span>
                  <span className="circle"></span>

                  <span className="timestamp">
                    <span className="timeSpanText">dsjkl.hdgfjsb</span>
                  </span>
                  <span className="description">hafscdgsv</span>
                </li> */}
              </ol>
            </div>
            <div
              aria-label="close"
              style={{
                margin: "166px 2px 9px 1px",
                cursor:
                  currentIndex === props.data.length ? "not-allowed" : "pointer"
              }}
              onClick={Next}>
              <ArrowRight
                color={
                  currentIndex === props.data.length ? "disabled" : "inherit"
                }
              />
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "125px"
            }}>
            No events available
          </div>
        )}
      </div>
    </TimelineStyle>
  );
};
export default Timeline;
