import React, { useState, useRef, useEffect } from "react";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import { MenuList, MenuItem } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { useSnackbar } from "notistack";
import moment from "moment";

import {
  StatusWrapper,
  StatusWrapperText
} from "../../pages/MassNotification/massnotification.details.style";
import AddToWatch from "../AddToWatchList/Generic";
import Safe from "./SafeContent";
import Distress from "./DistressContent";
import store from "../../store";

function index(props) {
  const { enqueueSnackbar } = useSnackbar();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [openModelDistress, setOpenModelDistress] = useState(false);
  const [Reasonpayload, setReasonPayload] = useState("");

  const handleCloseModal = () => {
    setOpenModel(false);
    setReasonPayload("");
  };

  const handleCloseModalDistress = () => {
    setOpenModelDistress(false);
    setReasonPayload("");
  };

  const handleNotification = () => {
    const payload = {
      mass_notification_user_id: [props.item.id.toString()],
      status: "safe",
      notes: Reasonpayload
    };
    store.dispatch.MassNotification.patchNotificationUserStatus({
      id: props.dt.id,
      payload,
      enqueueSnackbar,
      handleCloseModal,
      setPage: props.setPage
    });
  };

  const handleNotificationDistress = () => {
    const payload = {
      mass_notification_user_id: [props.item.id.toString()],
      status: "distress",
      notes: Reasonpayload
    };
    store.dispatch.MassNotification.patchNotificationUserStatus({
      id: props.dt.id,
      payload,
      enqueueSnackbar,
      handleCloseModal: handleCloseModalDistress,
      setPage: props.setPage
    });
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = useRef(open);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const isOld =
    props.item.status === "safe"
      ? moment().isSameOrAfter(
          moment(props.item.updated_on)
            .add(12, "hours")
            .local(),
          "minute"
        )
      : false;

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  return (
    <div>
      <StatusWrapper
        status={props.item.status}
        isOld={isOld}
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}>
        <StatusWrapperText status={props.item.status} isOld={isOld}>
          {props.item.status}
          <KeyboardArrowDownIcon style={{ marginLeft: "auto" }} />
        </StatusWrapperText>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          style={{ zIndex: "200" }}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom"
              }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem
                      style={{
                        color: "#33b549"
                      }}
                      onClick={() => {
                        setOpenModel(true);
                        if (
                          anchorRef.current &&
                          anchorRef.current.contains(event.target)
                        ) {
                          return;
                        }
                        setOpen(false);
                      }}>
                      Safe
                    </MenuItem>
                    <MenuItem
                      style={{
                        color: "#ff3b30"
                      }}
                      onClick={() => {
                        setOpenModelDistress(true);
                        if (
                          anchorRef.current &&
                          anchorRef.current.contains(event.target)
                        ) {
                          return;
                        }
                        setOpen(false);
                      }}>
                      Distress
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </StatusWrapper>
      {openModel && (
        <AddToWatch
          open={openModel}
          handleClose={handleCloseModal}
          handleCloseMassText={handleCloseModal}
          handleAddWatchlist={handleNotification}
          buttonName="save">
          <Safe setReasonPayload={setReasonPayload} />
        </AddToWatch>
      )}
      {openModelDistress && (
        <AddToWatch
          open={openModelDistress}
          handleClose={handleCloseModalDistress}
          handleAddWatchlist={handleNotificationDistress}
          buttonName="save">
          <Distress setReasonPayload={setReasonPayload} />
        </AddToWatch>
      )}
    </div>
  );
}

export default index;
