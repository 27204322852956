import React, { useState, useEffect } from "react";
import moment from "moment";
import { DatePicker } from "../DatePicker";
import { TimePicker } from "../TimePicker";
import {
  DatePickerWrapper,
  TimePickerWrapper,
  DateTimeWraper
} from "./DateTimePicker.style";

export default function DateTimePicker(props) {
  const [chosenDate, setChosenDate] = useState(
    props.selectedDate
      ? moment(props.selectedDate, "MM DD YYYY").format("MM DD YYYY")
      : ""
  );
  const [chosenTime, setChosenTime] = useState(
    props.selectedDate ? moment(props.selectedDate).format("HH:mm") : ""
  );
  const onDateChange = e => {
    setChosenDate(e);
    if (chosenTime) {
      const date = moment(new Date(e.toISOString())).format("MM DD YYYY");
      const dateTime = date + " " + chosenTime;
      const m = moment(dateTime, "MM DD YYYY HH:mm").utc();
      props.handleDateChange(m);
    } else {
      props.handleDateChange(e);
    }
  };
  useEffect(() => {
    if (props.selectedDate) {
      setChosenDate(
        moment(new Date(props.selectedDate), "MM DD YYYY").format("MM DD YYYY")
      );
      setChosenTime(moment(new Date(props.selectedDate)).format("HH:mm"));
    }
  }, [props.selectedDate]);
  const onTimeChange = e => {
    if (event.keyCode !== 38 || event.keyCode !== 40) {
      // if(isObject(chosenDate) && isEmpty(chosenDate)) {
      //   // const date = moment(chosenDate, 'MM DD YYYY').format('MM DD YYYY');
      //   const dateTime= moment(new Date(), 'MM DD YYYY').format('MM DD YYYY') + ' ' + e.target.value;
      //   // const m = moment(`${date} ${e.target.value}`).utc();
      //   const m = moment(dateTime, 'MM DD YYYY HH:mm').utc();
      //   props.handleDateChange(m);
      // } else {
      if (chosenDate) {
        const date = moment(chosenDate, "MM DD YYYY").format("MM DD YYYY");
        const dateTime = date + " " + e.target.value;
        // const m = moment(`${date} ${e.target.value}`).utc();
        const m = moment(dateTime, "MM DD YYYY HH:mm").utc();
        props.handleDateChange(m);
      } else {
        props.handleDateChange(null);
      }
      setChosenTime(e.target.value);
    }
  };
  const onClose = () => {
    setChosenTime("00:00");
  };
  return (
    <DateTimeWraper>
      <DatePickerWrapper>
        <DatePicker {...props} onDateChange={e => onDateChange(e)} />
      </DatePickerWrapper>
      <TimePickerWrapper>
        <TimePicker
          {...props}
          selectedTime={chosenTime}
          onTimeChange={e => onTimeChange(e)}
          onClose={onClose}
        />
      </TimePickerWrapper>
    </DateTimeWraper>
  );
}
