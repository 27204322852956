import styled from "styled-components";
export const WatchlistWrapper = styled.section`
         display: flex !important;
         margin: 11px 0 70px 0 !important;
         .sticky {
           width:100%
           position: sticky;
           border-radius: 8px;
    overflow: hidden;
           top: 0;
           background-color: rgb(250, 250, 250);
           padding-top: 20px;
               z-index: 999;
                   padding-left: 5px;
    padding-right: 5px;

    }
        .watchlist-content{
    
}
}
         }
       `;
export const WatchlistHeading = styled.div`
  margin-bottom: 15px;
  width: 127px;
  height: 38px;
  font-family: Google Sans;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const WatchlistStcikyBarWrapper = styled.section`
 display:flex
 flex: 0 0 auto;
 flex-direction:row
 width: 100%;
 height: 66px;
 border-radius: 10px;
 box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
 border: solid 2px rgba(151, 151, 151, 0.06);
 background-color: #ffffff;
                          `;
export const StcikyBarContentDiv = styled.div`
margin:25px auto
display:flex
  width: 100%;
    height: ${props => (props.height ? props.height : "548px")};
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.17);
  background-color: #ffffff;
`;

