import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Select from "./components/Select";
import FormControl from "@material-ui/core/FormControl";
import CSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { SliderComponentOrganizationSelect, BlueToothAutoSuggestWrapper, TextFieldWrapper } from "./watchlist.style";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import getBluetoothList from "../../models/Watchlist.model";
import AutoSuggest from "../../components/AutoSuggest";
import icon from "../../assets/img/search2x.png";
import store from "../../store";
function AddtoWatchListLocation(props) {
 const [age, setAge] = React.useState("");
 const [Value, setValue] = React.useState(props.DeviceWatchlistName);
 const suggestionsFetchDelay = React.useRef(null);
  const handleChange = event => {
    setAge(event.target.value);
  };
  const handleValue = val => {
    setValue(val);
      props.setDeviceWatchlistName(val)
  };
  const onKeyDown = event => {
      event.preventDefault();
      event.stopPropagation();
      let text = event.target.value;
      setValue(event.target.value);
      //ONCE API IS COMMENCED
      if(event.target.value.length > 0){
        if (suggestionsFetchDelay.current) {
          clearTimeout(suggestionsFetchDelay.current);
        }
        suggestionsFetchDelay.current = setTimeout(() => {
          store.dispatch.Watchlist.getBluetoothList(text);
        }, 1000);
      }
  };
  const onSelect = option => {
    handleValue(option);
  };
  if (props.comp === "Location") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}>
        <div>What would you like to name this watchlist polygon?</div>
        <TextFieldWrapper>
          <TextField
            id="reason"
            InputProps={{
              disableUnderline: true
            }}
            // //   value={reason}
            onChange={e => {
              e.target.value = e.target.value.substring(0, 15);
              props.setReasonPayload(e.target.value);
            }}
            margin="normal"
            placeholder="enter name for polygon/circle watchlist"
            type="text"
            fullWidth
          />
        </TextFieldWrapper>
      </div>
    );
  }

  if (props.comp === "User") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column"
        }}>
        <div
          style={{
            justifyContent: "center",
            display: "flex"
          }}>
          Select user to add to watchlist.
        </div>
        <SliderComponentOrganizationSelect>
          <Select
            data={props.organizationsList}
            valueProp={props.organization}
            onChange={props.handleOrgChange}
            label="Organizations"
            id={"User"}
            multiple={false}
          />
        </SliderComponentOrganizationSelect>
        <SliderComponentOrganizationSelect>
          <Select
            data={props.userList}
            valueProp={props.user}
            onChange={props.handleUserChange}
            label="Users"
            id={"User"}
            multiple={false}
          />
        </SliderComponentOrganizationSelect>
        <TextFieldWrapper>
          <TextField
            id="reason"
            // InputProps={{
            //   disableUnderline: true
            // }}
            value={props.reason}
            onChange={e => {
              e.target.value = e.target.value.substring(0, 500);
              props.setReasonPayload(e.target.value);
            }}
            margin="normal"
            placeholder="enter the reason for adding to watchlist."
            type="text"
            fullWidth
          />
        </TextFieldWrapper>
      </div>
    );
    
  }
   if (props.comp === "Wifi") {
return (
  <div
    style={{
      display: "flex",
      flexDirection: "column"
    }}>
    <div>WiFi network to add to watchlist ?</div>
    <TextFieldWrapper>
      <TextField
        id="reason"
        // InputProps={{
        //   disableUnderline: true
        // }}
        value={props.DeviceWatchlistName}
        onChange={e => {
          e.target.value = e.target.value.substring(0, 500);
          props.setDeviceWatchlistName(e.target.value);
        }}
        margin="normal"
        placeholder="enter name of wifi for watchlist"
        type="text"
        fullWidth
      />
    </TextFieldWrapper>
    <TextFieldWrapper>
      <TextField
        id="reason"
        // InputProps={{
        //   disableUnderline: true
        // }}
        value={props.reason}
        onChange={e => {
          e.target.value = e.target.value.substring(0, 500);
          props.setReasonPayload(e.target.value);
        }}
        margin="normal"
        placeholder="enter reason for adding to watchlist"
        type="text"
        fullWidth
      />
    </TextFieldWrapper>
  </div>
);

   }
   if (props.comp === "Bluetooth") {
     return (
       < BlueToothAutoSuggestWrapper>
         <div>Bluetooth Device to add to watchlist ?</div>
         {/* <TextField
           id="reason"
           // InputProps={{
           //   disableUnderline: true
           // }}
           value={props.DeviceWatchlistName}
           onChange={e => {
             e.target.value = e.target.value.substring(0, 500);
             props.setDeviceWatchlistName(e.target.value);
           }}
           margin="normal"
           placeholder="enter name of bluetooth device"
           type="text"
           fullWidth
         /> */}
         <AutoSuggest
          value={props.DeviceWatchlistName}
          options={{devices: props.suggestions}}
          onOptionSelect={onSelect}
          loading={true}
          textFieldProps={{
            setValue: handleValue,
            error: false,
            // classes,
            placeHolder: "enter name of bluetooth device",
            width: "fullwidth",
            type: "Text",
            id: "search",
            margin: "normal",
            variant: "outlined",
            icon,
            onKeyDown
          }}
        />
         <TextField
           id="reason"
           // InputProps={{
           //   disableUnderline: true
           // }}
           value={props.reason}
           onChange={e => {
             e.target.value = e.target.value.substring(0, 500);
             props.setReasonPayload(e.target.value);
           }}
           margin="normal"
           placeholder="enter reason for adding to watchlist"
           type="text"
           fullWidth
         />
       </BlueToothAutoSuggestWrapper>
     );
   }
     if (props.comp === "Cellular Network") {
       return (
         <div
           style={{
             display: "flex",
             flexDirection: "column"
           }}>
           <div>Enter Cellular Network to add to watchlist ?</div>
           <TextFieldWrapper>
            <TextField
              id="reason"
              // InputProps={{
              //   disableUnderline: true
              // }}
              value={props.DeviceWatchlistName}
              onChange={e => {
                e.target.value = e.target.value.substring(0, 500);
                props.setDeviceWatchlistName(e.target.value);
              }}
              margin="normal"
              placeholder="enter cellular network id"
              type="text"
              fullWidth
            />
          </TextFieldWrapper>
          <TextFieldWrapper>
            <TextField
              id="reason"
              // InputProps={{
              //   disableUnderline: true
              // }}
              value={props.reason}
              onChange={e => {
                e.target.value = e.target.value.substring(0, 500);
                props.setReasonPayload(e.target.value);
              }}
              margin="normal"
              placeholder="enter reason for adding to watchlist"
              type="text"
              fullWidth
            />
            </TextFieldWrapper>
         </div>
       );
     }
  return <div></div>;
}

export default AddtoWatchListLocation;
