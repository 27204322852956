import styled from 'styled-components';

export const DateTimeWraper = styled.div`
  display: flex;
`;

export const DatePickerWrapper = styled.div`
  margin-right:15px;
  div {
    textDecoration: none;
  }
`;
export const TimePickerWrapper = styled.div`
  div {
    textDecoration: none;
  }
`;