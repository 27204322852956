import styled from "styled-components";
export const SliderComponentsWrapper = styled.div`
         width: 322px;
         height: 585px;
         box-shadow: -8px 0 10px 0 rgba(0, 0, 0, 0.09);
         background-color: #ffffff;
         margin: -19px -8px 0px 5px;
         .container {
           display: flex;
           flex-direction: column;
         }
         .flex {
           display: flex;
           margin: 17px 3px 27px 2px;
         }
       `;
export const SliderComponentOrganizationSelect = styled.div`
         margin: 0px 18px 0px 61px;
         display: flex;
         height: 38px;
         border-radius: 8px;
         box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
         border: solid 1px rgba(71, 59, 59, 0.2);
         background-color: #ffffff;
       `;

export const SliderComponentSearch = styled.div`
         width: 224px;
         height: 38px;
         border-radius: 8px;
         box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
         border: solid 1px rgba(71, 59, 59, 0.2);
         background-color: #ffffff;
       `;
export const SliderComponentButtonClose = styled.div`
         margin-right: 20px;
         width: 38px;
         height: 38px;
         border-radius: 10px;
         box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.05),
           0 0 10px 0 rgba(0, 0, 0, 0.2);
         background-color: #60bca7;
         cursor: pointer;
         .img {
           width: 11px;
           height: 14px;
           margin: 12px 0px 0px 12px;
         }
       `;

export const onlineOval = styled.span`
  width: 8px;
  height: 11px;
  backgroundcolor: #4cd964;
  padding: 0px 0px 0px 10px;
  borderradius: 30px;
  margin: 9px;
`;

export const offlineOval = styled.span`
  width: 8px;
  height: 11px;
  backgroundcolor: rgb(255, 45, 33);
  padding: 0px 0px 0px 10px;
  borderradius: 30px;
  margin: 9px;
`;

