

import React from "react";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { useStyles } from "./addtowatchlist.style";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function AddToWatchDialogSlide({
                 open,
                 handleClose,
                 children,
                 textTitle,
                 handleAddWatchlist,
                 buttonName,
                 comp
               }) {
                 const classes = useStyles();
                 const [reason, setReason] = React.useState("");

                 return (
                   <div>
                     <Dialog
                       open={open}
                       TransitionComponent={Transition}
                       keepMounted
                       className={classes.modalOverflow}
                       onClose={handleClose}
                       aria-labelledby="alert-dialog-slide-title"
                       aria-describedby="alert-dialog-slide-description">
                       <DialogTitle id="alert-dialog-slide-title">
                         {textTitle}
                       </DialogTitle>
                       <DialogContent>{children}</DialogContent>
                       <DialogActions>
                         <div
                           style={{
                             display: "flex",
                             marginRight: "0px",
                             marginTop: "0px",
                             justifyContent: "center",
                             alignItems: "center",
                             marginBottom: "16px"
                           }}>
                           <div
                             className={classes.cancelButton}
                             onClick={handleClose}>
                             <span className={classes.cancelText}>Cancel</span>
                           </div>
                           <div
                             className={classes.updateButton}
                             onClick={() => {
                               handleAddWatchlist(comp);
                             }}>
                             <span className={classes.cancelText}>
                               {buttonName}
                             </span>
                           </div>
                         </div>
                       </DialogActions>
                     </Dialog>
                   </div>
                 );
               }
