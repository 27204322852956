import React, { useEffect } from "react";

//utilities and helper functions
import { api } from "../utils/http";
import { getCookie, setCookie, deleteCookie } from '../utils/CookieHelper'

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();
function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true, error: false };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "LOGIN_FAILURE":
      return { ...state, error: true };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!getCookie('token'),
    error: false
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

async function loginUser(dispatch, login, password, history, setIsLoading) {
  setIsLoading(true);
  if (!!login && !!password) {
    try {
      const response = await api.post("/web-token-auth/", {
        username: login,
        password
      });

      setCookie('token', response.data.data.token);
      setCookie('username', JSON.parse(response.config.data).username);
      setCookie('roles', response.data.data.roles);
      setCookie('userOrgID', response.data.data.user_org_id);

      setTimeout(() => {
        dispatch({ type: "LOGIN_SUCCESS" });
        setIsLoading(false);
        history.push("/dashboard");
      }, 2000);
    } catch (e) {
      dispatch({ type: "LOGIN_FAILURE" });
      setIsLoading(false);
    }
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setIsLoading(false);
  }
}

function signOut(dispatch, history) {
    //remove cookies
    deleteCookie('token')
    deleteCookie('username')
    deleteCookie('roles')
    deleteCookie('userOrgID')

    dispatch({ type: "SIGN_OUT_SUCCESS" });
    history.push("/login");
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };
