import { api } from "../utils/http";

export const initialState = {
  error: "",
  timeLineData: [],
  page: [],
  safteyData: [],
  pageSaftey: [],
  loading: false
};
export const reducers = {
  setLoader(state, payload) {
    return {
      ...state,
      loading: payload
    };
  },
  getHistoryListClear(state, payload) {
    return {
      ...state,
      timeLineData: []
    };
  },
  getHistoryListClearPage(state, payload) {
    return {
      ...state,
      page: []
    };
  },
  getHistoryListSafteyClear(state, payload) {
    return {
      ...state,
      safteyData: [],
      pageSaftey: []
    };
  },
  getHistoryListWacthlistClear(state, payload) {
    return {
      ...state,
      watchlistData: [],
      pageWatchlist: []
    };
  },
  getHistoryList(state, payload) {
    return {
      ...state,
      timeLineData: payload.timeline,
      page: payload.page
    };
  },
  getHistoryListSaftey(state, payload) {
    return {
      ...state,
      safteyData: payload.timeline,
      pageSaftey: payload.page
    };
  },
  getHistoryListWacthlist(state, payload) {
    return {
      ...state,
      watchlistData: payload.timeline,
      pageWatchlist: payload.page
    };
  },
  addError(state, payload) {
    return {
      ...state,
      error: payload
    };
  },
  removeError(state) {
    return {
      ...state,
      error: ""
    };
  }
};

export const effects = {
  async getTimeLine(payload, state, params) {
    this.getHistoryListClear();
    this.setLoader(true);
    //pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&
    let url = ``;
    if (payload.from_date !== undefined && payload.to_date !== undefined) {
      url = `api/v1/timeline/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&from_date=${payload.from_date}&to_date=${payload.to_date}&data_type[]=watchlist&data_type[]=safety`;
      if (payload.organizations && payload.organizations.length > 0) {
        let s = "";
        for (var i = 0; i < payload.organizations.length; i++) {
          if (payload.organizations[i] === "0") {
            s = ``;
          } else {
            s += `&organization_id[]=${payload.organizations[i]}`;
          }
        }
        url = `${url}${s}`;
      }
      if (payload.users && payload.users.length > 0) {
        let s = "";
        for (var i = 0; i < payload.users.length; i++) {
          if (payload.users[i] === "0") {
            s = ``;
          } else {
            s += `&user_id[]=${payload.users[i]}`;
          }
        }
        url = `${url}${s}`;
      }
      if (payload.search) {
        this.getHistoryListClearPage();
        this.getHistoryListClear();
        url = `api/v1/timeline/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&from_date=${payload.from_date}&to_date=${payload.to_date}&search=${payload.search}&data_type[]=watchlist&data_type[]=safety`;
      }
      this.removeError();

      try {
        const res = await api
          .get(url)
          .then(response => {
            if (response.status === 200) {
              this.setLoader(false);
              this.getHistoryList(response.data.data);
            }
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async getTimeLineSaftey(payload, state, params) {
    this.getHistoryListSafteyClear();
    this.setLoader(true);
    //pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&
    let url = ``;
    if (payload.from_date !== undefined && payload.to_date !== undefined) {
      url = `api/v1/timeline/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&from_date=${payload.from_date}&to_date=${payload.to_date}&data_type[]=safety`;
      if (payload.organizations && payload.organizations.length > 0) {
        let s = "";
        for (var i = 0; i < payload.organizations.length; i++) {
          if (payload.organizations[i] === "0") {
            s = ``;
          } else {
            s += `&organization_id[]=${payload.organizations[i]}`;
          }
        }
        url = `${url}${s}`;
      }
      if (payload.users && payload.users.length > 0) {
        let s = "";
        for (var i = 0; i < payload.users.length; i++) {
          if (payload.users[i] === "0") {
            s = ``;
          } else {
            s += `&user_id[]=${payload.users[i]}`;
          }
        }
        url = `${url}${s}`;
      }
      if (payload.search) {
        this.getHistoryListSafteyClear();
        url = `api/v1/timeline/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&from_date=${payload.from_date}&to_date=${payload.to_date}&search=${payload.search}&data_type[]=safety`;
      }
      this.removeError();

      try {
        const res = await api
          .get(url)
          .then(response => {
               if (response.status === 200) {
            this.setLoader(false);
            this.getHistoryListSaftey(response.data.data);}
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async getTimeLineWatchlist(payload, state, params) {
    this.setLoader(true);
    this.getHistoryListWacthlistClear();
    //pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&
    let url = ``;
    if (payload.from_date !== undefined && payload.to_date !== undefined) {
      url = `api/v1/timeline/?pagination=1&limit=${
        payload.rowsPerPage
      }&offset=${payload.offset}&from_date=${payload.from_date}&to_date=${
        payload.to_date
      }&data_type[]=watchlist${
        payload.subtype ? `&sub_data_type=${payload.subtype}` : ``
      }`;
      if (payload.organizations && payload.organizations.length > 0) {
        let s = "";
        for (var i = 0; i < payload.organizations.length; i++) {
          if (payload.organizations[i] === "0") {
            s = ``;
          } else {
            s += `&organization_id[]=${payload.organizations[i]}`;
          }
        }
        url = `${url}${s}`;
      }
      if (payload.users && payload.users.length > 0) {
        let s = "";
        for (var i = 0; i < payload.users.length; i++) {
          if (payload.users[i] === "0") {
            s = ``;
          } else {
            s += `&user_id[]=${payload.users[i]}`;
          }
        }
        url = `${url}${s}`;
      }
      if (payload.search) {
        url = `api/v1/timeline/?pagination=1&limit=${
          payload.rowsPerPage
        }&offset=${payload.offset}&from_date=${payload.from_date}&to_date=${
          payload.to_date
        }&data_type[]=watchlist${
          payload.subtype ? `&sub_data_type=${payload.subtype}` : ``
        }&search=${payload.search}`;
      }
      this.removeError();

      try {
        const res = await api
          .get(url)
          .then(response => {
               if (response.status === 200) {
            this.setLoader(false);
            this.getHistoryListWacthlist(response.data.data);}
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  }
};
export default {
  state: initialState,
  reducers,
  effects
};
