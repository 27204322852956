import React, { useState, useEffect, useRef } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import SubformComponents from "../../components/SubformComponents";
import { Field } from "../../components/FormField";
import { FieldDatePicker } from "../../components/FormField/DatePicker";
import { FieldPhoneNumber } from "../../components/FormField/PhoneNumber";
import {
  OrganizationWrapper,
  OrganizationForm,
  OrganizationBlock,
  OrganizationSection,
  DetailsText,
  Textspan,
  PhoneNumber
} from "./organization.styles";
import { PageHeading, CancelButton, CreateButton } from "../../styles/Body.style"
import { useField, useForm } from "../../utils/validations/ValidationHooks";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import store from "../../store";
import moment from "moment";
import { useSnackbar } from "notistack";
import BackToPrevious from "../../components/BackToPrevious";

const payload = {
  name: "",
  address: "",
  email: "",
  phone: "",
  is_active: true,
  activated_at: new Date(),
  POC: [],
  number_of_accounts: "",
  account_start_at: "",
  organization_id: ""
};

const blankContact = {
  name: "",
  office_phone_number: "",
  email: "",
  phone: ""
};

const Form = props => {
  let { organizationDetails, isEdit } = props.location.state || {};
  const { enqueueSnackbar } = useSnackbar();
  const [ownerState, setOwnerState] = useState(organizationDetails || payload);
  const [errorState, setErrorState] = useState([]);
  const [isEditState, setEditState] = useState(isEdit || false);
  const [error, setError] = useState({
    phone: true,
    message: ""
  });
  const forms = useRef([{ ...blankContact }]);
  const handleOwnerChange = e =>
    setOwnerState({
      ...ownerState,
      [e.target.name]: e.target.value
    });
  const handlePhoneNumberChange = number => {
    setOwnerState({
      ...ownerState,
      phone: number
    });
    if (ownerState.phone === "" || !isValidPhoneNumber(number)) {
      setError({
        phone: false,
        message: "please provide a valid phone number"
      });
    } else {
      setError({
        phone: true,
        message: ""
      });
    }
  };
  useEffect(() => {
    let temp = ownerState.POC;
    if (ownerState.POC && ownerState.POC.length > 0) {
      forms.current = [...ownerState.POC];
      temp = ownerState.POC;
    }
    if (ownerState.POC && ownerState.POC.length > 0) {
      forms.current = [...ownerState.POC];
      temp = ownerState.POC;
    }
    // forms.current = [...ownerState.POC];
    // const temp = ownerState.POC;
    let arr = [];
    if (isEdit) {
      temp &&
        temp.forEach(element => {
          arr.push(true);
        });
      setFormValid(arr);
    }
  }, [ownerState.POC]);

  React.useEffect(() => {
    if (organizationDetails && isEdit) {
      const date = new Date(organizationDetails.activated_at);
      if (organizationDetails) {
        setOwnerState({
          ...ownerState,
          activated_at: moment(date)
            .local()
            .format("YYYY-MM-DDTHH:mm:ss")
        });
      }
    }
  }, []);
  const [contactState, setContactState] = useState([{ ...blankContact }]);

  const deleteContact = idx => {
    const temp = [...forms.current];
    let state = temp.filter(function(e, index) {
      return index !== idx;
    });
    forms.current = state;
    const valid = [...formValid];
    let stateFilter = valid.filter(function(e, index) {
      return index !== idx;
    });
    setFormValid(stateFilter);
  };
  const clear = () => {
    forms.current = [{ ...blankContact }];
    setFormValid([false]);
    const payload = {
      name: "",
      address: "",
      email: "",
      phone: "",
      is_active: true,
      activated_at: new Date(),
      POC: [],
      number_of_accounts: "",
      account_start_at: "",
      organization_id: ""
    };
    setOwnerState(payload);
  };
  const handleContactChange = e => {
    const updatedContact = [...contactState];
    // updatedContact[e.target.dataset.idx]["id"] = parseInt(e.target.dataset.idx+1);
    updatedContact[e.target.dataset.idx][e.target.className] = e.target.value;
    setContactState(updatedContact);
  };

  const form = useForm({
    onSubmit: async (formData, valid) => {
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const regNum = /^\d+$/;
      const phoneRegExp = /^[0-9-+()]*$/;
      let error = [];
      setErrorState(error);
      if (!valid) return;
      // Simulate network time
      await timeout(1000);
      if (formData) {
        let validEmail = regex.test(formData.email),
          validOrganization = true;
        let validAccounts = regNum.test(formData.number_of_accounts);
        let validstartAt = regNum.test(formData.account_start_at);
        let validPhoneNum = isValidPhoneNumber(ownerState.phone);
        if (!validEmail) {
          emailField.setErrors(["please provide a valid email id"]);
        }
        if (ownerState.phone === "" || !validPhoneNum) {
          setError({
            phone: false,
            message: "please provide a valid phone number"
          });
        } else {
          setError({
            phone: true,
            message: ""
          });
        }
        // if (formData.name.length < 4) {
        //   validOrganization = false;
        //   usernameField.setErrors([
        //     "provide atleast 4 characters for organization name"
        //   ]);
        // }
        if (!validAccounts) {
          numberOfAccountsField.setErrors([
            "provide a number for number of accounts"
          ]);
        }
        if (!validstartAt) {
          startAtField.setErrors(["provide a number for start at field"]);
        }
        if (
          valid &&
          formData &&
          validOrganization &&
          validEmail &&
          validAccounts &&
          validPhoneNum
        ) {
          let payload = formData;
          payload.POC = forms.current;
          payload.phone = ownerState.phone;
          payload.number_of_accounts = parseInt(formData.number_of_accounts);
          payload.account_start_at = parseInt(formData.account_start_at);
          payload.activated_at = moment
            .utc(new Date(formData.activated_at))
            .toISOString();
          if (isEditState) {
            store.dispatch.Organization.patchOrganizations(payload, {
              enqueueSnackbar,
              id: ownerState.id ? ownerState.id : "",
              history: props.history
            });
          } else {
            store.dispatch.Organization.createOrganizations(payload, {
              enqueueSnackbar,
              clear,
              history: props.history
            });
          }
        }
      }
    }
  });

  const usernameField = useField("name", form, {
    defaultValue: ownerState.name,
    validations: [
      async formData => {
        return formData.name.length === 0 && "please provide organization name";
      }
    ],
    fieldsToValidateOnChange: ["name"]
  });
  const organizationIdField = useField("organization_id", form, {
    defaultValue: ownerState.organization_id,
    validations: [
      async formData => {
        // await timeout(1000);
        return (
          formData.organization_id.length === 0 &&
          "please provide organization id"
        );
      }
    ],
    fieldsToValidateOnChange: ["organization_id"]
  });
  const numberOfAccountsField = useField("number_of_accounts", form, {
    defaultValue: ownerState.number_of_accounts,
    validations: [
      async formData => {
        const re = /^[0-9]*$/;
        // await timeout(1000);
        if (!re.test(formData.number_of_accounts)) {
          return "please provide number ";
        } else if (formData.number_of_accounts.length === 0) {
          return "please provide number of accounts to be generated";
        }
      }
    ],
    fieldsToValidateOnChange: ["number_of_accounts"]
  });
  const startAtField = useField("account_start_at", form, {
    defaultValue: ownerState.account_start_at,
    validations: [
      async formData => {
        const re = /^[0-9]*$/;
        // await timeout(1000);
        if (!re.test(formData.account_start_at)) {
          return "please provide number ";
        } else if (formData.account_start_at.length === 0) {
          return "please provide start at number to be generated";
        }
      }
    ],
    fieldsToValidateOnChange: ["account_start_at"]
  });
  const activatedAtField = useField("activated_at", form, {
    defaultValue: ownerState.activated_at,
    validations: [
      async formData => {
        // if (formData.activated_at < moment()) {
        //   return 'Past time not allowed.'
        // }
        return (
          formData.activated_at.length === 0 && "please set initaited date"
        );
      }
    ],
    fieldsToValidateOnChange: ["activated_at"]
  });
  const addressField = useField("address", form, {
    defaultValue: ownerState.address,
    validations: [
      async formData => {
        // await timeout(1000);
        return (
          formData.address.length === 0 &&
          "please provide address of organization "
        );
      }
    ],
    fieldsToValidateOnChange: ["address"]
  });
  const emailField = useField("email", form, {
    defaultValue: ownerState.email,
    validations: [
      async formData => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        // await timeout(1000);
        if (!regex.test(formData.email)) {
          return "please provide valid email";
        } else if (formData.email.length === 0) {
          return "please provide email of organization";
        }
      }
    ],
    fieldsToValidateOnChange: ["email"]
  });
  // const phoneNumberField = useField("phone", form, {
  //   defaultValue: ownerState.phone,
  //   validations: [
  //     async formData => {
  //       const re = /^[0-9-+()]*$/;
  //       // await timeout(1000);
  //       if (!re.test(ownerState.phone)) {
  //         return "please provide number ";
  //       } else if (ownerState.phone.length === 0) {
  //         return "please provide phone number";
  //       }
  //     }
  //   ],
  //   fieldsToValidateOnChange: ["phone"]
  // });

  const [formValid, setFormValid] = useState([false]);

  const updateFormField = (state, index, value) => {
    const form = [...forms.current];
    const temp = { ...forms.current[index] };
    temp[state] = value;
    form[index] = temp;
    forms.current = form;
  };

  const updateFormValid = (index, isValid) => {
    const temp = [...formValid];
    temp[index] = isValid;
    setFormValid(temp);
  };

  const addContact = () => {
    forms.current = [...forms.current, { ...blankContact }];
    const validForm = [...formValid];
    validForm.push(false);
    setFormValid(validForm);
  };

  return (
    <OrganizationWrapper>
      <Grid container>
        <OrganizationBlock>
          <BackToPrevious destination="organizations" />
          <PageHeading>
            {`${isEditState ? "Edit" : "Create"}`} Organization
          </PageHeading>
          <div
            style={{
              display: "flex"
            }}>
            <OrganizationForm onSubmit={form.onSubmit}>
              <OrganizationSection>
                <Field
                  {...usernameField}
                  formSubmitted={form.submitted}
                  label="ORGANIZATION NAME"
                  type="text"
                  name="name"
                  id="name"
                  placeholder="Organization Name"
                />
                <Field
                  {...organizationIdField}
                  formSubmitted={form.submitted}
                  label="ORGANIZATION ID"
                  type="text"
                  name="organization_id"
                  id="organization_id"
                  placeholder="Organization ID"
                  disabled={isEdit}
                />
                <Field
                  {...numberOfAccountsField}
                  formSubmitted={form.submitted}
                  label="NUMBER OF ACCOUNT"
                  type="text"
                  name="number_of_accounts"
                  id="number_of_accounts"
                  placeholder="Number of accounts"
                />
                <Field
                  {...startAtField}
                  formSubmitted={form.submitted}
                  label="START AT"
                  type="text"
                  name="account_start_at"
                  id="account_start_at"
                  placeholder="Start at"
                  // disabled={isEdit}
                />
              </OrganizationSection>
              <OrganizationSection>
                <FieldDatePicker
                  {...activatedAtField}
                  disableFuture={false}
                  disablePast={!isEdit}
                  formSubmitted={form.submitted}
                  label="INITIATE DATE"
                  name="activated_at"
                  id="activated_at"
                  placeholder="Activate Date"
                  // maxDate={new Date("02/02/2300")}
                  disabled={isEdit}
                />
                <Field
                  {...addressField}
                  formSubmitted={form.submitted}
                  label="ADDRESS"
                  type="text"
                  name="address"
                  id="address"
                  placeholder="Address"
                />
                <Field
                  {...emailField}
                  formSubmitted={form.submitted}
                  label="EMAIL ID"
                  type="text"
                  name="email"
                  id="email"
                  placeholder="Email id"
                />
                <PhoneNumber>
                  <FieldPhoneNumber
                    // {...phoneNumberField}
                    formSubmitted={form.submitted}
                    label="PHONE NUMBER"
                    type="text"
                    name="phone"
                    id="phone"
                    error={error}
                    placeholder="Phone Number"
                    value={ownerState.phone}
                    onChange={number => handlePhoneNumberChange(number)}
                  />
                </PhoneNumber>
              </OrganizationSection>
              <Divider
                style={{
                  border: "1px dashed #d1d1d6",
                  margin: " 40px 20px 40px 20px"
                }}
              />
              <DetailsText>Details of POC</DetailsText>
              {forms.current.map((form, idx) => (
                <SubformComponents
                  addContact={addContact}
                  key={`POC-${idx}`}
                  idx={idx}
                  contactState={form}
                  handleContactChange={handleContactChange}
                  deleteContact={deleteContact}
                  useField={useField}
                  timeout={timeout}
                  errorState={errorState}
                  valid={formValid.every(condition => condition)}
                  updateFormField={updateFormField}
                  updateFormValid={updateFormValid}
                />
              ))}
              <Divider
                style={{
                  border: "1px dashed #d1d1d6",
                  margin: " 40px 20px 40px 20px"
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "40px"
                }}>
                <CancelButton
                  onClick={() =>
                    props.history.replace(
                      isEditState
                        ? {
                            pathname: "/organizationdetails",
                            state: {
                              organizationItem: organizationDetails
                            }
                          }
                        : {
                            pathname: "/organizations"
                          }
                    )
                  }>
                  <Textspan>Cancel</Textspan>
                </CancelButton>
                <CreateButton
                  onClick={form.onSubmit}
                  disabled={!formValid.every(condition => condition)}>
                  {`${isEditState ? "Save" : "Create"}`}
                </CreateButton>
              </div>
            </OrganizationForm>
          </div>
        </OrganizationBlock>
      </Grid>
    </OrganizationWrapper>
  );
};
function timeout(delay) {
  return new Promise(resolve => {
    setTimeout(resolve, delay);
  });
}

export default Form;
