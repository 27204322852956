import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  NotificationWrapper,
  NotificationTopWrapper,
  NotificationCreateWrapper,
  CreateText,
  SearchWrapper,
  NotificationTopWrapperSearch,
  NotificationTableWrapper,
  NotificationTable
} from "./massnotification.styles";
import { PageHeading } from "../../styles/Body.style";
import AddIcon from "../../assets/img/addicon.png";
import { Grid } from "@material-ui/core";
import store from "../../store";
import Table from "./NotificationTable";
import icon from "../../assets/img/search2x.png";
import TextField from "@material-ui/core/TextField";
import { TextFieldWrapper }from "../../styles/Wrapper.style";
const header = [
  "ID",
  "NAME",
  "POLYGON",
  "NOTIFIED AT",
  "RECIPIENT",
  "RESPONSES"
];

const sortHeaders = ["NOTIFIED AT", "RECIPIENT", "RESPONSES"];
function MassNotifications(props) {
  const [isLoading, setLoader] = React.useState([]);
  const [notificationList, setNotificationlist] = useState([]);
  const [appPage, setAppPage] = React.useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const handleDataSearch = e => {
    setSearchValue(e.target.value);
  };
  const handleDetails = item => {
    store.dispatch.MassNotification.getMassNotificationDetail(item);
    props.history.push({
      pathname: "/notificationdetails",
      state: {
        notificationItem: item
      }
    });
  };
  useEffect(() => {
    let payload = {
      offset: 0,
      rowsPerPage: 5
    };
    store.dispatch.MassNotification.getMassNotifications(payload);
  }, []);
  useEffect(() => {
    setLoader(props.massNotificationState.loading);
  }, [props.massNotificationState.loading]);
  useEffect(() => {
    setNotificationlist(props.massNotificationState.notifications);
    setAppPage(props.massNotificationState.page);
  }, [
    props.massNotificationState.notifications,
    props.massNotificationState.page
  ]);
  return (
    <NotificationWrapper>
      <Grid container>
        <Grid item xs={12}>
          <NotificationTopWrapper>
            <PageHeading>Mass Notifications</PageHeading>
            <NotificationCreateWrapper
              onClick={() => {
                props.history.push("/createnotifications");
              }}>
              <CreateText> Create Notifications</CreateText>
              <img
                style={{
                  margin: "12px 7px 13px 9px"
                }}
                src={AddIcon}
              />
            </NotificationCreateWrapper>
          </NotificationTopWrapper>
          <NotificationTopWrapperSearch>
            <SearchWrapper>
              <TextFieldWrapper>
                <TextField
                  style={{
                    margin: " 9px",
                    fontFamily: "Google Sans",
                    fontSize: "12px"
                  }}
                  id="reason"
                  InputProps={{
                    startAdornment: (
                      <img
                        style={{
                          width: " 21px",
                          margin: " 0px 4px 0px 2px",
                          padding: "2px 3px 5px 4px"
                        }}
                        src={icon}
                      />
                    ),
                    disableUnderline: true
                  }}
                  // //   value={reason}
                  onChange={e => {
                    handleDataSearch(e);
                  }}
                  margin="normal"
                  placeholder="Search..."
                  type="text"
                  fullWidth
                />
              </TextFieldWrapper>
            </SearchWrapper>
          </NotificationTopWrapperSearch>
          <NotificationTableWrapper>
            <NotificationTable>
              <Table
                sortHeaders={sortHeaders}
                isLoading={isLoading}
                handleDetails={handleDetails}
                searchValue={searchValue}
                tableData={notificationList}
                tableHeader={header}
                getDataList={
                  store.dispatch.MassNotification.getMassNotifications
                }
                organizationPage={appPage}
              />
            </NotificationTable>
          </NotificationTableWrapper>
        </Grid>
      </Grid>
    </NotificationWrapper>
  );
}

const mapStateToProps = state => {
  return { massNotificationState: state.MassNotification };
};

export default connect(
  mapStateToProps,
  null
)(MassNotifications);
