import React from "react";
import styled from "styled-components";
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import {
  Typography,
  InputBase,
  Box,
  Tab,
  Tabs,
  makeStyles,
  withStyles
 } from "@material-ui/core";

export const TicketTopWrapper = styled.div`
  display: flex !important;
  justify-content: space-between;
  margin: 11px 0 10px 0;
`;
export const TicketTableWrapper = styled.section`
  display: flex;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;
export const DetailsText = styled.div`
  display: flex;
  margin: 2px 6px 21px 52px;
  height: 25px;
  font-family: Google Sans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;

export const Textspan = styled.span`
  margin: 9px 0px 0px 1px;
  width: 47px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;
export const TicketWrapper = styled.section`
  display: flex !important;
  margin: 11px 0 70px 0 !important;
  input::-ms-clear {
    width: 0;
    height: 0;
  }
`;
export const TicketOrgNameSection = styled.section`
  display: flex;
  //  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0px 0px 0px 45px;
`;
export const TicketSelectSection = styled.section`
  // display: flex;
  //  justify-content: space-between;
  width: 50%;
  margin-top: 30px;
  padding: 0px 0px 0px 45px;
`;
export const TicketDescriptionSection = styled.section`
  display: flex;
  //  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0px 0px 0px 45px;
`;
export const TicketBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const TicketForm = styled.form`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;
export const FAQSection = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
`;
export const FAQSectionBodyBackground = styled.div`
  display: flex;
  justify-content: space-between;
  height: 34px;
  padding: 5px;
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
  border: solid 1px rgba(151, 151, 151, 0.06);
  font-family: Google Sans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
  background-color: #ffffff;
`;
export const FAQSectionBody = styled.section`
  display: flex;
  padding: 5px;
  border-radius: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: Google Sans;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const TicketFormControl = styled.div`
  display: inline-flex;
  flex-direction: column;
  width: 100%;
`;
export const TicketInputWrap = styled(InputBase)`
  width: 96%;
  padding: 12px;
  font-size: 14px;
  height: 153px; //38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: ${props =>
    props.showErrors ? "solid 1px #ff2d21" : "solid 1px rgba(71, 59, 59, 0.2)"};
  background-color: #ffffff;
`;
export const TicketLabel = styled.label`
  width: 200px;
  margin-bottom: 10px;
  height: 16px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: ${props => (props.error ? " #ff2d21" : "#292b2e;")};
`;
export const T = styled.table`
  padding-right: 20px;
  width: 100%;
  .MuiCollapse-container {
    width: 32px;
  }
  .wrapper-long {
    margin: 0 auto;
    padding: 0 0 20px;
  }
  .wrapper {
    margin: 0 auto;
    padding: 40px;
    max-width: 800px;
  }
  .table {
    margin: 0 0 40px 0;
    width: 100%;
    display: table;
    text-align: left;
    position: relative;
    border-collapse: collapse;
  }
  @media screen and (max-width: 580px) {
    .table {
      display: block;
      text-align: left;
      position: relative;
      border-collapse: collapse;
    }
  }
  .row-head {
    //cursor: pointer;
    display: table-row;
    background-color: #ffffff;
  }
  .row {
    //cursor: pointer;
    display: table-row;
    background-color: #ffffff;
    &:hover,
    &:focus {
      outline: none;
      background-color: #f8f8f8;
    }
  }
  .row:nth-of-type(odd) {
    background-color: #ffffff;
    &:hover,
    &:focus {
      outline: none;
      background-color: #f8f8f8;
    }
  }
  .row-head.header {
    height: 38px;
    border-radius: 8px;
    border: solid 1px #414a52;
    color: #ffffff;
    position: sticky;
  }
  .row-head.green {
    background-color: #414549;
  }
  .row.blue {
    background: #2980b9;
  }
  @media screen and (max-width: 580px) {
    .row {
      padding: 14px 0 7px;
      display: block;
    }
    .row-head.header {
      padding: 0;
      height: 6px;
    }
    .row-head.header .cell {
      display: none;
    }
    .row .cell {
      margin-bottom: 10px;
    }
    .row .cell:before {
      margin-bottom: 3px;
      content: attr(data-title);
      min-width: 98px;
      font-size: 10px;
      line-height: 10px;
      font-weight: bold;
      text-transform: uppercase;
      color: #969696;
      display: block;
    }
  }
  .cellHead {
    padding: 6px 20px;
    display: table-cell;
    width: 132px;
    height: 18px;
    font-family: Google Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffffff;
  }
  .cellHead-small {
    padding: 6px 20px;
    display: table-cell;
    width: 32px;
    height: 18px;
    font-family: Google Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffffff;
  }
  .cell {
    border-bottom: 1px solid lavender;
    padding: 21px 5px 16px 11px;
    display: table-cell;
    width: 132px;
    height: 18px;
    font-family: Google Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #161a1e;
  }
  .cell-long {
    border-bottom: 1px solid lavender;
    padding: 21px 5px 16px 20px;
    display: table-cell;
    width: 157px;
    height: 18px;
    font-family: Google Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #161a1e;
  }
  .cell-long-line {
    justify-content: center;
    text-align: center;
    border-bottom: 1px solid lavender;
    padding: 21px 5px 16px 20px;
    display: table-cell;
    width: 100%;
    height: 18px;
    font-family: Google Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #161a1e;
  }
  .cell-small {
    border-bottom: 1px solid lavender;
    padding: 21px 5px 16px 20px;
    display: table-cell;
    width: 32px;
    height: 18px;
    font-family: Google Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #161a1e;
  }
  .cell-yellow {
    color: #ff9500;
  }
  @media screen and (max-width: 580px) {
    .cell {
      padding: 2px 16px;
      display: block;
    }
  }
`;

export const StyledTabs = withStyles({
  root: {
    borderBottom: "1px solid #eff1f4"
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > div": {
      maxWidth: 40,

      width: "68px",
      height: "7px",
      borderRadius: "100px",
      backgroundImage: "linear-gradient(to bottom, #f14b4b, #ff0e00)"
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

export const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontFamily: "Google Sans",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={1}>{children}</Box>}
    </Typography>
  );
}

export const AccordionSummary = withStyles({
  root: {
    // backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  // padding: {
  //   padding: theme.spacing(3)
  // },
  demo1: {
    backgroundColor: theme.palette.background.paper
  },
  demo2: {
    backgroundColor: "#ffffff"
  },
  spantest: {
    margin: " 15px 0px 0px 0px",
    color: "#5a5d60"
  },
  dateTimePicker: {
    margin: " 1px 0px 0px 19px !important",
    width: "182px",
    height: " 39px",
    borderRadius: "8px",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    border: "solid 1px rgba(71, 59, 59, 0.2) !important",
    backgroundColor: " #ffffff",
    "& div": {
      textDecoration: "none",
      "& input": {
        margin: "10px",
        width: "100%",
        height: "16px",
        fontFamily: "Google Sans",
        fontSize: "12px",
        fontWeight: "normal",
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: "normal",
        letterSpacing: "normal",
        color: "#999999"
      }
    }
  },
  adornment: {
    padding: "0px"
  },
  heading:{
    fontSize: '115%'
  },
  description:{
    opacity: "60%"
  }
}));