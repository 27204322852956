import React, { useEffect } from "react"
import { connect } from "react-redux"
import Table from "../../components/Table/ResuableTable"
import BackIcon from "../../assets/img/reddropdown.png"
import TextField from "@material-ui/core/TextField"
import { TextFieldWrapper } from "../../styles/Wrapper.style"
import icon from "../../assets/img/search2x.png"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import Grow from "@material-ui/core/Grow"
import Popper from "@material-ui/core/Popper"
import MenuItem from "@material-ui/core/MenuItem"
import MenuList from "@material-ui/core/MenuList"
import moment from "moment"
import {
  OrganizationWrapper,
  OrganizationBlock,
  OrganizationTable,
  OrganizationTableWrapper,
  MoreAction,
  MoreActionText,
  OrganizationDetailBlock,
  OrganizationDetailBlockSections,
  OrganizationDetailHeadingSpan,
  OrganizationDetailContentSpan,
  OrganizationFormControlBlock,
  OrganizationBlockFilter,
  OrganizationBlockFilterSelect,
  OrganizationTopWrapperSearchDetails,
  SearchWrapper,
  // BackIcon as BreadcrumbBackIcon
} from "./organization.styles"
import { PageHeading } from "../../styles/Body.style"
import Select from "../../components/GeoSpatialFilterComponent/components/Select"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import store from "../../store"
import { useSnackbar } from "notistack"
import DialogOrganizationExport from "../../components/DialogExport/OrganizationExport"
import BackToPrevious from "../../components/BackToPrevious"

const sortHeader = "LAST UPDATED AT"
const FilterSubDataType = [
  {
    id: 1,
    subtype: "online",
    label: "Online"
  },
  {
    id: 2,
    subtype: "offline",
    label: "Offline"
  },
  {
    id: 3,
    subtype: "not_setup",
    label: "Not Setup"
  }
]

const filterMobileStatus = [
  {
    id: 1,
    status: "cellular",
    label: "Cellular"
  },
  {
    id: 1,
    status: "wifi",
    label: "WiFi"
  },
  {
    id: 1,
    status: "other",
    label: "Other"
  }
]

const header = [
  "USER NAME",
  "USER STATUS",
  "MOBILE STATUS",
  "LAST KNOWN LOCATION",
  "LAST UPDATED AT"
]

function OrganizationDetails(props) {
  const [organization, setOrganization] = React.useState([])
  const { enqueueSnackbar } = useSnackbar()
  const [organizationDetails, setOrganizationDetails] = React.useState([])
  const [appUser, setAppUser] = React.useState([])
  const [appPage, setAppPage] = React.useState([])
  const anchorRef = React.useRef(null)
  const [searchValue, setSearchValue] = React.useState("")
  const [userDatalist, setuserDatalist] = React.useState("")
  const [open, setOpen] = React.useState(false)
  const [openDialog, setOpenDialog] = React.useState(false)
  const [subtype, setSubtype] = React.useState(0)
  const [mobileStatusFilter, setMobileStatusFilter] = React.useState(0)
  const handleCloseDialog = value => {
    setOpenDialog(false)
  }
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }
  const handleSelectFilter = event => {
    setSubtype(event.target.value)
  }

  const handleMobileStatusFilter = event => {
    setMobileStatusFilter(event.target.value)
  }

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault()
      setOpen(false)
    }
  }
  const handleUserDetails = item => {
    props.history.push(`/${item.username}`)
  }
  const handleDataSearch = e => {
    setSearchValue(e.target.value)
  }
  useEffect(() => {
    setOrganization(props.location.state.organizationItem)
    let org = [props.location.state.organizationItem.organization_id]
    store.dispatch.Dashboard.getUserlist(org)
  }, [props.location.state])
  useEffect(() => {
    setuserDatalist(props.dashboardState.userList)
  }, [props.dashboardState.userList])
  useEffect(() => {
    if (organization && organization.id !== undefined) {
      let payload = {
        id: organization.id,
        offset: 0,
        rowsPerPage: 5
      }
      store.dispatch.Organization.getOrganizationlistDetails(payload)
      store.dispatch.Organization.getOrganizationlistAppUsers(payload)
    }
  }, [organization])

  useEffect(() => {
    setOrganizationDetails(props.organizationState.organizationDetails)
  }, [props.organizationState.organizationDetails])
  useEffect(() => {
    setAppUser(props.organizationState.appuser)
    setAppPage(props.organizationState.apppage)
  }, [props.organizationState.appuser, props.organizationState.apppage])
  return (
    <>
      <OrganizationWrapper>
        <Grid container>
          <OrganizationBlock>
            <BackToPrevious destination="organizations" />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <PageHeading>{organization.name}</PageHeading>
              <MoreAction
                ref={anchorRef}
                aria-controls={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={handleToggle}>
                <MoreActionText>More Actions</MoreActionText>
                <img
                  style={{
                    width: "12px",
                    height: "7px",
                    margin: "14px 2px 2px 3px"
                  }}
                  src={BackIcon}
                />
                <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  style={{ zIndex: "10" }}
                  disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom"
                      }}>
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}>
                            <MenuItem
                              onClick={() => {
                                setOpenDialog(true)
                              }}>
                              Export
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                props.history.replace({
                                  pathname: "/createorganization",
                                  state: {
                                    organizationDetails: organizationDetails,
                                    isEdit: true
                                  }
                                })
                              }}>
                              Edit Organization
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </MoreAction>
            </div>
            <OrganizationDetailBlock>
              <OrganizationDetailBlockSections>
                <OrganizationFormControlBlock>
                  <OrganizationDetailHeadingSpan>
                    ADDRESS
                  </OrganizationDetailHeadingSpan>
                  <OrganizationDetailContentSpan>
                    {organizationDetails.address}
                  </OrganizationDetailContentSpan>
                </OrganizationFormControlBlock>
                <OrganizationFormControlBlock>
                  <OrganizationDetailHeadingSpan>
                    POC
                  </OrganizationDetailHeadingSpan>
                  <OrganizationDetailContentSpan>
                    {organizationDetails &&
                    organizationDetails.POC &&
                    organizationDetails.POC.length > 0
                      ? organizationDetails.POC[0].name
                      : "no primary contact name"}
                  </OrganizationDetailContentSpan>
                </OrganizationFormControlBlock>
                <OrganizationFormControlBlock>
                  <OrganizationDetailHeadingSpan>
                    MEMBER SINCE
                  </OrganizationDetailHeadingSpan>
                  <OrganizationDetailContentSpan>
                    {moment(organizationDetails.activated_at).format(
                      "DD MMM , YYYY ,HH:mm:ss"
                    )}
                  </OrganizationDetailContentSpan>
                </OrganizationFormControlBlock>
                <OrganizationFormControlBlock>
                  <OrganizationDetailHeadingSpan>
                    ORGANIZATION MAIL ID
                  </OrganizationDetailHeadingSpan>
                  <OrganizationDetailContentSpan>
                    {organizationDetails.email}
                  </OrganizationDetailContentSpan>
                </OrganizationFormControlBlock>
              </OrganizationDetailBlockSections>
              <OrganizationDetailBlockSections>
                <OrganizationFormControlBlock>
                  <OrganizationDetailHeadingSpan>
                    PRIMARY CONTACT EMAIL ID
                  </OrganizationDetailHeadingSpan>
                  <OrganizationDetailContentSpan>
                    {organizationDetails &&
                    organizationDetails.POC &&
                    organizationDetails.POC.length > 0
                      ? organizationDetails.POC[0].email
                      : "no primary contact email"}
                  </OrganizationDetailContentSpan>
                </OrganizationFormControlBlock>
                <OrganizationFormControlBlock>
                  <OrganizationDetailHeadingSpan>
                    NUMBER OF ACCOUNTS
                  </OrganizationDetailHeadingSpan>
                  <OrganizationDetailContentSpan>
                    {organizationDetails.number_of_accounts}
                  </OrganizationDetailContentSpan>
                </OrganizationFormControlBlock>
                <OrganizationFormControlBlock>
                  <OrganizationDetailHeadingSpan>
                    PRIMARY CONTACT
                  </OrganizationDetailHeadingSpan>
                  <OrganizationDetailContentSpan>
                    {organizationDetails && organizationDetails.phone}
                  </OrganizationDetailContentSpan>
                </OrganizationFormControlBlock>
                <OrganizationFormControlBlock>
                  <OrganizationDetailHeadingSpan>
                    SECONDARY CONTACT
                  </OrganizationDetailHeadingSpan>
                  <OrganizationDetailContentSpan>
                    {organizationDetails &&
                    organizationDetails.POC &&
                    organizationDetails.POC.length > 0
                      ? organizationDetails.POC[0].phone
                      : "no secondary contact phone"}
                  </OrganizationDetailContentSpan>
                </OrganizationFormControlBlock>
              </OrganizationDetailBlockSections>
            </OrganizationDetailBlock>
            <PageHeading>User List</PageHeading>
            <OrganizationBlockFilter>
              <div>
                <Select
                  // classesParent={classes}
                  data={FilterSubDataType}
                  valueProp={subtype}
                  onChange={handleSelectFilter}
                  label="User Status"
                />
              </div>
              <OrganizationBlockFilterSelect>
                <Select
                  data={filterMobileStatus}
                  valueProp={mobileStatusFilter}
                  onChange={handleMobileStatusFilter}
                  label="Mobile Status"
                />
              </OrganizationBlockFilterSelect>
              <OrganizationTopWrapperSearchDetails>
                <SearchWrapper>
                  {" "}
                  <TextFieldWrapper>
                    <TextField
                      style={{
                        margin: " 9px",
                        fontFamily: "Google Sans",
                        fontSize: "12px"
                      }}
                      id="reason"
                      InputProps={{
                        startAdornment: (
                          <img
                            style={{
                              width: " 21px",
                              margin: " 0px 4px 0px 2px",
                              padding: "2px 3px 5px 4px"
                            }}
                            src={icon}
                          />
                        ),
                        disableUnderline: true
                      }}
                      // //   value={reason}
                      onChange={e => {
                        handleDataSearch(e)
                      }}
                      margin="normal"
                      placeholder="Search..."
                      type="text"
                      fullWidth
                    />
                  </TextFieldWrapper>
                </SearchWrapper>
              </OrganizationTopWrapperSearchDetails>
            </OrganizationBlockFilter>
            <OrganizationTableWrapper>
              <OrganizationTable>
                <div
                  style={{
                    display: "flex",
                    width: "15%"
                  }}></div>
                <Table
                  sortHeader={sortHeader}
                  subtype={subtype}
                  mobileStatusFilter={mobileStatusFilter}
                  payloadData={organization}
                  searchValue={searchValue}
                  isPaginationNormal={false}
                  isAppUser={true}
                  tableData={appUser}
                  tableHeader={header}
                  handleDetails={handleUserDetails}
                  getDataList={
                    store.dispatch.Organization.getOrganizationlistAppUsers
                  }
                  organizationPage={appPage}
                />
              </OrganizationTable>
            </OrganizationTableWrapper>
          </OrganizationBlock>
          {openDialog && (
            <DialogOrganizationExport
              orgID={
                organization && organization.id !== undefined && organization.id
              }
              userList={userDatalist}
              open={openDialog}
              handleClose={handleCloseDialog}
              exportToExcel={
                store.dispatch.Organization.exportToExcelOrganization
              }
              setOpenDialog={setOpenDialog}
              enqueueSnackbar={enqueueSnackbar}
            />
          )}
        </Grid>
      </OrganizationWrapper>
    </>
  )
}

const mapStateToProps = state => {
  return {
    organizationState: state.Organization,
    dashboardState: state.Dashboard
  }
}

export default connect(
  mapStateToProps,
  null
)(OrganizationDetails)
