import styled, { css } from "styled-components";
export const TimelineStyle = styled.div`
         ol {
           list-style: none;
           margin: 0;
           display: -webkit-box;
           padding: 0;
           margin: 0 10px;
           ::-webkit-scrollbar-track {
             -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
             background-color: #f5f5f5;
             /* background-color:red */
           }

           ::-webkit-scrollbar {
             height: 5px;
             background-color: #f5f5f5;
           }

           ::-webkit-scrollbar-thumb {
             border-radius: 10px;
             background-image: -webkit-gradient(
               linear,
               left bottom,
               left top,
               color-stop(0.44, #9e9e9e61),
               color-stop(0.72, #9e9e9e9e),
               color-stop(0.86, #9e9e9ec7)
             );
           }
         }
         /* ol::before {
           content: "";
           width: 20px;
           height: 20px;
           background: #f2a700;
           position: absolute;
           border-radius: 50%;
           top: 108px;
           z-index: 2;
           left: 10px;
         } */
         /* ol::after {
           content: "";
           width: 20px;
           height: 20px;
           position: absolute;
           top: 104px;
           z-index: 2;
           right: 7px;
           border: 15px solid transparent;
           border-right: 0;
           border-left: 20px solid #f2a700;
           border-radius: 1px;
         } */
         li {
           width: 235px;
           min-height: 150px;
           display: flex;
           align-items: center;
           position: relative;
           margin: 20px 0;
           flex-direction: column;
           justify-content: flex-start;
         }

         li::after {
           content: "";
           height: 2px;
           width: 81%;
           position: absolute;
           top: 38px;
           background: #0a0a0a;
           opacity: 0.15;
           right: 60%;
         }
         li p {
           margin-bottom: 20px;
         }
         li {
           color: black;
         }
         li:nth-child(odd) {
           .point::after {
             content: "";
             border: 1px solid;
             position: absolute;
             top: 26px;
             left: 50%;
             height: 51px;
             bottom: 34px;
             opacity: 0.15;
           }
           .circle {
             width: 4px;
             height: 4px;
             border: 4px solid #f2a700;
             border-radius: 50%;
             position: absolute;
             top: 117px;
             z-index: 2;
             background: #fff;
             left: calc(50% - 3px);
           }
           .timestamp {
             display: flex;
             position: absolute;
             bottom: 130px;
             width: 175px;
             height: 30px;
             border-radius: 100px;
             box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04) !important;
             border: solid 1px rgba(0, 0, 0, 0.09);
             background-color: #ffffff;
             font-family: Google Sans;
             font-size: 14px;
             font-weight: 500;
             font-stretch: normal;
             font-style: normal;
             line-height: normal;
             letter-spacing: normal;
             text-align: center;
             color: #161a1e;
           }
           .timeSpanText {
             margin: 4px 0px 0px 22px;
           }
           .description {
             position: absolute;
             top: 127px;
             width: 187px;
             height: 36px;
             font-family: Google Sans;
             font-size: 14px;
             font-weight: normal;
             font-stretch: normal;
             font-style: normal;
             line-height: normal;
             letter-spacing: normal;
             text-align: center;
             color: #161a1e;
           }
         }
         li:nth-child(even) {
           .point::before {
             content: "";
             border: 1px solid;
             position: absolute;
             top: -76px;
             left: 50%;
             height: 55px;
             opacity: 0.15;
           }
           .circle-up {
             width: 4px;
             height: 4px;
             border: 4px solid #f2a700;
             border-radius: 50%;
             position: absolute;
             top: -54px;
             z-index: 2;
             background: #fff;
             left: calc(50% - 3px);
           }
           .timestamp {
             position: absolute;
             height: 30px;
             top: 55px;
             width: 175px;
             border-radius: 100px;
             box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04) !important;
             border: solid 1px rgba(0, 0, 0, 0.09);
             background-color: #ffffff;
             font-family: Google Sans;
             font-size: 15px;
             font-weight: 500;
             font-stretch: normal;
             font-style: normal;
             line-height: normal;
             letter-spacing: normal;
             text-align: center;
             color: #161a1e;
           }
           .description {
             position: absolute;
             bottom: 210px;
             width: 187px;
             font-family: Google Sans;
             font-size: 14px;
             font-weight: normal;
             font-stretch: normal;
             font-style: normal;
             line-height: normal;
             letter-spacing: normal;
             text-align: center;
             color: #161a1e;
           }
         }
         span.point {
           width: 15px;
           height: 15px;
           border: 3px solid #f2a700;
           border-radius: 50%;
           position: absolute;
           top: 31px;
           z-index: 2;
           background: #fff;
         }
         p.description {
           /* display: none; */
           /* text-align: center;
    margin: 0;
    margin-top: 25px; */
           text-align: center;
           margin: 0;
           margin-top: 30px;
           position: relative;
           padding-top: 45px;
         }

         /*          p.description::after {
           content: "";
           border: 1px solid;
           position: absolute;
           top: -30px;
           left: 50%;
           height: 51px;
           bottom: 34px;
           opacity: 0.15;
         } */

         @media (min-width: 1467px) {
           .sliderContainer {
             width: 1477px;
             margin: 0 auto;
             display: flex;
             max-width:100%;
           }
           .sliderWrapper {
             display: flex;
             width: 1467px;
             overflow: hidden;
             padding-top: 120px;
             padding-bottom: 50px;
             position: relative;
             margin: 0 auto;
           }
         }
         @media (min-width: 1412px) and (max-width: 1446px) {
           .sliderContainer {
             width: 1422px;
             margin: 0 auto;
             display: flex;
           }
           .sliderWrapper {
             display: flex;
             width: 1412px;
             overflow: hidden;
             padding-top: 120px;
             padding-bottom: 50px;
             position: relative;
             margin: 0 auto;
           }
         }

         @media (max-width: 1411px) {
           .sliderContainer {
             width: 1185px;
             margin: 0 auto;
             display: flex;
             max-width: 100%;
           }
           .sliderWrapper {
             display: flex;
             width: 1175px;
             overflow: hidden;
             padding-top: 120px;
             padding-bottom: 50px;
             position: relative;
             margin: 0 auto;
             max-width: calc(100% - 54px);
           }
         }
       `;



export const SliderContainer = styled.div`
  position: relative;
  background: #fafafa;
  overflow: hidden;
  width: 100%;

  height: ${props => props.height || "500px"};
`;

export const SliderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  display: flex;
  flex-wrap: wrap;
`;

export const SliderItem = styled.div`
  position: relative;
  height: 500px;
  width: ${props => props.width + "px" || "100%"};
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
`;

export const Navigation = styled.ul`
  position: absolute;
  bottom: 10px;
  margin: 0;
  padding: 0;
  left: 0;
  display: flex;
`;

export const NavigationItem = styled.li`
  list-style: none;
  width: 10px;
  height: 10px;
  margin: 0 3px;
  background: #f00;
  border-radius: 100%;
  cursor: pointer;

  ${props =>
    props.active &&
    css`
      background: #000;
    `};
`;

export const Control = styled.div`
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  margin: 10px;
  cursor: pointer;
`;

export const ControlLeft = styled(Control)`
  left: 0;
`;
export const ControlRight = styled(Control)`
  right: 0;
`;

export const BigElement = styled.div`
  min-height: 1000px;
  background: #61dafb;
  width: 30px;
`;

