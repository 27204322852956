import React from "react";
import { Grid } from "@material-ui/core";
import { useHistory } from "react-router-dom";

import PermissionDeniedImage from "../../assets/img/permission_denied.png";
import { useStyles } from "./permissiondenied.style";

const PermissionDenied = () => {
  let history = useHistory();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container direction="column" justify="center" alignItems="center">
        <img
          src={PermissionDeniedImage}
          className={classes.permissionDeniedImg}
          alt="Error 403: Permission Denied"
        />
        <h3 className={classes.errorText}>Sorry, you do not have permission to access this page.</h3>
        <button onClick={() => history.goBack()} className={classes.linkHome}>
          <span>Back</span>
        </button>
      </Grid>
    </div>
  );
};

export default PermissionDenied;
