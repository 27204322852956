import { api } from "../utils/http";

export const initialState = {
  error: "",
  userWatchlist: [],
  polygonWatchList: [],
  plottedCoordinates: [],
  userLocations: [],
  network: [],
  bluetooth: [],
  wifiData: [],
  networkDataTimeline: [],
  bluetoothDataTimeline: [],
  wifiDataTimeline: [],
  bluetoothDeviceList: [],
  searchText: "",
  islocationWatchlistLoading: false,
  isuserWatchlistLoading: false,
  isnetworkWatchlistLoading: false,
  iswifiWatchlistLoading: false,
  isbluetoothWatchlistLoading: false
};

export const reducers = {
  SetSearchText(state, data) {
    return {
      ...state,
      searchText: data
    };
  },
  BluetoothDeviceList(state, payload, searchData) {
    if (state.searchText === searchData) {
      return {
        ...state,
        bluetoothDeviceList: payload.data
      };
    }
    return state;
  },
  UserWatchlistClear(state) {
    return {
      ...state,
      userWatchlist: []
    };
  },
  UserWatchlist(state, payload) {
    return {
      ...state,
      userWatchlist: payload.data.users
    };
  },
  NetworkWatchlist(state, payload) {
    return {
      ...state,
      network: payload
    };
  },
  BluetoothWatchlist(state, payload) {
    return {
      ...state,
      bluetooth: payload
    };
  },
  WifiWatchlist(state, payload) {
    return {
      ...state,
      wifiData: payload
    };
  },
  NetworkWatchlistClear(state) {
    return {
      ...state,
      network: []
    };
  },
  BluetoothWatchlistClear(state) {
    return {
      ...state,
      bluetooth: []
    };
  },
  WifiWatchlistClear(state) {
    return {
      ...state,
      wifiData: []
    };
  },
  NetworkWatchlistTimeline(state, payload) {
    return {
      ...state,
      networkDataTimeline: payload
    };
  },
  BluetoothWatchlistTimeline(state, payload) {
    return {
      ...state,
      bluetoothDataTimeline: payload
    };
  },
  WifiWatchlistTimeline(state, payload) {
    return {
      ...state,
      wifiDataTimeline: payload
    };
  },
  NetworkWatchlistTimelineClear(state) {
    return {
      ...state,
      networkDataTimeline: []
    };
  },
  BluetoothWatchlistTimelineClear(state) {
    return {
      ...state,
      bluetoothDataTimeline: []
    };
  },
  WifiWatchlistTimelineClear(state) {
    return {
      ...state,
      wifiDataTimeline: []
    };
  },
  PolygonWatchlistClear(state) {
    return {
      ...state,
      polygonWatchList: []
    };
  },
  PolygonWatchlist(state, payload) {
    return {
      ...state,
      polygonWatchList: payload
    };
  },
  PolygonWatchlistUser(state, payload) {
    return {
      ...state,
      userLocations: payload
    };
  },
  PolygonWatchlistPlottedClear(state) {
    return {
      ...state,
      plottedCoordinates: [],
      watchlist_id: "",
      radius: 0
    };
  },
  PolygonWatchlistPlotted(state, payload) {
    return {
      ...state,
      plottedCoordinates: payload.coordinate,
      watchlist_id: payload.watchlist_id,
      radius: payload.radius
    };
  },
  addError(state, payload) {
    return {
      ...state,
      error: payload
    };
  },
  removeError(state) {
    return {
      ...state,
      error: ""
    };
  },
  setWatchlistLoading(state, payload) {
    return {
      ...state,
      [`is${payload.type}WatchlistLoading`]: payload.status
    };
  }
};

export const effects = {
  async getBluetoothList(searchData) {
    this.removeError();
    this.SetSearchText(searchData);
    try {
      const res = await api
        .get(`/api/v1/device/bluetooth/?unique=1&search=${searchData}`)
        .then(response => {
          this.BluetoothDeviceList(response.data, searchData);
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getUserWatchList() {
    this.removeError();
    this.UserWatchlistClear();
    let url = `/api/v1/watchlist/user/`;
    this.setWatchlistLoading({ type: "user", status: true });
    try {
      const res = await api
        .get(url)
        .then(response => {
          this.UserWatchlist(response.data);
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        })
        .finally(() =>
          this.setWatchlistLoading({ type: "user", status: false })
        );
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getUserWatchListFilter(payload, state, cleartimeline) {
    this.removeError();
    let url = `/api/v1/watchlist/user/`;
    if (payload.organizations && payload.organizations.length > 0) {
      let s = "";
      for (var i = 0; i < payload.organizations.length; i++) {
        if (payload.organizations[i] === "0") {
          s = ``;
        } else {
          s += `&organization_id[]=${payload.organizations[i]}`;
        }
      }
      url = `/api/v1/watchlist/user/?${s}`;
    }
    if (payload.Search) {
      url = `/api/v1/watchlist/user/?search=${payload.Search}`;
    }
    this.setWatchlistLoading({ type: "user", status: true });
    try {
      const res = await api
        .get(url)
        .then(response => {
          if (response.data.data.users.length === 0 && cleartimeline) {
            cleartimeline();
          }
          this.UserWatchlist(response.data);
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        })
        .finally(() =>
          this.setWatchlistLoading({ type: "user", status: false })
        );
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getConnectivityWatchlist({ payload }) {
    this.removeError();
    let url = `/api/v1/watchlist/device_data/?data_type=${payload.connectivityType}`;
    if (payload.searchTerm) {
      url = `/api/v1/watchlist/device_data/?search=${payload.searchTerm}&data_type=${payload.connectivityType}`;
    }
    if (payload.from_datetime && payload.to_datetime) {
      url = `/api/v1/watchlist/device_data/?data_type=${payload.connectivityType}&from_datetime=${payload.from_datetime}&to_datetime=${payload.to_datetime}`;
    }
    // `/api/v1/watchlist/device_data/?data_type=wifi&search=ssid1&sort_by=-created_on&from_datetime=%s&to_datetime=%s' % ( from_datetime_str, to_datetime_str)`
    this.setWatchlistLoading({ type: payload.connectivityType, status: true });
    switch (payload.connectivityType) {
      case "wifi":
        this.WifiWatchlistTimelineClear();
        break;
      case "bluetooth":
        this.BluetoothWatchlistTimelineClear();
        break;
      case "network":
        this.NetworkWatchlistTimelineClear();
        break;
      default:
        break;
    }
    try {
      const res = await api
        .get(url)
        .then(response => {
          if (payload.connectivityType === "wifi") {
            this.WifiWatchlist(response.data.data.watchlists);
          } else if (payload.connectivityType === "bluetooth") {
            this.BluetoothWatchlist(response.data.data.watchlists);
          } else {
            this.NetworkWatchlist(response.data.data.watchlists);
          }
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        })
        .finally(() =>
          this.setWatchlistLoading({
            type: payload.connectivityType,
            status: false
          })
        );
    } catch (err) {
      this.addError(err.toString());
    }
  },

  async startTimelineDevice(payload, state) {
    let URL = "";
    if (payload.data !== undefined) {
      URL = `api/v1/timeline/device_data?data=${payload.data}&data_type=${payload.connectivityType}&from_date=${payload.from_datetime}&to_date=${payload.to_datetime}`;
      try {
        const res = await api
          .get(URL, payload)
          .then(response => {
            if (payload.connectivityType === "wifi") {
              this.WifiWatchlistTimeline(response.data.data.timeline);
            } else if (payload.connectivityType === "bluetooth") {
              this.BluetoothWatchlistTimeline(response.data.data.timeline);
            } else {
              this.NetworkWatchlistTimeline(response.data.data.timeline);
            }
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },

  async getPolygonWatchList(search) {
    this.removeError();
    let url = `/api/v1/watchlist/location/`;
    if (search) {
      url = `/api/v1/watchlist/location/?search=${search}`;
    }
    this.setWatchlistLoading({ type: "location", status: true });
    try {
      const res = await api
        .get(url)
        .then(response => {
          this.PolygonWatchlist(response.data.data.watchlists);
          if (!search) {
            let plottingData = {
              coordinate: response.data.data.watchlists[0].coordinates,
              watchlist_id: response.data.data.watchlists[0].id,
              radius: response.data.data.watchlists[0].radius
            };
            this.PolygonWatchlistPlotted(plottingData);
          }
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        })
        .finally(() =>
          this.setWatchlistLoading({ type: "location", status: false })
        );
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getPolygonWatchlistCoordinates({
    payload,
    coodrinatesForPlotting,
    radius
  }) {
    this.removeError();
    let url = `/api/v1/watchlist/w_location/users_in/?watchlist_id=${payload.watchlist_id}&from_datetime=${payload.from_datetime}&to_datetime=${payload.to_datetime}`;
    // if(searchTerm){
    // url = `/api/v1/watchlist/Polygon/?pagination=1&limit=1&organization_id[]=1&organization_id[]=1&search=dpk`;
    // }
    try {
      const res = await api
        .get(url)
        .then(response => {
          let plottingData = {
            coordinate: coodrinatesForPlotting,
            watchlist_id: payload.watchlist_id,
            radius
          };
          this.PolygonWatchlistUser(response.data.data.user_locations);
          this.PolygonWatchlistPlotted(plottingData);
          //  this.PolygonWatchlist(response.data.data.watchlists);
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async addToWatchlistLocation(
    payload,
    state,
    {
      enqueueSnackbar,
      setOpenWatchList,
      setDrawerManager,
      setAddLocation,
      setReasonPayload,
      setFilterDisable,
      setSelectedIndexLoc,
      setOrganization,
      setUser
    }
  ) {
    let URL = `/api/v1/watchlist/location/`;
    try {
      const res = await api
        .post(URL, payload)
        .then(response => {
          if (response.status === 200) {
            setUser(["0"]);
            setOrganization(["0"]);
            setReasonPayload("");
            let variant = "success";
            enqueueSnackbar("Added shape to watchlist successfully!", {
              variant
            });
            setOpenWatchList(false);
            setDrawerManager(false);
            setAddLocation(false);
            setFilterDisable(true);
            setSelectedIndexLoc(0);
            setTimeout(() => this.getPolygonWatchList(), 3000);
          }
        })
        .catch(err => {
          let variant = "error";
          this.addError(err.response.toString());
          if (err.response.data.message === "Name: Not a valid string.") {
            enqueueSnackbar("Please enter the name for adding polygon/circle", {
              variant
            });
          }
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async notifications({
    payload,
    enqueueSnackbar,
    popupState,
    setReasonPayload,
    setMassTextPayload,
    setOpenMassText,
    setOpenMassPush,
    payloadData,
    isGeoSpatial,
    setFilterDisable,
    history
  }) {
    let URL = `/api/v1/mass_notification/`;
    try {
      const dataPayload = isGeoSpatial ? payloadData : payload;
      const res = await api
        .post(URL, dataPayload)
        .then(response => {
          let variant = "success";
          if (dataPayload.notification_type == "push") {
            setReasonPayload(""), setMassTextPayload({});
            setOpenMassPush(false);
            enqueueSnackbar("Push created  successfully!", {
              variant
            });
          } else {
            setReasonPayload(""), setMassTextPayload({});
            setOpenMassText(false);
            enqueueSnackbar("Text created  successfully!", {
              variant
            });
          }
          if (history) {
            history.push("/massnotification");
          }
        })
        .catch(err => {
          let variant = "error";
          this.addError(err.response.toString());

          if (dataPayload.notification_type == "push") {
            if (
              err.response.data.message === "Name: Not a valid string." ||
              err.response.data.message === "Name: This field may not be blank."
            ) {
              enqueueSnackbar(
                "Please enter the name for sending mass push notifications",
                {
                  variant
                }
              );
            }
          }
          if (dataPayload.notification_type == "text") {
            if (
              err.response.data.message === "Name: Not a valid string." ||
              err.response.data.message === "Name: This field may not be blank."
            ) {
              enqueueSnackbar("Please enter the name for sending mass text", {
                variant
              });
            }
          }
        });
    } catch (err) {
      let variant = "error";
      //  this.addError(err.toString());
    }
  },
  async removefromPolygonWatchlist({
    payload,
    enqueueSnackbar,
    popupState,
    setPolygonWatch
  }) {
    try {
      const res = await api
        .delete(`/api/v1/watchlist/location/${payload.watchlist_id}/`)
        .then(response => {
          popupState.close();
          let variant = "success";
          enqueueSnackbar("Removed user from watchlist successfully!", {
            variant
          });
          setTimeout(() => {
            // window.location.reload();
            this.PolygonWatchlistClear();
            this.PolygonWatchlistPlottedClear();
            window.location.reload();
          }, 1000);
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
          enqueueSnackbar(err.response.data.message, {
            variant
          });
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
      enqueueSnackbar(err.response.data.message, {
        variant
      });
    }
  },
  async removefromUserWatchlist(
    payload,
    state,
    { setOpenConfirmWatchlist, enqueueSnackbar }
  ) {
    try {
      const res = await api
        .post(`/api/v1/watchlist/remove_user/`, payload)
        .then(response => {
          if (response.status === 200) {
            let variant = "success";
            enqueueSnackbar("Removed user from watchlist successfully!", {
              variant
            });
            setOpenConfirmWatchlist(false);
            this.getUserWatchList();
          }
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
    }
  },
  async addToWatchlist(
    payload,
    state,
    {
      enqueueSnackbar,
      setOpenWatchList,
      setReasonPayload,
      setOrganizationAdding,
      setUser,
      setSelectedIndexUsr,
      getUserlist
    }
  ) {
    try {
      const res = await api
        .post(`/api/v1/watchlist/user/`, payload)
        .then(response => {
          let variant = "success";
          enqueueSnackbar("Added user to watchlist successfully!", {
            variant
          });
          this.getUserWatchList();
          setOpenWatchList(false);
          setReasonPayload("");
          setOrganizationAdding(0);
          setUser(0);
          setSelectedIndexUsr(0);
          getUserlist();
        });
      //  .catch(err => {
      //    let variant = "error";
      //    this.addError(err);
      //    enqueueSnackbar(err.response.data.message, {
      //      variant
      //    });
      //    this.removeSuccess();
      //  });
    } catch (err) {
      let variant = "error";
      this.addError(err);
      if (err.response.data.message == "User id: This field is required.") {
        enqueueSnackbar("Please select user for adding to watchlist", {
          variant
        });
      } else {
        enqueueSnackbar(err.response.data.message, {
          variant
        });
      }

      this.removeSuccess();
    }
  },
  async addDeviceToWatchlist(
    payloadData,
    state,
    {
      enqueueSnackbar,
      setOpenWatchList,
      setReasonPayload,
      setDeviceWatchlistName,
      setSelectedIndex
    }
  ) {
    try {
      const res = await api
        .post(`/api/v1/watchlist/device_data/`, payloadData)
        .then(response => {
          let variant = "success";
          enqueueSnackbar("Added device to watchlist successfully!", {
            variant
          });
          let payload = {
            connectivityType: payloadData.data_type
          };
          this.getUserWatchList();
          this.getConnectivityWatchlist({ payload });
          setOpenWatchList(false);
          setReasonPayload("");
          setDeviceWatchlistName("");
          setSelectedIndex(0);
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
          enqueueSnackbar(err.response.data.message, {
            variant
          });
          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
      enqueueSnackbar(err.response.data.message, {
        variant
      });
      this.removeSuccess();
    }
  },
  async removeDevicesfromWatchlist(
    payloadData,
    state,
    { setOpenConfirmWatchlist, enqueueSnackbar, type }
  ) {
    try {
      const res = await api
        .delete(`/api/v1/watchlist/device_data/${payloadData.id}/`)
        .then(response => {
          if (response.status === 200) {
            this.NetworkWatchlistClear();
            let variant = "success";
            enqueueSnackbar("Removed user from watchlist successfully!", {
              variant
            });
            setOpenConfirmWatchlist(false);

            this.getUserWatchList();
            let type = ["network", "bluetooth", "wifi"];
            type.forEach(element => {
              let payload = {
                connectivityType: element
              };
              this.getConnectivityWatchlist({ payload });
            });
          }
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
    }
  }
};
export default {
  state: initialState,
  reducers,
  effects
};
