import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import "babel-polyfill";
// import "react-app-polyfill/ie11"; 
window.markers = [];
window.paths=[];
window.polygon=[];
window.circle = [];
ReactDOM.render(<App />, document.getElementById("app"));
