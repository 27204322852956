import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
         root: {
           flexGrow: 1
         },
         paper: {
           padding: theme.spacing(1),
           textAlign: "center",
           color: theme.palette.text.secondary
         },
         orgSelect: {
           height: "39px !important",
           borderRadius: "8px !important",
           boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.04) !important",
           border: "solid 1px rgba(71, 59, 59, 0.2) !important",
           backgroundColor: "#ffffff !important",
           padding: "5px",
           fontFamily: "Google Sans",
           margin: "5px 16px 3px 5px",
           width: "173px"
         },
         moreActionsText: {
           height: "18px",
           fontFamily: "Google Sans",
           fontSize: "14px",
           fontHeight: "500",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: " normal",
           color: " #ff2d21",
           margin: "4px 0px 0px 27px"
         },
         imgOpen: {
           width: "12px",
           height: "7px",
           margin: " 10px 2px 2px 30px"
         },
         errorMessage:{
           color:"#ff2d21",
           fontSize: "12px",
           textAlign: "center",
           width: "100%",
         },
         dateTimePicker: {
           margin: "5px 16px 3px 5px !important",
              width: "242px",
           "& $div": {
             textDecoration: "none",
             "& $input": {
               margin: "10px",
               width: "100%",
              //  height: "26px",
               fontFamily: "Google Sans",
               fontSize: "12px",
               fontWeight: "normal",
               fontStretch: "normal",
               fontStyle: "normal",
              //  lineHeight: "normal",
               letterSpacing: "normal",
               color: "#999999",
               paddingTop: "0px",
              //  paddingBottom: "10px"
             },
             "& $button": {
              // marginBottom: "10px",
              // marginBight: "3px",
             }
           }
         }
       }));
