import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    width: "100%"
  },
  mapContainer: {
    minHeight: "740px",
    position: "relative",
    overflow: "hidden",
    width: "100%",
    borderRadius: "16px"
  },
  mapRefStyle: {
    width: "100%",
    height: "100%"
  },
  loaderStyle: {
    position: "absolute",
    left: "0",
    top: "0",
    right: "0",
    bottom: "0",
    background: "rgba(0,0,0,0.5)",
    zIndex: "1"
  },
  overContent: {
    position: "absolute",
    top: "10px",
    left: "10px",
    zIndex: "99"
  }
}));
