import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(() => ({
  root: {
    height: "calc(100% - 50px)",
    overflowY: "hidden"
  },
  pageNotFoundImg: {
    marginTop: "5%"
  },
  errorText: {
    marginTop: "2%",
    fontFamily: "Google Sans",
    fontWeight: "300"
  },
  linkHome: {
    marginTop: "2%",
    height: "38px",
    borderRadius: "8px",
    backgroundColor: "#ff2d21",
    cursor: "pointer",
    padding: "10px 25px",
    textDecoration: "none",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.2)",
    "& span": {
      textDecoration: "none",
      display: "flex",
      color: "#ffffff",
      fontFamily: "Google Sans",
      fontWeight: "bold",
      fontSize: "14px"
    }
  }
}));
