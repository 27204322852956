import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ListSubheader from "@material-ui/core/ListSubheader";
import TextField from "../TextField";
import AutoStyled from "./styled";


const AutoSuggest = ({
  textFieldProps,
  value,
  options,
  optionLabel,
  onOptionSelect,
  loading,
  autoChange,
   optionLabelOrg,
  ...props
}) => {
  const [showDrop, toggleDrop] = useState(false);
  const [inputVal, setInputVal] = useState(value);
  const searchRef = useRef(null);
  const optionsList = useRef(null);
  const cursorPos = useRef(-1);

  const outsideClick = e => {
    if (searchRef.current && !searchRef.current.contains(e.target)) {
      toggleDrop(false);
    }
  };

  useEffect(() => {
    cursorPos.current = -1;
  }, [showDrop]);

  useEffect(() => {
    window.addEventListener("mousedown", outsideClick);
    return () => {
      window.removeEventListener("mousedown", outsideClick);
    };
  }, [searchRef]);

  useEffect(() => {
    if (value.trim("").length > 0  && inputVal !== value) {
      toggleDrop(true);
    } else {
      toggleDrop(false);
    }
  }, [value, inputVal]);

  const handleListKeyUp = event => {
    if (optionsList.current) {
      let childNodes = [...optionsList.current.childNodes];
      childNodes = childNodes.filter(node => {
        const classList = [...node.classList];
        return classList.indexOf("droplist-item") > -1;
      });
      if (event.key === "ArrowUp" && showDrop && cursorPos.current - 1 >= 0) {
        childNodes[cursorPos.current - 1].focus();
        cursorPos.current = cursorPos.current - 1;
      } else if (
        event.key === "ArrowDown" &&
        showDrop &&
        cursorPos.current + 1 < options.length
      ) {
        childNodes[cursorPos.current + 1].focus();
        cursorPos.current = cursorPos.current + 1;
      }
    }
  };

  const onSelect = option => () => {
    toggleDrop(false);
    onOptionSelect(option);
    setInputVal(option)
  };

  const onOptionKeyUp = option => event => {
    if (event.keyCode === 13) {
      onSelect(option);
    }
  };

  return (
    <AutoStyled ref={searchRef}>
      <TextField
        {...textFieldProps}
        onKeyUp={handleListKeyUp}
        Value={value}
        autoChange={autoChange}
        // endAdornment={
        //     loading ? <span className='progress'><CircularProgress color="inherit" size={20} /></span> : null
        // }
      />
      <MenuList
        ref={optionsList}
        className={`options-wrapper ${!showDrop ? "hide" : ""}`}>
        {options && options.users && (
          <div>
            {options.users.length > 0 && <ListSubheader disableSticky={true}>USERS</ListSubheader>}
        
            {options.users.map(option => (
              <MenuItem
                className="droplist-item option-item"
                tabIndex="0"
                onKeyUp={onOptionKeyUp(option)}
                key={option[optionLabel]}
                onClick={onSelect(option)}>
                {option[optionLabel]}
              </MenuItem>
            ))}
          </div>
        )}
        {options && options.organizations && (
          <div>
            {options.organizations.length > 0 && (
              <ListSubheader>ORGANIZATION</ListSubheader>
            )}
            {options.organizations.map(option => (
              <MenuItem
                className="droplist-item option-item"
                tabIndex="0"
                onKeyUp={onOptionKeyUp(option)}
                key={option[optionLabelOrg]}
                onClick={onSelect(option)}>
                {option[optionLabelOrg]}
              </MenuItem>
            ))}
          </div>
        )}
        {options && options.devices && (
          <div>
            {options.devices.map(option => (
              <MenuItem
                className="droplist-item option-item"
                tabIndex="0"
                onKeyUp={onOptionKeyUp(option.b_device_name)}
                key={option.id}
                onClick={onSelect(option.b_device_name)}>
                <div title={option.b_device_name}> {option.b_device_name} </div>
              </MenuItem>
            ))}
          </div>
        )}
      </MenuList>
    </AutoStyled>
  );
};

AutoSuggest.defaultProps = {
  textFieldProps: {},
  onOptionSelect: () => {}
};

AutoSuggest.propTypes = {
  textFieldProps: PropTypes.object,
  onOptionSelect: PropTypes.func
};

export default AutoSuggest;
