import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import DeleteIcon from "@material-ui/icons/Delete";
import { onlineOval, offlineOval } from "../slider.style";
import Delete from "../../../assets/img/delete.png";
import MoreIcon from "../../../assets/img/moreicon.png";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Fade from "@material-ui/core/Fade";
import moment from "moment";
import Popper from "@material-ui/core/Popper";
import ListPopper from "../../WatchList/ListPopper";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    MuiDividerInset: {
      marginLeft: "0px"
    }
  },
  inline: {
    display: "inline"
  },
  onlineOval: {
    margin: "4px 0px 0px 0px",
    display: "flex",
    width: "8px",
    height: "11px",
    backgroundColor: "#4cd964",
    padding: "0px 0px 0px 10px",
    borderRadius: "30px",
    color: "#4cd964"
  },
  offlineOval: {
    margin: "4px 0px 0px 0px",
    display: "flex",
    width: "8px",
    height: "11px",
    backgroundColor: "rgb(255, 45, 33)",
    padding: "0px 0px 0px 10px",
    borderRadius: "30px",
    color: "rgb(255, 45, 33)"
  },
  lastUpdated: {
    width: "274px",
    height: "13px",
    opacity: "0.48",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontHeight: " 500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #000000"
  },
  spanText: {
    display: " flex",
    margin: " -4px 0px 0px 6px"
  },
  spanTextPolygon: {
    display: "inline-flex",
    margin: " -2px 0px 0px 0px",
    padding: "2px 5px",
    height: " 24.7px",
    borderRadius: "100px",
    border: "solid 1px #4cd964",
    backgroundcolor: " #ffffff",
    fontFamily: " Google Sans",

    textAlign: "center",
    color: "#31c04a"
  },
  moreicon: {
    width: " 5px"
  },
  moreActions: {
    cursor: "pointer"
  },
  listItem: {
    "&$active": {
      backgroundColor: "#f7f8f9"
    }
  },
  noRecordsFound: {
    textAlign: "center"
  }
}));

export default function AlignItemsList({
  id,
  dataSlider,
  deleteWatchlist,
  plotted,
  handleNotification,
  handleClickOpenConfirm,
  handleClickOpenMassText,
  handleClickOpenMassPush,
  handelSelectItem,
  selectedIndex,
  setSelectedIndex
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <List className={classes.root}>
      {id === "User" &&
        (dataSlider &&
          dataSlider.map((i, index) => (
            <React.Fragment key={index}>
              <ListItem
                style={{
                  cursor: "pointer"
                }}
                selected={selectedIndex === index}
                alignItems="flex-start"
                onClick={() => {
                  setSelectedIndex(index);
                  handelSelectItem(i);
                }}>
                <ListItemText
                  primary={
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}>
                        <span>{i.user.username}</span>
                        <span
                          className={classes.moreActions}
                          onClick={() => {
                            handleClickOpenConfirm(i, id);
                          }}>
                          <img src={Delete} />
                        </span>
                      </div>
                    </>
                  }
                  secondary={
                    <>
                      <span
                        className={
                          i.user.is_online
                            ? classes.onlineOval
                            : classes.offlineOval
                        }>
                        <span className={classes.spanText}>
                          {i.user.is_online ? "Online" : "Offline"}
                        </span>
                      </span>
                      <span className={classes.lastUpdated}>
                        On watchlist since{" "}
                        {moment
                          .utc(i.created_on)
                          .local()
                          .format("MMM - DD -YYYY hh:mm:ss")}
                      </span>
                    </>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          )))}
      {id === "Location" &&
        (dataSlider &&
          dataSlider.map((i, index) => (
            <React.Fragment key={index}>
              <ListItem
                button
                selected={selectedIndex === index}
                alignItems="flex-start"
                onClick={() => {
                  setSelectedIndex(index);
                  plotted(i);
                }}>
                <ListItemText
                  primary={
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between"
                        }}>
                        <span
                          style={{
                            cursor: "pointer",
                            overflow: " hidden",
                            textOverflow: "ellipsis"
                          }}>
                          {i.name}
                        </span>

                        <ListPopper
                          data={i}
                          classes={classes}
                          handleClickOpenConfirm={handleClickOpenConfirm}
                          deleteWatchlist={deleteWatchlist}
                          MoreIcon={MoreIcon}
                          handleClick={handleClick}
                          handleClose={handleClose}
                          handleNotification={handleNotification}
                          handleClickOpenMassText={handleClickOpenMassText}
                          handleClickOpenMassPush={handleClickOpenMassPush}
                        />
                      </div>
                    </>
                  }
                  secondary={
                    <>
                      <div>
                        <span className={classes.spanTextPolygon}>
                          <span
                            style={{
                              display: "flex",
                              margin: "0px 0px 0px 3px"
                            }}>
                            {`${i.active_users_count} active users`}
                          </span>
                        </span>
                      </div>
                      <span className={classes.lastUpdated}>
                        On watchlist since{" "}
                        {moment(i.created_on_timestamp).format(
                          "MMM-DD-YYYY hh:mm:ss"
                        )}
                      </span>
                    </>
                  }
                />
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          )))}
      {(id === "Wifi" || id === "Cellular Network" || id === "Bluetooth") &&
        dataSlider &&
        dataSlider.map((i, index) => (
          <React.Fragment key={index}>
            <ListItem
              button
              selected={selectedIndex === index}
              alignItems="flex-start"
              onClick={() => {
                setSelectedIndex(index);
                handelSelectItem(i, id);
              }}>
              <ListItemText
                primary={
                  <>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between"
                      }}>
                      <span
                        style={{
                          cursor: "pointer",
                          overflow: " hidden",
                          textOverflow: "ellipsis"
                        }}>
                        {i.name}
                      </span>

                      <span
                        className={classes.moreActions}
                        onClick={() => {
                          handleClickOpenConfirm(i, id);
                        }}>
                        <img src={Delete} />
                      </span>
                    </div>
                  </>
                }
                secondary={
                  <>
                    <div>
                      <span className={classes.spanTextPolygon}>
                        <span
                          style={{
                            display: "flex",
                            margin: "0px 0px 0px 3px"
                          }}>
                          {`${i.active_users_count} active users`}
                        </span>
                      </span>
                    </div>
                    <span className={classes.lastUpdated}>
                      On watchlist since{" "}
                      {moment
                        .utc(i.created_on)
                        .local()
                        .format("DD MMM,YYYY hh:mm:ss")}
                    </span>
                  </>
                }
              />
            </ListItem>
            <Divider component="li" />
          </React.Fragment>
        ))}
      {dataSlider && dataSlider.length === 0 && (
        <p className={classes.noRecordsFound}>No records found.</p>
      )}
    </List>
  );
}
