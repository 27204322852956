import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
         root: {
           display: "flex",
           width: "100%"
         },
         riskoveryFooter: {
           flex: "auto",
           width: "218px",
           height: "23px",
           fontFamily: 'Google Sans !important',
           fontSize: "12px",
           fontHeight: "normal",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "1.92",
           letterSpacing: "normal",
           textAlign: "center",
           color: "#161a1e"
         }
       }));
