import React, { Component, useEffect } from "react";
import { Line } from "react-chartjs-2";
import moment from "moment";
import { arrayIncludes } from "@material-ui/pickers/_helpers/utils";
function LineChart(props) {
  const [LABEL, setLabel] = React.useState([]);
  const [DATA, setSpeedData] = React.useState([]);
  const [dataLabel, setdataLabel] = React.useState("speed");

  const setXaxisLabel = (dataType, labelSet, dataSet) => {
    if (dataType.length != 0) {
      if (new Date(props.fromDate) < new Date(dataType[0].timestamp)) {
        labelSet.unshift(
          moment(new Date(props.fromDate), "MM/DD/YYYY HH:mm:ss")
            .local()
            .format("MM/DD/YYYY HH:mm:ss")
        );
        dataSet.unshift(0);
      }
      if (
        new Date(props.toDate) >
        new Date(dataType[dataType.length - 1].timestamp)
      ) {
        const dateTimeWithoutSec =
          moment(new Date(props.toDate), "MM/DD/YYYY HH:mm")
            .local()
            .format("MM/DD/YYYY HH:mm") + ":00";
        labelSet.push(dateTimeWithoutSec);
        dataSet.push(0);
      }
    } else if (dataType.length === 0) {
      labelSet.push(
        moment(new Date(props.fromDate), "MM/DD/YYYY HH:mm:ss")
          .local()
          .format("MM/DD/YYYY HH:mm:ss")
      );
      labelSet.push(
        moment(new Date(props.toDate), "MM/DD/YYYY HH:mm:ss")
          .local()
          .format("MM/DD/YYYY HH:mm:ss")
      );
    }
    return { label: labelSet, data: dataSet };
  };
  useEffect(() => {
    let labelSet = [],
      dataSet = [];
    setLabel([]);
    setSpeedData([]);
    setdataLabel("");
    if (props.chartData && props.chartData !== undefined) {
      if ("speed_data" in props.chartData) {
        setdataLabel("Speed");
        const { speed_data } = props.chartData;
        speed_data.map(item => {
          let timeString = item.timestamp;
          labelSet.push(moment(item.timestamp).format("MM/DD/YYYY HH:mm:ss"));

          dataSet.push(item.speed);
        });
        const newLabelData = setXaxisLabel(speed_data, labelSet, dataSet);
        labelSet = newLabelData.label;
        dataSet = newLabelData.data;
      }
      const uniqList = labelSet.filter(
        (s1, pos, arr) => arr.findIndex(s2 => s2 === s1) === pos
      );
      setLabel(uniqList);
      setSpeedData(dataSet);
    }
    if (props.chartData && props.chartData !== undefined) {
      if ("elevation_data" in props.chartData) {
        setdataLabel("Elevation");
        const { elevation_data } = props.chartData;
        elevation_data.map(item => {
          labelSet.push(moment(item.timestamp).format("MM/DD/YYYY HH:mm:ss"));

          dataSet.push(item.elevation);
        });

        const newLabelData = setXaxisLabel(elevation_data, labelSet, dataSet);
        labelSet = newLabelData.label;
        dataSet = newLabelData.data;
      }
      const uniqList = labelSet.filter(
        (s1, pos, arr) => arr.findIndex(s2 => s2 === s1) === pos
      );
      setLabel(uniqList);
      setSpeedData(dataSet);
    }
    if (props.chartData && props.chartData !== undefined) {
      if ("heading_data" in props.chartData) {
        setdataLabel("Heading");
        const { heading_data } = props.chartData;
        heading_data.map(item => {
          let temp = item.timestamp;
          labelSet.push(moment(item.timestamp).format("MM/DD/YYYY HH:mm:ss"));
          dataSet.push(item.heading);
        });
        const newLabelData = setXaxisLabel(heading_data, labelSet, dataSet);
        labelSet = newLabelData.label;
        dataSet = newLabelData.data;
      }
      const uniqList = labelSet.filter(
        (s1, pos, arr) => arr.findIndex(s2 => s2 === s1) === pos
      );
      setLabel(labelSet);
      setSpeedData(dataSet);
    }
    ["2019-01-01", "", ""];
  }, [props.chartData]);

  const data = {
    // labels: LABEL.length > 20 ? [...LABEL.slice(0, 10), ...LABEL.slice((LABEL.length -10), LABEL.length)]: LABEL,
    labels: LABEL,
    datasets: [
      {
        label: dataLabel,
        data: DATA,
        fill: false, // Don't fill area under the line
        borderColor: "#5ac8fa" // Line color
      }
    ]
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            beginAtZero: true,
            autoSkip: true,
            maxTicksLimit: 22
          }
        }
      ]
    }
  };
  return (
    <article
      className="canvas-container"
      style={{
        height: props.shrink ? "70vh" : "50vh",
        width: "100%"
      }}>
      <Line
        data={data}
        width={15000}
        options={options}
        key={props.shrink ? "shrinked" : "notshrinked"}
      />
    </article>
  );
}

export default LineChart;

// width="600" height="600"
