import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: "Google Sans",
    display: "flex",
    height: " 585px",
    borderRadius: " 10px",
    backgroundColor: " #ffffff",
    flexDirection: "column"
  },
  selectDropdown: {
    margin: " 0px 20px 0px 0px",
    width: "234px",
    height: "38px",
    borderRadius: " 8px",
    border: " solid 1px rgba(71, 59, 59, 0.2)",
    backgroundColor: " #ffffff"
  },
  heading: {
    marginBottom: "15px",
    width: " 100%",
    height: "38px",
    fontFamily: " Google Sans",
    fontSize: " 30px",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: " normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#161a1e"
  },
  tableHead: {
    width: "100%",
    height: "38px",
    borderRadius: "8px",
    border: " solid 1px #414a52",
    backgroundColor: " rgba(39, 45, 49, 0.9)"
  },
  updateButton: {
    width: "146px",
    height: "38px",
    borderRadius: " 8px",
    backgroundColor: "#ff2d21",
    display: "flex",
    cursor: "pointer"
  },
  picker: {
    margin: "3px 1px 2px 14px"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  }
}));
