import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import InputBase from "@material-ui/core/InputBase";
import {
  Button,
  Typography
} from "@material-ui/core";

export const DashboardTopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 11px 0 10px 0;
  width: 100%;
`;
export const DashboardHeading = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
  margin-left: 0.5%;
  height: 38px;
  font-family: Google Sans;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
  width: 100%;
`;
export const DashboardButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
`;
export const DashboardCreateWrapper = styled.section`
  display: flex;
  justify-self: end;
  margin-left: 2%;
  margin-right: 1.4%;
  min-width: 140px;
  width: 140px;
  height: 38px;
  border-radius: 8px;
  background-color: #ff2d21;
  cursor: pointer;

`;
export const DashboardListWrapper = styled.section`
  display: flex;
  justify-self: end;
  min-width:130px;
  width: 130px;
  height: 38px;
  border-radius: 8px;
  background-color: #ff2d21;
  cursor: pointer;
`;
export const DetailsText = styled.div`
         display: flex;
         margin: 2px 6px 21px 52px;
         height: 25px;
         font-family: Google Sans;
         font-size: 20px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         color: #161a1e;
       `;
export const CancelButton = styled.div`
        margin: 0px 30px 0px 4px;
        display: flex;
        cursor: pointer;
        justify-content: center;
        width: 146px;
        height: 38px;
        border-radius: 8px;
        background-color: #4e4e54;
       `;
export const CreateButton = styled(Button)`
         text-transform: capitalize;
         font-family: Google Sans !important;
         color: #ffffff !important;
         cursor: pointer;
         display: flex;
         justify-content: center;
         width: 146px;
         height: 38px;
         border-radius: 8px;
         background-color: #ff2d21;
       `;
export const CreateText = styled.span`
  margin: 9px 3px 3px 13px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;
export const Textspan = styled.span`
         margin: 9px 0px 0px 1px;
         width: 47px;
         height: 18px;
         font-family: Google Sans;
         font-size: 14px;
         font-weight: bold;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         color: #ffffff;
       `;
export const TicketWrapper = styled.section`
  display: flex !important;
  margin: 11px 0 70px 0 !important;
  input::-ms-clear {
    width: 0;
    height: 0;
  }
`;
export const TicketOrgNameSection = styled.section`
         display: flex;
        //  justify-content: space-between;
         width: 100%;
         margin-top: 30px;
         padding: 0px 0px 0px 45px;
       `;
export const TicketSelectSection = styled.section`
         // display: flex;
         //  justify-content: space-between;
         width: 50%;
         margin-top: 30px;
         padding: 0px 0px 0px 45px;
       `;
export const TicketDescriptionSection = styled.section`
         display: flex;
        //  justify-content: space-between;
         width: 100%;
         margin-top: 30px;
         padding: 0px 0px 0px 45px;
       `;
export const TicketBlock = styled.div`
 display: flex; flex-direction: column;    width: 100% ;
`;
export const TicketForm = styled.form`
width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;
export const TicketBack = styled.div`
  cursor:pointer;
  display: flex !important;
  width: 164px;
  justify-Content: center;
`;
export const TicketBackText = styled(Typography)`
         width: 120px;
         height: 16px;
         font-family: Google Sans;
         font-size: 12px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         color: #4e4e4e;
         margin: 9px 0px 0px 7px;
       `;
export const BackIcon = styled.img`
margin: 11px 0px 0px 22px;
`;
export const FAQSection = styled.section`
         display: flex;
         justify-content: center;
         width: 100%;
         margin-top: 5px;
`;
export const FAQSectionBodyBackground = styled.div`
display:flex;
justify-content: space-between;
height: 34px;
padding: 5px;
border-radius: 5px;
margin-top: 2px;
margin-bottom: 2px;
border: solid 1px rgba(151, 151, 151, 0.06);
font-family: Google Sans;
font-size: 18px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #161a1e;
background-color: #ffffff;
`;
export const FAQSectionBody = styled.section`
 display:flex;
 padding: 5px;
 border-radius: 5px;
 margin-top: 5px;
 margin-bottom: 5px;
 font-family: Google Sans;
 font-size: 15px;
 font-weight: normal;
 font-stretch: normal;
 font-style: normal;
 line-height: normal;
 letter-spacing: normal;
 color: #161a1e;
`;
export const TicketFormControl = styled.div`
display: inline-flex;
flex-direction: column;
width: 100%;
`;
export const TicketInputWrap = styled(InputBase)`
         width: 96%;
         padding: 12px;
         font-size: 14px;
         height: 153px;//38px;
         border-radius: 8px;
         box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
         border: ${props =>
           props.showErrors
             ? "solid 1px #ff2d21"
             : "solid 1px rgba(71, 59, 59, 0.2)"};
         background-color: #ffffff;
       `;
export const TicketLabel = styled.label`
width: 200px;
margin-bottom: 10px;
height: 16px;
font-family: Google Sans;
font-size: 12px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.6px;
color: ${props => (props.error ? " #ff2d21" : "#292b2e;")};
`;   
export const PhoneNumber = styled.div`
.react-phone-number-input__country-select{
  position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
    border: 0;
    opacity: 0;
    cursor: pointer;
}
.react-phone-number-input__country-select-arrow{
  display: block;
   content: '';
   width: 0;
   height: 0;
   margin-bottom: 0.1em;
   margin-top: 0.3em;
   margin-left: 0.3em;
   border-width: 0.35em 0.2em 0 0.2em;
   border-style: solid;
   border-left-color: transparent;
   border-right-color: transparent;
   color: #B8BDC4;
   opacity: 0.7;
   transition: color 0.1s;
}
.react-phone-number-input__country{
  display: flex;
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
  margin-right: 0.5em;
  margin-bottom: 0.4em;
}
.react-phone-number-input__row{
  display: flex;
}
.react-phone-number-input {
  input {
    border-bottom: 1px solid #c5d2e0;
    width: 234px;
    border: solid 1px rgba(71, 59, 59, 0.2);
    height: 38px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    background-color: #ffffff;
    padding-left: 12px;
  }
}
.react-phone-number-input__icon {
  width: 32px;
  height: 22px;
  border: none;
}
 .MuiFormHelperText-root.Mui-error{
   width: 300px;
 }
 .errorElement {
   color: #990000;
   /* margin-left: 45px; */
   margin-top: -4px;
   font-family: Gilroy;
   font-size: 14px;
   line-height: 25px;
 }
`;
export const T = styled.table`
         padding-right: 20px;
         width: 100%;
         .MuiCollapse-container {
           width: 32px;
         }
         .wrapper-long {
           margin: 0 auto;
           padding: 0 0 20px;
         }
         .wrapper {
           margin: 0 auto;
           padding: 40px;
           max-width: 800px;
         }
         .table {
           margin: 0 0 40px 0;
           width: 100%;
           display: table;
           text-align: left;
           position: relative;
           border-collapse: collapse;
         }
         @media screen and (max-width: 580px) {
           .table {
             display: block;
             text-align: left;
             position: relative;
             border-collapse: collapse;
           }
         }
         .row-head {
           //cursor: pointer;
           display: table-row;
           background-color: #ffffff;
         }
         .row {
           //cursor: pointer;
           display: table-row;
           background-color: #ffffff;
           &:hover,
           &:focus {
             outline: none;
             background-color: #f8f8f8;
           }
         }
         .row:nth-of-type(odd) {
           background-color: #ffffff;
           &:hover,
           &:focus {
             outline: none;
             background-color: #f8f8f8;
           }
         }
         .row-head.header {
           height: 38px;
           border-radius: 8px;
           border: solid 1px #414a52;
           color: #ffffff;
           position: sticky;
         }
         .row-head.green {
           background-color: #414549;
         }
         .row.blue {
           background: #2980b9;
         }
         @media screen and (max-width: 580px) {
           .row {
             padding: 14px 0 7px;
             display: block;
           }
           .row-head.header {
             padding: 0;
             height: 6px;
           }
           .row-head.header .cell {
             display: none;
           }
           .row .cell {
             margin-bottom: 10px;
           }
           .row .cell:before {
             margin-bottom: 3px;
             content: attr(data-title);
             min-width: 98px;
             font-size: 10px;
             line-height: 10px;
             font-weight: bold;
             text-transform: uppercase;
             color: #969696;
             display: block;
           }
         }
         .cellHead {
           padding: 6px 20px;
           display: table-cell;
           width: 132px;
           height: 18px;
           font-family: Google Sans;
           font-size: 12px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #ffffffff;
         }
         .cellHead-small {
           padding: 6px 20px;
           display: table-cell;
           width: 32px;
           height: 18px;
           font-family: Google Sans;
           font-size: 12px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #ffffffff;
         }

         .cell {
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 11px;
           display: table-cell;
           width: 132px;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }
         .cell-long {
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 20px;
           display: table-cell;
           width: 157px;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }
         .cell-long-line {
           justify-content: center;
           text-align: center;
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 20px;
           display: table-cell;
           width: 100%;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }
         .cell-small {
           border-bottom: 1px solid lavender;
           padding: 21px 5px 16px 20px;
           display: table-cell;
           width: 32px;
           height: 18px;
           font-family: Google Sans;
           font-size: 14px;
           font-weight: normal;
           font-stretch: normal;
           font-style: normal;
           line-height: normal;
           letter-spacing: normal;
           color: #161a1e;
         }
         .cell-yellow {
           color: #ff9500;
         }
         @media screen and (max-width: 580px) {
           .cell {
             padding: 2px 16px;
             display: block;
           }
         }
       `;
export const useStyles = makeStyles(theme => ({
         root: {
           flexGrow: 1,
           marginTop: "0",
           padding: "15px 0 15px 10px",
           fontFamily: "Google Sans",
           fontSize: "30px",
           marginBottom: "20px"
         },
         paper: {
           padding: theme.spacing(2),
           textAlign: "center",
           color: theme.palette.text.secondary
         },
         flex: {
           display: "flex"
         },
         searchMargin: {
           margin: " 7px 0px 0px 18px",
           width: "438px",
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: "#ffffff",
          //  "& $img": {
          //     marginBottom: "20px !important"
          //  },
          //  "& $input": {
          //   marginBottom: "23px"
          //  }
         },
         errorMessage:{
          color:"#ff2d21",
          fontSize: "12px",
          textAlign: "right",
          width: "100%",
          paddingTop: "5px",
          marginRight: "9%",
        },
         dateTimePicker: {
           margin: "5px 0px 0px 0px !important",
           width: "240px",
           height: " 39px",
           borderRadius: "8px",
          //  boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
          //  border: "solid 1px rgba(71, 59, 59, 0.2) !important",
          //  backgroundColor: " #ffffff",
           "& div": {
             textDecoration: "none",
             "& button": {
              // marginBottom: "9px"
            },
            "& input": {
              margin: "10px",
              width: "100%",
              // height: "26px",
              fontFamily: "Google Sans",
              fontSize: "12px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              // lineHeight: "normal",
              letterSpacing: "normal",
              color: "#999999",
              paddingTop: "0px",
              // paddingBottom: "10px"
            }
           }
         },
         dashboard: {
           width: "150px !important",
           height: " 38px !important",
           fontFamily: "Google Sans",
           fontSize: "30px !important",
           fontWeight: "normal !important",
           fontStretch: "normal !important",
           fontStyle: "normal !important",
           lineHeight: "normal !important",
           letterSpacing: "normal !important",
           color: "#161a1e !important"
         },
         rectangle: {
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: "#ffffff"
         },
         textField: {
           marginLeft: theme.spacing(1),
           marginRight: theme.spacing(1),
           width: 200,
           fontFamily: "Google Sans"
         },
         textBox: {
          //  margin: "9px 5px 4px 2px",
           fontFamily: "Google Sans",
           fontSize: "12px !important",
           width: "100%",
           height: "100%",
           display: "flex !important",
           "& div": {
             "& input": {
               width: "100%",
               height: "16px",
               fontFamily: "Google Sans",
               fontSize: "12px !important",
               fontWeight: "normal",
               fontStretch: "normal",
               fontStyle: "normal",
               lineHeight: "normal",
               letterSpacing: "normal",
               color: "#999999"
             }
           },
           "& img": {
             width: "11px",
             height: "19px",
             objectFit: "contain"
           }
         },
         formControl: {
           margin: theme.spacing(1),
           minWidth: 120
         },
         organizationDropDown: {
           margin: "18px 0px 0px 0px",
           width: "182px !important",
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: " solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: "#ffffff",
           fontFamily: "Google Sans"
         },
         orgSelect: {
           height: "39px !important",
           borderRadius: "8px !important",
           boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.04) !important",
           border: "solid 1px rgba(71, 59, 59, 0.2) !important",
           backgroundColor: "#ffffff !important",
           padding: "5px",
           margin: "18px 0px 0px 0px",
           fontFamily: "Google Sans"
         },
         allOrganaizations: {
           width: "93px",
           height: "16px",
           fontFamily: "Google Sans",
           fontSize: "12px",
           fontHeight: "normal",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: " #6f6e6e"
         },
         mapStyles: [
           {
             elementType: "geometry",
             stylers: [
               {
                 color: "#f5f5f5"
               }
             ]
           },
           {
             elementType: "labels.icon",
             stylers: [
               {
                 visibility: "off"
               }
             ]
           },
           {
             elementType: "labels.text.fill",
             stylers: [
               {
                 color: "#616161"
               }
             ]
           },
           {
             elementType: "labels.text.stroke",
             stylers: [
               {
                 color: "#f5f5f5"
               }
             ]
           },
           {
             featureType: "administrative.land_parcel",
             elementType: "labels.text.fill",
             stylers: [
               {
                 color: "#bdbdbd"
               }
             ]
           },
           {
             featureType: "poi",
             elementType: "geometry",
             stylers: [
               {
                 color: "#eeeeee"
               }
             ]
           },
           {
             featureType: "poi",
             elementType: "labels.text.fill",
             stylers: [
               {
                 color: "#757575"
               }
             ]
           },
           {
             featureType: "poi.park",
             elementType: "geometry",
             stylers: [
               {
                 color: "#e5e5e5"
               }
             ]
           },
           {
             featureType: "poi.park",
             elementType: "labels.text.fill",
             stylers: [
               {
                 color: "#9e9e9e"
               }
             ]
           },
           {
             featureType: "road",
             elementType: "geometry",
             stylers: [
               {
                 color: "#ffffff"
               }
             ]
           },
           {
             featureType: "road.arterial",
             elementType: "labels.text.fill",
             stylers: [
               {
                 color: "#757575"
               }
             ]
           },
           {
             featureType: "road.highway",
             elementType: "geometry",
             stylers: [
               {
                 color: "#dadada"
               }
             ]
           },
           {
             featureType: "road.highway",
             elementType: "labels.text.fill",
             stylers: [
               {
                 color: "#616161"
               }
             ]
           },
           {
             featureType: "road.local",
             elementType: "labels.text.fill",
             stylers: [
               {
                 color: "#9e9e9e"
               }
             ]
           },
           {
             featureType: "transit.line",
             elementType: "geometry",
             stylers: [
               {
                 color: "#e5e5e5"
               }
             ]
           },
           {
             featureType: "transit.station",
             elementType: "geometry",
             stylers: [
               {
                 color: "#eeeeee"
               }
             ]
           },
           {
             featureType: "water",
             elementType: "geometry",
             stylers: [
               {
                 color: "#c9c9c9"
               }
             ]
           },
           {
             featureType: "water",
             elementType: "labels.text.fill",
             stylers: [
               {
                 color: "#9e9e9e"
               }
             ]
           }
         ],
         mapContainer: {
           display: "flex",
           marginTop: "21px !important",
           marginLeft: "13px !important",
           position: "relative",
           border: "0px"
         },
         selectDropDown: {
           width: "182px",
           margin: "15px 25px 0px -5px",
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: " #ffffff"
         }
       }));
