import { api } from "../utils/http";

export const initialState = {
  error: "",
  notifications: [],
  page: [],
  pagenotificationsDetail: [],
  loading: false,
  notificationsDetail: {},
  massNotificationDetails: {},
  pushTableData: [],
  textTableData: [],
  pagePush: [],
  pageText: [],
  wifiList: [],
  boundData: []
};
export const reducers = {
  SearchDataClear(state) {
    return {
      ...state,
      boundData: []
    };
  },
  SearchData(state, payload) {
    return {
      ...state,
      boundData: payload.data.mass_notification_users
    };
  },
  setLoader(state, payload) {
    return {
      ...state,
      loading: payload
    };
  },
  getMassNotificationListClear(state, payload) {
    return {
      ...state,
      notifications: []
    };
  },
  clearPushTableData(state, payload) {
    return {
      ...state,
      pushTableData: []
    };
  },
  clearTextTableData(state, payload) {
    return {
      ...state,
      textTableData: []
    };
  },
  setPushTableData(state, payload) {
    return {
      ...state,
      pushTableData: payload.mass_notification_users,
      pagePush: payload.page
    };
  },
  setTextTableData(state, payload) {
    return {
      ...state,
      textTableData: payload.mass_notification_users,
      pageText: payload.page
    };
  },
  setWifilist(state, payload) {
    return {
      ...state,
      wifiList: payload
    };
  },
  getMassNotificationList(state, payload) {
    return {
      ...state,
      notifications: payload.mass_notifications,
      page: payload.page
    };
  },
  getMassNotificationDetailListClear(state, payload) {
    return {
      ...state,
      notificationsDetailList: []
    };
  },
  getMassNotificationDetailList(state, payload) {
    return {
      ...state,
      notificationsDetailList: payload.mass_notification_users,
      massNotificationDetails: payload.mass_notification,
      pagenotificationsDetail: payload.page
    };
  },
  getMassNotificationDetail(state, payload) {
    return {
      ...state,
      notificationsDetail: payload
    };
  },
  addError(state, payload) {
    return {
      ...state,
      error: payload
    };
  },
  removeError(state) {
    return {
      ...state,
      error: ""
    };
  }
};

export const effects = {
  async getMassNotifications(payload, state, params) {
    this.setLoader(true);
    this.getMassNotificationListClear();
    let url = `/api/v1/mass_notification/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}`;

    if (payload.sortName !== "" && payload.sortName !== undefined) {
      url = `${url}&sort_by=${
        payload.sort ? `-${payload.sortName}` : `${payload.sortName}`
      }`;
    }
    if (payload.search) {
      this.getMassNotificationListClear();
      url = `${url}&search=${payload.search}`;
    }
    this.removeError();
    try {
      const res = await api
        .get(url)
        .then(response => {
          this.setLoader(false);
          this.getMassNotificationList(response.data.data);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getMassNotificationsDetailsList(payload, state, params) {
    if (payload.id !== undefined) {
      let url = `/api/v1/mass_notification/user/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&mass_notification_id=${payload.id}`;
      if (payload.subtype) {
        this.getMassNotificationListClear();
        url = `/api/v1/mass_notification/user/?pagination=1&limit=${
          payload.rowsPerPage
        }&offset=${payload.offset}&mass_notification_id=${
          payload.id
        }&status_filter=${payload.subtype !== "0" ? payload.subtype : ""}`;
      }
      this.removeError();
      try {
        const res = await api
          .get(url)
          .then(response => {
            this.getMassNotificationDetailList(response.data.data);
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async getMassNotificationsPushList(payload, state, params) {
    if (payload.id !== undefined) {
      let url = `/api/v1/mass_notification/user/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&mass_notification_id=${payload.id}&notification_type=push`;
      if (payload.subtype) {
        this.clearPushTableData();
        url = `/api/v1/mass_notification/user/?pagination=1&limit=${
          payload.rowsPerPage
        }&offset=${payload.offset}&mass_notification_id=${
          payload.id
        }&status_filter=${
          payload.subtype !== "0" ? payload.subtype : ""
        }&notification_type=push`;
      }
      this.removeError();
      try {
        const res = await api
          .get(url)
          .then(response => {
            this.setPushTableData(response.data.data);
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async getMassNotificationsTextList(payload, state, params) {
    if (payload.id !== undefined) {
      let url = `/api/v1/mass_notification/user/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&mass_notification_id=${payload.id}&notification_type=text`;
      if (payload.subtype) {
        this.clearTextTableData();
        url = `/api/v1/mass_notification/user/?pagination=1&limit=${
          payload.rowsPerPage
        }&offset=${payload.offset}&mass_notification_id=${
          payload.id
        }&status_filter=${
          payload.subtype !== "0"
            ? payload.subtype === "not_delivered"
              ? "undelivered"
              : payload.subtype
            : ""
        }&notification_type=text`;
      }
      this.removeError();
      try {
        const res = await api
          .get(url)
          .then(response => {
            this.setTextTableData(response.data.data);
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async getMassNotificationsWifiList(payload, state) {
    let url = `/api/v1/device/wifi/`;
    this.removeError();
    try {
      const res = await api
        .post(url, payload)
        .then(response => {
          this.setWifilist(response.data.data);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async patchNotificationUserStatus({
    id,
    payload,
    enqueueSnackbar,
    handleCloseModal,
    setPage
  }) {
    try {
      const res = await api
        .patch(`/api/v1/mass_notification/user/`, payload)
        .then(response => {
          if (response.status === 200) {
            handleCloseModal();
            setPage(0);
            let payload = {
              id,
              offset: 0,
              rowsPerPage: 5
            };
            this.getMassNotificationsPushList(payload);
            this.getMassNotificationsTextList(payload);
            this.getMassNotificationsDetailsList(payload);
            this.getMassNotifications(payload);
            let variant = "success";
            enqueueSnackbar("Edited successfully", {
              variant
            });
          }
        });
    } catch (err) {
      let variant = "error";
      this.addError(err.response.data.message);
      enqueueSnackbar(err.response.data.message, {
        variant
      });
      this.removeSuccess();
    }
  },
  async notifications({
    payload,
    enqueueSnackbar,
    popupState,
    setReasonPayload,
    setMassTextPayload,
    setOpenMassText,
    setOpenMassPush,
    payloadData,
    isGeoSpatial
  }) {
    let URL = `/api/v1/mass_notification/`;
    try {
      const dataPayload = isGeoSpatial ? payloadData : payload;
      const res = await api
        .post(URL, dataPayload)
        .then(response => {
          let variant = "success";
          if (response.status === 200) {
            if (dataPayload.notification_type == "push") {
              enqueueSnackbar("Push created  successfully!", {
                variant
              });
              let Datapayload = {
                id: dataPayload.mass_notification_id,
                offset: 0,
                rowsPerPage: 5
              };
              this.getMassNotificationsDetailsList(Datapayload);
              this.getMassNotificationsPushList(Datapayload);
              setReasonPayload(""), setMassTextPayload({});
              setOpenMassPush(false);
            } else {
              let Datapayload = {
                id: dataPayload.mass_notification_id,
                offset: 0,
                rowsPerPage: 5
              };
              this.getMassNotificationsDetailsList(Datapayload);
              this.getMassNotificationsTextListList(Datapayload);
              setReasonPayload(""), setMassTextPayload({});
              setOpenMassText(false);
              enqueueSnackbar("Text created  successfully!", {
                variant
              });
            }
          }
        })
        .catch(err => {
          let variant = "error";
          this.addError(err.response.toString());

          if (dataPayload.notification_type == "push") {
            if (
              err.response.data.message === "Name: Not a valid string." ||
              err.response.data.message === "Name: This field may not be blank."
            ) {
              enqueueSnackbar(
                "Please enter the name for sending mass push notifications",
                {
                  variant
                }
              );
            }
          }
          if (dataPayload.notification_type == "text") {
            if (
              err.response.data.message === "Name: Not a valid string." ||
              err.response.data.message === "Name: This field may not be blank."
            ) {
              enqueueSnackbar("Please enter the name for sending mass text", {
                variant
              });
            }
          }
        });
    } catch (err) {
      let variant = "error";
      //  this.addError(err.toString());
    }
  },
  async startViewOnMap(payload) {
    this.removeError();
    this.SearchDataClear();
    try {
      const res = await api
        .get(
          `/api/v1/mass_notification/user/?mass_notification_id=${payload.massId}&notification_type=push&latest_only=1`
        )
        .then(response => {
          if (response.status === 200) {
            this.SearchData(response.data);
          }
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async startViewOnMapSearch(payload) {
    this.removeError();
    this.SearchDataClear();
    try {
      const res = await api
        .post(`/api/v1/mass_notification/user/list/`, payload)
        .then(response => {
          if (response.status === 200) {
            this.SearchData(response.data);
          }
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        });
    } catch (err) {
      this.addError(err.toString());
    }
  }
};

export default {
  state: initialState,
  reducers,
  effects
};
