import Dashboard from "./Dashboard.model";
import Geospatial from "./Geospatial.model";
import UserProfile from "./UserProfile.model"
import Watchlist from "./Watchlist.model";
import Organization from "./Organization.model";
import History from "./History.model";
import MassNotification from "./MassNotification.model";
import Ticket from "./Ticket.model";
const models = {
  Dashboard,
  Geospatial,
  UserProfile,
  Watchlist,
  Organization,
  History,
  MassNotification,
  Ticket
};
export default models;
