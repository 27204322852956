import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: "10px",
    marginRight: "10px",
    fontFamily: "Google Sans"
  },
  modalOverflow: {
    "& $div":{
    overflowY: "initial !important"
    }
  },
  breadCrumb: {
    width: "120px",
    height: "16px",
    fontFamily: "Google Sans",
    fontSize: " 12px",
    fontHeight: "normal",
    fontStretch: " normal",
    fontStyle: " normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#4e4e4e",
    margin: "9px 0px 0px 7px",
    cursor: "pointer"
  },
  breadCrumbContainer: {
    width: "164px",
    display: "flex",
    justifyContent: "center"
  },
  imageBack: {
    margin: "11px 0px 0px 22px"
  },
  Heading: {
    margin: " 13px 0px 0px 23px",
    width: "122px",
    height: "38px",
    fontFamily: "Google Sans",
    fontSize: "30px",
    fontWeight: "normal",
    fontStretch: " normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#161a1e,"
  },
  EditUsers: {
    width: "100%",
    borderRadius: "10px",
    boxShadow: " 0 5px 10px 0 rgba(0, 0, 0, 0.06)",
    border: "solid 2px rgba(151, 151, 151, 0.06)",
    backgroundColor: " #ffffff"
  },
  label: {
    width: "105px",
    height: "16px",
    fontFamily: " Google Sans",
    fontSize: " 12px",
    fontWeight: " normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: " 0.6px",
    color: "#292b2e"
  },
  textBox: {
    width: " 234px",
    height: " 38px",
    borderRadius: "8px",
    backgroundColor: "#f2f7fa"
  },
  divider: {
    border: "dashed 1px rgba(151, 151, 151, 0.06)",
    borderRadius: " 1px",
    boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
    backgroundColor: " #ffffff",
    width: "93%",
    margin: "30px 0px 0px 45px"
  },
  activeTextBox: {
    width: "234px",
    height: "38px",
    borderRadius: " 8px",
    boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    border: "solid 1px rgba(71, 59, 59, 0.2)",
    backgroundColor: " #ffffff"
  },
  cancelButton: {
    marginRight: "25px",
    width: "146px",
    height: "38px",
    borderRadius: " 8px",
    backgroundColor: "#4e4e54",
    display: "flex",
    cursor: "pointer",
    margin: "0px 25px 0px 0px"
  },
  cancelText: {
    justifyContent: "center",
    alignContent: "center",
    alignSelf: "center",
    margin: "0px 0px 0px 45px",
    width: "47px",
    height: "18px",
    fontFamily: " Google Sans",
    fontSize: "14px",
    fontWeight: "bold",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#ffffff"
  },
  updateButton: {
    width: "146px",
    height: "38px",
    borderRadius: " 8px",
    backgroundColor: "#ff2d21",
    display: "flex",
    cursor: "pointer"
  },
  valueText: {
    width: "78px",
    height: " 16px",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontWeight: "normal",
    fontStretch: " normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#161a1e",
    margin: "12px 0px 0px 22px"
  },
  selectFormat: {
    width: "234px",
    height: "38px",
    borderRadius: "8px",
    boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.04)",
    border: " solid 1px rgba(71, 59, 59, 0.2)",
    backgroundColor: "#ffffff"
  }
}));
