import { init } from "@rematch/core";
import models from "../models";
import logger from "redux-logger";

const store = init({
  models,
  redux: {
    rootReducers: {
      RESET: () => undefined // reset the store when user logs out
    }
  }
  // redux: {
  //   middlewares: [logger]
  // }
});
export default store;
