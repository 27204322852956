import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const TabContent = styled.div`
  flex: 1;
  width: 100%;
`;

const TabPanel = ({ children }) => (
  <TabContent role="tabpanel" tabindex="0">
    {children}
  </TabContent>
);

TabPanel.propTypes = {
  children: PropTypes.any.isRequired
};

function Item({ children }) {
  const ref = React.useRef();

  React.useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, []);

  return (
    <div className="Item" ref={ref}>
      {children}
    </div>
  );
}

class Tabs extends Component {
  static Panel = TabPanel;

  static propTypes = {
    children: PropTypes.any.isRequired,
    tabBreak: PropTypes.string
  };

  static defaultProps = {
    tabBreak: "768px"
  };

  state = {
    selectedTab: 0
  };

  selectTab = tabIndex => {
    this.setState({ selectedTab: tabIndex });
  };

  render() {
    const { children, tabBreak } = this.props;
    const { selectedTab } = this.state;

    return (
      <TabsWrapper>
        <TabList breakPoint={tabBreak} role="tablist">
          {React.Children.map(
            children,
            (
              { props: { label, handleContent, activeIcon, inActiveIcon } },
              index
            ) => (
              <TabWrapper>
              <TabButton
                role="tab"
                id={index}
                selected={selectedTab === index}
                activeIcon={activeIcon}
                aria-selected={selectedTab === index ? "true" : "false"}
                onClick={() => {
                  this.selectTab(index);
                  handleContent(label);
                }}>
                <img
                  style={{
                    width: "13px",
                    margin: "0px 8px 1px 2px"
                  }}
                  src={selectedTab === index?activeIcon:inActiveIcon}
                />{" "}
                {label}
              </TabButton>
              </TabWrapper>
            )
          )}
        </TabList>
        <Content>
          {React.Children.map(children, (comp, index) =>
            selectedTab === index ? comp : undefined
          )}
        </Content>
      </TabsWrapper>
    );
  }
}

const TabsWrapper = styled.div`
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TabButton = styled.button`
    margin: 0;
    color: ${props => (props.selected ? "#f14b4b" : "#161a1e")};
    height: 30px;
    padding: 0 16px;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
      font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
        font-family: Google Sans;
    cursor: pointer;
    background: ${props => (props.selected ? "#f7f8f9" : "#ffffffff")};
    outline: none;
    -webkit-transition: border-color 0.2s ease-in;
    transition: border-color 0.2s ease-in;
    border: none;
    border-radius: 21.5px;
  }
     &:hover, &:focus, &:active {
    color: ${props => (props.selected ? "#f14b4b" : "#161a1e")};
    margin: 0;
    height: 30px;
    padding: 0 16px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
     font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
    font-family: Google Sans;
    cursor: pointer;
    background: #f7f8f9;
    outline: none;
    -webkit-transition: border-color 0.2s ease-in;
    transition: border-color 0.2s ease-in;
    border: none;
    border-radius: 21.5px;
    background-color: #f7f8f9;

  }
`;

const TabList = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media (max-width: ${props => props.breakPoint}) {
    flex-direction: column;
    & > div,
    & > div > button {
      width: 100%;
    }
  }
`;
const TabWrapper=styled.div`
  flex: 1;
  display: flex;
  height: 64px;
  justify-content: center;
  align-items: center;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 16px;
    top: calc(50% - 8px);
    right: 0;
    background: #e4e4e4;
  }
  &:last-child:after {
    display: none;
  }
`
const Content = styled.div`
  flex: 1;
  width: 100%;
  padding-top: 16px;
`;

export default Tabs;
