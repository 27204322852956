import React, { useEffect, useState, useRef } from "react";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import moment from "moment";
import { connect } from "react-redux";
import { Grid, MenuList, MenuItem } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { useSnackbar } from "notistack";
import get from "lodash/get";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import {
  StyledTab,
  StyledTabs,
  useStyles,
  TabPanel,
  HistoryWrapper,
  HistoryTopWrapper,
  HistoryHeading,
  HistoryTopWrapperFilter,
  HistoryTableTabWrapper,
  OrganizationBack,
  OrganizationBackText,
  NotifivationWrapperArea,
  AreaSpan,
  WatchlistName,
  SentAt,
  TimeData,
  SentAtWrapper,
  RecipientWrapper,
  DateWrapper,
  WatchlistRecipientName,
  ImageImg,
  SafteyAlert,
  Safe,
  NoResponse,
  Distress,
  FilterWrapper,
  FilterWrapperSections,
  SafeText,
  OvalWrapper,
  NoResponseText,
  DistressText,
  ViewMap,
  SafteyAlertText,
  ViewMapText,
  Deliverd,
  AreaSpanDel,
  DeliveredItem,
  OvalContent,
  BackIcon
} from "./massnotification.details.style";
import calendar from "../../assets/svg/calander.svg";
import Select from "../../components/GeoSpatialFilterComponent/components/Select";
import store from "../../store";
import Table from "./NotificationTableDetails";
import backIcon from "../../assets/img/backicon.png";
import BackToPrevious from "../../components/BackToPrevious";

const header = ["STATUS", "RECIPIENT", "RESPONSE", "TIME", "NOTES"];
const FilterSubDataType = [
  {
    id: 1,
    subtype: "safe",
    label: "Safe"
  },
  {
    id: 2,
    subtype: "distress",
    label: "Distress"
  },
  {
    id: 3,
    subtype: "responded",
    label: "Responded"
  },
  {
    id: 4,
    subtype: "not_responded",
    label: "Not Responded"
  }
];
const FilterSubDataTypeText = [
  {
    id: 1,
    subtype: "delivered",
    label: "Delivered"
  },
  {
    id: 2,
    subtype: "safe",
    label: "Safe"
  },
  {
    id: 3,
    subtype: "distress",
    label: "Distress"
  },
  {
    id: 4,
    subtype: "responded",
    label: "Responded"
  },
  {
    id: 5,
    subtype: "failed",
    label: "Failed"
  },
  {
    id: 6,
    subtype: "sent",
    label: "Sent"
  }
];
function NotificationDetails(props) {
  const classes = useStyles();
  const anchorRef = useRef(null);

  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);
  const [notificationDetails, setNotificationDetails] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);

  const [pagePush, setPagePush] = useState(0);

  const [pageText, setPageText] = useState(0);
  const [tableData, setTableData] = useState({});
  const [tableDataPush, setTableDataPush] = useState({});
  const [tableDataTest, setTableDataText] = useState({});
  const [selected, setSelected] = useState([]);
  const [selectedTab, setSelectedTab] = useState({});
  const [open, setOpen] = useState(false);
  const [massNotificationDetails, setMassNotificationsDetails] = useState();
  const [subtype, setSubtype] = useState(0);
  const [subtypeText, setSubtypeText] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClickToggle = event => {
    if (anchorEl !== null) {
      setAnchorEl(null);
    } else {
      if (event && event.currentTarget) setAnchorEl(event.currentTarget);
    }
  };

  const handleCloseToggle = () => {
    setAnchorEl(null);
  };
  const handleSelectFilter = event => {
    setSelected([]);
    setSubtype(event.target.value);
  };
  const handleSelectFilterText = event => {
    setSelected([]);
    setSubtypeText(event.target.value);
  };
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };

  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setOffset(0);
  };
  const handleChange = (event, newValue) => {
    setSelected([]);
    setSubtype(0);
    setValue(newValue);
    setSelectedTab(event.currentTarget.id);
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    setNotificationDetails(props.location.state.notificationItem);
    if (
      props.location.state.notificationItem &&
      props.location.state.notificationItem.id !== undefined
    ) {
      let payload = {
        id: notificationDetails.id,
        offset,
        rowsPerPage
      };
      store.dispatch.MassNotification.getMassNotificationsDetailsList(payload);
      store.dispatch.MassNotification.getMassNotificationsPushList(payload);
      store.dispatch.MassNotification.getMassNotificationsTextList(payload);
    }
  }, [props.location.state.notificationItem.id]);

  useEffect(() => {
    if (
      props.location.state.notificationItem &&
      props.location.state.notificationItem.id !== undefined
    ) {
      let payload = {
        id: notificationDetails.id,
        offset,
        rowsPerPage,
        subtype
      };
      store.dispatch.MassNotification.getMassNotificationsDetailsList(payload);
      store.dispatch.MassNotification.getMassNotificationsPushList(payload);
    }
  }, [subtype]);

  useEffect(() => {
    if (
      props.location.state.notificationItem &&
      props.location.state.notificationItem.id !== undefined
    ) {
      let payload = {
        id: notificationDetails.id,
        offset,
        rowsPerPage,
        subtype: subtypeText
      };
      store.dispatch.MassNotification.getMassNotificationsDetailsList(payload);
      store.dispatch.MassNotification.getMassNotificationsTextList(payload);
    }
  }, [subtypeText]);

  useEffect(() => {
    if (
      props.location.state.notificationItem &&
      props.location.state.notificationItem.id !== undefined
    ) {
      let { notifications } = props.notificationDetailAState;
      const detailitem = notifications.filter(
        item => item.id === props.location.state.notificationItem.id
      );
      if (detailitem !== undefined && detailitem.length > 0) {
        setNotificationDetails(detailitem[0]);
      }
    }
  }, [props.notificationDetailAState.notifications]);

  useEffect(() => {
    setPage(props.notificationDetailAState.pagenotificationsDetail);
    setTableData(props.notificationDetailAState.notificationsDetailList);
  }, [
    props.notificationDetailAState.notificationsDetailList,
    props.notificationDetailAState.pagenotificationsDetail
  ]);

  useEffect(() => {
    if (notificationDetails && notificationDetails.id !== undefined) {
      let payload = {
        id: notificationDetails.id,
        offset,
        rowsPerPage
      };
      store.dispatch.MassNotification.getMassNotificationsPushList(payload);
      store.dispatch.MassNotification.getMassNotificationsTextList(payload);
      store.dispatch.MassNotification.getMassNotificationsDetailsList(payload);
    }
  }, [notificationDetails.id]);

  useEffect(() => {
    if (props.notificationDetailAState.massNotificationDetails) {
      const {
        notification_count
      } = props.notificationDetailAState.massNotificationDetails;
      let val = "";
      if (
        notification_count &&
        notification_count.push_count > 0 &&
        notification_count.text_count > 0
      ) {
        if (value === 0) {
          val = "push";
          setValue(0);
          setSelected([]);
        } else if (value === 2) {
          setValue(2);
          setSelected([]);
          val = "text";
        }
      } else if (
        notification_count &&
        notification_count.push_count > 0 &&
        notification_count.text_count === 0
      ) {
        val = "push";
      } else if (
        notification_count &&
        notification_count.push_count === 0 &&
        notification_count.text_count > 0
      ) {
        val = "text";
      }
      setSelectedTab(val);
    }
    setMassNotificationsDetails(
      props.notificationDetailAState.massNotificationDetails
    );
    return () => {
      setValue(0);
    };
  }, [JSON.stringify(props.notificationDetailAState.massNotificationDetails)]);

  useEffect(() => {
    setTableDataPush(props.notificationDetailAState.pushTableData);
    setPagePush(props.notificationDetailAState.pagePush);
  }, [
    props.notificationDetailAState.pushTableData,
    props.notificationDetailAState.pagePush
  ]);

  useEffect(() => {
    setTableDataText(props.notificationDetailAState.textTableData);
    setPageText(props.notificationDetailAState.pageText);
  }, [
    props.notificationDetailAState.textTableData,
    props.notificationDetailAState.pageText
  ]);
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <HistoryWrapper>
        <Grid container>
          <Grid item xs={12}>
           <BackToPrevious destination="massnotification" />
            <HistoryTopWrapper>
              <HistoryHeading>{notificationDetails.name}</HistoryHeading>
            </HistoryTopWrapper>
            <HistoryTopWrapperFilter>
              <NotifivationWrapperArea>
                <AreaSpan>Area:</AreaSpan>
                <WatchlistName>
                  {get(notificationDetails, "watchlist_location_name") ||
                    "custom"}
                </WatchlistName>
              </NotifivationWrapperArea>
              <SentAtWrapper>
                <SentAt>SENT AT</SentAt>
                <TimeData>
                  <DateWrapper>
                    {moment
                      .utc(notificationDetails.created_on)
                      .local()
                      .format("DD MMM YYYY,HH:mm:ss")}
                  </DateWrapper>
                  <ImageImg src={calendar} />
                </TimeData>
              </SentAtWrapper>
              <RecipientWrapper>
                <AreaSpan>Recipient:</AreaSpan>
                <WatchlistRecipientName>
                  {notificationDetails.recipient_count}
                </WatchlistRecipientName>
              </RecipientWrapper>
            </HistoryTopWrapperFilter>
            <HistoryTableTabWrapper>
              <div className={classes.demo2}>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="styled tabs example">
                  {get(
                    massNotificationDetails,
                    "notification_count.push_count",
                    0
                  ) !== 0 && (
                    <StyledTab label="Notifcation Responses" id="push" />
                  )}
                  {get(
                    massNotificationDetails,
                    "notification_count.push_count",
                    0
                  ) > 0 &&
                  get(
                    massNotificationDetails,
                    "notification_count.text_count",
                    0
                  ) > 0 ? (
                    <span style={{ marginTop: "14px" }}>|</span>
                  ) : (
                    ""
                  )}
                  {get(
                    massNotificationDetails,
                    "notification_count.text_count",
                    0
                  ) !== 0 && <StyledTab label="Mass Text" id="text" />}
                </StyledTabs>
                {get(
                  massNotificationDetails,
                  "notification_count.push_count",
                  0
                ) > 0 ? (
                  <TabPanel
                    value={value}
                    index={0}
                    tabId="push"
                    selectedTab={selectedTab}>
                    <FilterWrapper>
                      <FilterWrapperSections>
                        <Safe>
                          <SafeText>Safe</SafeText>
                          <OvalWrapper>
                            <OvalContent>
                              {get(
                                massNotificationDetails,
                                "response_stats.safe_count",
                                ""
                              )}
                            </OvalContent>
                          </OvalWrapper>
                        </Safe>
                        <NoResponse>
                          <NoResponseText>No Response</NoResponseText>
                          <OvalWrapper>
                            <OvalContent>
                              {get(
                                massNotificationDetails,
                                "response_stats.not_responded_count",
                                ""
                              )}
                            </OvalContent>
                          </OvalWrapper>
                        </NoResponse>

                        <Distress>
                          <DistressText>Distress</DistressText>
                          <OvalWrapper>
                            <OvalContent>
                              {get(
                                massNotificationDetails,
                                "response_stats.distress_count",
                                ""
                              )}
                            </OvalContent>
                          </OvalWrapper>
                        </Distress>
                      </FilterWrapperSections>
                      <FilterWrapperSections>
                        {selected.length > 0 && (
                          <SafteyAlert
                            // ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            // onClick={handleToggle}>
                            onClick={handleClickToggle}>
                            <SafteyAlertText>
                              Safety Alerts <KeyboardArrowDownIcon />
                            </SafteyAlertText>
                            <Popper
                              anchorEl={anchorEl}
                              // keepMounted
                              open={Boolean(anchorEl)}
                              // onClose={handleClickToggle}
                              // open={open}
                              // anchorEl={anchorRef.current}
                              // role={undefined}
                              transition
                              style={{ zIndex: "150" }}
                              disablePortal>
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom"
                                  }}>
                                  <ClickAwayListener
                                    onClickAway={handleCloseToggle}>
                                    <Paper>
                                      <MenuList
                                        id="menu-list-grow"
                                        // onKeyDown={handleListKeyDown}
                                      >
                                        <MenuItem
                                          onClick={() => {
                                            let dataSet = selected.map(
                                              ({ user_id, device_id }) => {
                                                return {
                                                  user_id,
                                                  device_id
                                                };
                                              }
                                            );
                                            let payload = {
                                              data: dataSet,
                                              mass_notification_id:
                                                massNotificationDetails.id
                                            };
                                            store.dispatch.UserProfile.sendTextMessage(
                                              {
                                                payload,
                                                enqueueSnackbar,
                                                callback:
                                                  store.dispatch
                                                    .MassNotification
                                                    .getMassNotificationsDetailsList,
                                                callbackPush:
                                                  store.dispatch
                                                    .MassNotification
                                                    .getMassNotificationsTextList,
                                                callbackPayload: {
                                                  id:
                                                    massNotificationDetails.id,
                                                  offset: 0,
                                                  rowsPerPage: 5
                                                }
                                              }
                                            );
                                            if (
                                              anchorRef.current &&
                                              anchorRef.current.contains(
                                                event.target
                                              )
                                            ) {
                                              return;
                                            }
                                            setOpen(false);
                                          }}>
                                          Send Text Message
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            let dataSet = selected.map(
                                              ({ user_id, device_id }) => {
                                                return {
                                                  user_id,
                                                  device_id
                                                };
                                              }
                                            );
                                            let payload = {
                                              data: dataSet,
                                              mass_notification_id:
                                                massNotificationDetails.id
                                            };
                                            store.dispatch.UserProfile.sendPushNotification(
                                              {
                                                payload,
                                                enqueueSnackbar,
                                                callback:
                                                  store.dispatch
                                                    .MassNotification
                                                    .getMassNotificationsDetailsList,
                                                callbackPush:
                                                  store.dispatch
                                                    .MassNotification
                                                    .getMassNotificationsPushList,
                                                callbackPayload: {
                                                  id:
                                                    massNotificationDetails.id,
                                                  offset: 0,
                                                  rowsPerPage: 5
                                                }
                                              }
                                            );
                                            if (
                                              anchorRef.current &&
                                              anchorRef.current.contains(
                                                event.target
                                              )
                                            ) {
                                              return;
                                            }
                                            setOpen(false);
                                          }}>
                                          Push Notification
                                        </MenuItem>
                                      </MenuList>
                                    </Paper>
                                  </ClickAwayListener>
                                </Grow>
                              )}
                            </Popper>
                          </SafteyAlert>
                        )}
                        <div
                          style={{
                            display: "flex",
                            width: "172px",
                            height: "38px",
                            borderRadius: "8px",
                            backgroundColor: "#ffffff"
                          }}>
                          <Select
                            classesParent={classes.inputClass}
                            data={FilterSubDataType}
                            valueProp={subtype}
                            onChange={handleSelectFilter}
                            label="Select Status"
                          />
                        </div>
                        <ViewMap
                          onClick={() => {
                            props.history.push({
                              pathname: "/viewnotificationmap",
                              state: {
                                notificationItem: notificationDetails
                              }
                            });
                          }}>
                          <ViewMapText> View Map</ViewMapText>
                        </ViewMap>
                      </FilterWrapperSections>
                    </FilterWrapper>
                    <>
                      <Table
                        selected={selected}
                        history={props.history}
                        id={notificationDetails.id}
                        details={notificationDetails}
                        tableHeader={header}
                        tableData={tableDataPush}
                        organizationPage={pagePush}
                        handleDetails={selecetd => {
                          setSelected(selecetd);
                          handleClickToggle();
                        }}
                        getDataList={
                          store.dispatch.MassNotification
                            .getMassNotificationsPushList
                        }
                      />
                    </>
                  </TabPanel>
                ) : (
                  ""
                )}
                {get(
                  massNotificationDetails,
                  "notification_count.text_count",
                  0
                ) > 0 ? (
                  <TabPanel
                    value={value}
                    index={2}
                    tabId="text"
                    selectedTab={selectedTab}>
                    <FilterWrapper>
                      <FilterWrapperSections>
                        <Deliverd>
                          <AreaSpanDel>Delivered:</AreaSpanDel>
                          <DeliveredItem>
                            {get(
                              massNotificationDetails,
                              "response_stats.text_delivered_count",
                              ""
                            )}
                          </DeliveredItem>

                          {/* <AreaSpanDel>Not delivered:</AreaSpanDel>
                            <DeliveredItem>
                              {massNotificationDetails &&
                                massNotificationDetails.response_stats &&
                                massNotificationDetails.response_stats
                                  .text_pending_count &&
                                massNotificationDetails.response_stats
                                  .text_pending_count}
                            </DeliveredItem> */}
                          <AreaSpanDel>Failed:</AreaSpanDel>
                          <DeliveredItem>
                            {get(
                              massNotificationDetails,
                              "response_stats.text_failed_count",
                              ""
                            )}
                          </DeliveredItem>
                          <AreaSpanDel>Safe:</AreaSpanDel>
                          <DeliveredItem>
                            {get(
                              massNotificationDetails,
                              "massNotificationDetails.response_stats.text_failed_count",
                              ""
                            )}
                            {get(
                              massNotificationDetails,
                              "response_stats.text_safe_count",
                              ""
                            )}
                          </DeliveredItem>
                          <AreaSpanDel>Distress:</AreaSpanDel>
                          <DeliveredItem>
                            {get(
                              massNotificationDetails,
                              "response_stats.text_distress_count",
                              ""
                            )}
                          </DeliveredItem>
                          <AreaSpanDel>Responded:</AreaSpanDel>
                          <DeliveredItem>
                            {get(
                              massNotificationDetails,
                              "response_stats.text_responded_count"
                            )}
                          </DeliveredItem>
                        </Deliverd>
                      </FilterWrapperSections>
                      <FilterWrapperSections>
                        {selected.length > 0 && (
                          <SafteyAlert
                            ref={anchorRef}
                            aria-controls={open ? "menu-list-grow" : undefined}
                            aria-haspopup="true"
                            onClick={handleToggle}>
                            <SafteyAlertText>
                              Safety Alerts <KeyboardArrowDownIcon />
                            </SafteyAlertText>
                            <Popper
                              open={open}
                              anchorEl={anchorRef.current}
                              role={undefined}
                              transition
                              style={{ zIndex: "150" }}
                              disablePortal>
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom"
                                  }}>
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={handleClose}>
                                      <MenuList
                                        id="menu-list-grow"
                                        onKeyDown={handleListKeyDown}>
                                        <MenuItem
                                          onClick={() => {
                                            let dataSet = selected.map(
                                              ({ user_id, device_id }) => {
                                                return {
                                                  user_id,
                                                  device_id
                                                };
                                              }
                                            );

                                            let payload = {
                                              data: dataSet,
                                              mass_notification_id:
                                                massNotificationDetails.id
                                            };
                                            store.dispatch.UserProfile.sendTextMessage(
                                              {
                                                payload,
                                                enqueueSnackbar,
                                                callback:
                                                  store.dispatch
                                                    .MassNotification
                                                    .getMassNotificationsDetailsList,
                                                callbackPush:
                                                  store.dispatch
                                                    .MassNotification
                                                    .getMassNotificationsTextList,
                                                callbackPayload: {
                                                  id:
                                                    massNotificationDetails.id,
                                                  offset: 0,
                                                  rowsPerPage: 5
                                                }
                                              }
                                            );
                                            if (
                                              anchorRef.current &&
                                              anchorRef.current.contains(
                                                event.target
                                              )
                                            ) {
                                              return;
                                            }
                                            setOpen(false);
                                          }}>
                                          Send Text Message
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            let dataSet = selected.map(
                                              ({ user_id, device_id }) => {
                                                return {
                                                  user_id,
                                                  device_id
                                                };
                                              }
                                            );
                                            let payload = {
                                              data: dataSet,
                                              mass_notification_id:
                                                massNotificationDetails.id
                                            };
                                            store.dispatch.UserProfile.sendPushNotification(
                                              {
                                                payload,
                                                enqueueSnackbar,
                                                callback:
                                                  store.dispatch
                                                    .MassNotification
                                                    .getMassNotificationsDetailsList,
                                                callbackPush:
                                                  store.dispatch
                                                    .MassNotification
                                                    .getMassNotificationsPushList,
                                                callbackPayload: {
                                                  id:
                                                    massNotificationDetails.id,
                                                  offset: 0,
                                                  rowsPerPage: 5
                                                }
                                              }
                                            );
                                            if (
                                              anchorRef.current &&
                                              anchorRef.current.contains(
                                                event.target
                                              )
                                            ) {
                                              return;
                                            }
                                            setOpen(false);
                                          }}>
                                          Push Notification
                                        </MenuItem>
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </SafteyAlert>
                        )}
                        <div
                          style={{
                            display: "flex",
                            width: "172px",
                            height: "38px",
                            borderRadius: "8px",
                            backgroundColor: "#ffffff"
                          }}>
                          <Select
                            classesParent={classes.inputClass}
                            data={FilterSubDataTypeText}
                            valueProp={subtypeText}
                            onChange={handleSelectFilterText}
                            label="Select Status"
                          />
                        </div>
                      </FilterWrapperSections>
                    </FilterWrapper>
                    <>
                      <Table
                        selected={selected}
                        history={props.history}
                        isText
                        id={notificationDetails.id}
                        details={notificationDetails}
                        tableHeader={header}
                        tableData={tableDataTest}
                        organizationPage={pageText}
                        handleDetails={selecetd => {
                          setSelected(selecetd);
                        }}
                        getDataList={
                          store.dispatch.MassNotification
                            .getMassNotificationsTextList
                        }
                      />
                    </>
                  </TabPanel>
                ) : (
                  ""
                )}
              </div>
            </HistoryTableTabWrapper>
          </Grid>
        </Grid>
      </HistoryWrapper>
    </div>
  );
}
function handleListKeyDown(event) {
  if (event.key === "Tab") {
    event.preventDefault();
    setOpen(false);
  }
}
const mapStateToProps = state => {
  return {
    dashboardState: state.Dashboard,
    historyState: state.History,
    notificationDetailAState: state.MassNotification
  };
};
export default connect(
  mapStateToProps,
  null
)(NotificationDetails);
