import { api } from "../utils/http";

export const initialState = {
  error: "",
  status: {},
  userdata: {},
  organizations: {},
  userList: {},
  boundData: {},
  locationTrail: {},
  switchDataFromUser: {},
  deviceBoundData:{}
};
export const reducers = {
         SearchDataClear(state) {
           return {
             ...state,
             boundData: {}
           };
         },
         SearchData(state, payload) {
           return {
             ...state,
             boundData: payload.data.users
           };
         },
         SearchDataClearDevice(state) {
           return {
             ...state,
             deviceBoundData: {}
           };
         },
         SearchDataDevice(state, payload) {
           return {
             ...state,
             deviceBoundData: payload.data
           };
         },
         trailDataClear(state) {
           return {
             ...state,
             locationTrail: {}
           };
         },
         switchData(state, payload) {
           return {
             ...state,
             switchDataFromUser: payload
           };
         },
         trailSearchData(state, payload) {
           return {
             ...state,
             locationTrail: payload.data.trails
           };
         },
         organizationList(state, payload) {
           return {
             ...state,
             organizations: payload.data.organizations
           };
         },
         userList(state, payload) {
           return {
             ...state,
             userList: payload.data.users
           };
         },
         addError(state, payload) {
           return {
             ...state,
             error: payload
           };
         },
         removeError(state) {
           return {
             ...state,
             error: ""
           };
         }
       };

export const effects = {
  async startGeoSpatial(payload) {
    if (payload !== undefined) {
      this.removeError();
      this.SearchDataClear();
      try {
        const res = await api
          .post("api/v1/geospatial/search_by_geometry/", payload)
          .then(response => {
            this.SearchData(response.data);
          })
          .catch(err => {
            this.addError(err.response.data.errors);
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async startGeoSpatialDevice({ payloadDevice, devices }) {
    if (typeof payloadDevice !== "undefined" && devices !== 0 && devices!=="0") {
      this.removeError();
      this.SearchDataClearDevice();
      try {
        const res = await api
          .post(`/api/v1/device/${devices}/`, payloadDevice)
          .then(response => {
            this.SearchDataDevice(response.data);
          })
          .catch(err => {
            this.addError(err.response.data.errors);
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },
  async startTrailSearch(payload) {
    this.removeError();
    try {
      const res = await api
        .post("api/v1/geospatial/location_trail/", payload)
        .then(response => {
          this.trailSearchData(response.data);
        })
        .catch(err => {
          this.addError(err.response.data.errors);
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getOrganizationlist() {
    this.removeError();
    try {
      const res = await api
        .get("/api/v1/organization/?sort_by=name")
        .then(response => {
          this.organizationList(response.data);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getUserlist(data) {
    let payload = {
      organizations: data,
      sort_by: "username"
    };
    let URL = `api/v1/user/list/`;
    //  if(queryParam!==undefined){
    //   // URL = `api/v1/user/list/?organization_id[${queryParam}]=1`;
    //  }
    try {
      const res = await api
        .post(URL, payload)
        .then(response => {
          this.userList(response.data);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  }
};
export default {
  state: initialState,
  reducers,
  effects
};
