import React from 'react';
import { useStyles } from "./backtoprevious.style";
import backIcon from "../../assets/img/backicon.png";

const BackToPrevious = (props) => {
    const classes = useStyles();
    //destination is page you want the link to point to
    const { destination } = props;
    
    return (
        <div aria-label="breadcrumb" className={classes.breadcrumbContainer} onClick={()=>{location.href=destination}}>
            <img className={classes.imageBack} src={backIcon} />
            <div className={classes.breadCrumb}>
                Back to Previous Page
            </div>
        </div>
    )
}

export default BackToPrevious;

