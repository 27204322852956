import styled from "styled-components";
import { Button } from "@material-ui/core";

export const PageHeading = styled.div`
  display: flex;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 0.5%;
  height: 38px;
  font-family: Google Sans;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const CancelButton = styled.div`
  margin: 0px 30px 0px 4px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  width: 146px;
  height: 38px;
  border-radius: 8px;
  background-color: #4e4e54;
`;
export const CreateButton = styled(Button)`
  text-transform: capitalize !important;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 146px;
  height: 38px;
  border-radius: 8px !important;
  background-color: #ff2d21 !important;
`;

export const DetailsText = styled.div`
  display: flex;
  margin: 2px 6px 21px 52px;
  height: 25px;
  font-family: Google Sans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;