import React from "react";
import { T as Table } from "../../components/Table/table.styled";
import TablePagination from "@material-ui/core/TablePagination";
import moment from "moment";
import Checkbox from "@material-ui/core/Checkbox";
import Popper from "../../components/Popper";
import {
  StatusWrapper,
  StatusWrapperText
} from "./massnotification.details.style";
function TableComponent(props) {
  const {
    id,
    details,
    payloadData,
    searchValue,
    tableData,
    tableHeader,
    getDataList,
    organizationPage,
    handleDetails,
    isText,
    isHistory,
    isAppUser,
    history,
    selected
  } = props;
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [offset, setOffset] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [selectedCheck, setSelected] = React.useState(selected);
  React.useEffect(() => {
    setSelected(selected);
  }, [JSON.stringify(selected)]);

  const handleSelectAllClick = event => {
    let arr = [];
    if (event.target.checked) {
      const newSelecteds = tableData.map(n => n);
      newSelecteds.forEach(element => {
        arr.push({
          user_id: element.user_id,
          device_id: element.device_id,
          ...element
        });
      });
      handleDetails(arr);
      // setSelected(newSelecteds);
      return;
    }
    handleDetails([]);
  };
  const handleClick = (event, item) => {
    const selectedItem = selectedCheck.find(ele => ele.id === item.id);
    let newSelected = [];
    if (selectedItem) {
  newSelected = selectedCheck.filter(ele => ele.id !== item.id);
    } else {
      newSelected = [...selectedCheck, item];
    }
    // const selectedIndex = selectedCheck.indexOf(item);
    // let newSelected = [];
    // if (selectedIndex === -1) {
    //   newSelected = newSelected.concat(selectedCheck, item);
    // } else if (selectedIndex === 0) {
    //   newSelected = newSelected.concat(selectedCheck.slice(1));
    // } else if (selectedIndex === selectedCheck.length - 1) {
    //   newSelected = newSelected.concat(selectedCheck.slice(0, -1));
    // } else if (selectedIndex > 0) {
    //   newSelected = newSelected.concat(
    //     selectedCheck.slice(0, selectedIndex),
    //     selectedCheck.slice(selectedIndex + 1)
    //   );
    // }
    let arr = [];
    newSelected.forEach(element => {
      arr.push({
        user_id: element.user_id,
        device_id: element.device_id,
        ...element
      });
    });
    handleDetails(arr);
    // setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setOffset(rowsPerPage * newPage);
  };
  const handleChangeRowsPerPage = event => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setOffset(0);
  };

  React.useEffect(() => {
    let payload = {
      id,
      offset,
      rowsPerPage,
      search: searchValue
    };
    getDataList(payload);
  }, [offset, rowsPerPage, searchValue, id]);

  const isSelected = item => selectedCheck.find(ele => ele.id === item.id);
  return (
    <Table>
      <div className="wrapper-long">
        <table
          className="table"
          style={{
            marginBottom: "0",
            position: "sticky",
            top: "0",
            zIndex: "1",
            borderRadius: "8px",
            overflow: "hidden"
          }}>
          <tr className="row-head header green">
            <th className="cellHead">
              <Checkbox
                indeterminate={
                  selectedCheck.length > 0 && selectedCheck.length < rowsPerPage
                }
                checked={selectedCheck.length === rowsPerPage}
                onChange={handleSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
              />
            </th>
            {isText ? (
              <React.Fragment>
                <th className="cellHead">RECIPIENTS</th>
                <th className="cellHead">DELIVERED AT</th>
                <th className="cellHead">STATUS</th>
                <th className="cellHead">RESPONSE</th>
                <th className="cellHead">NOTES</th>
              </React.Fragment>
            ) : (
              tableHeader &&
              tableHeader.map((item, index) => (
                <th key={index} className="cellHead">
                  {item}
                </th>
              ))
            )}
          </tr>
        </table>
        <table className="table">
          <tbody>
            {tableData && tableData.length > 0 ? (
              tableData.map((item, index) => {
                const isItemSelected = Boolean(isSelected(item));
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <React.Fragment key={index}>
                    {!isText ? (
                      <tr className="row">
                        <td
                          className="cell-long"
                          onClick={event => {
                            handleClick(event, item);
                          }}>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </td>
                        <td className="cell-long">
                          {item.status !== "not_responded" ? (
                            <Popper
                              item={item}
                              dt={details}
                              setPage={setPage}
                            />
                          ) : (
                            <StatusWrapper>
                              <StatusWrapperText>
                                {item.status}
                              </StatusWrapperText>
                            </StatusWrapper>
                          )}
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          className="cell-long cell-yellow"
                          onClick={() => {
                            history.push(`/${item.username}`);
                          }}>
                          {item.username}
                        </td>
                        <td className="cell-long">
                          {item.response_text ? item.response_text : "-"}
                        </td>
                        <td className="cell-long">
                          {moment.utc(item.updated_on)
                            .local()
                            .format("DD MMM YYYY,HH:mm:ss")}
                        </td>
                        <td className="cell-long">
                          {item.notes ? item.notes : "-"}
                        </td>
                      </tr>
                    ) : (
                      <tr className="row" style={{ cursor: "auto" }}>
                        <td
                          style={{ cursor: "pointer" }}
                          className="cell-long"
                          onClick={event => {
                            handleClick(event, item);
                            // handleDetails(item);
                          }}>
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </td>
                        <td
                          style={{ cursor: "pointer" }}
                          className="cell-long cell-yellow"
                          onClick={() => {
                            history.push(`/${item.username}`);
                          }}>
                          {item.username}
                        </td>
                        <td className="cell-long">
                          {item.delivered_on
                            ? moment
                                .utc(item.updated_on)
                                .local()
                                .format("DD MMM YYYY,HH:mm:ss")
                            : "-"}
                        </td>
                        <td className="cell-long">
                          {item.status === "responded" ||
                          item.status === "safe" ||
                          item.status === "distress" ? (
                            <Popper
                              item={item}
                              dt={details}
                              setPage={setPage}
                            />
                          ) : (
                            <StatusWrapper>
                              <StatusWrapperText>
                                {item.status}
                              </StatusWrapperText>
                            </StatusWrapper>
                          )}
                        </td>
                        <td className="cell-long">
                          {item.response_text ? item.response_text : "-"}
                        </td>
                        <td className="cell-long">
                          {item.notes ? item.notes : "-"}
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })
            ) : (
              <tr className="cell-long-line">No Record Found</tr>
            )}
          </tbody>
        </table>
        {
          <TablePagination
            rowsPerPageOptions={[5, 10]}
            component="div"
            count={organizationPage && organizationPage.count}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
        <div></div>
      </div>
    </Table>
  );
}

export default TableComponent;
