import React from "react";
import {
  WatchlistTableHeader,
  WatchlistTableHeaderViewProfile,
  WatchlistTableHeaderViewProfileText,
  WatchlistTableHeaderStatusText,
  WatchlistTableHeaderStatusOvalOnline,
  WatchlistTableHeaderTitle
} from "./watchlist.style";
import Table from "../../components/Table/Generic";
function UserTable({ userData, history, activityData, id, table }) {
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{
          display: "flex",
          marginTop: "10px",
          marginBottom: "5px",
          flexDirection: "column"
        }}>
        <WatchlistTableHeader>
          {activityData && activityData.name}
        </WatchlistTableHeader>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <WatchlistTableHeaderTitle>
            {userData && userData.user && userData.user.location
              ? `${userData.user.location.location.latitude},${userData.user.location.location.longitude}`
              : ""}
          </WatchlistTableHeaderTitle>
          {activityData && activityData.reason && (
            <div
              title={activityData.reason}
              style={{
                cursor:"pointer",
                display: "inline-block",
                textOverflow: "ellipsis",
                width: "calc(100% - 20px)",
                // marginBottom: "5px",
                whiteSpace: "nowrap",
                overflow: "hidden"
              }}>
              Reason:{activityData.reason}
            </div>
          )}
        </div>
      </div>
      <Table tableData={table && table} id={id} />
    </div>
  );
}

export default UserTable;
