import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
         root: {
           height: "600px",
           "*::-webkit-scrollbar": {
             width: "0.4em"
           },
           "*::-webkit-scrollbar-track": {
             "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)"
           },
           "*::-webkit-scrollbar-thumb": {
             backgroundColor: "rgba(0,0,0,.1)",
             outline: "1px solid slategrey"
           }
         },
         countryTestMenuItem: {
           height: " 38px",
           borderRadius: "8px",
           border: "solid 1px #414a52",
           backgroundColor: " rgba(39, 45, 49, 0.9)",
           margin: " 21px 15px 0px 15px",
           display: "flex",
           width: "100%"
         },
         countryText: {
           width: " 58px",
           height: "16px",
           fontFamily: "Google Sans",
           fontSize: "15px",
           fontWeight: "500",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: " #ffffff",
           margin: "10px 0px 0px 30px"
         },
         highlightCountrySpan: {
          color: "red",
         },
         styledMenu: {
           width: "321px",
           height: "91px",
           backgroundImage: "linear-gradient(to left; #f8f8fa 44%; #ffffff 0%)",
           margin: "6px 0px 0px 58px !important",
           cursor: "pointer",
           display: "flex",
           flexDirection: "column"
         },
         spanTextUser: {
           width: "35px",
           height: "16px",
           fontFamily: "Google Sans",
           fontSize: "12px",
           fontHeight: "500",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: "#afafb8",
           display: ""
         },
         countText: {
           width: " 14px",
           height: "16px",
           fontFamily: "Google Sans",
           fontSize: "12px",
           fontHeight: "500",
           fontStretch: "normal",
           fontStyle: " normal",
           lineHeight: " normal",
           letterSpacing: "normal",
           color: "#000000"
         },
         countryLongText: {
           margin: "25px 0px 0px 1px",
           width: "231px",
           height: "25px",
           fontFamily: "Google Sans",
           fontSize: "12px",
           fontHeight: "500",
           fontStretch: "normal",
           fontStyle: " normal",
           lineHeight: " normal",
           letterSpacing: "normal",
           color: "#000000"
         },
         spanOvalOnline: {
           width: "8px",
           height: "8px",
           backgroundColor: "#4cd964",
           borderRadius: "4px"
         },
         userIconOval: {
           width: "36px",
           height: "36px",
           backgroundColor: "#eff2f5",
           borderRadius: "17px",
           textAlign: "center"
         },
         userOvalText: {
           width: "107px",
           height: "18px",
           fontFamily: "Google Sans",
           fontSize: "18px",
           fontHeight: "normal",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: "#161a1e",
           marginLeft: "19px",
           marginTop: " 9px"
         },
         scrollBar: {
           width: "4px",
           height: "240px",
           borderRadius: "2px",
           backgroundColor: "#ceced5"
         },
         menuItemRoot: {
           "&$selected, &$selected:focus, &$selected:hover": {
             backgroundColor: "green !important"
           }
         },
         rectangle: {
           height: "0px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           // border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: "#ffffff"
         },
         textField: {
           marginLeft: theme.spacing(1),
           marginRight: theme.spacing(1),
           width: 200
         },
         textBox: {
           width: "321px",
           borderRadius: " 8px",
           boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           backgroundColor: "#ffffff",
           margin: "3px 0px 0px 0px",
           "& $div": {
             "& $input": {
               width: "100%",
               height: "0x",
               fontFamily: "Google Sans",
               fontSize: "15px",
               fontWeight: "normal",
               fontStretch: "normal",
               fontStyle: "normal",
               lineHeight: "normal",
               letterSpacing: "normal",
               color: "#999999"
             }
           },
           "& $img": {
             width: "11px",
             height: "15px",
             objectFit: "contain"
           }
         },
         spanActive: {
           color: "#ff3b30",
           fontWeight: "bold",
           "& $img": {
             width: "11px",
             height: "15px",
             objectFit: "contain"
           }
         },
         spanInActive: {
           color: "#000000",
           fontWeight: "normal"
         },

         imgOpen: {
           margin: "-23px 0px 0px 201px"
         },
         pathLine: {
           width: "263px",
           height: "1px",
           border: "solid 1px #eff1f4",
           margin: " 0px 0px 0px 42px"
         },

         countryMenuStyle: {
           "&$selected": {
             backgroundColor: "#ffffff",
             "& $img": {
               display: "flex"
             },
             "&:focus": {
               "& $img": {
                 display: "flex"
               }
             },
             "&:active": {
              backgroundColor: "#f2f5ff"
             }
           }
         },
         searchMargin: {
           width: "321px",
           height: "38px",
           borderRadius: "8px",
           boxShadow: "0 2px 5px 0 rgba(0, 0, 0, 0.04)",
           border: "solid 1px rgba(71, 59, 59, 0.2)",
           backgroundColor: "#ffffff",
           fontFamily: "Google Sans",
           marginLeft: "250px"
         }
       }));
