import React from "react";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { useDispatch } from "react-redux";
import {
  StyledSection,
  StyledHeader,
  StyledSpan,
  SyledSpanItem
} from "./Header.styled";
import { useUserDispatch, signOut } from "../../context/UserContext";

//utilities and helpers
import { getCookie } from "../../utils/CookieHelper"
import Roles from "../../config/Roles"

const nav = ({ history }) => {
  const usernameToDisplay = getCookie('username') ? getCookie('username').replaceAll('_', ' ').toUpperCase() : 'Welcome'
  const role = getCookie('roles')
  
  const userDispatch = useUserDispatch();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const dispatch = useDispatch();

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  function logoutAction() {
    // localStorage.removeItem("token");
    // history.push("/login");
    dispatch({ type: "RESET" });
    signOut(userDispatch, history);
  }
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <StyledHeader>
      <StyledSection className="global-width">
        <img src={require("../../assets/img/logo.png")} alt="logo" />
        <StyledSpan>
          { (role && role === Roles.SUPER_ADMIN) ? 
            <SyledSpanItem
              onClick={() => {
                history.push("/history");
              }}>
              <img src={require("../../assets/img/bell.png")} alt="bell" />
              {/* <StyledOvalBadge style={{ display: "flex" }}>
                <StyledOvalBadgeText>4</StyledOvalBadgeText>
              </StyledOvalBadge> */}
            </SyledSpanItem>
          : 
          <></>
          }
          <SyledSpanItem className="admin-copy">{usernameToDisplay}</SyledSpanItem>
          
          <SyledSpanItem
            // ref={anchorRef}
            // aria-controls={open ? "menu-list-grow" : undefined}
            // aria-haspopup="true"
            // onClick={handleToggle}
            style={{
              width: " 36px",
              height: "36px",
              backgroundColor: " #eff2f5",
              borderRadius: "18px",
              textAlign: "center",
              justifyContent: "center",
              margin: " 3px 0px 0px 2px"
            }}>
            <img
              style={{ margin: "-3px 0px 0px 0px" }}
              src={require("../../assets/img/user.png")}
              alt="user"
            />
          </SyledSpanItem>
          <SyledSpanItem
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className="admin-copy">
            <img
              style={{ height: "8px", cursor: "pointer" }}
              src={require("../../assets/img/patharrow.png")}
              alt="path"
            />
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              style={{ left: "-45px", zIndex: "10000" }}
              transition
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                    left: "-45px",
                    transform: "translate3d(-41px, 11px, 0px)"
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}>
                        <MenuItem
                          onClick={() => {
                            logoutAction();
                          }}>
                          Logout
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </SyledSpanItem>
        </StyledSpan>
      </StyledSection>
    </StyledHeader>
  );
};
export default nav;
/* 
<a className="logo">
          <img src={require("../../assets/img/group.png")} className="group" />
        </a>
        <div className="admin-Copy">
          <span className="rectangle Group">
            <img
              src={require("../../assets/svg/group.png")}
            />
          </span>
          <span className="admin-Copy">Admin</span>

*/
