import styled from "styled-components";
import InputBase from "@material-ui/core/InputBase";
export const NotificationWrapper = styled.section`
  display: flex !important;
  margin: 11px 0 70px 0 !important;
`;
export const NotificationTopWrapper = styled.div`
  display: flex !important;
  justify-content: space-between;
  margin: 11px 0 10px 0;
`;

export const NotificationBack = styled.div`
  cursor: pointer;
  display: flex !important;
`;
export const NotificationBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const NotificationFormControl = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const NotificationFormControlBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  padding-right: 15px;
`;

export const NotificationSection = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0px 0px 0px 45px;
`;
export const InputWrap = styled(InputBase)`
  width: 88%;
  padding: 12px;
  font-size: 14px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: ${props =>
    props.showErrors ? "solid 1px #ff2d21" : "solid 1px rgba(71, 59, 59, 0.2)"};
  background-color: #ffffff;
`;

export const Label = styled.label`
  width: 200px;
  margin-bottom: 10px;
  height: 16px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.6px;
  color: ${props => (props.error ? " #ff2d21" : "#292b2e;")};
`;
export const DetailsText = styled.div`
  display: flex;
  margin: 2px 6px 21px 52px;
  height: 25px;
  font-family: Google Sans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const NotificationForm = styled.form`
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;
export const CancelButton = styled.div`
  margin: 0px 30px 0px 4px;
  display: flex;
  justify-content: center;
  width: 146px;
  height: 38px;
  border-radius: 8px;
  background-color: #4e4e54;
`;

export const CreateButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  width: 146px;
  height: 38px;
  border-radius: 8px;
  background-color: #ff2d21;
`;

export const Textspan = styled.span`
  margin: 9px 0px 0px 1px;
  width: 47px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;
export const NotificationBackText = styled.div`
  width: 120px;
  height: 16px;
  font-family: Google Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #4e4e4e;
`;
export const NotificationHeading = styled.div`
  margin-top: 25px;
  margin-bottom: 15px;
  height: 38px;
  font-family: Google Sans;
  font-size: 30px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const NotificationCreateWrapper = styled.div`
  display: flex;
  width: 194.4px;
  height: 38px;
  border-radius: 8px;
  background-color: #ff2d21;
  cursor: pointer;
`;
export const CreateText = styled.span`
  margin: 9px 3px 3px 13px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;
export const NotificationTopWrapperSearch = styled.div`
  display: flex !important;
  justify-content: flex-end;
  margin: 11px 0 10px 0;
`;
export const SearchWrapper = styled.div`
  width: 410px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(71, 59, 59, 0.2);
  background-color: #ffffff;
`;
export const WatchlistStcikyBarWrapper = styled.section`
 display:flex
 flex: 0 0 auto;
 flex-direction:row
 width: 100%;
 height: 66px;
 border-radius: 10px;
 box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
 border: solid 2px rgba(151, 151, 151, 0.06);
 background-color: #ffffff;
                          `;
export const StcikyBarContentDiv = styled.div`
margin:25px auto
display:flex
  width: 100%;
  height: 548px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.17);
  background-color: #ffffff;
`;
export const NotificationTableWrapper = styled.section`
  display: flex;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;

export const NotificationTable = styled.div`
  width: 100%;
  margin: 40px 0px 0px 25px;
`;

export const MoreAction = styled.div`
  justify-content: center;
  display: flex;
  margin: 17px 2px 0px 2px;
  width: 143px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
`;

export const MoreActionText = styled.span`
  margin-top: 8px;
  width: 89px;
  height: 18px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ff2d21;
`;

export const NotificationDetailBlock = styled.div`
  flex-direction: column;
  display: flex;
  height: 145px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;

export const NotificationDetailBlockSections = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 21px 0px 10px 44px;
`;

export const NotificationDetailHeadingSpan = styled.span`
  min-width: 200px;
  height: 13px;
  opacity: 0.7;
  font-family: Google Sans;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
export const NotificationDetailContentSpan = styled.span`
  min-width: 110px;
  height: 20px;
  font-family: Google Sans;
  font-size: 16px;
  word-break: break-all;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #161a1e;
`;
