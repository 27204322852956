import React from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import moment from "moment";
import { get, find } from "lodash";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import { useSnackbar } from "notistack";

import { useStyles } from "./DialogUser.style";
import store from "../../store";

export default function SimpleSlide({ checked, handelClose, userinfo }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const {
    user_info,
    cellular_info,
    device_info,
    location_info,
    wifi_info,
    bluetooth_info
  } = userinfo;
  return (
    <Slide
      className={classes.root}
      direction="left"
      in={checked}
      mountOnEnter
      unmountOnExit>
      <Paper elevation={4} className={classes.paper}>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handelClose}>
          <CloseIcon />
        </IconButton>

        {user_info !== undefined &&
        cellular_info !== undefined &&
        wifi_info !== undefined &&
        device_info !== undefined &&
        location_info !== undefined &&
        bluetooth_info !== undefined ? (
          <section className={classes.flex}>
            <section className={classes.flexSection}>
              <div className={classes.userText}>
                <Link to={{ pathname: `/${user_info.username}` }}>
                  {user_info !== null ? user_info.username : ""}
                </Link>
                <span
                  className={
                    user_info !== null
                      ? user_info.online
                        ? classes.onlineOval
                        : classes.offlineOval
                      : "-"
                  }></span>
              </div>
              <div className={classes.lastUpdated}>
                Last Updated:{" "}
                {moment(
                  user_info !== null ? user_info.recent_update : new Date()
                ).fromNow()}{" "}
                at{" "}
                {moment(
                  user_info !== null ? user_info.recent_update : new Date()
                ).format("h:mm:ss a")}
              </div>
              <div
                className={classes.pushNotification}
                onClick={() => {
                  let payload = {
                    data: [
                      {
                        user_id: user_info.id,
                        device_id: device_info.device_id
                      }
                    ]
                  };
                  store.dispatch.UserProfile.sendPushNotification({
                    payload,
                    enqueueSnackbar
                  });
                }}>
                <span className={classes.pushNotificationText}>
                  PUSH NOTIFICATION
                </span>
              </div>
            </section>
            <Divider
              style={{
                border: "1px dashed #d1d1d6",
                marginTop: "20px",
                width: "300px",
                marginLeft: "12px"
              }}
            />
            <section
              className={classes.flex}
              style={{
                overflow: "scroll",
                height: "576px",
                overflowX: "hidden"
              }}>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>CLIENT ID</span>
                <span className={classes.detailContent}>
                  {user_info !== null
                    ? user_info.organization_id
                      ? user_info.organization_id
                      : "-"
                    : ""}
                </span>
              </div>
              <div
                className={classes.detailSub}
                style={{ flexDirection: "row" }}>
                <div className={classes.detailWrapper}>
                  <span className={classes.detailHeading}>IMSI</span>
                  <span
                    title={get(cellular_info, "imsi", "-")}
                    className={classes.detailContent}>
                    {get(cellular_info, "imsi", "-")}
                  </span>
                </div>
                <div className={classes.detailWrapper}>
                  <span className={classes.detailHeading}>IMEI</span>
                  <span
                    title={get(device_info, "imei", "-")}
                    className={classes.detailContent}>
                    {get(device_info, "imei", "-")}
                  </span>
                </div>
              </div>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>Phone Number</span>
                <span
                  className={classes.detailContent}
                  style={{ width: "120px" }}>
                  {user_info && user_info.phone_number
                    ? user_info.phone_number
                    : "-"}
                </span>
              </div>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>Location</span>
                <span
                  className={classes.detailContent}
                  style={{ width: " 194px" }}>
                  {`(${location_info &&
                    location_info.location.latitude}, ${location_info &&
                    location_info.location.longitude})`}
                </span>
              </div>
              {/* <div className={classes.detailSub}>
                <span className={classes.detailHeading}>Accuracy</span>
                <span className={classes.detailContent}>{"-"}</span>
              </div> */}
              <div
                className={classes.detailSub}
                style={{ flexDirection: "row" }}>
                <div className={classes.detailWrapper}>
                  <span className={classes.detailHeading}>Speed</span>
                  <span className={classes.detailContent}>
                    {get(location_info, "speed", "-")}
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "0px 0px 0px -14px"
                  }}>
                  <span className={classes.detailHeading}>Heading</span>
                  <span className={classes.detailContent}>
                    {get(location_info, "heading", "-")}
                  </span>
                </div>
              </div>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>Elevation </span>
                <span className={classes.detailContent}>
                  {location_info && location_info.elevation
                    ? location_info.elevation
                    : "-"}
                </span>
              </div>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>
                  Wireless Access Point
                </span>
                <span className={classes.detailContent}>
                  {get(find(wifi_info, { connected: true }), "ssid", "-")}
                </span>
              </div>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>Bluetooth Device</span>
                <span className={classes.detailContent}>
                  {get(
                    find(bluetooth_info, { connected: true }),
                    "b_device_name",
                    "-"
                  )}
                </span>
              </div>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>Cellular Network</span>
                <span
                  className={classes.detailContent}
                  style={{ width: " 194px" }}>
                  {cellular_info !== null
                    ? cellular_info.network_operator_name
                      ? cellular_info.network_operator_name
                      : ""
                    : "-"}
                </span>
              </div>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>CID</span>
                <span className={classes.detailContent}>
                  {cellular_info !== null ? cellular_info.cid : "-"}
                </span>
              </div>
              <div className={classes.detailSub}>
                <span className={classes.detailHeading}>MCC</span>
                <span className={classes.detailContent}>
                  {cellular_info !== null ? cellular_info.mcc : "-"}
                </span>
              </div>
              <div
                className={classes.detailSub}
                style={{ margin: "11px 18px 43px 4px" }}>
                <span className={classes.detailHeading}>LAC</span>
                <span className={classes.detailContent}>
                  {cellular_info !== null
                    ? cellular_info.lac
                      ? cellular_info.lac
                      : "-"
                    : "-"}
                </span>
              </div>
            </section>
          </section>
        ) : (
          ""
        )}
      </Paper>
    </Slide>
  );
}
