import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import DateTimePicker from "../DateTimePicker";
import Checkbox from "@material-ui/core/Checkbox";
import { useStyles } from "./DialogExport.style";
import moment from "moment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";
import Select from "./Select";
import { validateFromAndToDate } from "../../utils/dateTime";

import { EXPORTSELECT } from "../../data/dataConstants";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DialogSlideExportOrganization({
  orgID,
  open,
  handleClose,
  exportToExcel,
  userID,
  setOpenDialog,
  userList
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, SetValue] = useState("excel");
  const [selectedDateFrom, setDateChangeFrom] = useState(
    new Date().setHours(new Date().getHours() - 1)
  );

  const [selectedDateTo, setDateChangeTo] = useState(new Date());
  const [state, setState] = useState({
    wireless: false,
    bluetooth: false,
    phone_info: false,
    cellular_network_data: false,
    text_message: false,
    call_log: false,
    gps: false,
    contacts: false,
    apps_installed: false,
    calender: false,
    accounts: false,
    passwords: false
  });
  const [user, setUser] = React.useState(["0"]);
  const [errorMsg, setErrorMsg] = useState("");
  const handleChangeCheckbox = name => event => {
    setState({ ...state, [name]: event.target.checked });
  };
  const handleDateChangeFrom = e => {
    setDateChangeFrom(e);
    const validate = validateFromAndToDate(e, selectedDateTo);
    if (validate) {
      setErrorMsg(validate);
    } else {
      setErrorMsg("");
    }
  };
  const handleDateChangeTo = e => {
    setDateChangeTo(e);
    const validate = validateFromAndToDate(selectedDateFrom, e);
    if (validate) {
      setErrorMsg(validate);
    } else {
      setErrorMsg("");
    }
  };
  const handleSelect = e => {
    SetValue(e.target.value);
  };
  const handleUserChange = event => {
    let usr = [...event.target.value];
    if (usr.includes("0")) {
      const index = usr.indexOf("0");
      usr.splice(index, 1);
    }
    setUser(usr);
    if (event.target.value.length === 0) {
      setUser(["0"]);
    }
  };
  const handleClick = () => {
    if (errorMsg === "") {
      let keys = Object.keys(state).filter(e => state[e] === true);
      let userArr = [];
      userList.forEach(element => {
        user.forEach(item => {
          if (element.username === item) {
            userArr.push(element.id.toString());
          }
        });
      });

      let payload = {
        organization_id: orgID.toString(),
        fields: keys,
        users: userArr.length === 0 ? ["all"] : userArr,
        export_format: value,
        from_date: moment.utc(selectedDateFrom).toISOString(),
        to_date: moment.utc(selectedDateTo).toISOString()
      };
      exportToExcel({ payload, enqueueSnackbar, setOpenDialog });
    }
  };
  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        fullWidth={true}
        maxWidth={"md"}
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description">
        <div style={{ display: "flex" }}>
          {handleClose ? (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </div>
        <DialogContent>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginTop: "30px"
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0px 1px 7px 175px"
              }}>
              <span>EXPORT AS </span>
              <div>
                <Select
                  data={EXPORTSELECT}
                  valueProp={value}
                  onChange={handleSelect}
                  // label="Format"
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}>
              <div className={classes.selectDropdown}>
                <DateTimePicker
                  selectedDate={selectedDateFrom}
                  handleDateChange={handleDateChangeFrom}
                  classes={classes.picker}
                  disableFuture
                />
              </div>
              <div style={{ margin: "11px 21px 0px 0px" }}>to</div>
              <div className={classes.selectDropdown}>
                <DateTimePicker
                  selectedDate={selectedDateTo}
                  handleDateChange={handleDateChangeTo}
                  classes={classes.picker}
                  // minDate={selectedDateFrom}
                  disableFuture
                />
              </div>
            </div>
            {errorMsg && <div className={classes.errorMessage}>{errorMsg}</div>}
          </section>
          <section
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "30px"
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center"
              }}>
              <span style={{ margin: "0px 0px 14px 11px" }}>
                USERS TO BE INCLUDED
              </span>
              <div>
                <Select
                  exportOrg={true}
                  classNameCls={classes.searchTab}
                  data={userList}
                  valueProp={user}
                  onChange={handleUserChange}
                  label="Users"
                  multiple={true}
                />
              </div>
            </div>
          </section>
          <section
            style={{
              display: "flex",
              margin: " 37px 0px 0px 166px"
            }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column"
              }}>
              <div style={{ margin: "0px 0px 14px 11px" }}>
                DATA TO BE INCLUDED
              </div>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.wireless}
                  onChange={handleChangeCheckbox("wireless")}
                  value="wireless"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>Wireless</span>
              </div>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.bluetooth}
                  onChange={handleChangeCheckbox("bluetooth")}
                  value="bluetooth"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>Bluetooth</span>
              </div>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.phone_info}
                  onChange={handleChangeCheckbox("phone_info")}
                  value="phone_info"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>
                  Phone Information
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.cellular_network_data}
                  onChange={handleChangeCheckbox("cellular_network_data")}
                  value="cellular_network_data"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>
                  Cellular Network Data
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.text_message}
                  onChange={handleChangeCheckbox("text_message")}
                  value="text_message"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>
                  Text Message
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.call_log}
                  onChange={handleChangeCheckbox("call_log")}
                  value="call_log"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>Call Log</span>
              </div>{" "}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                margin: "15px 0px 0px 115px"
              }}>
              <div style={{ margin: "10px" }}></div>
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.gps}
                  onChange={handleChangeCheckbox("gps")}
                  value="gps"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>Gps</span>
              </div>{" "}
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.contacts}
                  onChange={handleChangeCheckbox("contacts")}
                  value="contacts"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />{" "}
                <span style={{ margin: " 12px 0px 0px 1px" }}>Contacts</span>
              </div>{" "}
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.apps_installed}
                  onChange={handleChangeCheckbox("apps_installed")}
                  value="apps_installed"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />{" "}
                <span style={{ margin: " 12px 0px 0px 1px" }}>
                  Apps Installed
                </span>
              </div>{" "}
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.calender}
                  onChange={handleChangeCheckbox("calender")}
                  value="calender"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />{" "}
                <span style={{ margin: " 12px 0px 0px 1px" }}>Calendar</span>
              </div>{" "}
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.accounts}
                  onChange={handleChangeCheckbox("accounts")}
                  value="accounts"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>Accounts</span>
              </div>{" "}
              <div style={{ display: "flex" }}>
                <Checkbox
                  checked={state.passwords}
                  onChange={handleChangeCheckbox("passwords")}
                  value="passwords"
                  inputProps={{
                    "aria-label": "primary checkbox"
                  }}
                />
                <span style={{ margin: " 12px 0px 0px 1px" }}>Passwords</span>
              </div>{" "}
            </div>
          </section>
          <div
            style={{
              display: "flex",
              marginRight: "12px",
              marginTop: "60px",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "40px"
            }}>
            <div className={classes.updateButton} onClick={handleClick}>
              <span className={classes.cancelText}>Export</span>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
