import styled from "styled-components";
import InputBase from "@material-ui/core/InputBase";
import {
  Button,
  Typography
} from "@material-ui/core";

export const OrganizationWrapper = styled.section`
  display: flex !important;
  margin: 11px 0 70px 0 !important;
  input::-ms-clear {
    width: 0;
    height: 0;
  }
`;
export const OrganizationTopWrapper = styled.div`
  display: flex !important;
  justify-content: space-between;
  margin: 11px 0 10px 0;
`;

export const OrganizationBlock = styled.div`
 display: flex; flex-direction: column;    width: 100% ;
`;
export const OrganizationBlockFilter = styled.div`
  display: flex;
  width: 100% ;
  justify-content: flex-start;
`;
export const OrganizationBlockFilterSelect = styled.div`
  .MuiInputBase-root {
    width: 110px
  }
`;
export const OrganizationFormControl = styled.div`
         display: flex;
         flex-direction: column;
         width: 25%;
         .datepicker {
           width: 88%;
           padding: 12px;
           font-size: 14px;
           height: 38px;
           border-radius: 8px;
           box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
           border: solid 1px rgba(71, 59, 59, 0.2);
           background-color: #ffffff;
         }
       `;

export const OrganizationFormControlBlock = styled.div`
 display: flex; flex-direction: column;  width:25%; padding-right:15px;
`;

export const OrganizationSection = styled.section`
         display: flex;
        //  justify-content: space-between;
         width: 100%;
         margin-top: 30px;
         padding: 0px 0px 0px 45px;
       `;
export const InputWrap = styled(InputBase)`
         width: 88%;
         padding: 12px;
         font-size: 14px;
         height: 38px;
         border-radius: 8px;
         box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
         border: ${props =>
           props.showErrors
             ? "solid 1px #ff2d21"
             : "solid 1px rgba(71, 59, 59, 0.2)"};
         background-color: #ffffff;
       `;

export const Label = styled.label`
         width: 200px;
         margin-bottom: 10px;
         height: 16px;
         font-family: Google Sans;
         font-size: 12px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: 0.6px;
         color: ${props => (props.error ? " #ff2d21" : "#292b2e;")};
       `;   
export const DetailsText = styled.div`
         display: flex;
         margin: 2px 6px 21px 52px;
         height: 25px;
         font-family: Google Sans;
         font-size: 20px;
         font-weight: normal;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         color: #161a1e;
       `;
export const OrganizationForm = styled.form`
width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.06);
  background-color: #ffffff;
`;
export const Textspan = styled.span`
         margin: 9px 0px 0px 1px;
         width: 47px;
         height: 18px;
         font-family: Google Sans;
         font-size: 14px;
         font-weight: bold;
         font-stretch: normal;
         font-style: normal;
         line-height: normal;
         letter-spacing: normal;
         color: #ffffff;
       `;
export const OrganizationCreateWrapper = styled.div`
  display: flex;
  width: 194.4px;
  height: 38px;
  border-radius: 8px;
  background-color: #ff2d21;
  cursor: pointer;
`;
export const CreateText = styled.span`
  margin: 9px 3px 3px 13px;
  font-family: Google Sans;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
`;
export const OrganizationTopWrapperSearch = styled.div`
  display: flex !important;
  justify-content: flex-end;
  margin: 11px 0 10px 0;
`;
export const OrganizationTopWrapperSearchDetails = styled.div`
         display: flex !important;
         justify-content: flex-end;
         margin: 11px 2px 10px 8px;
         margin-left: auto;
       `;
    

export const SearchWrapper = styled.div`
  width: 410px;
  height: 38px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: solid 1px rgba(71, 59, 59, 0.2);
  background-color: #ffffff;
`;
export const WatchlistStcikyBarWrapper = styled.section`
 display:flex
 flex: 0 0 auto;
 flex-direction:row
 width: 100%;
 height: 66px;
 border-radius: 10px;
 box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
 border: solid 2px rgba(151, 151, 151, 0.06);
 background-color: #ffffff;
                          `;
export const StcikyBarContentDiv = styled.div`
margin:25px auto
display:flex
  width: 100%;
  height: 548px;
  border-radius: 10px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
  border: solid 2px rgba(151, 151, 151, 0.17);
  background-color: #ffffff;
`;
export const OrganizationTableWrapper = styled.section`
          display:flex;
         border-radius: 10px;
         box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
         border: solid 2px rgba(151, 151, 151, 0.06);
         background-color: #ffffff;
       `;


export const OrganizationTable = styled.div`
         width: 100%;
         margin: 40px 0px 0px 25px;
       `;     

export const MoreAction = styled.div`
          cursor:pointer;
         justify-content: center;
         display: flex;
         margin: 17px 2px 0px 2px;
         width: 143px;
         height: 38px;
         border-radius: 8px;
         box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
         border: solid 1px rgba(0, 0, 0, 0.09);
         background-color: #ffffff;
       `;       

 export const MoreActionText = styled.span`
          display: flex;
          margin-top: 8px;
          width: 89px;
          height: 18px;
          font-family: Google Sans;
          font-size: 14px;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #ff2d21;
        `;          

        export const OrganizationDetailBlock = styled.div`
        
                 flex-direction: column;
                 display: flex;
                 height: 145px;
                 border-radius: 10px;
                 box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.06);
                 border: solid 2px rgba(151, 151, 151, 0.06);
                 background-color: #ffffff;
               `;

          export const OrganizationDetailBlockSections = styled.div`
                   display: flex;
                   justify-content: space-between;
                   margin: 21px 0px 10px 44px;
                 `;     


         export const OrganizationDetailHeadingSpan = styled.span`
                  min-width: 200px;
                  height: 13px;
                  opacity: 0.7;
                  font-family: Google Sans;
                  font-size: 10px;
                  font-weight: 500;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: normal;
                  letter-spacing: normal;
                  color: #161a1e;
                `;      
          export const OrganizationDetailContentSpan = styled.span`
                   min-width: 110px;
                   height: 20px;
                   font-family: Google Sans;
                   font-size: 16px;
                   word-break: break-all;
                   font-weight: normal;
                   font-stretch: normal;
                   font-style: normal;
                   line-height: normal;
                   letter-spacing: normal;
                   color: #161a1e;
                 `;
                 export const PhoneNumber = styled.div`
                //  .PhoneInput{
                //    display: flex;
                //  }
                //  .PhoneInputInput {
                //    border-bottom: 1px solid #c5d2e0;
                //    width: 234px;
                //    border: solid 1px rgba(71, 59, 59, 0.2);
                //    height: 38px;
                //    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
                //    border-radius: 8px;
                //    background-color: #ffffff;
                //    padding-left: 12px;
                //    input {
                //      height: auto !important;
                //    }
                //  }
                //  .PhoneInputCountry{
                //    position: relative;
                //    align-self: stretch;
                //    display: flex;
                //    align-items: center;
                //    margin-right: 0.5em;
                //  }
                //  .PhoneInputCountryIcon {
                //    width: 32px;
                //    height: 22px;
                //    border: none;
                //  }
                //  .PhoneInputCountrySelect{
                //    position: absolute;
                //    top: 0;
                //    left: 0;
                //    height: 100%;
                //    width: 100%;
                //    z-index: 1;
                //    border: 0;
                //    opacity: 0;
                //    cursor: pointer;
                //  }
                //  .PhoneInputCountrySelectArrow{
                //    display: block;
                //    content: '';
                //    width: 0;
                //    height: 0;
                //    margin-bottom: 0.1em;
                //    margin-top: 0.3em;
                //    margin-left: 0.3em;
                //    border-width: 0.35em 0.2em 0 0.2em;
                //    border-style: solid;
                //    border-left-color: transparent;
                //    border-right-color: transparent;
                //    color: #B8BDC4;
                //    opacity: 0.7;
                //    transition: color 0.1s;
                //  }

                .react-phone-number-input__country-select{
                  position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    z-index: 1;
                    border: 0;
                    opacity: 0;
                    cursor: pointer;
                }
                .react-phone-number-input__country-select-arrow{
                  display: block;
                   content: '';
                   width: 0;
                   height: 0;
                   margin-bottom: 0.1em;
                   margin-top: 0.3em;
                   margin-left: 0.3em;
                   border-width: 0.35em 0.2em 0 0.2em;
                   border-style: solid;
                   border-left-color: transparent;
                   border-right-color: transparent;
                   color: #B8BDC4;
                   opacity: 0.7;
                   transition: color 0.1s;
                }
                .react-phone-number-input__country{
                  display: flex;
                  position: relative;
                  align-self: stretch;
                  display: flex;
                  align-items: center;
                  margin-right: 0.5em;
                  margin-bottom: 0.4em;
                }
                .react-phone-number-input__row{
                  display: flex;
                }
                .react-phone-number-input {
                  input {
                    border-bottom: 1px solid #c5d2e0;
                    width: 234px;
                    border: solid 1px rgba(71, 59, 59, 0.2);
                    height: 38px;
                    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
                    border-radius: 8px;
                    background-color: #ffffff;
                    padding-left: 12px;
                  }
                }
                .react-phone-number-input__icon {
                  width: 32px;
                  height: 22px;
                  border: none;
                }
                 .MuiFormHelperText-root.Mui-error{
                   width: 300px;
                 }
                 .errorElement {
                   color: #990000;
                   /* margin-left: 45px; */
                   margin-top: -4px;
                   font-family: Gilroy;
                   font-size: 14px;
                   line-height: 25px;
                 }
               `;
               