import React, { useMemo } from "react";
import moment from "moment";
import { find, get } from "lodash";

export default function DetailComponents({ userInfo, classes, shrink }) {
  const userDetails = useMemo(
    () => [
      {
        label: "IMSI",
        value: get(userInfo, "cellular_info.imsi", "-")
      },
      {
        label: "IMEI",
        value: get(userInfo, "device_info.imei", "-")
      },
      {
        label: "PHONE NUMBER",
        value: get(userInfo, "user_info.phone_number", "-")
      },
      {
        label: "MARK AS SAFE AT",
        value: get(userInfo, "marked_safe_info.location")
          ? `(${userInfo.marked_safe_info.location.latitude}, ${userInfo.marked_safe_info.location.longitude})`
          : "-"
      },
      {
        label: "LAST ALERTED AT",
        value: get(userInfo, "areyou_ok_latest.created_on")
          ? moment(userInfo.areyou_ok_latest.created_on)
              .local()
              .format("DD-MM-YYYY, HH:mm:ss")
          : "-"
      },
      {
        label: "LAST KEYWORD ALERTED AT",
        value: get(userInfo, "areyou_ok_latest.created_on")
          ? moment(userInfo.areyou_ok_latest.created_on)
              .local()
              .format("DD-MM-YYYY, HH:mm:ss")
          : "-"
      },
      {
        label: "WIRELESS ACCESS POINT",
        value: get(find(userInfo.wifi_info, { connected: true }), "ssid", "-")
      },
      {
        label: "BLUETOOTH DEVICE",
        value: get(
          find(userInfo.bluetooth_info, { connected: true }),
          "b_device_name",
          "-"
        )
      },
      {
        label: "CELLULAR NETWORK",
        value: get(userInfo, "cellular_info.network_operator_name", "-")
      }
    ],
    [userInfo]
  );

  return (
    <div className={classes.mapComponentDetails}>
      <div className={classes.mapBottomContent}>
        {userDetails.map(detail => (
          <div
            key={detail.label}
            className={`${classes.userDetailContent} ${
              shrink ? classes.userDetailShrink : ""
            }`}>
            <div className={classes.userDetailWrapper}>
              <span className={classes.detailHeading}>{detail.label}</span>
              <span title={detail.value} className={classes.detailContent}>
                {detail.value}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
