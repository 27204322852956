import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles(theme => ({
         root: {
           flexGrow: 1,
           fontFamily: "Google Sans",
           display: "flex",
           width: "100%",
           height: " 585px",
           borderRadius: " 10px",
           boxShadow: "0 5px 10px 0 rgba(0, 0, 0, 0.06)",
           border: "solid 2px rgba(151, 151, 151, 0.06)",
           backgroundColor: " #ffffff",
           flexDirection: "column"
         },
         formControl: {
           margin: theme.spacing(1),
           minWidth: 120,
           maxWidth: 300
         },
         chips: {
           display: "flex",
           flexWrap: "wrap"
         },
         chip: {
           margin: 2
         },
         noLabel: {
           marginTop: theme.spacing(3)
         },
         selectDropdown: {
           margin: " 0px 20px 0px 0px",
           width: "234px",
           height: "38px"
           //  borderRadius: " 8px",
           //  border: " solid 1px rgba(71, 59, 59, 0.2)",
           //  backgroundColor: " #ffffff"
         },
         orgSelect: {
           height: "39px !important",
           borderRadius: "8px !important",
           boxShadow: " 0 2px 5px 0 rgba(0, 0, 0, 0.04) !important",
           border: "solid 1px rgba(71, 59, 59, 0.2) !important",
           backgroundColor: "#ffffff !important",
           padding: "5px",
           fontFamily: "Google Sans",
           margin: "5px 16px 3px 5px",
           width: "173px"
         },
         errorMessage: {
           color: "#ff2d21",
           fontSize: "12px",
           textAlign: "center",
           width: "100%",
           paddingTop: "10px"
         },
         cancelText: {
           justifyContent: "center",
           alignContent: "center",
           alignSelf: "center",
           margin: "0px 0px 0px 45px",
           width: "47px",
           height: "18px",
           fontFamily: " Google Sans",
           fontSize: "14px",
           fontWeight: "bold",
           fontStretch: "normal",
           fontStyle: "normal",
           lineHeight: "normal",
           letterSpacing: "normal",
           color: "#ffffff"
         },
         searchTab: {
           width: "410px !important",
           height: "20px !important"
         },
         updateButton: {
           width: "146px",
           height: "38px",
           borderRadius: " 8px",
           backgroundColor: "#ff2d21",
           display: "flex",
           cursor: "pointer"
         },
         picker: {
           margin: "3px 1px 2px 14px"
         },
         closeButton: {
           position: "absolute",
           right: theme.spacing(1),
           top: theme.spacing(1),
           color: theme.palette.grey[500]
         }
       }));
