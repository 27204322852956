import React from "react";
import {
  StyledMenu,
  StyledSideNav,
  SpanText,
  StyledLink
} from "./Sidebar.styled";

import Roles from "../../config/Roles"
import { getCookie } from "../../utils/CookieHelper"


const Sidebar = ({ open, ...props }) => {
  const role = getCookie('roles')
  const [active, setActive] = React.useState(false);
  const structure = [
    {
      id: 0,
      label: "Dashboard",
      link: "/dashboard",
      icon: "dashboard",
      active: "dashboardactive",
      isDisable: false,
      accessRoles: [Roles.GUEST_USER, Roles.REGULAR_USER, Roles.TENANT_ADMIN, Roles.RISKOVERY_ADMIN, Roles.SUPER_ADMIN, Roles.TEST_ADMIN] //Roles.TEST_ADMIN is for use when debugging in browser developer tools
    },
    {
      id: 1,
      label: "Geospatial Search",
      link: "/geospatial",
      icon: "geospatial_search",
      active: "geospatial_searchactive",
      isDisable: false,
      accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN, Roles.TENANT_ADMIN]
    },
    {
      id: 2,
      label: "Watchlist",
      link: "/watchlist",
      icon: "watchlist",
      active: "watchlistactive",
      isDisable: false,
      accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN,Roles.TENANT_ADMIN]
    },
    {
      id: 3,
      label: "Mass Notifiaction",
      link: "/massnotification",
      icon: "mess_notification",
      active: "mess_notificationactive",
      isDisable: false,
      accessRoles: [Roles.SUPER_ADMIN]
    },
    {
      id: 4,
      label: "History",
      link: "/history",
      icon: "history",
      active: "historyactive",
      isDisable: false,
      accessRoles: [Roles.SUPER_ADMIN]
    },
    {
      id: 5,
      label: "Organizations",
      link: "/organizations",
      icon: "organization",
      active: "organizationactive",
      isDisable: false,
      accessRoles: [Roles.SUPER_ADMIN, Roles.RISKOVERY_ADMIN]
    }
  ];
  const checkActive = (link, label) => (ref, { pathname }) => {
    if (label === "Dashboard") {
      return pathname === link || pathname === "/";
    }
    return pathname === link;
  };

  return (
    <StyledMenu>
      <StyledSideNav>
        <div className="sidenav">
          {structure.map((item, index) => {
            return (
              <>
                { (role && item.accessRoles.includes(role)) ? 
                <StyledLink
                  style={{
                    pointerEvents: item.isDisable ? "none" : "visible"
                  }}
                  to={item.link}
                  className="navlink"
                  key={item.id}
                  onClick={e => {
                    setActive(true);
                  }}
                  title={item.label}
                  isActive={checkActive(item.link, item.label)}
                  activeIcon={require(`../../assets/img/${item.active}.png`)}>
                  <img
                    className="icon"
                    alt={item.label}
                    src={require(`../../assets/img/${item.icon}.png`)}
                  />

                  <SpanText isOpen={open}>{item.label}</SpanText>
                </StyledLink>
                :
                <></>
                }
              </>
            );
          })}
        </div>
      </StyledSideNav>
    </StyledMenu>
  );
};

export default Sidebar;
