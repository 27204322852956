import { api } from "../utils/http";

export const initialState = {
  error: "",
  status: {},
  appuser: {},
  apppage: [],
  organizations: [],
  organizationDetails: [],
  page: []
};
export const reducers = {
  organizationList(state, payload) {
    return {
      ...state,
      organizations: payload.data.organizations,
      page: payload.data.page
    };
  },
  organizationDetails(state, payload) {
    return {
      ...state,
      organizationDetails: payload.data
    };
  },
  appUsers(state, payload) {
    return {
      ...state,
      appuser: payload.data.users,
      apppage: payload.data.page
    };
  },
  addError(state, payload) {
    return {
      ...state,
      error: payload
    };
  },
  removeError(state) {
    return {
      ...state,
      error: ""
    };
  }
};

export const effects = {
  async getOrganizationlist(payload, state, params) {
    let url = `api/v1/organization/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}`;
    this.removeError();

    if (typeof payload.sort === "boolean") {
      url = `${url}${
        payload.sort === true ? "&sort_by=-created_at" : "&sort_by=created_at"
      }`;
    }
    if (payload.search) {
      url = `${url}&search=${payload.search}${
        payload.sort === true ? "&sort_by=-created_at" : "&sort_by=created_at"
      }`;
    }
    try {
      const res = await api
        .get(url)
        .then(response => {
          this.organizationList(response.data);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async getOrganizationlistDetails(payload, state, params) {
    this.removeError();
    try {
      const res = await api
        .get(`/api/v1/organization/${payload.id}/`)
        .then(response => {
          this.organizationDetails(response.data.data);
        })
        .catch(err => {
          this.addError(err.toString());
        });
    } catch (err) {
      this.addError(err.toString());
    }
  },
  async exportToExcelOrganization({ payload, enqueueSnackbar, setOpenDialog }) {
    try {
      const res = await api
        .post(`/api/v1/organization/export/`, payload)
        .then(response => {
          let variant = "success";
          window.open(response.data.data.url);
          enqueueSnackbar(response.data.message, {
            variant
          });
          setOpenDialog(false);
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
          if (
            err.response.data.message == "Fields: This list may not be empty."
          ) {
            enqueueSnackbar(
              "Please check any of the above for generating the excel",
              {
                variant
              }
            );
          } else if (
            err.response.data.message === "Users: This list may not be empty."
          ) {
            enqueueSnackbar(
              "Please select atleast one user  for generating the excel",
              {
                variant
              }
            );
          }

          //  this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      //  this.addError(err);
      // if (err.response.data.message === "Users: This list may not be empty.") {
      //   enqueueSnackbar("Please select atleast one user", {
      //     variant
      //   });
      // }

      //  this.removeSuccess();
    }
  },
  async getOrganizationlistAppUsers(payload, state, params) {
    if (payload.id !== undefined) {
      this.removeError();
      let URL = `/api/v1/user/app_user/?pagination=1&limit=${
        payload.rowsPerPage
      }&offset=${payload.offset}${
        payload.id ? `&organization_id[]=${payload.id}` : ``
      }${
        payload.sortAppuser
          ? `&sort_by=-recent_sync_on`
          : `&sort_by=recent_sync_on`
      }`;
      if (payload.search) {
        URL = `/api/v1/user/app_user/?pagination=1&limit=${
          payload.rowsPerPage
        }&offset=${payload.offset}${
          payload.id ? `&organization_id[]=${payload.id}` : ``
        }${
          payload.sortAppuser
            ? `&sort_by=-recent_sync_on`
            : `&sort_by=recent_sync_on`
        }&search=${payload.search}`;
      }
      if (payload.subtype) {
        URL = `/api/v1/user/app_user/?pagination=1&limit=${
          payload.rowsPerPage
        }&offset=${payload.offset}${
          payload.id ? `&organization_id[]=${payload.id}` : ``
        }${
          payload.sortAppuser
            ? `&sort_by=-recent_sync_on`
            : `&sort_by=recent_sync_on`
        }${
          payload.subtype !== "0" ? `&user_status=${payload.subtype}` : ""
        }&search=${payload.search}`;
      }
      if (payload.mobileStatusFilter && payload.mobileStatusFilter !== "0") {
        URL = `${URL}&connected_to=${payload.mobileStatusFilter}`;
      }
      try {
        const res = await api
          .get(URL)
          .then(response => {
            this.appUsers(response.data);
          })
          .catch(err => {
            this.addError(err.toString());
          });
      } catch (err) {
        this.addError(err.toString());
      }
    }
  },

  async createOrganizations(
    payload,
    state,
    { enqueueSnackbar, clear, history }
  ) {
    let URL = `/api/v1/organization/`;
    try {
      const res = await api.post(URL, payload).then(response => {
        let variant = "success";
        enqueueSnackbar("Created organization.", {
          variant
        });
        clear();
        history.push("/organizations");
      });
    } catch (err) {
      let variant = "error";
      this.addError();
      enqueueSnackbar(err.response.data.message, {
        variant
      });
    }
  },
  async patchOrganizations(payload, state, { enqueueSnackbar, id, history }) {
    let URL = `/api/v1/organization/${id}/`;
    try {
      const res = await api.patch(URL, payload).then(response => {
        let variant = "success";
        enqueueSnackbar("Edited organization successfully.", {
          variant
        });
        history.push("/organizations");
      });
    } catch (err) {
      let variant = "error";
      this.addError();
      enqueueSnackbar(err.response.data.message, {
        variant
      });
    }
  },
  async patchUserOrganization({
    payload,
    enqueueSnackbar,
    username,
    getUserInfo
  }) {
    try {
      const res = await api
        .patch(`/api/v1/user/app_user/${username}/`, payload)
        .then(response => {
          getUserInfo(username, { detailed: true });
          let variant = "success";
          enqueueSnackbar("Edited successfully", {
            variant
          });
        })
        .catch(err => {
          let variant = "error";
          this.addError(err);
          enqueueSnackbar(err.response.data.message, {
            variant
          });
          this.removeSuccess();
        });
    } catch (err) {
      let variant = "error";
      this.addError(err);
      enqueueSnackbar(err.response.data.message, {
        variant
      });
      this.removeSuccess();
    }
  }
};
export default {
  state: initialState,
  reducers,
  effects
};
//` api/v1/organization/?pagination=1&limit=${payload.rowsPerPage}&offset=${payload.offset}&sort_by=name`
