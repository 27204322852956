import moment from "moment";

export const validateFromAndToDate = (fromDate, toDate) => {
  const currentDate = new Date();
  if (fromDate && toDate) {
    const convertedFromDate = new Date(fromDate);
    const convertedToDate = new Date(toDate);
    if (convertedToDate - convertedFromDate === 0) {
      return "Same date not allowed.";
    } else if (
      convertedFromDate > currentDate ||
      convertedToDate > currentDate
    ) {
      return "Future date-time not allowed";
    } else if (convertedFromDate > convertedToDate) {
      return "From date should not be after To date.";
    }
  }
  return "";
};
