import React from "react";
import {
  WatchlistTableHeader,
  WatchlistTableHeaderViewProfile,
  WatchlistTableHeaderViewProfileText,
  WatchlistTableHeaderStatusText,
  WatchlistTableHeaderStatusOvalOnline,
  WatchlistTableHeaderTitle
} from "./watchlist.style";
import Table from '../../components/Table/Generic'
function UserTable({ userData, history, activityData }) {
  return (
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", marginTop: "30px", marginBottom: "5px" }}>
        <WatchlistTableHeader>
          {userData && userData.user && userData.user.username}
        </WatchlistTableHeader>
        {userData && userData.user && userData.user.username && (
          <>
            <WatchlistTableHeaderViewProfile>
              <WatchlistTableHeaderViewProfileText
                onClick={() => {
                  history.push(
                    `/${userData && userData.user && userData.user.username}`
                  );
                }}>
                VIEW PROFILE
              </WatchlistTableHeaderViewProfileText>
            </WatchlistTableHeaderViewProfile>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <WatchlistTableHeaderStatusText>
                STATUS
              </WatchlistTableHeaderStatusText>
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  minWidth: "198px"
                }}>
                <WatchlistTableHeaderStatusOvalOnline
                  isOnline={
                    userData && userData.user && userData.user.is_online
                  }></WatchlistTableHeaderStatusOvalOnline>
                <WatchlistTableHeaderTitle>
                  {userData && userData.user && userData.user.is_online
                    ? "Online"
                    : "Offline"}
                </WatchlistTableHeaderTitle>
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <WatchlistTableHeaderStatusText>
                CURRENT LOCATION
              </WatchlistTableHeaderStatusText>
              <WatchlistTableHeaderTitle>
                {userData &&
                userData.user &&
                userData.user.location &&
                userData.user.location.location.latitude !== null &&
                userData.user.location.location.longitude !== null
                  ? `${userData.user.location.location.latitude},${userData.user.location.location.longitude}`
                  : ""}
              </WatchlistTableHeaderTitle>
            </div>
          </>
        )}
      </div>
      {userData && userData.reason && (
        <div
          title={userData.reason}
          style={{
            cursor: "pointer",
            display: "inline-block",
            textOverflow: "ellipsis",
            width: "calc(100% - 20px)",
            marginBottom: "5px",
            whiteSpace: "nowrap",
            overflow: "hidden"
          }}>
          Reason:{userData.reason}
        </div>
      )}

      <Table tableData={activityData} />
    </div>
  );
}

export default UserTable;
