import styled from 'styled-components';
import { DatePicker } from '@material-ui/pickers';

const DatePickerStyled = styled(DatePicker)`
  margin: 0px 0px 0px 0px !important;
  width: 142px;
  height: 39px;
  border-radius: 8px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.04);
  border: ${props=>props.border? props.border : 'solid 1px rgba(71, 59, 59, 0.2)'} !important;
  background-color:  ${props=>props.bgcolor? props.bgcolor : '#ffffff'};
  input {
    margin: 10px;
    width: 100%;
    height: 16px;
    font-family: Google Sans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    // line-height: normal;
    letter-spacing: normal;
    color: #999999;
    // padding-top: 5px;
    padding: 0px;
    line-height: 17px;
    pointer-events: none;
  }
  .Mui-error input {
    padding-top: 41px;
  }
  .MuiFormHelperText-root.Mui-error{
    padding-top: 38px;
  }
  .Mui-error .MuiInputAdornment-positionEnd{
    padding-top: 38px;
  }
`;

export default DatePickerStyled;