import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Divider
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
// styles
import { useStyles, PasswordFieldWrapper } from "./login.styles";
import { TextFieldWrapper } from "../../styles/Wrapper.style";
// context
import {
  useUserDispatch,
  loginUser,
  useUserState
} from "../../context/UserContext";
import Logo from "../../assets/img/logologin.png";

function Login(props) {
  const classes = useStyles();

  // global
  const userDispatch = useUserDispatch();

  const userState = useUserState();

  // local
  const [isLoading, setIsLoading] = useState(false);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [passwordPlaceholder, setPasswordPlaceholder] = useState(
    "enter your password"
  );
  const [usenamePlaceholder, setUsernamePlaceholder] = useState(
    "enter your username"
  );

  const onKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      loginUser(
        userDispatch,
        loginValue,
        passwordValue,
        props.history,
        setIsLoading
      );
    }
  };

  return (
    <Grid container className={classes.container} justify="space-around">
      <section style={{ display: "flex", padding: "20px" }}>
        <div style={{ flexDirection: "column" }}>
          <div className={classes.logo}>
            <img src={Logo} alt="Riskovery" />
          </div>
          <div className={classes.typo}>
            <Typography
              color="secondary"
              style={{
                maxWidth: "146px"
              }}
              className={classes.WelcomeBackPlease}>
              Welcome back,
            </Typography>
            <Typography
              color="secondary"
              style={{
                maxWidth: "400px",
                margin: " 0px 0px 0px 126px"
              }}
              className={classes.WelcomeBackPlease}>
              please login to your account.
            </Typography>
          </div>
          <div className={classes.form}>
            <div className={classes.loginbox}>
              <>
                {userState.error ? (
                  <Fade in={userState.error}>
                    <Typography
                      color="secondary"
                      className={classes.errorMessage}>
                      Unable to log in with provided credentials
                    </Typography>
                  </Fade>
                ) : (
                  ""
                )}
                <Typography
                  className={classes.caption}
                  variant="caption"
                  display="block"
                  gutterBottom>
                  Username
                </Typography>
                <TextFieldWrapper>
                  <TextField
                    id="username"
                    // disableUnderline={true}

                    autoFocus={false}
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField
                      },
                      disableUnderline: true
                    }}
                    menuprops={{
                      disableAutoFocusItem: true
                    }}
                    value={loginValue}
                    onChange={e => {
                      setLoginValue(e.target.value);
                    }}
                    onFocus={e => {
                      setUsernamePlaceholder("");
                    }}
                    onBlur={e => {
                      setUsernamePlaceholder("enter your username");
                    }}
                    margin="normal"
                    placeholder={usenamePlaceholder}
                    type="username"
                    fullWidth
                  />
                </TextFieldWrapper>
                <Divider />
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div className={classes.caption}>Password</div>
                  <PasswordFieldWrapper>
                    <TextField
                      id="password"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField
                        },
                        disableUnderline: true
                      }}
                      value={passwordValue}
                      onFocus={e => {
                        setPasswordPlaceholder("");
                      }}
                      onBlur={e => {
                        setPasswordPlaceholder("enter your password");
                      }}
                      onChange={e => {
                        setPasswordValue(e.target.value);
                      }}
                      margin="normal"
                      placeholder={passwordPlaceholder}
                      type="password"
                      fullWidth
                      onKeyDown={onKeyDown}
                    />
                  </PasswordFieldWrapper>
                </div>
              </>
            </div>
          </div>
          <div
            className={classes.formButtons}
            style={{ display: "flex", justifyContent: "center" }}>
            {isLoading ? (
              <CircularProgress size={26} className={classes.loginLoader} />
            ) : (
              <Button
                style={{
                  width: "146px",
                  height: "44px",
                  borderRadius: "8px",
                  backgroundColor: "#ff2d21"
                }}
                disabled={loginValue.length === 0 || passwordValue.length === 0}
                onClick={() =>
                  loginUser(
                    userDispatch,
                    loginValue,
                    passwordValue,
                    props.history,
                    setIsLoading
                  )
                }
                variant="contained"
                // color="primary"
                size="large">
                <span className={classes.textButton}>Sign In</span>
              </Button>
            )}
          </div>
        </div>
      </section>
    </Grid>
  );
}

export default withRouter(Login);
