import React, { useEffect } from "react";
import { Grid, Paper, FormControl, InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import BackIcon from "../../assets/img/reddropdown.png";
import DateRangePicker from "../DateRangePicker";
import DateTimePicker from "../DateTimePicker";
import Select from "./components/Select";
import { useStyles } from "./geospatial.style";

const Filter = ({
  handleClickOpenMassText,
  handleDeviceChange,
  handleClickOpenMassPush,
  userList,
  organizations,
  handleFilterChange,
  organization,
  user,
  handleOrgChange,
  handleUserChange,
  selectedDateFrom,
  selectedDateTo,
  handleFromDate,
  handleToDate,
  isFilterDisable,
  handleClickOpenWatchList,
  errorMsg,
  initailRender,
  device,
  DEVICEFILTER,
  devices
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const [shape, setShape] = React.useState(["0"]);

  const [selectedDate, setDateChange] = React.useState(new Date());
  const [anchorElRange, setAnchorEl] = React.useState(null);
  const handleClickRange = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleDateChange = e => {
    setDateChange(e);
  };

  const handleCloseRange = () => {
    setAnchorEl(null);
  };

  const anchorRef = React.useRef(null);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    }
  }
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  return (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          marginLeft: "24px",
          width: "100%",
          paddingRight: "17px",
          justifyContent: "space-between"
        }}>
        {!device ? (
          <div
            style={{
              width: "490px",
              display: "flex"
            }}>
            <Select
              isFilterDisable={isFilterDisable}
              classesParent={classes}
              data={organizations}
              valueProp={organization}
              onChange={handleOrgChange}
              label="Organizations"
              multiple={true}
            />

            <Select
              isFilterDisable={isFilterDisable}
              classesParent={classes}
              data={userList}
              valueProp={user}
              onChange={handleUserChange}
              label="Users"
              multiple={true}
            />
          </div>
        ) : (
          <div
            style={{
              width: "240px",
              display: "flex"
            }}>
            <Select
              isFilterDisable={isFilterDisable}
              classesParent={classes}
              data={DEVICEFILTER}
              valueProp={devices}
              onChange={handleDeviceChange}
              label="Devices"
            />
          </div>
        )}

        <div
          style={{
            display: "flex",
            minWidth: "840px",
            justifyContent: "space-between"
          }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div
              style={{
                display: "flex",
                minWidth: "640px",
                justifyContent: "space-between",
                alignItems: "center"
              }}>
              <span
                style={{
                  // margin: " 17px 0px 0px 4px",
                  width: "28px",
                  height: "16px",
                  fontFamily: "Google Sans",
                  fontSize: "12px",
                  fontHeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: "rgb(10, 10, 10)"
                }}>
                From
              </span>
              <div className={classes.dateTimePicker}>
                <DateTimePicker
                  disabled={isFilterDisable}
                  selectedDate={selectedDateFrom}
                  handleDateChange={handleFromDate}
                  disableFuture
                />
              </div>
              <span
                style={{
                  // margin: " 17px 0px 0px -8px",
                  marginLeft: "11px",
                  width: "15px",
                  height: "16px",
                  fontFamily: "Google Sans",
                  fontSize: "12px",
                  fontHeight: "normal",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  color: "rgb(10, 10, 10)"
                }}>
                To
              </span>
              <div className={classes.dateTimePicker}>
                <DateTimePicker
                  disabled={isFilterDisable}
                  selectedDate={selectedDateTo}
                  handleDateChange={handleToDate}
                  disableFuture
                />
              </div>
            </div>
            {errorMsg && !initailRender && (
              <div className={classes.errorMessage}>{errorMsg}</div>
            )}
          </div>
          <div
            className={classes.orgSelect}
            style={{
              display: "flex",
              cursor: "pointer",
              margin: "5px 0px 0px 0px",
              pointerEvents: isFilterDisable || device ? "none" : "visible"
            }}
            ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            
            >
            <span className={classes.moreActionsText}>More Actions</span>
            <img src={BackIcon} className={classes.imgOpen} />
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              style={{ zIndex: "10" }}
              disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}>
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}>
                        <MenuItem onClick={handleClickOpenWatchList}>
                          Add to watchlist
                        </MenuItem>
                        <MenuItem onClick={handleClickOpenMassText}>
                          Send Mass text
                        </MenuItem>
                        <MenuItem onClick={handleClickOpenMassPush}>
                          Push notifications
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Filter;
