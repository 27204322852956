import { makeStyles } from "@material-ui/core/styles";
import { textAlign } from "@material-ui/system";
export const useStyles = makeStyles(theme => ({
  root: {
    margin: "-12px 0px 0px 0px",
    zIndex: "100",
    width: "322px",
    height: " 740px",
    borderRadius: "0px 13px 12px 0px",
    boxShadow: " -9px 0 14px 0 rgba(0, 0, 0, 0.04)",
    backgroundColor: "#ffffff",
    position: "absolute",
    right: "0px",
    top: "12px",
    bottom: "2px"
  },
  flex: {
    display: "flex",
    flexDirection: "column"
  },
  flexSection: {
    display: "flex",
    flexDirection: "column",
    margin: "21px 0px 0px 12px"
  },
  flexSectionContent: {
    overflow: "scroll",
    height: "673px",
    overflowX: "hidden"
  },
  onlineOval: {
    width: "8px",
    height: "11px",
    backgroundColor: "#4cd964",
    padding: "0px 0px 0px 10px",
    borderRadius: "30px",
    margin: "9px"
  },
  offlineOval: {
    width: "8px",
    height: "11px",
    backgroundColor: "rgb(255, 45, 33)",
    padding: "0px 0px 0px 10px",
    borderRadius: "30px",
    margin: "9px"
  },
  detailsWidth: {
    width: "80px",
    height: "35px",
    objectFit: " contain"
  },
  wrapper: {
    width: 100 + theme.spacing(2)
  },
  paper: {
    zIndex: 1,
    position: "relative",
    margin: theme.spacing(1)
  },
  userText: {
    width: "150px",
    height: "30px",
    fontFamily: "Google Sans",
    fontSize: "24px",
    fontHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: " normal",
    letterSpacing: " normal",
    color: "#161a1e",
    display: "flex"
  },
  lastUpdated: {
    width: "274px",
    height: "13px",
    opacity: "0.48",
    fontFamily: "Google Sans",
    fontSize: "15px",
    fontHeight: " 500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #000000"
  },
  pushNotification: {
    width: "120px",
    height: "25px",
    borderRadius: "100px",
    border: "solid 1px #60bca7",
    textAlign: "center",
    marginTop: "24px"
  },
  pushNotificationText: {
    width: "99px",
    height: "13px",
    fontFamily: "Google Sans",
    fontSize: "10px",
    fontHeight: "500",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: "#60bca7"
  },
  path: {
    width: "282px",
    height: "1px",
    border: "solid 1px #d1d1d6",
    margin: "31px 3px 2px 13px"
  },
  detailSub: {
    //  height: " 35px",
    objectFit: "contain",
    display: "flex",
    flexDirection: "column",
    padding: "14px 0px 0px 26px",
    margin: "0 0px 0px 3px"
  },
  detailHeading: {
    width: "122px",
    height: "13px",
    opacity: " 0.7",
    fontFamily: "Google Sans",
    fontSize: "10px",
    fontHeight: "500",
    fontStretch: " normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #161a1e"
  },
  detailContent: {
    width: "80px",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #161a1e"
  },
  detailContentw: {
    width: "200px",
    fontFamily: "Google Sans",
    fontSize: "12px",
    fontHeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: " #161a1e"
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  Heading: {
    display: "flex",
    margin: "0px 0px 0px 18px",
    height: "30px",
    fontFamily: "Google Sans",
    fontSize: "17px",
    fontWeight: "normal",
    fontStretch: " normal",
    fontStyle: " normal",
    lineHeight: " normal",
    letterSpacing: "normal",
    color: "#161a1e"
  },
  noRecentActivity: {
    textAlign: "center"
  }
}));
