import React from "react"
import { Switch, Route } from 'react-router-dom'
import CustomAlert from "../components/Alert"
import Roles from "../config/Roles"

//utilities and helpers
import { getCookie } from "../utils/CookieHelper"

export default function MapFilteredRoutes({routes}) {
	const userRole = getCookie('roles') ? getCookie('roles') : ''
	const isSuperAdmin = (userRole && userRole.includes(Roles.SUPER_ADMIN) || userRole.includes(Roles.RISKOVERY_ADMIN)) ?  true : false

	return (
		<Switch>
			{
				routes.map((route) => {
					const { path, exact, component, accessRoles, isSuperAdminOnly, ...rest } = route

					return (
						(accessRoles && accessRoles.includes(Roles.SUPER_ADMIN) || accessRoles.includes(Roles.RISKOVERY_ADMIN)) ? (
							<Route
								{...rest}
								exact={exact}
								key={path}
								path={path}
								render={props => (isSuperAdmin || !isSuperAdminOnly) ? (
									React.createElement(component, props)
								) : (
									<CustomAlert
										buttonText={'BACK TO DASHBOARD'}
										alertMessage={'You do not have access to this page. Please go back to the dashboard.'}
									/>
								)
								}
							/>
						) : (
							<Switch>
								<Route {...rest} exact={exact} key={path} path={path} component={component}/>
							</Switch>
						)
					)
				})
			}
		</Switch>
	)
}