import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

import { useStyles } from "./DialogTable.style";
import Table from "./Table";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export default function DialogSlideTable({
  open,
  handleClose,
  state,
  userInfo,
  isCallLogLoading,
  getCallLog,
  userId
}) {
  const classes = useStyles();
  const { callLogs, callLogPage, callLogCount } = state;
  return (
    <div>
      {open && (
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description">
          <div style={{ display: "flex" }}>
            {handleClose ? (
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            ) : null}
          </div>
          <DialogContent
            style={{
              marginTop: "10px"
            }}>
            <section>
              <div className={classes.heading}>Call Log</div>
            </section>
              <Table
                userInfo={userInfo}
                call={callLogs}
                currentPage={callLogPage}
                totalCount={callLogCount}
                getCallLog={getCallLog}
                userId={userId}
                isCallLogLoading={isCallLogLoading}
              />
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
