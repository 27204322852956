import React, { useReducer } from "react";
import { Grid } from "@material-ui/core";
import MenuList from "@material-ui/core/MenuList";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import { get, groupBy } from "lodash";
import TextField from "../TextField";
import { useStyles } from "./countryMaterial.style";
import userIcon from "../../assets/img/user.png";
import icon from "../../assets/img/search2x.png";
import path from "../../assets/img/path.png";
import { SpanCountryText, StyledCountryBar } from "./Country.style";
import { TextFieldWrapper } from "../../styles/Wrapper.style";

const initialState = {};

const reducer = (state, newState) => ({
  ...state,
  country: newState.country,
  users: newState.users
});
const UserList = ({
  users,
  classes,
  stateData,
  userData,
  history,
  filtered,
  searchValue
}) => {
  return (
    <>
      {stateData !== undefined && (
        <div>
          <SpanCountryText className={classes.countryLongText}>
            {stateData.country !== undefined ? (
              <div>
                <span>
                  {stateData.country.length > 0 && stateData.users.length > 0
                    ? `${stateData.country} : Online Users ( ${stateData.users.length} )`
                    : ""}
                </span>
                <span className={classes.spanOvalOnline}></span>
                <div>
                  {stateData.country.length > 0 &&
                  stateData.users.length > 0 ? (
                    <span className={classes.spanOvalOnline}></span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </SpanCountryText>
          <div style={{ overflowY: "auto", height: "310px" }}>
            {stateData.users !== undefined &&
              stateData.users.length > 0 &&
              stateData.users.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      marginTop: "25px",
                      paddingBottom: "10px",
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      history.push(`/${item}`);
                    }}>
                    <div className={classes.userIconOval}>
                      <img style={{ margin: "6px" }} src={userIcon} />
                    </div>
                    <div className={classes.userOvalText}>
                      {Object.values(item)}
                    </div>
                  </div>
                );
              })}
            {stateData.users !== undefined &&
              stateData.users.length === 0 &&
              searchValue === "" &&
              "No Online Users Present"}
            {searchValue !== "" && filtered.length === 0 && "No records found"}
          </div>
        </div>
      )}
    </>
  );
};

export default function DialogCountryFilter(props) {
  const [active, setActive] = React.useState(true);
  const [state, setState] = useReducer(reducer, initialState);
  const [Value, setValue] = React.useState("");
  const classes = useStyles();
  const { userdata, title } = props;
  const filterd = userdata.filter(user => {
    return user.username.toLowerCase().indexOf(Value.toLowerCase()) !== -1;
  });

  const userdatafilter = filterd.length > 0 ? filterd : [];
  const groupedList = groupBy(userdatafilter, acc =>
    get(acc, "country_details.name", "Unknown")
  );

  const dataforountrylist = Object.entries(groupedList).map(
    ([country, users]) => ({ user: users.map(user => user.username), country })
  );

  const onKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      setValue(event.target.value);
    }
  };
  React.useEffect(() => {
    setState({
      country: dataforountrylist.length > 0 ? dataforountrylist[0].country : [],
      users: dataforountrylist.length > 0 ? dataforountrylist[0].user : []
    });
  }, [setState, JSON.stringify(userdatafilter)]);
  return (
    <Paper className={classes.root} style={{ overflow: "hidden" }}>
      <Grid container>
        <Grid
          style={{
            display: "flex",
            margin: "40px 20px 0px 20px"
            // justifyContent: "space-evenly"
          }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <span
              style={{
                fontSize: "26px",
                // margin: "10px 14px -2px -66px",
                fontFamily: "Google Sans !important"
              }}>
              {title}
            </span>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <div className={classes.searchMargin}>
              <TextFieldWrapper>
                <TextField
                  error={false}
                  classes={classes}
                  Value={Value}
                  setValue={setValue}
                  placeHolder="Search..."
                  width="fullwidth"
                  type="Text"
                  id="search"
                  margin="normal"
                  variant="outlined"
                  icon={icon}
                  onKeyDown={onKeyDown}
                />
              </TextFieldWrapper>
            </div>
          </Grid>
        </Grid>
        <Grid
          style={{ display: "flex" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}>
          <StyledCountryBar className={classes.countryTestMenuItem}>
            <div className={classes.countryText}>COUNTRY</div>
          </StyledCountryBar>
        </Grid>
        <Grid
          style={{ display: "flex", height: "460px" }}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}>
          <Grid
            item
            xs={12}
            sm={4}
            md={4}
            lg={4}
            xl={4}
            style={{
              overflowY: "auto"
            }}>
            <MenuList
              style={{
                margin: "0px 0px 0px 25px",
                paddingTop: "0px"
              }}>
              {dataforountrylist.map((item, index) => {
                return (
                  <div key={index} className={classes.countryMenuStyle}>
                    <MenuItem
                      className={classes.menuItemRoot}
                      style={{
                        width: "263px",
                        height: " 91px",
                        backgroundImage:
                          "linear-gradient(to left, #f8f8fa 44%, #ffffff 0%);",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        textAlign: " inherit"
                      }}
                      key={index}
                      onClick={() => {
                        setActive(true);
                        setState({
                          country: item.country,
                          users: item.user
                        });
                      }}
                      divider
                      disableGutters={false}>
                      <div>
                        <span
                          className={
                            state.country === item.country
                              ? classes.highlightCountrySpan
                              : ""
                          }>
                          {item.country}
                        </span>
                        <span className={classes.spanTextUser}>
                          {item.user.length > 0 ? (
                            <div>
                              Users :
                              <span className={classes.countText}>
                                {item.user.length}
                              </span>
                            </div>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                      <img src={path} />
                    </MenuItem>
                    {/* <Divider /> */}
                  </div>
                );
              })}
              {/* <div className={classes.pathLine}></div> */}
            </MenuList>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              flexDirection: "column",
              padding: "0px 0px 0px 30px"
            }}>
            <UserList
              users={state.users}
              stateData={state}
              filtered={filterd}
              searchValue={Value}
              classes={classes}
              history={props.history}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}
